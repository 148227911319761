import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getApiRoute, routes } from '../../config/routes';
import {
  BackLink,
  Layout,
  Link,
  Loader,
  Modal,
  Notification
} from '../../components';
import { Anchor, Box, Button, CheckBox, Heading, Text } from 'grommet';
import {
  Add,
  Document,
  FormCheckmark,
  FormClose,
  StatusInfo,
  Trash,
  View
} from 'grommet-icons';
import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT } from '../../config/site';
import Auth from '../../services/auth';
import { FLASH_TYPES, useFlash } from '../../hooks';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  CellActionsFormatter,
  getFilterRenderer,
  ToolbaredTable
} from '../../components/DataTable';
import { SAVE_OPTION } from '../../redux/app';
import { FormatUtils } from '../../utils/FormatUtils';

const TRADER_STATUS = {
  active: 'ACTIVE',
  disabled: 'DISABLED'
};

const TABS = {
  all: 'all',
  live: 'live',
  demo: 'demo'
};

function getEndpoint(key) {
  const endpoints = {
    addTrader: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'Add').path,
    viewTrader: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'View').path,
    viewPartner: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Partners')
      .childs.find(route => route.name === 'View').path,
    broker: routes.find(route => route.name === 'Broker').path,
    traders: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders').path
  };
  return endpoints[key];
}

const Traders = props => {
  // Load hidden column indexes from the storage (global state)
  const hiddenColumns =
    useSelector(
      state => state.app.storage.options.tradersTableCols,
      shallowEqual
    ) || props.hiddenColumns;
  const [rows, setRows] = useState(props.traders);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    itemToRemove: null
  });
  const [flash, showFlash, dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );
  const [modal, showModal, dismissModal] = useFlash();

  const currentTab = useMemo(() => {
    const type = FormatUtils.getUrlParameter(
      'type',
      window.location
    ).toLowerCase();
    switch (type) {
      case TABS.demo:
      case TABS.live:
        return type;
      default:
        return TABS.all;
    }
  }, [props]);

  useEffect(() => {
    let rows = props.traders;
    if (currentTab === TABS.demo || currentTab === TABS.live) {
      rows = props.traders.filter(r => {
        return currentTab === TABS.demo ? r.hasDemoAccount : !r.hasDemoAccount;
      });
    }
    setRows(rows);
  }, [props.traders, currentTab]);

  const columns = useMemo(() => {
    return [
      {
        key: 'mt_id',
        name: 'MT ID',
        width: 200,
        filterRenderer: getFilterRenderer('text'),
        formatter({ row }) {
          const actions = [
            {
              icon: <View size="small" color="status-ok" />,
              text: 'View',
              callback: () =>
                props.history.push(
                  getEndpoint('viewTrader').replace(':id', row.userId)
                )
            },
            {
              icon: <Trash size="small" color="status-critical" />,
              text: 'Delete',
              callback: () => {
                showModal();
                return setState({
                  ...state,
                  itemToRemove: row
                });
              }
            },
            {
              icon: <StatusInfo size="small" color="status-warning" />,
              text: `Set ${
                row.status !== TRADER_STATUS.active
                  ? TRADER_STATUS.active
                  : TRADER_STATUS.disabled
              }`,
              title: `Set ${
                row.status !== TRADER_STATUS.active
                  ? TRADER_STATUS.active
                  : TRADER_STATUS.disabled
              } status`,
              callback: () =>
                updateTrader(row.id, {
                  status:
                    row.status !== TRADER_STATUS.active
                      ? TRADER_STATUS.active
                      : TRADER_STATUS.disabled
                })
            },
            {
              icon: <Document size="small" color="brand" />,
              text: `Documents ${
                !row.documentsProvided ? 'PROVIDED' : 'NOT PROVIDED'
              }`,
              title: `Set documents as ${
                !row.documentsProvided ? 'PROVIDED' : 'NOT PROVIDED'
              }`,
              callback: () =>
                updateTrader(row.id, {
                  documentsProvided: !row.documentsProvided ? 1 : 0
                })
            }
          ];

          return (
            <>
              <CellActionsFormatter actions={actions} />
              <div>{row.mt_id}</div>
            </>
          );
        }
      },
      {
        key: 'email',
        name: 'Email',
        width: 200,
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'phone',
        name: 'Phone',
        width: 200,
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'name',
        name: 'Name',
        width: 200,
        filterRenderer: getFilterRenderer('text'),
        formatter({ row }) {
          return (
            <Anchor
              title="Show user"
              target="_blank"
              href={getEndpoint('viewTrader').replace(':id', row.userId)}
              color="brand"
              label={<Text weight="bold">{row.name}</Text>}
            />
          );
        }
      },
      {
        key: 'referredBy',
        name: 'Referred by',
        filterRenderer: getFilterRenderer('text'),
        formatter: ({ row }) =>
          !row.referredBy ? null : (
            <Anchor
              title="Show partner"
              target="_blank"
              href={getEndpoint('viewPartner').replace(
                ':id',
                row.referer.userId
              )}
              color="brand"
              label={<Text weight="bold">{row.referer.name}</Text>}
            />
          )
      },
      {
        key: 'totalDeposits',
        name: 'Total deposits'
      },
      {
        key: 'documentsProvided',
        name: 'Documents OK',
        width: 40,
        formatter: ({ row }) => (
          <Box height="full" justify="center">
            <CheckBox checked={row.documentsProvided} disabled />
          </Box>
        )
      },
      {
        key: 'documents',
        name: 'Documents',
        formatter({ row }) {
          const values = row.documents.nodes;
          return values === 0 ? (
            <Text>No documents</Text>
          ) : (
            <Box gap="small" direction="row-responsive" align="center">
              {values.map(document => (
                <Box
                  key={document.id}
                  gap="xsmall"
                  className="text-ellipsis"
                  align="center"
                  direction="row-responsive"
                >
                  <Anchor
                    target="_blank"
                    title="Download file"
                    style={{ display: 'flex' }}
                    href={getApiRoute('downloadDocument', [
                      Auth.getAuthToken(),
                      document.document_id
                    ])}
                    label={document.name}
                  />
                  {!document.status ||
                  document.status.toLowerCase() !== 'approved' ? (
                    <Anchor
                      target="_blank"
                      style={{ display: 'flex' }}
                      title="Set status as approved"
                      href={getApiRoute('setDocumentStatus', [
                        Auth.getAuthToken(),
                        document.document_id,
                        'APPROVED'
                      ])}
                      label={<FormCheckmark color="status-ok" />}
                    />
                  ) : (
                    <Text color="status-ok">Approved</Text>
                  )}
                  <Anchor
                    target="_blank"
                    style={{ display: 'flex' }}
                    title="Reject and remove document"
                    href={getApiRoute('setDocumentStatus', [
                      Auth.getAuthToken(),
                      document.document_id,
                      'REJECTED'
                    ])}
                    label={<FormClose color="status-critical" />}
                  />
                </Box>
              ))}
            </Box>
          );
        }
      },
      {
        key: 'registeredDate',
        name: 'Registration date',
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        filterRenderer: getFilterRenderer('date', {
          datePicker: true,
          placeholder: 'Select Date/Range'
        }),
        formatter: ({ row }) =>
          row.registeredDate
            ? moment(row.registeredDate).format(DEFAULT_DATETIME_FORMAT)
            : null
      },
      {
        key: 'status',
        name: 'Status',
        filterRenderer: getFilterRenderer('checklist', {
          rows: [
            { label: TRADER_STATUS.active, value: 'active' },
            { label: TRADER_STATUS.disabled, value: 'disabled' }
          ]
        })
      }
    ].map(col => {
      return {
        ...col,
        sortable: true,
        resizable: true
      };
    });
  }, []);

  const hideColumns = nextState => {
    dispatch({ type: SAVE_OPTION, key: 'tradersTableCols', value: nextState });
  };

  const updateTrader = async (id, data = {}) => {
    await props
      .updateItem({
        id: id,
        ...data
      })
      .then(
        () => {
          showFlash(`Trader updated`, FLASH_TYPES.OK);
          return true;
        },
        error => {
          showFlash("Error changing trader's data", FLASH_TYPES.CRITICAL);
          return false;
        }
      );
  };

  const removeTrader = async item => {
    if (!item || !item.id) {
      return;
    }

    await props.removeTrader({ id: item.id }).then(
      () => {
        showFlash(`Trader ${item.email} removed`, FLASH_TYPES.OK);
        setState({
          ...state,
          itemToRemove: null
        });
        return true;
      },
      error => {
        showFlash('Error removing trader', FLASH_TYPES.CRITICAL);
        setState({
          ...state,
          itemToRemove: null
        });
        return false;
      }
    );
  };

  const { itemToRemove } = state;
  const { loading } = props;

  return (
    <Layout title="Traders" fluid>
      <BackLink link={getEndpoint('broker')} />

      <Box direction="row" justify="between" margin={{ vertical: 'medium' }}>
        <Box direction="row" align="center">
          <Heading margin={{ right: 'small', vertical: 'none' }} size="small">
            My Traders
          </Heading>

          <Box direction="row" align="center">
            {loading && <Loader />}
          </Box>
        </Box>

        <Button
          label="Add"
          icon={<Add size="small" />}
          primary
          color="brand"
          onClick={() => {
            props.history.push(getEndpoint('addTrader'));
          }}
        />
      </Box>

      <Box direction="row" gap="small" margin={{ bottom: 'medium' }}>
        {Object.keys(TABS).map(t => {
          const active = t === currentTab;
          return (
            <Link key={t} href={`${getEndpoint('traders')}?type=${t}`}>
              <Button
                label={t}
                primary={active}
                color={active ? 'brand' : 'light-2'}
              />
            </Link>
          );
        })}
      </Box>

      {flash.visible && (
        <Notification
          type={flash.type ? flash.type : 'info'}
          onClose={dismissFlash}
          open={flash.visible}
          message={flash.text}
        />
      )}

      {modal.visible && (
        <Modal open={modal.visible} heading={'Confirm'} onClose={dismissModal}>
          <Text>
            Are you sure you want to delete trader{' '}
            {itemToRemove && itemToRemove.email}?
          </Text>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            <Button
              label="Cancel"
              onClick={dismissModal}
              disabled={loading}
              color="dark-3"
            />
            <Button
              label={
                <Text color="white">
                  <strong>Delete</strong>
                </Text>
              }
              onClick={() => {
                dismissModal();
                removeTrader(itemToRemove);
              }}
              disabled={loading}
              primary
              color="status-critical"
            />
          </Box>
        </Modal>
      )}

      <div style={{ width: `100%`, height: '100vh', position: 'relative' }}>
        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
          <ToolbaredTable
            toggleableColumns={columns}
            columns={columns}
            rows={rows}
            loading={loading}
            hiddenColumns={hiddenColumns}
            onHideColumn={hideColumns}
          />
        </div>
      </div>
    </Layout>
  );
};

Traders.propTypes = {
  traders: PropTypes.array,
  hiddenColumns: PropTypes.array,
  removeTrader: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  notification: PropTypes.object
};

Traders.defaultProps = {
  traders: [],
  hiddenColumns: [],
  loading: false,
  notification: {}
};

export default Traders;
