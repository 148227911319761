import React from 'react';
import PropTypes from 'prop-types';
import download from 'downloadjs';
import { getApiRoute, routes } from '../../config/routes';
import { BackLink, Layout, Loader, Notification } from '../../components';
import { Box, Button, Form, Heading, Text } from 'grommet';
import { useFlash } from '../../hooks';

function getEndpoint(key) {
  const endpoints = {
    broker: routes.find(route => route.name === 'Broker').path
  };
  return endpoints[key];
}

export const Exports = props => {
  const [flash, showFlash, dismissFlash] = useFlash();

  const exportTraders = async () => {
    const url = getApiRoute('exportTraders');
    return fetch(url, {
      method: 'POST',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      redirect: 'follow',
      referrer: 'no-referrer'
      //body: JSON.stringify({})
    })
      .then(response => {
        if (response.ok) {
          return response.blob();
        } else {
          throw new Error('Error during exporting');
        }
      })
      .catch(error => {
        showFlash(error.toString());
      })
      .then(file => {
        download(file, `traders_vcard.vcf`, 'text/csv');
      });
  };

  const { loading } = props;

  return (
    <Layout title="Export data">
      <BackLink link={getEndpoint('broker')} />

      <Box direction="row" justify="between" margin={{ vertical: 'medium' }}>
        <Box direction="row" align="center">
          <Heading margin={{ right: 'small', vertical: 'none' }} size="small">
            Export data
          </Heading>

          <Box direction="row" align="center">
            {loading && <Loader />}
          </Box>
        </Box>
      </Box>

      {flash.visible && (
        <Notification
          type={flash.type ? flash.type : 'info'}
          onClose={dismissFlash}
          open={flash.visible}
          message={flash.text}
        />
      )}

      <Box gap="small" width="full" margin={{ top: 'large' }}>
        <Form
          style={{ width: '100%', flex: 1 }}
          name="exportTraders"
          onSubmit={exportTraders}
        >
          <Box align="center" gap="small" direction="row-responsive">
            <Text weight="bold" size="large">
              Traders
            </Text>

            <Button
              primary
              color="status-ok"
              label="Export"
              type="submit"
              disabled={loading}
            />
          </Box>
        </Form>
      </Box>
    </Layout>
  );
};

Exports.propTypes = {
  loading: PropTypes.bool,
  notification: PropTypes.object
};

Exports.defaultProps = {
  loading: false,
  notification: {}
};
