import {
  AccountHistory,
  AddBankAccount,
  AddDemoAccount,
  AddPartner,
  AddRealAccount,
  AddTrader,
  BankAccounts,
  BrokerDashboard,
  BrokerWithdrawals,
  Dashboard,
  Deposits,
  Exports,
  Inbox,
  Login,
  LostPassword,
  OpenedTrades,
  Partners,
  Profile,
  Registration,
  Support,
  Traders,
  Translations,
  ViewAccount,
  ViewBankAccount,
  ViewPartner,
  ViewTrader,
  Withdrawals,
  MarginCall,
  TradingAccounts
} from '../containers';
import Auth, { USER_ROLES } from '../services/auth';
import { createBrowserHistory, createHashHistory } from 'history';

/**
 * Application screen routes. Flattened are passed to the react router
 * @type {*[]}
 */
export const routes = [
  {
    name: 'Broker',
    path: '/broker',
    category: 'broker',
    props: { verification: USER_ROLES.administrator },
    component: BrokerDashboard,
    private: true,
    childs: [
      {
        name: 'Traders',
        path: '/broker/traders',
        props: { verification: USER_ROLES.administrator },
        component: Traders,
        private: true,
        childs: [
          {
            name: 'Add',
            path: '/broker/traders/add',
            props: { verification: USER_ROLES.administrator },
            hidden: true,
            component: AddTrader,
            private: true
          },
          {
            name: 'View',
            path: '/broker/traders/:id',
            props: { verification: USER_ROLES.administrator },
            hidden: true,
            component: ViewTrader,
            private: true
          },
          {
            name: 'Profile',
            path: '/broker/traders/:id/profile',
            props: { verification: USER_ROLES.administrator },
            hidden: true,
            component: Profile,
            private: true
          },
          {
            name: 'Inbox',
            path: '/broker/traders/:id/inbox',
            props: { verification: USER_ROLES.administrator },
            hidden: true,
            component: Inbox,
            private: true
          },
          {
            name: 'Account',
            path: '/broker/traders/:id/account',
            props: { verification: USER_ROLES.administrator, brokerView: true },
            hidden: true,
            component: ViewAccount,
            private: true
          },
          {
            name: 'History',
            path: '/broker/traders/:id/history',
            props: { verification: USER_ROLES.administrator },
            hidden: true,
            component: AccountHistory,
            private: true
          }
        ]
      },
      {
        name: 'All opened trades',
        path: '/broker/opened-trades',
        props: { verification: USER_ROLES.administrator },
        component: OpenedTrades,
        private: true
      },
      {
        name: 'Margin call',
        path: '/broker/margin-call',
        props: {
          verification: USER_ROLES.administrator
        },
        component: MarginCall,
        private: true
      },
      {
        name: 'Trading accounts',
        path: '/broker/trading-accounts',
        props: {
          verification: USER_ROLES.administrator
        },
        component: TradingAccounts,
        private: true
      },
      {
        name: 'Partners',
        path: '/broker/partners',
        props: { verification: USER_ROLES.administrator },
        component: Partners,
        private: true,
        childs: [
          {
            name: 'Add',
            path: '/broker/partners/add',
            props: { verification: USER_ROLES.administrator },
            hidden: true,
            component: AddPartner,
            private: true
          },
          {
            name: 'View',
            path: '/broker/partners/:id',
            props: {
              isMyAccount: false,
              verification: USER_ROLES.administrator
            },
            hidden: true,
            component: ViewPartner,
            private: true
          }
        ]
      },
      {
        name: 'Deposits',
        path: '/broker/deposits',
        props: { verification: USER_ROLES.administrator },
        component: Deposits,
        private: true
      },
      {
        name: 'Bank accounts',
        path: '/broker/bank-accounts',
        props: { verification: USER_ROLES.administrator },
        component: BankAccounts,
        private: true,
        childs: [
          {
            name: 'Add',
            path: '/broker/bank-accounts/add',
            props: { verification: USER_ROLES.administrator },
            hidden: true,
            component: AddBankAccount,
            private: true
          },
          {
            name: 'View',
            path: '/broker/bank-accounts/:id',
            props: { verification: USER_ROLES.administrator },
            hidden: true,
            component: ViewBankAccount,
            private: true
          }
        ]
      },
      {
        name: 'Withdrawals',
        path: '/broker/withdrawals',
        props: { verification: USER_ROLES.administrator },
        component: BrokerWithdrawals,
        private: true
      },
      {
        name: 'Exports',
        path: '/broker/exports',
        props: { verification: USER_ROLES.administrator },
        component: Exports,
        private: true
      },
      {
        name: 'Translations',
        path: '/broker/translations',
        props: { verification: USER_ROLES.administrator },
        component: Translations,
        private: true
      }
    ]
  },
  {
    name: 'Partner',
    path: '/partner',
    category: 'partner',
    props: { isMyAccount: true, verification: USER_ROLES.partner },
    component: ViewPartner,
    private: true
  },
  {
    name: 'Dashboard',
    path: '/',
    category: 'dashboard',
    component: Dashboard,
    private: true,
    childs: [
      {
        name: 'My profile',
        path: '/trader/profile',
        component: Profile,
        props: { isMyAccount: true },
        private: true
      },
      {
        name: 'Account',
        path: '/trader/account',
        props: { isMyAccount: true },
        component: ViewAccount,
        private: true
      },
      {
        name: 'Withdrawals',
        path: '/trader/withdrawals',
        component: Withdrawals,
        private: true
      },
      {
        name: 'Add demo account',
        path: '/trader/add-demo',
        component: AddDemoAccount,
        private: true
      },
      {
        name: 'Add real account',
        path: '/trader/add-real',
        props: { verification: USER_ROLES.subscriber },
        component: AddRealAccount,
        private: true
      },
      {
        name: 'Inbox',
        path: '/trader/inbox',
        props: { isMyAccount: true },
        component: Inbox,
        private: true
      },
      {
        name: 'Support',
        path: '/trader/support',
        props: { isMyAccount: true },
        component: Support,
        private: true
      },
      {
        name: 'Account history',
        path: '/trader/history',
        props: { isMyAccount: true, backLink: '/' },
        hidden: true,
        component: AccountHistory,
        private: true
      }
    ]
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
    hidden: true
  },
  {
    name: 'Open Real Account',
    path: '/open-real-account',
    component: Registration,
    props: { realAccount: true },
    hidden: true
  },
  {
    name: 'Registration',
    path: '/registration',
    component: Registration,
    hidden: true
  },
  {
    name: 'Open Demo Account',
    path: '/open-demo-account',
    component: Registration,
    props: { realAccount: false },
    hidden: true
  },
  {
    name: 'Lost Password',
    path: '/lost-password',
    component: LostPassword,
    hidden: true
  }
];

export const apiRoutes = {
  changeBalance: {
    url: process.env.REACT_APP_REST_API_HOST + '/accounts/v2/change-balance',
    method: 'POST'
  },
  changeCredit: {
    url: process.env.REACT_APP_REST_API_HOST + '/accounts/v2/change-credit',
    method: 'POST'
  },
  uploadDocuments: {
    url: process.env.REACT_APP_REST_API_HOST + '/files/v2/upload-documents',
    method: 'POST'
  },
  downloadDocument: {
    url: process.env.REACT_APP_REST_API_HOST + '/files/v2/download-document',
    method: 'GET',
    params: ['authToken', 'documentId']
  },
  setDocumentStatus: {
    url: process.env.REACT_APP_REST_API_HOST + '/files/v2/set-status',
    method: 'GET',
    params: ['authToken', 'documentId', 'status']
  },
  findUsers: {
    url: process.env.REACT_APP_REST_API_HOST + '/users/v2/find',
    method: 'GET',
    params: ['authToken', 'field', 'value']
  },
  exportTraders: {
    url: process.env.REACT_APP_REST_API_HOST + '/export/v2/traders',
    method: 'POST'
  },
  getAccount: {
    url: process.env.REACT_APP_REST_API_HOST + '/accounts/v2/detail',
    method: 'POST'
  }
};

/**
 * Returns route with parameters appended
 * @param key string route name
 * @param params array of params to be appended
 * @return {*} string url
 */
export function getApiRoute(key, params = []) {
  if (!apiRoutes.hasOwnProperty(key)) {
    return '';
  }

  let url = apiRoutes[key].url,
    routeParams = apiRoutes[key].params;

  if (!routeParams || !params || params.length === 0) {
    return url;
  }

  routeParams.forEach((routeParam, i) => {
    url += '/' + params[i];
  });
  return url;
}

/**
 * This function will return flat array of routes, ready to be passed to Router
 * @param routes array of routes to be flattened
 * @param flattened array buffer used across iterations
 * @return array of flattened routes
 */
function createFlatRoutes(routes, flattened = []) {
  routes.forEach(i => {
    if (!i.minimal) {
      flattened.push({ ...i, exact: i.exact || true });
      if (i.childs) {
        createFlatRoutes(i.childs, flattened);
      }
    }
  });
  return flattened;
}

export const flatRoutes = createFlatRoutes(routes);

/**
 * Will strip parameter from the end of the path and returns clean path. E.x edit/:id will be edit
 * @param path
 * @return {*}
 */
export function stripParam(path) {
  const index = path.indexOf(':');
  if (index !== -1) {
    return path.slice(0, index);
  }
  return path;
}

export function getPages() {
  return routes.filter(item => {
    return !item.hidden && item.category === 'pages';
  });
}

export function getCategories() {
  const categories = {};
  routes.forEach(route => {
    if (route.hidden) {
      return;
    }

    if (!categories.hasOwnProperty(route.category)) {
      categories[route.category] = [];
    }
    categories[route.category].push(route);
  });
  return categories;
}

/**
 * User role route verification, checks if user has proper roles to see and visit route
 * Returns true or false whether user passed has the proper role, matching role specified for the route
 * @param routeItem from routes array
 * @param user
 * @return {boolean}
 */
export const canUserSeeRoute = (routeItem, user) => {
  const verificationRoles = routeItem.props && routeItem.props.verification;

  if (!!verificationRoles) {
    return Auth.roleCheckVerification(verificationRoles, user);
  }
  return true;
};

export const configureHistory = () => {
  return window.matchMedia('(display-mode: standalone)').matches
    ? createHashHistory()
    : createBrowserHistory();
};
