import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AUTH_IN_PROCESS, STORE_AUTH_DATA } from '../../redux/app';

const AuthProvider = props => {
  const dispatch = useDispatch();

  const currentUser =
    useSelector(state => state.app.auth.currentUser, shallowEqual) ||
    props.currentUser;

  const authToken =
    useSelector(state => state.app.auth.authToken) || props.authToken;

  const csrfToken =
    useSelector(state => state.app.auth.csrfToken) || props.csrfToken;

  const loading =
    useSelector(state => state.app.auth.authInProcess) || props.loading;

  useEffect(() => {
    dispatch({ type: AUTH_IN_PROCESS, loading: true });

    if (props.useCsrf && !csrfToken) {
      fetchCsrfToken().then(response => {
        silentRefresh({ csrfToken: response });
      });
    } else {
      silentRefresh();
    }
  }, []);

  async function silentRefresh({ csrfToken } = {}) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    if (props.useCsrf && csrfToken) {
      headers[props.csrfHeader] = csrfToken;
    }

    return fetch(props.refreshEndpoint, {
      method: 'POST',
      credentials: 'include', // include, *same-origin, omit
      headers,
    })
      .then(response => response.json())
      .then(({ token, user }) => {
        dispatchAuthState({ authToken: token, currentUser: user });
      })
      .catch(error => {
        return error;
      })
      .finally(() => dispatch({ type: AUTH_IN_PROCESS, loading: false }));
  }

  async function fetchCsrfToken() {
    return fetch(props.csrfTokenEndpoint, {
      method: 'POST',
      credentials: 'include', // include, *same-origin, omit
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(({ token }) => {
        dispatchAuthState({ csrfToken: token });
        return token;
      })
      .catch(error => error);
  }

  function dispatchAuthState(data) {
    dispatch({ type: STORE_AUTH_DATA, ...data });
  }

  return typeof props.children === 'function'
    ? props.children({ currentUser, authToken, csrfToken, loading })
    : props.children;
};

AuthProvider.propTypes = {
  currentUser: PropTypes.object,
  authToken: PropTypes.string,
  csrfToken: PropTypes.string,
  children: PropTypes.any,
  refreshEndpoint: PropTypes.string.isRequired,
  csrfTokenEndpoint: PropTypes.string,
  useCsrf: PropTypes.bool,
  csrfHeader: PropTypes.string,
  loading: PropTypes.bool
};

AuthProvider.defaultProps = {
  loading: false,
  useCsrf: true
};

export default AuthProvider;
