import { useEffect, useState } from 'react';

export const FLASH_TYPES = {
  CRITICAL: 'critical',
  OK: 'ok',
  INFO: 'info'
};

/**
 * Hook to manage flashing notifications state
 */
const useFlash = (
  text = null,
  type = FLASH_TYPES.CRITICAL,
  visible = false
) => {
  const [flash, setFlash] = useState({
    visible: visible,
    text: text,
    type: type
  });

  useEffect(() => {
    setFlash({ visible, text, type });
  }, [text, type, visible]);

  function dismissFlash() {
    setFlash({ ...flash, visible: false });
  }

  function showFlash(text, type) {
    setFlash({
      ...flash,
      text: text || flash.text,
      type: type || flash.type,
      visible: true
    });
  }

  return [flash, showFlash, dismissFlash, setFlash];
};

export default useFlash;
