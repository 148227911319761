import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';
import { BackLink } from '../../components/Link';
import {
  Layout,
  Loader,
  Notification,
  OpenDemoForm,
  ResponseScreen
} from '../../components';
import { routes } from '../../config/routes';

export class AddDemoAccount extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    handleSubmit: PropTypes.func,
    notification: PropTypes.object
  };

  static defaultProps = {
    loading: false,
    notification: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      notification: props.notification,
      isFadingOut: false,
      showOverlay: false
    };

    this.dashboardEndpoint = routes.find(
      route => route.name === 'Dashboard'
    ).path;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissNotifications = this.dismissNotifications.bind(this);
    this.dismissOverlay = this.dismissOverlay.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.notification.text || prevState.notification.text) {
      return {
        notification: prevState.notification.text
          ? prevState.notification
          : nextProps.notification
      };
    }
    // Object or null must be returned
    return null;
  }

  dismissOverlay() {
    this.setState({
      showOverlay: false
    });
  }

  dismissNotifications() {
    this.setState({
      notification: {}
    });
  }

  fadeOutRedirect(endpoint = '/') {
    this.setState({ isFadingOut: true });

    if (!this.state.isFadingOut) {
      setTimeout(() => {
        this.props.history.push(endpoint);
      }, 400);
    }
  }

  async handleSubmit(event) {
    const fields = ['leverage', 'account_currency'],
      requiredFields = fields,
      errors = {},
      data = {};
    let valid = true;

    // Validation
    for (let i = 0; i < event.target.length; i++) {
      let { value, name } = event.target[i];

      if (fields.includes(name)) {
        data[name] = value;
      }

      if (requiredFields.includes(name) && value.length === 0) {
        errors[name] = 'This field is required.';
        valid = false;
      }
    }

    if (!valid) {
      this.setState({ formErrors: errors });
      return false;
    }

    try {
      const response = await this.props.handleSubmit(data),
        template = response.data && response.data.openDemoAccount.response;

      this.setState({
        formErrors: {},
        notification: { text: `Account successfully added!`, type: 'ok' },
        showOverlay: true,
        responseScreen: {
          title: template && template.title,
          content: template && template.content
        }
      });
    } catch (e) {
      this.setState({
        formErrors: {},
        notification: { text: e.message, type: 'critical' }
      });
    }
    return true; // Request has been processed
  }

  render() {
    const {
        notification,
        formErrors,
        showOverlay,
        responseScreen
      } = this.state,
      { loading } = this.props;

    return (
      <Layout title="Add demo account">
        <BackLink link={this.dashboardEndpoint} />
        <Box direction="row" margin={{ vertical: 'medium' }}>
          <Heading size="small" margin={{ right: 'small', vertical: 'none' }}>
            Add demo account
          </Heading>

          <Box direction="row" align="center">
            {loading && <Loader />}
          </Box>
        </Box>

        {showOverlay && responseScreen && (
          <ResponseScreen
            onClose={() => {
              this.dismissOverlay();
              // After closing, go to dashboard screen
              this.fadeOutRedirect(this.dashboardEndpoint);
            }}
            title={responseScreen.title}
            content={responseScreen.content}
          />
        )}

        {!!notification.text && (
          <Notification
            type={notification.type ? notification.type : 'info'}
            onClose={this.dismissNotifications}
            open={!!notification.text}
            message={notification.text}
          />
        )}

        <Box
          align="center"
          justify="center"
          pad="medium"
          elevation="small"
          background="white"
        >
          <OpenDemoForm
            newRegistration={false}
            handleSubmit={this.handleSubmit}
            loading={loading}
            errors={formErrors}
          />
        </Box>
      </Layout>
    );
  }
}
