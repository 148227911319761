import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Heading, Layer } from 'grommet';
import { FormClose } from 'grommet-icons';

export class Modal extends Component {
  static propTypes = {
    name: PropTypes.string,
    width: PropTypes.string,
    showCross: PropTypes.bool,
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onClickOutside: PropTypes.func,
    children: PropTypes.any,
    heading: PropTypes.string,
    position: PropTypes.oneOf([
      'bottom',
      'bottom-left',
      'bottom-right',
      'center',
      'hidden',
      'left',
      'right',
      'top',
      'top-left',
      'top-right'
    ]),
    responsive: PropTypes.bool
  };

  static defaultProps = {
    position: 'top',
    showCross: true,
    width: '400px',
    responsive: false
  };

  constructor(props) {
    super(props);

    this.state = {
      open: props.open
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.open !== state.open) {
      return {
        open: props.open
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.open && !prevState.open) {
      this.onOpen();
    } else if (!this.state.open && prevState.open) {
      this.onClose();
    }
  }

  onOpen = () => {
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const { open } = this.state,
      {
        children,
        onClickOutside,
        heading,
        position,
        responsive,
        name,
        width,
        showCross,
        ...rest
      } = this.props;

    return open ? (
      <Layer
        name={name}
        position={position}
        modal={true}
        responsive={responsive}
        onClickOutside={onClickOutside ? onClickOutside : this.onClose}
        onEsc={this.onClose}
        {...rest}
      >
        <Box
          pad="medium"
          gap="small"
          margin={{ top: 'large' }}
          width={width}
          background={{ color: 'light-2' }}
          flex={false}
        >
          <Box direction="row" width="flex">
            {heading && (
              <Heading level={3} margin="none">
                {heading}
              </Heading>
            )}
            {showCross && (
              <Button
                margin={{ left: 'auto' }}
                plain
                style={{ padding: 0, position: 'absolute', right: '15px' }}
                icon={<FormClose size="20px" />}
                onClick={this.onClose}
              />
            )}
          </Box>
          {children}
        </Box>
      </Layer>
    ) : null;
  }
}
