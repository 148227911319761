import { grommet } from 'grommet/themes';
import { rgba } from 'polished';
import { generate } from 'grommet/themes/base';
import { deepMerge } from 'grommet/utils';

export const theme = {
  spacings: {
    // Scale factor used for margin/padding and other sizing
    base: 18
  },

  sizes: {
    containerMax: '1200px'
  },

  colors: {
    brand: '#006EFF',
    focus: '#006EFF',
    selected: '#006EFF',
    white: '#FFFFFF',
    black: '#000000',
    body: '#FBFBFB',
    header: '#353535',
    placeholder: '#AAAAAA',
    active: (0, rgba)(221, 221, 221, 0.5),
    accent: ['#6FFFB0', '#FD6FFF', '#81FCED', '#E8E8FE'],
    neutral: ['#00873D', '#3D138D', '#00739D', '#A2423D'],
    dark: ['#181818', '#555555', '#777777', '#999999', '#999999', '#999999'],
    light: ['#F8F8F8', '#F2F2F2', '#EDEDED', '#f0f0f0', '#DBDBDB', '#DADADA'],
    border: {
      dark: (0, rgba)(255, 255, 255, 0.33),
      light: (0, rgba)(0, 0, 0, 0.33)
    },
    control: {
      dark: '#6FFFB0',
      light: '#006EFF'
    },
    text: {
      dark: '#F8F8F8',
      light: '#444444'
    },
    icon: {
      dark: '#F8F8F8',
      light: '#666666'
    },
    status: {
      critical: '#FF4040',
      error: '#FF4040',
      warning: '#FFAA15',
      ok: '#00C133',
      unknown: '#CCCCCC',
      disabled: '#CCCCCC'
    }
  }
};

// Custom theme overrides
const grommetCustomized = {
  global: {
    colors: Object.keys(theme.colors).reduce((acc, key) => {
      const colors = theme.colors[key];

      if (Array.isArray(colors)) {
        colors.forEach((color, index) => {
          acc[key + '-' + (index + 1)] = color;
        });
      } else if (typeof colors === 'object') {
        Object.keys(colors).forEach(color => {
          acc[key + '-' + color] = colors[color];
        });
      } else {
        acc[key] = colors;
      }

      return acc;
    }, {}),
    focus: {
      border: {
        color: 'none'
      }
    },
    elevation: {
      light: {
        small: '0 2px 3px rgba(0,0,0,0.1)'
      }
    }
  },
  button: {
    border: {
      radius: '2px'
    },
    padding: {
      vertical: '6px',
      horizontal: '22px'
    },
    extend: {
      fontWeight: 'bold',
      textTransform: 'uppercase'
    }
  },
  layer: {
    background: 'transparent'
  },
  anchor: {
    hover: {
      textDecoration: 'none'
    }
  },
  select: {
    container: {
      extend: {
        background: 'brand'
      }
    },
    control: {
      extend: {
        border: 'none'
      }
    }
  },
  tab: {
    border: {
      color: {
        dark: 'transparent',
        light: 'transparent'
      },
      active: {
        color: {
          dark: 'white',
          light: 'black'
        }
      }
    }
  }
};

export const grommetTheme = deepMerge(
  generate(theme.spacings.base),
  grommet,
  grommetCustomized
);
