import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-data-grid';

const StyledRow = ({ getRowStyle, ...props }) => {
  return (
    // here we are just changing the style
    // but we could replace this with anything we liked, cards, images, etc
    // usually though it will just be a matter of wrapping a div, and then calling back through to the grid
    <Row style={getRowStyle(props)} {...props} />
  );
};

StyledRow.propTypes = {
  getRowStyle: PropTypes.func,
  style: PropTypes.object
};

StyledRow.defaultProps = {
  getRowStyle: props => props.style,
  style: {}
};

export default StyledRow;
