export default [
    'EURUSD',
    'GBPUSD',
    'USDCHF',
    'USDJPY',
    '.DE30Cash',
    '.US30Cash',
    '.US500Cash',
    'AUDNZD',
    'AUDUSD',
    'EURGBP',
    'EURJPY',
    'GBPJPY',
    'USDCAD',
    'WTI',
    'XAGUSD',
    'XAUUSD',
    'NZDUSD',
    'AUDJPY',
    'CHFJPY',
    'EURNZD',
    'NZDCAD',
    'CADJPY',
    'EURTRY',
    'GBPAUD',
    'USDTRY',
    'USDCNH',
    'BRENT',
    'NG',
    'HG',
    'BTCUSD',
    'DASHUSD',
    'ETHUSD',
    'LTCUSD',
    'XRPUSD',
    'JPN225',
    '.USTECHCash',
    'ESX50',
    'UK100',

    /*
    'USDZAR',
    'USDHKD',
    'AUDCAD',
    'AUDCHF',
    'CADCHF',
    'EURZAR',
    'EURNOK',
    'GBPCAD',
    'GBPCHF',
    'GBPZAR',
    'GBPNZD',
    'NZDCHF',
    'NZDJPY',
    'USDPLN',
    'USDCZK',
    'USDMXN',
    'GBPNOK',
    'USDNOK',
    'EURSEK',
    'USDRUB',
    'USDSEK',
    'USDSGD',
    'EURAUD',
    'EURCAD',
    'EURCZK',
    'EURCHF',
    'EURHUF',
    'EURPLN',
     */
];
