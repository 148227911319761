import React from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import { default as Partners } from './Partners';
import { default as ViewPartner } from './ViewPartner';
import { AddPartner } from './AddPartner';
import Auth from '../../services/auth';

const FETCH_PARTNERS_LIST_QUERY = gql`
  {
    partners(first: 3000) {
      nodes {
        id
        userId
        provision
        name
        user {
          born
          email
          mt_id
          name
          id
          registeredDate
          first_name
          last_name
        }
        info
        website
        status
        link
        created
      }
    }
  }
`;

const FETCH_PARTNER_QUERY = gql`
  query partnerBy($userId: String!) {
    partnerBy(userId: $userId) {
      id
      name
      website
      provision
      user {
        id
        email
        first_name
        last_name
        referrals {
          id
          userId
          email
          partnerClientFrom
          first_name
          last_name
          name
          email
          phone
          registeredDate
          totalDeposits
          totalProvisions
          referrals {
            id
            userId
            email
            partnerClientFrom
            first_name
            last_name
            name
            email
            phone
            registeredDate
            totalDeposits
            totalProvisions
          }
        }
      }
      deposit_provisions {
        nodes {
          id
          provision_percent
          provision_amount
          created
          updated
          deposit {
            amount
            id
            first
            user {
              name
              email
              phone
            }
          }
        }
      }
      link
      registration_url
      status
      created
      info
    }
  }
`;

const REMOVE_PARTNER_MUTATION = gql`
  mutation deletePartner($input: DeletePartnerInput!) {
    deletePartner(input: $input) {
      deletedId
    }
  }
`;

const UPDATE_PARTNER_MUTATION = gql`
  mutation updatePartner($input: UpdatePartnerInput!) {
    updatePartner(input: $input) {
      id
    }
  }
`;

const ADD_TRADER_MUTATION = gql`
  mutation createClient($input: CreateClientInput!) {
    createClient(input: $input) {
      id
    }
  }
`;

const ADD_PARTNER_CLIENT_MUTATION = gql`
  mutation addPartnerClient($input: AddPartnerClientInput!) {
    addPartnerClient(input: $input) {
      id
    }
  }
`;

const UPDATE_TOTAL_PROVISIONS_FROM_CLIENT = gql`
  mutation updateTotalProvisionsFromClient(
    $input: UpdateTotalProvisionsFromClientInput!
  ) {
    updateTotalProvisionsFromClient(input: $input) {
      id
    }
  }
`;

const REMOVE_PARTNER_CLIENT_MUTATION = gql`
  mutation deletePartnerClient($input: DeletePartnerClientInput!) {
    deletePartnerClient(input: $input) {
      id
    }
  }
`;

export const PartnersConnected = props => (
  <Query query={FETCH_PARTNERS_LIST_QUERY}>
    {({ loading, error, data }) => (
      <Mutation
        mutation={ADD_PARTNER_CLIENT_MUTATION}
        refetchQueries={[{ query: FETCH_PARTNERS_LIST_QUERY }]}
      >
        {addPartnerClient => (
          <Mutation
            mutation={REMOVE_PARTNER_MUTATION}
            refetchQueries={[{ query: FETCH_PARTNERS_LIST_QUERY }]}
          >
            {removePartner => (
              <Mutation
                mutation={UPDATE_PARTNER_MUTATION}
                refetchQueries={[{ query: FETCH_PARTNERS_LIST_QUERY }]}
              >
                {(updatePartner, updateMutationProps) => {
                  return (
                    <Partners
                      partners={data && data.partners && data.partners.nodes}
                      removePartner={async input =>
                        removePartner({
                          variables: {
                            input: {
                              ...input,
                              clientMutationId: 'removePartner'
                            }
                          }
                        })
                      }
                      loading={
                        loading ||
                        updateMutationProps.loading ||
                        addPartnerClient.loading
                      }
                      notification={
                        (updateMutationProps.error ||
                          error ||
                          addPartnerClient.error) && {
                          text:
                            (error && error.message) ||
                            (updateMutationProps.error &&
                              updateMutationProps.error.message) ||
                            (addPartnerClient.error &&
                              addPartnerClient.error.message),
                          type: 'critical'
                        }
                      }
                      {...props}
                      assignClient={async data => {
                        addPartnerClient({
                          variables: {
                            input: {
                              ...data,
                              clientMutationId: 'addPartnerClient'
                            }
                          }
                        });
                      }}
                      updateItem={async data =>
                        updatePartner({
                          variables: {
                            input: {
                              ...data,
                              clientMutationId: 'updatePartner' // WPGraphQL requires this
                            }
                          }
                        })
                      }
                    />
                  );
                }}
              </Mutation>
            )}
          </Mutation>
        )}
      </Mutation>
    )}
  </Query>
);

export const ViewPartnerConnected = ({ isMyAccount, ...props }) => {
  const userId = isMyAccount ? Auth.getUser().userId : props.match.params.id;

  return (
    // Id passed in route, FETCH data to edit
    <Query query={FETCH_PARTNER_QUERY} variables={{ userId }}>
      {loadDataQueryProps => {
        // Weird error here. Probably bug of the GraphqlPHP library...
        if (
          loadDataQueryProps.error &&
          loadDataQueryProps.error
            .toString()
            .includes('Network error: Error writing result to store for query')
        ) {
          window.location.reload();
        }
        return (
          <Mutation
            mutation={UPDATE_TOTAL_PROVISIONS_FROM_CLIENT}
            refetchQueries={[
              { query: FETCH_PARTNER_QUERY, variables: { userId } }
            ]}
          >
            {updateTotalProvisionsFromClient => (
              <Mutation
                mutation={REMOVE_PARTNER_CLIENT_MUTATION}
                refetchQueries={[
                  { query: FETCH_PARTNER_QUERY, variables: { userId } }
                ]}
              >
                {removePartnerClient => (
                  <Mutation
                    mutation={UPDATE_PARTNER_MUTATION}
                    refetchQueries={[
                      { query: FETCH_PARTNER_QUERY, variables: { userId } }
                    ]}
                  >
                    {(updatePartner, { loading, error }) => {
                      return (
                        <ViewPartner
                          isMyAccount={isMyAccount}
                          partner={
                            loadDataQueryProps.data &&
                            loadDataQueryProps.data.partnerBy
                          }
                          clients={
                            loadDataQueryProps.data &&
                            loadDataQueryProps.data.partnerBy
                              ? loadDataQueryProps.data.partnerBy.user.referrals
                              : []
                          }
                          deposits={
                            loadDataQueryProps.data &&
                            loadDataQueryProps.data.partnerBy
                              ? loadDataQueryProps.data.partnerBy.deposit_provisions.nodes.map(
                                  prov => ({
                                    ...prov,
                                    name: prov.deposit.user.name,
                                    first: prov.deposit.first,
                                    email: prov.deposit.user.email,
                                    phone: prov.deposit.user.phone
                                  })
                                )
                              : []
                          }
                          currentUserId={userId}
                          loading={
                            loadDataQueryProps.loading ||
                            removePartnerClient.loading ||
                            loading
                          }
                          notification={
                            (loadDataQueryProps.error || error) && {
                              text:
                                (error && error.message) ||
                                (loadDataQueryProps.error &&
                                  loadDataQueryProps.error.message),
                              type: 'critical'
                            }
                          }
                          {...props}
                          removeClient={async data =>
                            removePartnerClient({
                              variables: {
                                input: {
                                  ...data,
                                  clientMutationId: 'removePartnerClient' // WPGraphQL requires this
                                }
                              }
                            })
                          }
                          updateTotalProvisions={async data =>
                            updateTotalProvisionsFromClient({
                              variables: {
                                input: {
                                  ...data,
                                  clientMutationId: 'updateTotalProvisions' // WPGraphQL requires this
                                }
                              }
                            })
                          }
                        />
                      );
                    }}
                  </Mutation>
                )}
              </Mutation>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export const AddPartnerConnected = props => {
  return (
    <Mutation
      mutation={ADD_TRADER_MUTATION}
      refetchQueries={[{ query: FETCH_PARTNERS_LIST_QUERY }]}
    >
      {(createClient, { loading, error }) => (
        <AddPartner
          loading={loading}
          notification={
            error && {
              text: error && error.message,
              type: 'critical'
            }
          }
          {...props}
          createUser={async data => {
            return createClient({
              variables: {
                input: {
                  ...data,
                  clientMutationId: 'createClient' // WPGraphQL requires this
                }
              }
            });
          }}
        />
      )}
    </Mutation>
  );
};
