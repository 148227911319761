import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Form,
  TextInput,
  Text,
  FormField,
  Stack,
  Select,
  MaskedInput
} from 'grommet';
import { Translate } from 'react-localize-redux';

export class OpenDemoForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    newRegistration: PropTypes.bool,
    errors: PropTypes.object,
    loading: PropTypes.bool,
    formData: PropTypes.object
  };

  static defaultProps = {
    loading: false,
    newRegistration: true,
    errors: {},
    formData: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      formData: props.formData
      /*
      hints: {
        passwordLength: false,
        passwordStructure: false
      }
      */
    };
  }

  handleUpdate = event => {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: this.parseInput(event.target)
      }
    });
  };

  parseInput(target) {
    if (!target) {
      return false;
    }

    switch (target.type) {
      case 'checkbox':
        return target.checked;
      default:
        return target.value;
    }
  }

  handleSubmit = async event => {
    const result = await this.props.handleSubmit(event);
    if (result) {
      this.setState({ formData: {} });
    }
  };

  /*
  setPasswordHints = (password = "") => {
    this.setState({
      hints: {
        ...this.state.hints,
        ...validatePassword(password)
      }
    });
  };

  getHintIcon = (good = false) => {
    return !good ? <FormClose size="18px" color="status-critical"/> : <FormCheckmark size="18px" color="status-ok"/>
  };
  */

  render() {
    const {
        email,
        password,
        password_repeat,
        leverage,
        account_currency,
        first_name,
        phone,
        last_name,
        born,
        country
      } = this.state.formData,
      //{passwordLength, passwordStructure} = this.state.hints,
      { errors, loading, newRegistration } = this.props;

    return (
      <Translate>
        {({ translate }) => (
          <Form
            style={{ width: '100%', flex: 1 }}
            name="demo"
            className="signUpForm"
            onSubmit={this.handleSubmit}
          >
            {newRegistration && (
              <Stack anchor="top-left" margin={{ bottom: 'large' }}>
                <Box border={{ color: 'black' }} pad="small">
                  <Box
                    direction="row-responsive"
                    gap="medium"
                    margin={{ bottom: 'small' }}
                    align="center"
                  >
                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="registration.labels.firstName">
                          First name
                        </Translate>
                      }
                      htmlFor="first-name"
                      error={
                        errors['first_name'] &&
                        translate('errors.requiredField')
                      }
                    >
                      <TextInput
                        id="first-name"
                        name="first_name"
                        type="text"
                        value={first_name ? first_name : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.firstName'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>
                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="registration.labels.lastName">
                          Last name
                        </Translate>
                      }
                      htmlFor="last-name"
                      error={
                        errors['last_name'] && translate('errors.requiredField')
                      }
                    >
                      <TextInput
                        id="last-name"
                        name="last_name"
                        type="text"
                        value={last_name ? last_name : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.lastName'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>

                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="registration.labels.dateOfBirth">
                          Date of birth
                        </Translate>
                      }
                      htmlFor="date-of-birth"
                      error={
                        errors['born'] && translate('errors.requiredField')
                      }
                    >
                      <MaskedInput
                        id="date-of-birth"
                        name="born"
                        className={'drop_focus_onclick'}
                        mask={[
                          {
                            length: [1, 2],
                            regexp: /^1[0-9]?$|^2[0-9]?$|^3[0-1]?$|^0[1-9]?$/,
                            placeholder: 'DD'
                          },
                          { fixed: '/' },
                          {
                            length: [1, 2],
                            regexp: /^1[0-2]?$|^0[1-9]?$/,
                            placeholder: 'MM'
                          },
                          { fixed: '/' },
                          {
                            length: [4],
                            regexp: /^\d{4}?$|^\d{3}?$|^\d{2}?$|^\d{1}?$/,
                            placeholder: translate(
                              'registration.placeholders.dateOfBirthYear'
                            )
                          }
                        ]}
                        value={born ? born : ''}
                        disabled={loading}
                        onChange={option =>
                          this.handleUpdate({
                            target: { name: 'born', value: option.target.value }
                          })
                        }
                      />
                    </FormField>
                  </Box>

                  <Box
                    direction="row-responsive"
                    gap="medium"
                    margin={{ bottom: 'small' }}
                    align="center"
                  >
                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="registration.labels.phone">
                          Phone
                        </Translate>
                      }
                      htmlFor="phone"
                      error={
                        errors['phone'] && translate('errors.requiredField')
                      }
                    >
                      <TextInput
                        id="phone"
                        name="phone"
                        type="tel"
                        value={phone ? phone : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.phone'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>

                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="registration.labels.country">
                          Country
                        </Translate>
                      }
                      htmlFor="country"
                      error={
                        errors['country'] && translate('errors.requiredField')
                      }
                    >
                      <TextInput
                        id="country"
                        name="country"
                        type="text"
                        value={country ? country : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.country'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>
                  </Box>
                </Box>
                <Box
                  background="white"
                  margin={{ left: 'small', top: '-10px' }}
                  pad={{ horizontal: 'small' }}
                >
                  <Text weight="bold">
                    <Translate id="global.personalInformation">
                      Personal information
                    </Translate>
                  </Text>
                </Box>
              </Stack>
            )}

            <Stack anchor="top-left" margin={{ bottom: 'large' }}>
              <Box
                border={{ color: newRegistration ? 'black' : 'light-4' }}
                pad="small"
              >
                {newRegistration && (
                  <Box flex={false} margin={{ bottom: 'small' }}>
                    <Box direction="row-responsive" gap="medium" align="center">
                      <FormField
                        style={{ flex: 1 }}
                        label="Email"
                        htmlFor="email"
                        error={
                          errors['email'] && translate('errors.requiredField')
                        }
                      >
                        <TextInput
                          id="email"
                          name="email"
                          type="email"
                          value={email ? email : ''}
                          disabled={loading}
                          placeholder={translate(
                            'registration.placeholders.email'
                          )}
                          onChange={this.handleUpdate}
                        />
                      </FormField>

                      <FormField
                        style={{ flex: 1 }}
                        label={
                          <Translate id="login.password">Password</Translate>
                        }
                        htmlFor="password"
                        error={
                          errors['password'] &&
                          translate(`errors.${errors.password}`)
                        }
                      >
                        <TextInput
                          id="password"
                          name="password"
                          type="password"
                          value={password ? password : ''}
                          disabled={loading}
                          placeholder={translate(
                            'registration.placeholders.password'
                          )}
                          onChange={event => {
                            this.handleUpdate(event);
                            //this.setPasswordHints(event.target.value);
                          }}
                        />
                      </FormField>

                      <FormField
                        style={{ flex: 1 }}
                        label={
                          <Translate id="registration.labels.passwordRepeat">
                            Confirm password
                          </Translate>
                        }
                        htmlFor="password_repeat"
                        error={
                          errors['password_repeat'] &&
                          translate(`errors.${errors.password_repeat}`)
                        }
                      >
                        <TextInput
                          id="password_repeat"
                          name="password_repeat"
                          type="password"
                          value={password_repeat ? password_repeat : ''}
                          disabled={loading}
                          placeholder={translate(
                            'registration.placeholders.passwordRepeat'
                          )}
                          onChange={this.handleUpdate}
                        />
                      </FormField>
                    </Box>

                    {/*
                  <Box direction="row-responsive">
                    <Box gap="xsmall" pad={{horizontal: "small"}} basis="1/3">
                    </Box>
                    <Box gap="xsmall" pad={{horizontal: "small"}} basis="1/3">
                      <Box direction="row" align="center">
                        {this.getHintIcon(passwordLength)}
                        <Text size="small" color="border">Choose password at least 7 characters long</Text>
                      </Box>
                      <Box direction="row" align="center">
                        {this.getHintIcon(passwordStructure)}
                        <Text size="small" color="border">Must contain at least one digit and one uppercase
                          letter</Text>
                      </Box>
                    </Box>
                    <Box gap="xsmall" pad={{horizontal: "small"}} basis="1/3">
                      <Box direction="row" align="center">
                        {(password) &&
                        <>
                          {this.getHintIcon(password === password_repeat)}
                          <Text size="small"
                                color="border">Passwords {`does${password !== password_repeat ? "n't" : ""}`} match</Text>
                        </>}
                      </Box>
                    </Box>
                  </Box>
                  */}
                  </Box>
                )}

                <Box
                  direction="row-responsive"
                  gap="medium"
                  margin={{ bottom: 'small' }}
                  align="center"
                >
                  <FormField
                    style={{ flex: 1 }}
                    label={
                      <Translate id="registration.labels.accountCurrency">
                        Account currency
                      </Translate>
                    }
                    htmlFor="account_currency"
                    error={
                      errors['account_currency'] &&
                      translate('errors.requiredField')
                    }
                  >
                    <Select
                      id="account_currency"
                      name="account_currency"
                      plain
                      placeholder={translate(
                        'registration.placeholders.accountCurrency'
                      )}
                      options={['EUR', 'USD']}
                      disabled={loading}
                      value={account_currency ? account_currency : ''}
                      onChange={({ option }) => {
                        this.handleUpdate({
                          target: { name: 'account_currency', value: option }
                        });
                      }}
                    />
                  </FormField>
                  <FormField
                    style={{ flex: 1 }}
                    label={
                      <Translate id="registration.labels.leverage">
                        Leverage
                      </Translate>
                    }
                    htmlFor="leverage"
                    error={
                      errors['leverage'] && translate('errors.requiredField')
                    }
                  >
                    <Select
                      id="leverage"
                      name="leverage"
                      plain
                      placeholder={translate(
                        'registration.placeholders.leverage'
                      )}
                      options={['1:500', '1:400', '1:300', '1:200', '1:100']}
                      disabled={loading}
                      value={leverage ? leverage : ''}
                      onChange={({ option }) => {
                        this.handleUpdate({
                          target: { name: 'leverage', value: option }
                        });
                      }}
                    />
                  </FormField>
                </Box>
              </Box>
              <Box
                background="white"
                margin={{ left: 'small', top: '-10px' }}
                pad={{ horizontal: 'small' }}
              >
                <Text weight="bold">
                  <Translate id="global.accountSettings">
                    Account settings
                  </Translate>
                </Text>
              </Box>
            </Stack>

            <Box
              direction="row-responsive"
              pad={{ vertical: 'xsmall' }}
              gap="small"
            >
              <Button
                disabled={loading}
                label={<Translate id="global.submit">Submit</Translate>}
                type="submit"
                primary
                color="brand"
                fill
              />
            </Box>
          </Form>
        )}
      </Translate>
    );
  }
}
