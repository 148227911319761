import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BackLink,
  Layout,
  Loader,
  Modal,
  Notification
} from '../../components';
import { Anchor, Box, Button, Heading, Text } from 'grommet/es6';
import { Checkmark, Close, Trash, View, Clock } from 'grommet-icons';
import { routes } from '../../config/routes';
import { FormatUtils } from '../../utils/FormatUtils';
import {
  DEFAULT_DATETIME_FORMAT,
  WITHDRAWAL_STATUSES
} from '../../config/site';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FLASH_TYPES, useFlash } from '../../hooks';
import { SAVE_OPTION } from '../../redux/app';
import {
  CellActionsFormatter,
  getFilterRenderer,
  ToolbaredTable
} from '../../components/DataTable';

function getEndpoint(key) {
  const endpoints = {
    broker: routes.find(route => route.name === 'Broker').path,
    viewTrader: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'View').path
  };
  return endpoints[key];
}

const BrokerWithdrawals = props => {
  const hiddenColumns =
    useSelector(
      state => state.app.storage.options.brokerWithdrawalsTableCols,
      shallowEqual
    ) || props.hiddenColumns;
  const dispatch = useDispatch();

  const [state, setState] = useState({
    formErrors: props.formErrors,
    itemToUpdate: null,
    removeItem: false
  });

  const [flash, showFlash, dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );
  const [modal, showModal, dismissModal] = useFlash();

  const columns = useMemo(() => {
    return [
      {
        key: 'user',
        name: 'Client',
        width: 250,
        filterRenderer: getFilterRenderer('text'),
        formatter: ({ row }) => {
          const actions = [
            {
              icon: <View size="small" color="brand" />,
              text: 'View profile',
              title: "View Trader's profile",
              callback: () =>
                props.history.push(
                  getEndpoint('viewTrader').replace(':id', row.user_id)
                )
            },
            {
              icon: <Trash size="small" color="status-critical" />,
              text: 'Remove withdrawal',
              callback: () => {
                showModal();
                return setState({
                  ...state,
                  removeItem: true,
                  itemToUpdate: row
                });
              }
            },
            {
              icon: <Close size="small" color="status-warning" />,
              text: 'Decline',
              title: 'Decline withdrawal',
              callback: () => {
                showModal();
                return setState({
                  ...state,
                  itemToUpdate: { ...row, status: WITHDRAWAL_STATUSES.denied }
                });
              }
            },
            {
              icon: <Checkmark size="small" color="status-ok" />,
              text: 'Accept',
              title: 'Accept withdrawal',
              callback: () => {
                showModal();
                return setState({
                  ...state,
                  itemToUpdate: { ...row, status: WITHDRAWAL_STATUSES.accepted }
                });
              }
            },
            {
              icon: <Clock size="small" color="status-warning" />,
              text: 'Set Pending',
              callback: () => {
                showModal();
                return setState({
                  ...state,
                  itemToUpdate: { ...row, status: WITHDRAWAL_STATUSES.pending }
                });
              }
            }
          ];
          return (
            <>
              <CellActionsFormatter actions={actions} />
              <Anchor
                title="Show client"
                target="_blank"
                href={getEndpoint('viewTrader').replace(':id', row.user_id)}
                color="brand"
                label={
                  <Text weight="bold">{`${row.user.first_name} ${row.user.last_name}`}</Text>
                }
              />
            </>
          );
        }
      },
      {
        key: 'account',
        name: 'Account',
        width: 150,
        filterRenderer: getFilterRenderer('text'),
        formatter: ({ row }) => (
          <Text
            title={FormatUtils.formatTradingAccountDisplayName(
              row.account,
              row.account.mt_id
            )}
          >
            {row.account.mt_id}
          </Text>
        )
      },
      {
        key: 'amount',
        name: 'Amount',
        filterRenderer: getFilterRenderer('numeric'),
        formatter: ({ row }) =>
          FormatUtils.formatMoneyAmountDisplay(
            row.amount,
            row.account && row.account.account_currency
          )
      },
      {
        key: 'bank_account',
        name: 'Bank account',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'bank_name',
        name: 'Bank name',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'note',
        name: 'Note',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'status',
        name: 'Status',
        filterRenderer: getFilterRenderer('checklist', {
          rows: [
            { label: WITHDRAWAL_STATUSES.accepted, value: 'accepted' },
            { label: WITHDRAWAL_STATUSES.canceled, value: 'canceled' },
            { label: WITHDRAWAL_STATUSES.pending, value: 'pending' },
            { label: WITHDRAWAL_STATUSES.denied, value: 'denied' }
          ]
        })
      },
      {
        key: 'created',
        name: 'Issue date',
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        filterRenderer: getFilterRenderer('date', {
          datePicker: true,
          placeholder: 'Select Date/Range'
        }),
        formatter: ({ row }) =>
          row.created
            ? moment(row.created).format(DEFAULT_DATETIME_FORMAT)
            : null
      },
      {
        key: 'updated',
        name: 'Last update',
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        filterRenderer: getFilterRenderer('date', {
          datePicker: true,
          placeholder: 'Select Date/Range'
        }),
        formatter: ({ row }) =>
          row.updated
            ? moment(row.updated).format(DEFAULT_DATETIME_FORMAT)
            : null
      }
    ].map(col => {
      return {
        ...col,
        resizable: true,
        sortable: true
      };
    });
  }, []);

  const hideColumns = nextState => {
    dispatch({
      type: SAVE_OPTION,
      key: 'brokerWithdrawalsTableCols',
      value: nextState
    });
  };

  /**
   * Update of the item
   * */
  const updateItem = async (item, remove = false) => {
    if (!item || !item.id) {
      return;
    }

    const action = remove ? props.removeItem : props.updateItem;

    await action({ id: item.id, status: item.status }).then(
      () => {
        showFlash(
          `Withdrawal ${remove ? 'removed' : 'updated'}`,
          FLASH_TYPES.OK
        );
        setState({
          ...state,
          itemToUpdate: null,
          removeItem: false
        });
        return true;
      },
      error => {
        showFlash(
          `Error ${remove ? 'removing' : 'updating'} withdrawal request`,
          FLASH_TYPES.CRITICAL
        );
        setState({
          ...state,
          itemToUpdate: null,
          removeItem: false
        });
        return false;
      }
    );
  };

  const { removeItem, itemToUpdate } = state;
  const { loading, withdrawals } = props;

  return (
    <Layout title="Broker Withdrawals" fluid>
      <BackLink link={getEndpoint('broker')} />

      <Box direction="row" align="center" margin={{ vertical: 'medium' }}>
        <Heading margin={{ right: 'small', vertical: 'none' }} size="small">
          Withdrawal requests
        </Heading>

        <Box direction="row" align="center">
          {loading && <Loader />}
        </Box>
      </Box>

      {flash.visible && (
        <Notification
          type={flash.type ? flash.type : 'info'}
          onClose={dismissFlash}
          open={flash.visible}
          message={flash.text}
        />
      )}

      {/* Withdrawals table */}
      <div style={{ width: `100%`, height: '100vh', position: 'relative' }}>
        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
          <ToolbaredTable
            toggleableColumns={columns}
            columns={columns}
            rows={withdrawals}
            loading={loading}
            hiddenColumns={hiddenColumns}
            onHideColumn={hideColumns}
          />
        </div>
      </div>

      {/* Modals */}
      {modal.visible && (
        <Modal open={modal.visible} heading={'Confirm'} onClose={dismissModal}>
          <Text>
            Are you sure you want to {removeItem ? 'remove' : 'update'}{' '}
            withdrawal from {`${itemToUpdate.user.email}`}?
          </Text>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            <Button
              label="Cancel"
              onClick={() => {
                dismissModal();
                setState({ ...state, itemToUpdate: null, removeItem: null });
              }}
              disabled={loading}
              color="dark-3"
            />
            <Button
              label={
                <Text color="white">
                  <strong>Confirm</strong>
                </Text>
              }
              onClick={() => {
                dismissModal();
                updateItem(itemToUpdate, removeItem);
              }}
              disabled={loading}
              primary
              color="status-critical"
            />
          </Box>
        </Modal>
      )}
    </Layout>
  );
};

BrokerWithdrawals.propTypes = {
  withdrawals: PropTypes.array,
  updateItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hiddenColumns: PropTypes.array,
  notification: PropTypes.object
};

BrokerWithdrawals.defaultProps = {
  withdrawals: [],
  loading: false,
  hiddenColumns: [],
  notification: {}
};

export default BrokerWithdrawals;
