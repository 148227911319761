import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFlash } from '../../hooks';
import {
  CellActionsFormatter,
  ToolbaredTable
} from '../../components/DataTable';
import {
  BackLink,
  Layout,
  Loader,
  Modal,
  Notification
} from '../../components';
import { routes } from '../../config/routes';
import { Box, Button, Heading } from 'grommet/es6';
import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT } from '../../config/site';
import { View } from 'grommet-icons/es6';

function getEndpoint(key) {
  const endpoints = {
    viewTrader: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'View').path,
    broker: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders').path,
    dashboard: routes.find(route => route.name === 'Dashboard').path
  };
  return endpoints[key];
}

const Inbox = props => {
  const [state, setState] = useState({
    selectedItem: undefined
  });

  const [flash, , dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );
  const [modal, showModal, dismissModal] = useFlash();

  const columns = useMemo(() => {
    return [
      {
        key: 'subject',
        name: 'Předmět',
        formatter({ row }) {
          const actions = [
            {
              icon: <View size="small" color="brand" />,
              text: 'Zobrazit',
              callback: () => {
                showModal();
                return setState({ ...state, selectedItem: row });
              }
            }
          ];

          return (
            <>
              <CellActionsFormatter actions={actions} />
              <Button
                plain
                label={row.subject}
                color="brand"
                title="Zobrazit zprávu"
                onClick={() => {
                  showModal();
                  return setState({ ...state, selectedItem: row });
                }}
              />
            </>
          );
        }
      },
      {
        key: 'created',
        name: 'Přijato',
        width: 200,
        formatter: ({ row }) =>
          row.created
            ? moment(row.created).format(DEFAULT_DATETIME_FORMAT)
            : null
      }
    ].map(col => {
      return {
        ...col,
        resizable: true,
        sortable: true
      };
    });
  }, []);

  const { loading, isMyAccount, currentUserId } = props;

  return (
    <Layout title={isMyAccount ? 'Schránka zpráv' : "Trader's inbox"} fluid>
      <BackLink
        link={
          !isMyAccount
            ? getEndpoint('viewTrader').replace(':id', currentUserId)
            : getEndpoint('dashboard')
        }
      />

      <Box
        direction="row"
        justify="between"
        align="center"
        margin={{ vertical: 'medium' }}
      >
        <Box direction="row" align="center" margin={{ vertical: 'medium' }}>
          <Heading
            size="small"
            margin={{
              right: 'small',
              vertical: 'none'
            }}
          >
            {isMyAccount ? 'Schránka zpráv' : "Trader's inbox"}
          </Heading>

          <Box direction="row" align="center">
            {loading && <Loader />}
          </Box>
        </Box>
      </Box>

      {flash.visible && (
        <Notification
          type={flash.type ? flash.type : 'info'}
          onClose={dismissFlash}
          open={flash.visible}
          message={flash.text}
        />
      )}

      {modal.visible && (
        <Modal
          open={modal.visible}
          heading={state.selectedItem.subject}
          onClose={dismissModal}
          width="90vw"
          onClickOutside={dismissModal}
        >
          <Box pad={{ vertical: 'small' }}>
            Datum:{' '}
            {moment(state.selectedItem.created).format(DEFAULT_DATETIME_FORMAT)}
          </Box>
          <div
            style={{
              maxHeight: '75vh',
              overflow: 'auto',
              maxWidth: '600px',
              margin: '0 auto',
              padding: '20px',
              background: 'white'
            }}
            dangerouslySetInnerHTML={{
              __html: state.selectedItem.message && state.selectedItem.message
            }}
          />
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            <Button
              label="Close"
              onClick={() => {
                dismissModal();
                setState({ ...state, selectedItem: undefined });
              }}
              disabled={loading}
              color="dark-3"
            />
          </Box>
        </Modal>
      )}

      <div style={{ width: `100%`, height: '500px', position: 'relative' }}>
        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
          <ToolbaredTable
            toolbarProps={{ enableFiltering: false, enableColumnHide: false }}
            columns={columns}
            rows={props.messages}
          />
        </div>
      </div>
    </Layout>
  );
};

Inbox.propTypes = {
  loading: PropTypes.bool,
  isMyAccount: PropTypes.bool,
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateItem: PropTypes.func.isRequired,
  notification: PropTypes.object,
  messages: PropTypes.array,
  hiddenColumns: PropTypes.array
};

Inbox.defaultProps = {
  loading: false,
  isMyAccount: false,
  messages: [],
  hiddenColumns: [],
  notification: {
    text: '',
    type: 'info'
  }
};

export default Inbox;
