import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Heading, Layer } from 'grommet/es6';
import { Close } from 'grommet-icons';
import paperPlane from '../../images/paper_plane.png';

export class ResponseScreen extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
  };

  render() {
    const { onClose, title, content } = this.props;

    return (
      <Layer full>
        <Box fill background={{ color: 'light-4' }}>
          <Box height="50px" direction="row" justify="end" pad="small">
            <Button plain icon={<Close />} label="Close" onClick={onClose} />
          </Box>
          <Box height="full" align="center">
            <Box
              width="xlarge"
              height="full"
              align="center"
              pad="medium"
              style={{ backgroundSize: 'contain' }}
              background={{ position: 'bottom', image: `url(${paperPlane})` }}
            >
              <Heading margin={{ vertical: 'large' }}>{title && title}</Heading>
              <div
                style={{ fontSize: '16px' }}
                dangerouslySetInnerHTML={{ __html: content && content }}
              />
            </Box>
          </Box>
        </Box>
      </Layer>
    );
  }
}
