import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledRow } from '../DataTable';

const ReadOnlyRows = props => {
  const [rows, setRows] = useState(props.rows);
  const [active, setActive] = useState(props.active);

  useEffect(() => {
    if (props.active !== active) {
      setActive(props.active);
    }
  }, [props.active]);

  useEffect(() => {
    let rows = props.rows;
    if (active) {
      rows = props.rows.filter(r => {
        return (r.user !== null && r.user.enable_read_only !== true);
      });
    } else {
      rows = rows.sort((a, b) => {
        const [_a, _b] = [a.user !== null ? a.user.enable_read_only : 0, b.user !== null ? b.user.enable_read_only : 0];
        return _b - _a;
      });
    }
    if (props.onRowsChange) {
      props.onRowsChange(rows);
    }
    setRows(rows);
  }, [active, props.rows]);

  const tableOption = useMemo(
    () => ({
      label: 'Hide read only',
      name: 'hideReadOnly',
      onChange: e => {
        const { checked } = e.target;
        setActive(checked);
      }
    }),
    []
  );

  const rowRenderer = useMemo(() => {
    return props => (
      <StyledRow
        {...props}
        className={`${props.className || ''}${
            (props.row.user !== null && props.row.user.enable_read_only) ? ' red' : ''
        }`}
      />
    );
  }, []);

  return props.children({ rows, active, tableOption, rowRenderer });
};

ReadOnlyRows.propTypes = {
  rows: PropTypes.array,
  onRowsChange: PropTypes.func,
  active: PropTypes.bool,
  children: PropTypes.func
};

ReadOnlyRows.defaultProps = {
  rows: [],
  active: false
};

export default ReadOnlyRows;
