import {useContext} from "react";
import {ResponsiveContext} from "grommet";

export default function useResponsiveHelper() {
  const breakpoint = useContext(ResponsiveContext);

  const setValues = (_sizes = {}, base = undefined) => {
    const sizes = {
      small: base,
      medium: undefined,
      large: undefined,
      ..._sizes
    };
    return Object.keys(sizes).reduce(
      (acc, _breakpoint, i, keys) => {
        if ((breakpoint === _breakpoint && sizes[breakpoint]) || (keys.indexOf(breakpoint) > i && sizes[_breakpoint])) {
          acc.size = sizes[_breakpoint];
        }
        return acc;
      },
      { size: sizes.small }
    ).size;
  };

  return { breakpoint, setValues };
}
