import React, { useMemo, useState } from 'react';
import { Box, DropButton } from 'grommet';
import { More } from 'grommet-icons';

const CellAction = ({ icon, text, title, callback }) => {
  function onActionIconClick() {
    if (typeof callback === 'function') {
      callback();
    }
  }

  return (
    <div
      className="rdg-cell-action-button"
      onClick={onActionIconClick}
      title={title}
    >
      <span className="icon">{icon}</span>
      <span className="label">{text}</span>
    </div>
  );
};

const CellActionsFormatter = ({ actions }) => {
  const [showDrop, setShowDrop] = useState(false);
  const actionButtons = useMemo(() => {
    return actions.map((action, index) => {
      return <CellAction key={index} {...action} />;
    });
  }, [actions]);

  return (
    <Box
      className="rdg-cell-actions"
      pad="small"
      align="center"
      justify="center"
      style={{ position: 'absolute', right: '0', top: '0', bottom: '0' }}
    >
      <DropButton
        label={
          <Box align="center" justify="center">
            <More size="18px" color={showDrop ? 'brand' : 'dark-3'} />
          </Box>
        }
        plain
        open={showDrop}
        onOpen={() => setShowDrop(true)}
        onClose={() => setShowDrop(false)}
        dropContent={
          <Box elevation="small" border={{ color: 'brand' }}>
            {actionButtons}
          </Box>
        }
        dropProps={{ align: { top: 'bottom' } }}
      />
    </Box>
  );
};

export default CellActionsFormatter;
