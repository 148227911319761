import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

export class Link extends React.Component {
  static propTypes = {
    href: PropTypes.string.isRequired,
    history: PropTypes.object,
    children: PropTypes.any
  };

  render() {
    const { children, href, ...rest } = this.props;

    return (
      <RouterLink to={href} {...rest}>
        {children}
      </RouterLink>
    );
  }
}
