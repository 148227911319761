import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import {
  BackLink,
  Layout,
  Loader,
  Modal,
  Notification
} from '../../components';
import {
  Box,
  Button,
  Heading,
  ResponsiveContext,
  Text,
  TextInput
} from 'grommet';
import Auth, { USER_ROLES } from '../../services/auth';
import { Copy, Trash } from 'grommet-icons';
import { DEFAULT_DATETIME_FORMAT } from '../../config/site';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FLASH_TYPES, useFlash } from '../../hooks';
import { SAVE_OPTION } from '../../redux/app';
import {
  CellActionsFormatter,
  getFilterRenderer,
  StyledRow,
  ToolbaredTable
} from '../../components/DataTable';
import { routes } from '../../config/routes';
import moment from 'moment';

function getEndpoint(key) {
  const endpoints = {
    partners: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Partners').path
  };
  return endpoints[key];
}

const ViewPartner = props => {
  // Load hidden column indexes from the storage (global state)
  const clientHiddenColumns =
    useSelector(
      state => state.app.storage.options.viewPartnerClientTableCols,
      shallowEqual
    ) || props.hiddenColumns;
  const depositHiddenColumns =
    useSelector(
      state => state.app.storage.options.viewPartnerDepositTableCols,
      shallowEqual
    ) || props.hiddenColumns;
  const dispatch = useDispatch();

  const [deposits, setDeposits] = useState(props.deposits);
  const [state, setState] = useState({
    formErrors: props.formErrors,
    activeOptions: [],
    copySuccess: false,
    chosenItem: null
  });
  const [depositsStats, setDepositStats] = useState({
    totalDepositsInRange: 0
  });

  const [flash, showFlash, dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );
  const [modal, showModal, dismissModal] = useFlash();

  const inputRef = useRef(null);

  useEffect(() => {
    let rows = props.deposits;
    if (state.activeOptions.includes('showFirstOnly')) {
      rows = props.deposits.filter(r => {
        return r.first === true;
      });
    }
    setDeposits(rows);
  }, [state.activeOptions, props.deposits]);

  const clientsColumns = useMemo(() => {
    return [
      {
        key: 'name',
        name: 'Name',
        width: 200,
        filterRenderer: getFilterRenderer('text'),
        formatter({ row }) {
          const actions = [];

          if (Auth.roleCheckVerification(USER_ROLES.administrator)) {
            actions.push({
              icon: <Trash size="small" color="status-critical" />,
              text: 'Remove client',
              callback: () => {
                showModal();
                return setState({ ...state, chosenItem: row });
              }
            });
          }
          return (
            <>
              <CellActionsFormatter actions={actions} />
              <div>{row.name}</div>
            </>
          );
        }
      },
      {
        key: 'email',
        name: 'Email',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'phone',
        name: 'Phone',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'totalDeposits',
        name: 'Total deposits',
        filterRenderer: getFilterRenderer('numeric'),
        formatter: ({ row }) => {
          if (!row.referrals || row.referrals.length === 0)
            return row.totalDeposits;
          return `${row.totalDeposits} (${row.referrals.reduce((acc, item) => {
            return (acc += item.totalDeposits);
          }, 0)})`;
        }
      },
      {
        key: 'totalProvisions',
        name: 'Total provisions',
        editable: true,
        filterRenderer: getFilterRenderer('numeric'),
        formatter: ({ row }) => {
          if (!row.referrals || row.referrals.length === 0)
            return row.totalProvisions;
          return `${row.totalProvisions} (${row.referrals.reduce(
            (acc, item) => {
              return (acc += item.totalProvisions);
            },
            0
          )})`;
        }
      }
    ].map(col => {
      return {
        ...col,
        sortable: true,
        resizable: true
      };
    });
  }, []);

  const depositsColumns = useMemo(() => {
    return [
      {
        key: 'name',
        name: 'Name',
        width: 200,
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'amount',
        name: 'Deposit amount',
        filterRenderer: getFilterRenderer('numeric'),
        formatter: ({ row }) => row.deposit.amount
      },
      {
        key: 'email',
        name: 'Email',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'phone',
        name: 'Phone',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'created',
        name: 'Date',
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        filterRenderer: getFilterRenderer('date', {
          datePicker: true,
          placeholder: 'Select Date/Range'
        }),
        formatter: ({ row }) => {
          return row.created
            ? moment(row.created).format(DEFAULT_DATETIME_FORMAT)
            : null;
        }
      }
    ].map(col => {
      return {
        ...col,
        sortable: true,
        resizable: true
      };
    });
  }, []);

  const hideColumns = key => nextState => {
    dispatch({ type: SAVE_OPTION, key, value: nextState });
  };

  const updateTotalProvisions = async (id, amount) => {
    await props
      .updateTotalProvisions({
        user_id: id,
        total_provision: amount
      })
      .then(
        () => {
          showFlash(`Total provisions updated`, FLASH_TYPES.OK);
          return true;
        },
        error => {
          showFlash('Error changing total provisions', FLASH_TYPES.CRITICAL);
          return false;
        }
      );
  };

  const depositTableOptions = useMemo(
    () => [
      {
        label: 'Show first only',
        name: 'showFirstOnly',
        onChange: e => {
          const { checked, name } = e.target;
          setState({
            ...state,
            activeOptions: checked
              ? state.activeOptions.concat([name])
              : state.activeOptions.filter(option => option !== name)
          });
        }
      }
    ],
    []
  );

  const copyToClipboard = e => {
    inputRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setState({ ...state, copySuccess: 'Copied!' });
  };

  // Manual edit of cells
  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const _rows = props.clients.slice();
    for (let i = fromRow; i <= toRow; i++) {
      if (updated.totalProvisions) {
        updateTotalProvisions(_rows[i].userId, updated.totalProvisions);
      }
      _rows[i] = { ..._rows[i], ...updated };
    }
    return _rows;
  };

  const removeClient = async id => {
    await props
      .removeClient({
        user_id: id,
        partner_id: props.currentUserId
      })
      .then(
        () => {
          showFlash(`Client was removed.`, FLASH_TYPES.OK);
          dismissModal();
          return true;
        },
        error => {
          showFlash('Error removing client', FLASH_TYPES.CRITICAL);
          return false;
        }
      );
  };

  const onDepositsDisplay = r => {
    setDepositStats(
      r.reduce(
        (acc, item) => {
          acc.totalDepositsInRange += parseFloat(item.deposit.amount);
          return acc;
        },
        { totalDepositsInRange: 0 }
      )
    );
  };

  const { copySuccess, chosenItem } = state;
  const { loading, isMyAccount, partner, clients } = props;

  return (
    <Layout
      title={
        isMyAccount
          ? 'Partner area'
          : `Partner's profile ${partner.name ? '- ' + partner.name : ''}`
      }
      fluid
    >
      {!isMyAccount && <BackLink link={getEndpoint('partners')} />}

      <Box flex={false}>
        <Box direction="row" align="center" margin={{ vertical: 'large' }}>
          <Heading
            margin={{
              right: 'small',
              vertical: 'none'
            }}
          >
            {isMyAccount ? 'Partner area' : `Partner's profile`}
          </Heading>
          {loading && <Loader />}
        </Box>
      </Box>

      {!isMyAccount && (
        <Heading level={2} size="medium" style={{ minHeight: '30px' }}>
          {partner.name}
        </Heading>
      )}

      {flash.visible && (
        <Notification
          type={flash.type ? flash.type : 'info'}
          onClose={dismissFlash}
          open={flash.visible}
          message={flash.text}
        />
      )}

      <ResponsiveContext.Consumer>
        {size => (
          <Box
            margin={{ bottom: 'medium' }}
            flex={false}
            height="medium"
            justify="center"
            align="center"
            background={'linear-gradient(to right, #376eff, #2260b6)'}
          >
            <Heading color="white" size="large" level={3}>
              {isMyAccount ? 'Your referral link' : 'Referall link'}
            </Heading>

            <Box
              width={['large', 'medium'].includes(size) ? 'large' : 'auto'}
              pad="medium"
              direction="row"
              align="center"
            >
              <TextInput
                ref={inputRef}
                readonly
                value={props.partner.registration_url || `${config.read(['app', 'referralLink'])}${
                  props.partner.link
                }`}
                size="xxlarge"
                style={{
                  background: 'white',
                  color: '#181818',
                  borderRadius: '0'
                }}
              />
              <Button
                title="Copy"
                icon={<Copy />}
                primary
                color="status-ok"
                style={{ height: '100%' }}
                onClick={copyToClipboard}
              />
            </Box>
            {copySuccess && (
              <Text color="status-ok" size="large">
                {copySuccess}
              </Text>
            )}
          </Box>
        )}
      </ResponsiveContext.Consumer>

        <>
          <Heading level={2} size="medium">
            Clients
          </Heading>

          {clients.length > 0 ? (
            <div
              style={{ width: `100%`, height: '500px', position: 'relative' }}
            >
              <div
                className="partner-clients-table"
                style={{ width: '100%', height: '100%', position: 'absolute' }}
              >
                <ToolbaredTable
                  toggleableColumns={clientsColumns}
                  columns={clientsColumns}
                  rows={clients}
                  loading={loading}
                  hiddenColumns={clientHiddenColumns}
                  onHideColumn={hideColumns('viewPartnerClientTableCols')}
                  onRowsUpdate={onGridRowsUpdated}
                />
              </div>
            </div>
          ) : (
            <Text>No clients to display.</Text>
          )}
        </>

      <Heading level={2} size="medium">
        Deposits
      </Heading>

      {deposits.length > 0 ? (
        <div style={{ width: `100%`, height: '500px', position: 'relative' }}>
          <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
            <ToolbaredTable
              toolbarProps={{
                toggleableOptions: depositTableOptions,
                activeOptions: state.activeOptions,
                children: (
                  <Box direction="row-responsive" gap="small">
                    <Text>
                      Total deposits:{' '}
                      <b>{`${depositsStats.totalDepositsInRange.toFixed(
                        2
                      )}`}</b>
                    </Text>
                  </Box>
                )
              }}
              toggleableColumns={depositsColumns}
              columns={depositsColumns}
              rows={deposits}
              loading={loading}
              hiddenColumns={depositHiddenColumns}
              onHideColumn={hideColumns('viewPartnerDepositTableCols')}
              onRowsDisplay={onDepositsDisplay}
              rowRenderer={p => (
                <StyledRow
                  {...p}
                  className={`${p.className || ''}${
                    p.row.deposit.first ? ' green' : ''
                  }`}
                />
              )}
            />
          </div>
        </div>
      ) : (
        <Text>No deposits to display.</Text>
      )}

      {modal.visible && (
        <Modal open={modal.visible} heading={'Confirm'} onClose={dismissModal}>
          <Text>
            Are you sure you want to remove client{' '}
            {chosenItem && chosenItem.email}?
          </Text>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            <Button
              label="Cancel"
              onClick={dismissModal}
              disabled={loading}
              color="dark-3"
            />
            <Button
              label={
                <Text color="white">
                  <strong>Delete</strong>
                </Text>
              }
              disabled={loading}
              onClick={() => removeClient(chosenItem.userId)}
              primary
              color="status-critical"
            />
          </Box>
        </Modal>
      )}
    </Layout>
  );
};

ViewPartner.propTypes = {
  loading: PropTypes.bool,
  isMyAccount: PropTypes.bool,
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateTotalProvisions: PropTypes.func.isRequired,
  removeClient: PropTypes.func.isRequired,
  notification: PropTypes.object,
  hiddenColumns: PropTypes.array,
  formErrors: PropTypes.object,
  clients: PropTypes.array,
  deposits: PropTypes.array,
  partner: PropTypes.object
};

ViewPartner.defaultProps = {
  loading: false,
  isMyAccount: true,
  formErrors: {},
  clients: [],
  deposits: [],
  hiddenColumns: [],
  partner: {},
  notification: {
    text: '',
    type: 'info'
  }
};

export default ViewPartner;
