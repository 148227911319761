import React from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import { default as Traders } from './Traders';
import { default as ViewTrader } from './ViewTrader';
import { default as Profile } from './Profile';
import { AddTrader } from './AddTrader';
import Auth from '../../services/auth';

const FETCH_TRADERS_LIST_QUERY = gql`
  {
    clients(first: 3000) {
      nodes {
        id
        userId
        mt_id
        name
        email
        referer {
          name
          userId
          id
        }
        totalDeposits
        hasDemoAccount
        documentsProvided
        documents {
          nodes {
            id
            document_id
            name
            path
            status
            uploaded
          }
        }
        first_name
        last_name
        status
        registeredDate
        phone
      }
    }
  }
`;

const FETCH_TRADER_QUERY = gql`
  query clientBy($userId: String!) {
    clientBy(userId: $userId) {
      id
      email
      mt_id
      name
      enable_read_only
      locked_out
      hasRealAccount
      accounts {
        nodes {
          id
          mt_id
          account_type
          account_currency
          user_id
          ask
          cashback
          deposit
          leverage
        }
      }
      documents {
        nodes {
          id
          document_id
          name
          path
          status
          uploaded
        }
      }
      first_name
      last_name
      address
      born
      city
      country
      education
      zip
      phone
      income
      experience
      registeredDate
    }
  }
`;

const REMOVE_USER_MUTATION = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      deletedId
    }
  }
`;

const REMOVE_TRADING_ACCOUNT_MUTATION = gql`
  mutation removeTradingAccount($input: RemoveTradingAccountInput!) {
    removeTradingAccount(input: $input) {
      deletedId
    }
  }
`;

const UPDATE_CLIENT_MUTATION = gql`
  mutation updateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      id
    }
  }
`;

const ADD_TRADER_MUTATION = gql`
  mutation createClient($input: CreateClientInput!) {
    createClient(input: $input) {
      id
    }
  }
`;

const OPEN_ORDER_MUTATION = gql`
  mutation openOrder($input: OpenOrderInput!) {
    openOrder(input: $input) {
      result
    }
  }
`;

export const TradersConnected = props => (
  <Query query={FETCH_TRADERS_LIST_QUERY}>
    {({ loading, error, data }) => (
      <Mutation
        mutation={REMOVE_USER_MUTATION}
        refetchQueries={[{ query: FETCH_TRADERS_LIST_QUERY }]}
      >
        {removeUser => (
          <Mutation
            mutation={UPDATE_CLIENT_MUTATION}
            refetchQueries={[{ query: FETCH_TRADERS_LIST_QUERY }]}
          >
            {(updateClient, updateMutationProps) => {
              return (
                <Traders
                  traders={
                    data &&
                    data.clients &&
                    data.clients.nodes.map(trader => ({
                      ...trader,
                      referredBy: trader.referer ? trader.referer.name : null
                    }))
                  }
                  removeTrader={async data =>
                    removeUser({
                      variables: {
                        input: {
                          ...data,
                          clientMutationId: 'removeTrader' // WPGraphQL requires this
                        }
                      }
                    })
                  }
                  loading={
                    loading || removeUser.loading || updateMutationProps.loading
                  }
                  notification={
                    (updateMutationProps.error || error) && {
                      text:
                        (error && error.message) ||
                        (updateMutationProps.error &&
                          updateMutationProps.error.message),
                      type: 'critical'
                    }
                  }
                  {...props}
                  updateItem={async data =>
                    updateClient({
                      variables: {
                        input: {
                          ...data,
                          clientMutationId: 'updateTrader' // WPGraphQL requires this
                        }
                      }
                    })
                  }
                />
              );
            }}
          </Mutation>
        )}
      </Mutation>
    )}
  </Query>
);

export const ViewTraderConnected = ({ isMyAccount, ...props }) => {
  const userId = props.match.params.id;

  return (
    <Query query={FETCH_TRADER_QUERY} variables={{ userId }}>
      {loadDataQueryProps => {
        return (
          <Mutation
            mutation={OPEN_ORDER_MUTATION}
          >
            {(openOrder, openOrderProps) => (
              <Mutation
                mutation={UPDATE_CLIENT_MUTATION}
                refetchQueries={[
                  { query: FETCH_TRADER_QUERY, variables: { userId: userId } }
                ]}
              >
                {(updateClient, { loading, error }) => {
                  return (
                    <ViewTrader
                      userId={userId}
                      loading={loadDataQueryProps.loading || loading}
                      notification={
                        (loadDataQueryProps.error ||
                          error ||
                          openOrderProps.error) && {
                          text:
                            (error && error.message) ||
                            (loadDataQueryProps.error &&
                              loadDataQueryProps.error.message) ||
                            (openOrderProps.error &&
                              openOrderProps.error.message),
                          type: 'critical'
                        }
                      }
                      trader={
                        loadDataQueryProps.data &&
                        loadDataQueryProps.data.clientBy
                      }
                      {...props}
                      openOrder={async data =>
                        openOrder({
                          variables: {
                            input: {
                              ...data,
                              clientMutationId: 'openOrder'
                            }
                          }
                        })
                      }
                      updateItem={async data =>
                        updateClient({
                          variables: {
                            input: {
                              ...data,
                              clientMutationId: 'updateTrader' // WPGraphQL requires this
                            }
                          }
                        })
                      }
                    />
                  );
                }}
              </Mutation>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export const ProfileConnected = ({ isMyAccount, ...props }) => {
  const userId = isMyAccount ? Auth.getUser().userId : props.match.params.id;

  return (
    // Id passed in route, FETCH data to edit
    <Query query={FETCH_TRADER_QUERY} variables={{ userId }}>
      {loadDataQueryProps => {
        return (
          <Mutation
            mutation={REMOVE_TRADING_ACCOUNT_MUTATION}
            refetchQueries={[
              { query: FETCH_TRADER_QUERY, variables: { userId: userId } }
            ]}
          >
            {(removeAccount, { loadingRemove, errorRemove }) => (
              <Mutation
                mutation={UPDATE_CLIENT_MUTATION}
                refetchQueries={[
                  { query: FETCH_TRADER_QUERY, variables: { userId: userId } }
                ]}
              >
                {(updateClient, { loading, error }) => {
                  return (
                    <Profile
                      isMyAccount={isMyAccount}
                      currentUserId={userId}
                      loading={
                        loadDataQueryProps.loading || loading || loadingRemove
                      }
                      notification={
                        (loadDataQueryProps.error || error) && {
                          text:
                            (error && error.message) ||
                            (loadDataQueryProps.error &&
                              loadDataQueryProps.error.message) ||
                            (errorRemove && errorRemove.message),
                          type: 'critical'
                        }
                      }
                      data={
                        loadDataQueryProps.data &&
                        loadDataQueryProps.data.clientBy
                      }
                      accounts={
                        loadDataQueryProps.data &&
                        loadDataQueryProps.data.clientBy
                          ? loadDataQueryProps.data.clientBy.accounts.nodes
                          : []
                      }
                      documents={
                        loadDataQueryProps.data &&
                        loadDataQueryProps.data.clientBy
                          ? loadDataQueryProps.data.clientBy.documents.nodes
                          : []
                      }
                      {...props}
                      removeAccount={async data => {
                        removeAccount({
                          variables: {
                            input: {
                              ...data,
                              clientMutationId: 'removeTradingAccount' // WPGraphQL requires this
                            }
                          }
                        });
                      }}
                      updateItem={async data =>
                        updateClient({
                          variables: {
                            input: {
                              ...data,
                              clientMutationId: 'updateTrader' // WPGraphQL requires this
                            }
                          }
                        })
                      }
                    />
                  );
                }}
              </Mutation>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export const AddTraderConnected = props => {
  return (
    <Mutation
      mutation={ADD_TRADER_MUTATION}
      refetchQueries={[{ query: FETCH_TRADERS_LIST_QUERY }]}
    >
      {(createClient, { loading, error }) => (
        <AddTrader
          loading={loading}
          notification={
            error && {
              text: error && error.message,
              type: 'critical'
            }
          }
          {...props}
          createUser={async data => {
            return createClient({
              variables: {
                input: {
                  ...data,
                  clientMutationId: 'createClient' // WPGraphQL requires this
                }
              }
            });
          }}
        />
      )}
    </Mutation>
  );
};
