import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { Box, TextInput, Button } from 'grommet';
import { Search, Close } from 'grommet-icons';

export class SuggestionsTextInput extends React.Component {
  static propTypes = {
    selected: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    value: PropTypes.string,
    suggestionData: PropTypes.array,
    textInputProps: PropTypes.object,
    indexKey: PropTypes.string,
    disableSelectedTags: PropTypes.bool,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    suggestionItemRender: PropTypes.func
  };

  static defaultProps = {
    selected: [],
    value: '',
    disableSelectedTags: false,
    suggestionData: [],
    textInputProps: { plain: true, placeholder: 'Start typing' }
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: !Array.isArray(props.selected)
        ? [props.selected]
        : props.selected,
      value: props.value,
      suggestionOpen: false
    };
    this.boxRef = createRef();
  }

  componentDidMount() {
    this.forceUpdate();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      selected: !Array.isArray(nextProps.selected)
        ? [nextProps.selected]
        : nextProps.selected
    };
  }

  onChange = event => {
    this.setState({ value: event.target.value });

    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  };

  onSelect = event => {
    this.setState({
      selected: this.state.selected.concat(event.suggestion.value),
      value: ''
    });

    if (this.props.onSelect) {
      this.props.onSelect(event.suggestion.value);
    }
  };

  renderSuggestions = () => {
    const { value, selected } = this.state,
      { indexKey, suggestionItemRender, suggestionData } = this.props;

    // Ask for at least 2 characters to trigger suggestion
    if (!indexKey || value.length < 2) return;

    return suggestionData
      .filter(
        item =>
          item[indexKey].toLowerCase().indexOf(value.toLowerCase()) >= 0 &&
          !selected.find(obj => obj[indexKey] === item[indexKey])
      )
      .map((item, index, list) => ({
        label: (
          <Box
            direction="row"
            align="center"
            gap="small"
            border={index < list.length - 1 ? 'bottom' : undefined}
            pad="small"
          >
            {suggestionItemRender && suggestionItemRender(item)}
          </Box>
        ),
        value: item
      }));
  };

  removeSelected(item) {
    const index = this.state.selected.findIndex(
      selected => selected[this.props.indexKey] === item[this.props.indexKey]
    );
    if (index >= 0) {
      this.setState({ selected: this.state.selected.splice(index, 1) });
    }
  }

  render() {
    const { suggestionOpen, value, selected } = this.state,
      { textInputProps, disableSelectedTags, indexKey } = this.props;

    return (
      <Box>
        {selected.length > 0 && !disableSelectedTags && (
          <Box
            direction="row"
            wrap={true}
            pad="small"
            border={{
              side: 'all',
              color: 'border'
            }}
            round="xsmall"
            style={{
              borderBottom: 'none',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px'
            }}
          >
            {selected.map(item => (
              <Button
                key={item[indexKey]}
                plain
                primary
                icon={<Close color="dark-3" size="small" />}
                color="brand"
                onClick={() => this.removeSelected(item)}
                style={{ padding: '2px 7px', margin: '2px' }}
                label={item[indexKey]}
              />
            ))}
          </Box>
        )}
        <Box
          ref={this.boxRef}
          direction="row"
          align="center"
          pad={{ horizontal: 'small', vertical: 'xsmall' }}
          round="xsmall"
          elevation={suggestionOpen ? 'medium' : undefined}
          border={{
            side: 'all',
            color: 'border'
          }}
          style={{
            borderTopLeftRadius: selected.length > 0 ? '0' : '',
            borderTopRightRadius: selected.length > 0 ? '0' : '',
            borderTop: selected.length > 0 ? 'none' : '',
            borderBottomLeftRadius: suggestionOpen ? '0px' : '',
            borderBottomRightRadius: suggestionOpen ? '0px' : ''
          }}
        >
          <Search color="brand" />
          <TextInput
            type="search"
            {...SuggestionsTextInput.defaultProps.textInputProps}
            {...textInputProps}
            dropTarget={this.boxRef.current}
            value={value}
            onChange={this.onChange}
            onSelect={this.onSelect}
            suggestions={this.renderSuggestions()}
            onSuggestionsOpen={() => this.setState({ suggestionOpen: true })}
            onSuggestionsClose={() => this.setState({ suggestionOpen: false })}
          />
        </Box>
      </Box>
    );
  }
}
