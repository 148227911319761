import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropButton, Box, Text, Calendar, Button, MaskedInput } from 'grommet';
import { Checkmark, Schedule } from 'grommet-icons';
import moment from 'moment';

export default class DateTimeButton extends React.Component {
  static propTypes = {
    date: PropTypes.any,
    placeholder: PropTypes.string,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    calendarProps: PropTypes.object,
    timePicker: PropTypes.bool,
    datePicker: PropTypes.bool,
    range: PropTypes.bool,
    onDateSelect: PropTypes.func,
    time: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    onTimeChange: PropTypes.func,
    open: PropTypes.bool
  };

  static defaultProps = {
    open: false,
    range: false,
    timePicker: true,
    datePicker: false,
    dateFormat: 'DD/MM/YYYY',
    timeFormat: 'hh:mm:ss A',
    placeholder: 'Select date & time',
    calendarProps: {},
    time: []
  };

  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      time: typeof props === 'string' ? [props.time] : props.time
    };
  }

  onOpen = () => {
    this.setState({ open: true });
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  onClose = () => {
    this.setState({ open: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  /**
   * Will return string representation of selected date/time values.
   * Returns from {date} ?{time} to {date} ?{time} format if date range is picked. Or returns {date} ${time - time}
   * if single date and time range is picked
   * @return {string}
   */
  parseOutputDate() {
    const { date, time, dateFormat } = this.props,
      output = ['', '', '', ''],
      parse = (date, format) =>
        Array.isArray(date)
          ? date[0].map(arg => parse(arg, format))
          : moment(date).format(format);

    let parsedTime = Array.isArray(time) ? time : [time],
      parsedDate = parse(date, dateFormat);

    // Range date
    if (Array.isArray(parsedDate)) {
      output[0] = `${parsedDate[0]}`;
      output[2] = `- ${parsedDate[1]}`;

      // Time range
      if (parsedTime.length === 2) {
        output[1] = `${parsedTime[0] ? `${parsedTime[0]}` : ''}`;
        output[3] = `${parsedTime[1] ? ` ${parsedTime[1]}` : ''}`;
      }
    } else {
      // Single date
      output[0] = `${parsedDate}`;
      output[1] = `${parsedTime[0] ? parsedTime[0] : ''}`;
      output[2] = `${parsedTime[1] ? ` - ${parsedTime[1]}` : ''}`;
      // Single time and single date
    }
    return output.join(' ');
  }

  onTimeChange = (index, value) => {
    if (this.props.onTimeChange) {
      const time = this.state.time;
      time[index] = value;
      this.props.onTimeChange(time);
      this.setState({ time });
    }
  };

  render() {
    const {
        date,
        onDateSelect,
        time,
        calendarProps,
        range,
        placeholder,
        datePicker,
        timePicker,
        style = {}
      } = this.props,
      { open } = this.state;

    return (
      <DropButton
        open={open}
        onClose={this.onClose}
        onOpen={this.onOpen}
        style={{ width: '100%', height: '100%', background: '#333' }}
        dropContent={
          <DropContent
            datePicker={datePicker}
            calendarProps={calendarProps}
            onDateSelect={onDateSelect}
            date={date}
            timePicker={timePicker}
            range={range}
            onTimeChange={this.onTimeChange}
            time={time}
            onClose={this.onClose}
          />
        }
        {...style}
      >
        <Box
          direction="row"
          gap="medium"
          fill={true}
          align="center"
          justify="between"
          pad="xsmall"
        >
          <Text size="small" className="text-ellipsis">
            {date ? this.parseOutputDate() : placeholder}
          </Text>
          <Schedule size="small" />
        </Box>
      </DropButton>
    );
  }
}

const DropContent = ({
  date,
  onDateSelect,
  time,
  timePicker,
  onClose,
  range,
  onTimeChange,
  calendarProps,
  datePicker,
  ...props
}) => {
  const [calendarKey, setCalendarKey] = useState(Math.random());
  const [fromDateInputValue, setFromInputValue] = useState(undefined);
  const [toDateInputValue, setToInputValue] = useState(undefined);
  const datePickers = [[fromDateInputValue, setFromInputValue, 'From']].concat(
    range ? [[toDateInputValue, setToInputValue, 'To']] : []
  );

  const dateProp = Array.isArray(date) ? { dates: date } : { date }, // Calendar needs dates prop when range is selected and date prop if single date
    timeProp = time.length === 2 ? [time[0], time[1]] : time,
    timePickers = range ? [0, 1] : [0];

  const handleSetDate = () => {
    const from = moment(fromDateInputValue, 'DD/MM/YYYY');
    const to = moment(toDateInputValue, 'DD/MM/YYYY');
    let changed = false;

    if (from.isValid() && !to.isValid()) {
      onDateSelect(from.toISOString());
      changed = true;
    } else if (from.isValid() && to.isValid()) {
      onDateSelect([[from.toISOString(), to.toISOString()]]);
      changed = true;
    }

    if (changed) {
      setCalendarKey(Math.random());
      setFromInputValue(undefined);
      setToInputValue(undefined);
    }
  };

  return (
    <Box pad="small" gap="small" width={datePicker ? '300px' : 'auto'}>
      {datePicker && (
        <Box direction="row" gap="xsmall" align="end">
          {datePickers.map((picker, i) => (
            <Box key={`datePicker_${i}`}>
              <Text as="label" htmlFor={`datePicker_${i}`}>
                {picker[2]}:
              </Text>
              <MaskedInput
                id={`datePicker_${i}`}
                mask={[
                  {
                    length: [1, 2],
                    regexp: /^1[0-9]?$|^2[0-9]?$|^3[0-1]?$|^0[1-9]?$/,
                    placeholder: 'DD'
                  },
                  { fixed: '/' },
                  {
                    length: [1, 2],
                    regexp: /^1[0-2]?$|^0[1-9]?$/,
                    placeholder: 'MM'
                  },
                  { fixed: '/' },
                  {
                    length: [4],
                    regexp: /^\d{4}?$|^\d{3}?$|^\d{2}?$|^\d{1}?$/,
                    placeholder: 'YYYY'
                  }
                ]}
                value={picker[0]}
                onChange={({ target }) => picker[1](target.value)}
              />
            </Box>
          ))}

          <Button
            icon={<Checkmark size="small" />}
            primary
            color="status-ok"
            title="Apply filter"
            onClick={handleSetDate}
            style={{ height: '34px' }}
          />
        </Box>
      )}

      <Calendar
        key={calendarKey.toString()}
        {...dateProp}
        onSelect={onDateSelect}
        showAdjacentDays={true}
        range={range}
        {...calendarProps}
      />

      {timePicker && (
        <Box gap="small">
          <Text margin="small">Select time{range && ' range'}</Text>
          {timePickers.map((picker, i) => {
            return (
              <MaskedInput
                key={'picker' + i}
                mask={[
                  {
                    length: [1, 2],
                    options: [
                      '1',
                      '2',
                      '3',
                      '4',
                      '5',
                      '6',
                      '7',
                      '8',
                      '9',
                      '10',
                      '11',
                      '12'
                    ],
                    regexp: /^1[1-2]$|^[0-9]$/,
                    placeholder: `${
                      range ? (i === 0 ? 'from - ' : 'to - ') : ''
                    }hh`
                  },
                  { fixed: ':' },
                  {
                    length: 2,
                    options: ['00', '15', '30', '45'],
                    regexp: /^[0-5][0-9]$|^[0-9]$/,
                    placeholder: 'mm'
                  },
                  { fixed: ' ' },
                  {
                    length: 2,
                    options: ['am', 'pm'],
                    regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                    placeholder: 'ap'
                  }
                ]}
                value={timeProp[i]}
                name={i.toString()}
                onChange={({ target }) => onTimeChange(i, target.value)}
              />
            );
          })}
        </Box>
      )}
      <Button label="Close" color="brand" primary onClick={onClose} full />
    </Box>
  );
};

DropContent.propTypes = {
  date: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onDateSelect: PropTypes.func,
  time: PropTypes.array,
  datePicker: PropTypes.bool,
  onClose: PropTypes.func,
  range: PropTypes.bool,
  timePicker: PropTypes.bool,
  onTimeChange: PropTypes.func,
  calendarProps: PropTypes.object
};

DropContent.defaultProps = {
  time: [],
  timePicker: true,
  datePicker: false
};
