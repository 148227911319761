import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTimeButton } from '../../';
import moment from 'moment';
import { isEmptyArray, parseFilterTermValue } from '../data';

function parseDateFromDatePicker(date) {
  return moment(date, moment.ISO_8601).format('YYYY-MM-DD');
}

const DateRangeFilter = props => {
  const [filterValue, setFilterValue] = useState(
    parseFilterTermValue(props.value)
  );

  useEffect(() => {
    setFilterValue(parseFilterTermValue(props.value));
  }, [props.value]);

  function isWithinRange(value, range) {
    if (value !== undefined && range !== undefined) {
      const format = props.column.dateFormat || props.inputFormat;
      return moment(value.toString(), format).isBetween(
        range[0],
        range[1],
        'day',
        '[]'
      );
    }
    return false;
  }

  function filterValues(row, columnFilter, columnKey) {
    let include = true;
    if (columnFilter === null) {
      include = false;
    } else if (
      columnFilter.filterTerm &&
      !isEmptyArray(columnFilter.filterTerm)
    ) {
      include = columnFilter.filterTerm.some(filterTerm => {
        let range = filterTerm.value;
        if (Array.isArray(range)) {
          range = range[0].map(date => parseDateFromDatePicker(date));
        } else {
          range = parseDateFromDatePicker(range);
          range = [range, range];
        }
        return isWithinRange(row[columnKey], range) === true;
      });
    }
    return include;
  }

  /**
   * @param date ISO date
   */
  function handleChange(date) {
    const filters = date ? [{ value: date }] : null;
    setFilterValue(date);
    props.onChange({
      filterTerm: filters,
      column: props.column,
      rawValue: date,
      filterValues
    });
  }

  return (
    <div className={`rdg-filter-container ${props.className}`}>
      <DateTimeButton
        style={{ height: '100%', fontWeight: 'normal' }}
        name={`filter-${props.column.key}`}
        placeholder={props.placeholder}
        range={props.range}
        timePicker={props.timePicker}
        datePicker={props.datePicker}
        date={filterValue}
        onDateSelect={handleChange}
      />
    </div>
  );
};

DateRangeFilter.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  range: PropTypes.bool,
  column: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  datePicker: PropTypes.bool,
  timePicker: PropTypes.bool,
  getValidFilterValues: PropTypes.func,
  inputFormat: PropTypes.string
};

DateRangeFilter.defaultProps = {
  range: true,
  className: 'form-group date-range-filter',
  datePicker: false,
  timePicker: false,
  dateTimeButtonProps: {},
  inputFormat: 'DD-MM-YYYY HH:mm:ss',
  placeholder: 'Select date range'
};

export default DateRangeFilter;
