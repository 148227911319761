import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import 'react-data-grid/dist/react-data-grid.css';
import './DataTable.css';
import ReactDataGrid from 'react-data-grid';
import { sortRows } from './data';
import NoRows from './NoRows';

const DataTable = props => {
  const canvas = useRef(null);

  useEffect(() => {
    window.addEventListener('dataGridColumnsHidden', onColumnsHidden);
    window.addEventListener('dataGridScrollToRow', onScrollToRow);

    return () => {
      window.removeEventListener('dataGridScrollToRow', onScrollToRow);
      window.removeEventListener('dataGridColumnsHidden', onColumnsHidden);
    };
  }, []);

  useEffect(() => {
    if (!canvas.current) {
      return;
    }
    const event = new CustomEvent('dataGridScrollToRow', {
      detail: props.scrollToRowIndex
    });
    window.dispatchEvent(event);
  }, [props.scrollToRowIndex]);

  function onScrollToRow(event) {
    const canvas = getCanvasRef();
    if (event.detail === undefined || !canvas) return;

    if (event.detail === 0) {
      canvas.scroll({
        top: 0
      });
    }
  }

  /**
   * Columns hiding caused weird row rendering bug when user table scroll position was not at very top or near the top.
   * Dispatching scroll event resolves this issue
   * @param event
   */
  function onColumnsHidden() {
    const canvas = getCanvasRef();
    if (canvas) {
      const event = new Event('scroll');
      setTimeout(() => {
        canvas.dispatchEvent(event);
      }, 10);
    }
  }

  function getCanvasRef() {
    return canvas.current;
  }

  function filterStrategy(r) {
    let valid = true;
    for (let key in props.filters) {
      valid =
        valid &&
        (typeof props.filters[key] === 'object'
          ? props.filters[key].filterValues(r, props.filters[key], key)
          : true);
    }
    return valid;
  }

  const rowsToDisplay = useMemo(() => {
    const filtered = props.rows.filter(filterStrategy);
    const sorted = props.sortStrategy(
      filtered,
      props.sortColumn,
      props.sortDirection
    )(filtered);

    if (props.onRowsDisplay) {
      props.onRowsDisplay(sorted);
    }
    return sorted;
  }, [props.rows, props.sortDirection, props.sortColumn, props.filters]);

  const { width, height, loading, gridRef, ...rest } = props;

  return (
    <div className="themedDataGrid">
      <ReactDataGrid
        ref={gridRef}
        width={width}
        height={height}
        emptyRowsRenderer={props => <NoRows loading={loading} {...props} />}
        {...rest}
        rows={rowsToDisplay}
      />
    </div>
  );
};

DataTable.propTypes = {
  loading: PropTypes.bool,
  rows: PropTypes.array,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  gridRef: PropTypes.object,
  selectedRows: PropTypes.objectOf(Set),
  enableFilters: PropTypes.bool,
  sortColumn: PropTypes.string,
  filters: PropTypes.object,
  sortDirection: PropTypes.string,
  onSort: PropTypes.func,
  sortStrategy: PropTypes.func,
  onRowsDisplay: PropTypes.func,
  onFiltersChange: PropTypes.func,
  onRowsUpdate: PropTypes.func
};

DataTable.defaultProps = {
  loading: false,
  enableFilters: false,
  rows: [],
  filters: {},
  sortDirection: 'NONE',
  onSort: () => undefined,
  sortStrategy: sortRows,
  sortColumn: undefined
};

export default DataTable;
