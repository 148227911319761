import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet/es6';
import { Upload } from 'grommet-icons';

export class Dropzone extends React.Component {
  static propTypes = {
    onFilesAdded: PropTypes.func,
    multiple: PropTypes.bool,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.element
  };

  static defaultProps = {
    multiple: true,
    label: 'Click to upload or drag files here',
    disabled: false
  };

  constructor(props) {
    super(props);

    this.state = {
      highlight: false
    };

    this.dropzoneInput = React.createRef();
  }

  onFilesAdded = event => {
    if (this.props.disabled) return;

    const files = event.target.files;

    if (this.props.onFilesAdded) {
      const fileArr = this.fileListToArray(files);
      this.props.onFilesAdded(fileArr);
    }
  };

  fileListToArray(list) {
    const array = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  onDragOver = event => {
    event.preventDefault();
    if (this.props.disabled) return;

    this.setState({ highlight: true });
  };

  onDragLeave = () => {
    this.setState({ highlight: false });
  };

  onDrop = event => {
    event.preventDefault();

    if (this.props.disabled) return;

    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ highlight: false });
  };

  openFileDialog = event => {
    if (this.props.disabled) return;
    this.dropzoneInput.current.click();
  };

  render() {
    const { icon, multiple, disabled, label } = this.props,
      { highlight } = this.state;

    return (
      <Box
        round="small"
        border={{ style: 'dashed', size: '2px', color: 'grey' }}
        width="full"
        height="300px"
        justify="center"
        background={
          disabled
            ? 'rgba(0,0,0,0.1)'
            : highlight
            ? 'rgba(0,110,255,0.2)'
            : 'white'
        }
        align="center"
        margin={{ bottom: 'small' }}
        style={{ cursor: disabled ? 'default' : 'pointer' }}
        onClick={this.openFileDialog}
        onDrop={this.onDrop}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
      >
        {icon ? (
          icon
        ) : (
          <Upload color="grey" style={{ opacity: disabled ? 0.5 : 1 }} />
        )}
        <Text weight="bold" margin={{ vertical: 'medium' }}>
          {label}
        </Text>
        <input
          ref={this.dropzoneInput}
          type="file"
          multiple={multiple}
          onChange={this.onFilesAdded}
          style={{ display: 'none' }}
        />
      </Box>
    );
  }
}
