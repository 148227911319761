export const typeDefs = `

  type LocalUser {
    authToken: String
    refreshToken: String
    user: User
  }
  
  type User {
    id: ID!
    userId: String!
    name
    email
  }
  
  type BankAccount {
    id: ID!
    account_id: String
    account_number: String
    bank_name: String
    currency: String
    iban: String
    account_id: String
    owner_name: String
    swift: String
    user_id: String
  }
 
 type SubmitTicketInput {
    message: String!
    parentId: String
    senderId: ID!
    subject: String!
    userId: ID!
 }
  
  type Client {
    email: String!
    password: String
    first_name: String
    last_name: String
    education: String
    phone: String
    born: String
    income: String
    experience: String
    country: String
    zip: String
    city: String
    address: String
  }
  
  type CreateWithdrawalInput {
    user_id: String!
    account_id: String!
    amount: String!
    bank_account: String!
    bank_name: String!
    note: String
    status: String
  }
  
  type UpsertDepositInput {
    id: ID
    user_id: String!
    amount: String!
    created: String
  }
  
  type DeleteDepositInput {
    id: ID!
  }
  
  type DeleteWithdrawalInput {
    id: ID!
  }
  
  type UpdateTotalProvisionsFromClientInput {
    user_id: String!
    total_provision: Float!
  }
  
  type AddPartnerClientInput {
    user_id: String!
    partner_id: String!
  }
  
  type DeletePartnerClientInput {
    user_id: String!
    partner_id: String!
  }
  
  type UpdateClientInput {
    address: String
    born: String
    city: String
    clientMutationId: String!
    country: String
    education: String
    email: String
    experience: String
    first_name: String
    id: ID!
    income: String
    last_name: String
    mt_id: String
    password: String
    phone: String
    registeredDate: String
    status: String
    Clients status
    zip: String
  }
  
  type DeleteUserInput {
    id: ID!
  }
  
  type CreatePartnerInput {
    userId: ID!
    website: String,
    info: String!
  }
  
  type CreateClientInput {
    address: String
    born: String
    city: String
    clientMutationId: String!
    country: String
    education: String
    email: String
    experience: String
    first_name: String
    id: ID!
    income: String
    last_name: String
    mt_id: String
    password: String
    phone: String
    registeredDate: String
    status: String
    zip: String
  }
  
  type RegistrationInput {
    email: String!
    password: String!
    first_name: String!
    last_name: String!
    phone: String!
    born: String!
    clientMutationId!
    userId: String
  }
  
  type OpenLiveAccountInput {
    email: String!
    password: String!
    leverage: String!
    account_type: String!
    net: String!
    ask: String!
    deposit: String!
    cashback: String!
    first_name: String!
    last_name: String!
    education: String!
    phone: String!
    born: String!
    income: String!
    experience: String!
    country: String!
    zip: String!
    city: String!
    address: String!
    clientMutationId!
    userId: String
  }
  
   type OpenDemoAccountInput {
    email: String!
    password: String!
    leverage: String!
    account_type: String!
    first_name: String!
    last_name: String!
    phone: String!
    born: String!
    country: String!
    clientMutationId!
    userId: String
  }
  
  type SendPasswordResetEmailInput {
    clientMutationId: String!
    username: String!
  }
  
  type ResetUserPasswordInput {
    clientMutationId: String!
    key: String
    login: String
    password: String
  }
  
  type CreateBankAccountInput {
    account_number: String!
    bank_name: String!
    currency: String!
    iban: String!
    owner_name: String!
    swift: String!
    user_id: String!
    clientMutationId!
  }
    
  type DeleteBankAccountInput {
    id: ID!
    clientMutationId!
  }    
  
  type UpdateBankAccountInput {
    id: ID!
    account_number: String!
    bank_name: String!
    currency: String!
    iban: String!
    owner_name: String!
    swift: String!
    user_id: String!
    clientMutationId!
  }

  type LoginInput {
    username: String!
    password: String!
    clientMutationId!
  }
  
  type Query {

  }
  
  type Mutation {
  	logout(): Boolean
 }
  
  schema {
    query: Query
    mutation: Mutation
  }
`;
