import React from 'react';
import gql from 'graphql-tag';
import {Mutation, Query} from 'react-apollo';
import {useQuery} from '@apollo/react-hooks';
import {AddDemoAccount} from './AddDemoAccount';
import {AddRealAccount} from './AddRealAccount';
import Auth from '../../services/auth';
import ViewAccount from './ViewAccount';
import * as R from 'ramda';
import AccountHistory from './AccountHistory';
import {ACCOUNT_TYPES} from '../../config/site';
import TradingAccounts from './TradingAccounts';

const FETCH_TRADING_ACCOUNTS = gql`
{
  statistics {
    accounts(first: 3000, where: {accountTypes: ["Standard", "VIP", "VIP Platinum", "Akademie 1000"]}) {
      nodes {
        account_type
        user_id
        mt_id
        user {
          mt_id
          name
          enable_read_only
        }
        balance
      }
    }
  }
}
`;

const FETCH_TRADER_QUERY = gql`
  query clientBy($userId: String!) {
    clientBy(userId: $userId) {
      id
      mt_id
      email
      hasRealAccount
      accounts {
        nodes {
          id
          account_type
          account_currency
          user_id
          ask
          cashback
          deposit
          leverage
        }
      }
      documentsProvided
      first_name
      last_name
      address
      born
      city
      country
      education
      zip
      phone
      income
      experience
      registeredDate
    }
  }
`;

const OPEN_LIVE_ACCOUNT_MUTATION = gql`
  mutation openLiveAccount($input: OpenLiveAccountInput!) {
    openLiveAccount(input: $input) {
      response {
        content
        title
      }
    }
  }
`;

const OPEN_DEMO_ACCOUNT_MUTATION = gql`
  mutation openDemoAccount($input: OpenDemoAccountInput!) {
    openDemoAccount(input: $input) {
      response {
        content
        title
      }
    }
  }
`;

export const CLOSE_ORDER_MUTATION = gql`
  mutation closeOrder($input: CloseOrderInput!) {
    closeOrder(input: $input) {
      result
    }
  }
`;

const FETCH_ACCOUNT_HISTORY = gql`
  query clientBy($userId: String!) {
    clientBy(userId: $userId) {
      id
      mt_id
      email
      accounts {
        nodes {
          id
          login
          account_type
          mt_id
          account_history {
            nodes {
              agent_commission
              cmd
              close_price
              isGreen
              isRed
              close_time
              comment
              commission
              id
              login
              open_price
              open_time
              order
              profit
              sl
              swap
              volume
              tp
              symbol
            }
          }
        }
      }
    }
  }
`;

export const AddRealConnected = props => {
  return (
    <Mutation
      mutation={OPEN_LIVE_ACCOUNT_MUTATION}
      refetchQueries={[
        {
          query: FETCH_TRADER_QUERY,
          variables: { userId: Auth.getUser().userId }
        }
      ]}
    >
      {(addRealAccount, { loading, error }) => (
        <AddRealAccount
          loading={loading}
          notification={
            error && {
              text: error && error.message,
              type: 'critical'
            }
          }
          {...props}
          handleSubmit={async data => {
            return addRealAccount({
              variables: {
                input: {
                  ...data,
                  userId: Auth.getUser().id,
                  clientMutationId: 'openLiveAccount' // WPGraphQL requires this
                }
              }
            });
          }}
        />
      )}
    </Mutation>
  );
};

export const AddDemoConnected = props => {
  return (
    <Mutation
      mutation={OPEN_DEMO_ACCOUNT_MUTATION}
      refetchQueries={[
        {
          query: FETCH_TRADER_QUERY,
          variables: { userId: Auth.getUser().userId }
        }
      ]}
    >
      {(addDemoAccount, { loading, error }) => (
        <AddDemoAccount
          loading={loading}
          notification={
            error && {
              text: error && error.message,
              type: 'critical'
            }
          }
          {...props}
          handleSubmit={async data => {
            return addDemoAccount({
              variables: {
                input: {
                  ...data,
                  userId: Auth.getUser().id,
                  clientMutationId: 'openDemoAccount' // WPGraphQL requires this
                }
              }
            });
          }}
        />
      )}
    </Mutation>
  );
};

export const ViewAccountConnected = props => {
  const userId = props.isMyAccount
    ? Auth.getUser().userId
    : props.match.params.id;

  return (
      <Mutation mutation={CLOSE_ORDER_MUTATION}>
          {(closeOrder, closeOrderProps) => (
              <ViewAccount
                  userId={userId}
                  brokerView={props.brokerView}
                  loading={closeOrderProps.loading}
                  notification={
                      (closeOrderProps.error) && {
                          text: (closeOrderProps.error && closeOrderProps.error.message),
                          type: 'critical',
                      }
                  }
                  closeOrder={async data =>
                      closeOrder({
                          variables: {
                              input: {
                                  ...data,
                                  clientMutationId: 'closeOrder',
                              }
                          }
                      })
                  }
              />)
          }
      </Mutation>
  );
};

export const AccountHistoryConnected = props => {
    const userId = props.isMyAccount
        ? Auth.getUser().userId
        : props.match.params.id;

  return (
    <Query query={FETCH_ACCOUNT_HISTORY} variables={{ userId }}>
      {({ loading, error, data }) => {
        const lensPath = R.lensPath(['clientBy', 'accounts', 'nodes']);
        const accounts = R.view(lensPath, data) || [];
        const account =
          accounts.find(account =>
            ACCOUNT_TYPES.live.includes(account.account_type)
          ) || {};

        return (
          <AccountHistory
            loading={loading}
            backLink={props.backLink}
            userId={userId}
            account={account}
            orders={
              account && account.account_history
                ? account.account_history.nodes
                : []
            }
          />
        );
      }}
    </Query>
  );
};

const accountsPath = R.lensPath(['statistics', 'accounts', 'nodes']);

export const TradingAccountsConnected = props => {
  const { loading: accountsLoading, data: statistics } = useQuery(
    FETCH_TRADING_ACCOUNTS
  );

  return (
    <TradingAccounts
      loading={accountsLoading}
      accounts={(R.view(accountsPath, statistics) || []).map(acc => {
        acc.name = acc.user.name;
        return acc;
      })}
    />
  );
};
