import React from 'react';
import config from '../config';
import { Anchor, Box, Text } from 'grommet';
import { Translate } from 'react-localize-redux';
import {getBrand} from "../services/translations";
import {AKOSOLUTIONS_BRAND, EDU_ONLINE_TRADER_BRAND} from "../config/site";

export const Footer = ({ color = 'border' }) => {
  const year = new Date().getFullYear();
    const brand = getBrand();

    let copyright = 'Tradingacademy.club';

    let mail = config.read(['meta', 'supportEmail']);
    if (brand === AKOSOLUTIONS_BRAND) {
      mail = 'akosolutions@tradingacademy.club';
      copyright = 'Ako Solutions';
    } else if (brand === EDU_ONLINE_TRADER_BRAND) {
      mail = 'support@eduonlinetrader.com';
      copyright = 'Edu Online Trader';
    }

    return (
    <Box align="center" width="full" pad="medium" gap="small" justify="center">
      <Text color={color} textAlign="center">
        <Translate id="global.allRightsReserved">All rights reserved</Translate>{' '}
        &copy; {year} {copyright}
      </Text>
      <Anchor
        color={color}
        href={`mailto:${mail}`}
      >
        <Translate id="support.title">Support</Translate>
      </Anchor>
    </Box>
  );
};
