import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BackLink, Layout, Loader, Modal, Notification } from '../../components';
import { Anchor, Box, Button, Form, Heading, MaskedInput, Text, TextInput } from 'grommet/es6';
import { Trash, View } from 'grommet-icons';
import { routes } from '../../config/routes';
import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT } from '../../config/site';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FLASH_TYPES, useFlash } from '../../hooks';
import { CellActionsFormatter, getFilterRenderer, ToolbaredTable } from '../../components/DataTable';
import { SAVE_OPTION } from '../../redux/app';
import {fetchUser, fetchUserAccount, fetchUsersSuggestions} from '../../utils';

function getEndpoint(key) {
  const endpoints = {
    viewPartner: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Partners')
      .childs.find(route => route.name === 'View').path,
    dashboard: routes.find(route => route.name === 'Broker').path,
    editTrader: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'View').path
  };
  return endpoints[key];
}

const Deposits = props => {
  // Load hidden column indexes from the storage (global state)
  const hiddenColumns =
    useSelector(
      state => state.app.storage.options.depositsTableCols,
      shallowEqual
    ) || props.hiddenColumns;
  const dispatch = useDispatch();
  const timeout = useRef(null);
  const [total, setTotal] = useState(0);
  const [state, setState] = useState({
    itemToRemove: null,
    formErrors: props.formErrors,
    selectedUser: null,
    suggestions: [{ value: '-', label: 'Start typing MT Id' }],
    selectedUserObject: null,
  });
  const [inputs, setInputs] = useState({
    amount: undefined,
    created: moment().format('DD/MM/YYYY'),
    user_id: undefined
  });
  const [flash, showFlash, dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );
  const [modal, showModal, dismissModal] = useFlash();

  const columns = useMemo(() => {
    return [
      {
        key: 'name',
        name: 'Client',
        width: 250,
        filterRenderer: getFilterRenderer('text'),
        formatter: ({ row }) => {
          const { user_id, user } = row;
          const actions = [
            {
              icon: <View size="small" color="status-ok" />,
              text: 'View',
              callback: () =>
                props.history.push(
                  getEndpoint('editTrader').replace(':id', row.user_id)
                )
            },
            {
              icon: <Trash size="small" color="status-critical" />,
              text: 'Delete',
              callback: () => {
                showModal();
                return setState({
                  ...state,
                  itemToRemove: row
                });
              }
            }
          ];

          return (
            <>
              <CellActionsFormatter actions={actions} />
              <Button
                plain
                href={getEndpoint('editTrader').replace(':id', user_id)}
                target="_blank"
                label={<Text color="brand">{user && user.name}</Text>}
                weight="bold"
              />
            </>
          );
        }
      },
      {
        key: 'referredBy',
        name: 'Referred by',
        filterRenderer: getFilterRenderer('text'),
        formatter: ({ row }) => {
          const value = row.user.referer;
          return !value ? null : (
            <Anchor
              title="Show partner"
              target="_blank"
              href={getEndpoint('viewPartner').replace(':id', value.userId)}
              color="brand"
              label={<Text weight="bold">{value.name}</Text>}
            />
          );
        }
      },
      {
        key: 'amount',
        name: 'Amount',
        filterRenderer: getFilterRenderer('numeric')
      },
      {
        key: 'created',
        name: 'Date',
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        filterRenderer: getFilterRenderer('date', {
          datePicker: true,
          placeholder: 'Select Date/Range'
        }),
        formatter: ({ row }) =>
          row.created
            ? moment(row.created).format(DEFAULT_DATETIME_FORMAT)
            : null
      }
    ].map(col => {
      return {
        ...col,
        sortable: true,
        resizable: true
      };
    });
  }, []);

  const hideColumns = nextState => {
    dispatch({ type: SAVE_OPTION, key: 'depositsTableCols', value: nextState });
  };

  useEffect(() => {
    const mtValue = inputs.user_id;

    if (mtValue && mtValue.length > 5) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      setState({
        ...state,
        suggestions: [{ value: '-', label: 'Loading...' }]
      });
      timeout.current = setTimeout(async () => {
        const suggestions = await fetchUsersSuggestions(mtValue);
        setState({
          ...state,
          suggestions:
            suggestions.length > 0
              ? suggestions.map(user => ({
                  value: user.mt_id,
                  label: user.display_name
                }))
              : [
                  {
                    value: '-',
                    label: Array.isArray(suggestions)
                      ? 'No results'
                      : suggestions
                  }
                ]
        });
      }, 1500);
    } else if (mtValue && mtValue.length === 0) {
      clearTimeout(timeout.current);
      setState(oldState => ({
        ...oldState,
        suggestions: [{ value: '-', label: 'Start typing MT Id' }]
      }));
    } else {
      clearTimeout(timeout.current);
      setState(oldState => ({
        ...oldState,
        suggestions: [{ value: '-', label: 'Enter at least 6 digits' }]
      }));
    }
  }, [inputs.user_id]);

  const handleUpdate = event => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
    setState({ ...state, selectedUser: null, selectedUserObject: null });
  };

  const handleSubmit = async event => {
    const fields = ['user_id', 'amount', 'created'],
      requiredFields = fields,
      errors = {},
      data = {};
    let valid = true;

    // Validation
    for (let i = 0; i < event.target.length; i++) {
      let { value, name } = event.target[i];

      if (fields.includes(name)) {
        data[name] = value;
      }

      if (requiredFields.includes(name) && value.length === 0) {
        errors[name] = 'Please fill in this field.';
        valid = false;
      }
    }

    if (!valid) {
      return setState({ ...state, formErrors: errors });
    }

    new Promise(async (resolve, reject) => {
      // Creating
      try {
        data.created = moment(data.created, 'DD/MM/YYYY').format(
          'YYYY-MM-DD HH:mm:ss'
        );
        await props.saveItem(data);
        resolve(`Amount was assigned.`);
      } catch (e) {
        reject(e);
      }
    })
      .then(response => {
        setState({ ...state, formErrors: {}, selectedUser: null, selectedUserObject: null });
        setInputs({
          user_id: '',
          amount: '',
          created: moment().format('DD/MM/YYYY')
        });
        showFlash(response, FLASH_TYPES.OK);
      })
      .catch(error => {
        showFlash(error.message, FLASH_TYPES.CRITICAL);
      });
  };

  const removeItem = async item => {
    if (!item || !item.id) {
      return;
    }

    await props.removeItem({ id: item.id }).then(
      () => {
        showFlash(`Amount ${item.id} removed`, FLASH_TYPES.OK);
        dismissModal();
        setState({ ...state, itemToRemove: null });
        return true;
      },
      error => {
        dismissModal();
        setState({ ...state, itemToRemove: null });
        showFlash('Error removing amount', FLASH_TYPES.CRITICAL);
        return false;
      }
    );
  };

  async function onUserSelect({ suggestion: { label, value } }) {
    if (value !== '-') {
      let userObject = null;
      try {
        const account = await fetchUserAccount(value);
        if (account && account.data) {
          userObject = account.data;

          const user = await fetchUser(value);
          if (user && user.referrer_name) {
            userObject.referrerName = user.referrer_name;
          }
        }
      } catch (e) {
        console.log(e)
      }
      setInputs({ ...inputs, user_id: value });
      setState({ ...state, selectedUser: label, selectedUserObject: userObject });
    }
  }

  const onRowsDisplay = r => {
    setTotal(
      r.reduce((acc, item) => {
        acc += item.amount;
        return acc;
      }, 0)
    );
  };

  const { itemToRemove, formErrors, suggestions, selectedUser, selectedUserObject } = state;
  const { loading, deposits } = props;
  const { user_id, amount, created } = inputs;

  const { currency, referrerName } = selectedUserObject || {};

  return (
    <Layout title="Deposits" fluid>
      <BackLink link={getEndpoint('dashboard')} />

      <Box direction="row" align="center" margin={{ vertical: 'medium' }}>
        <Heading margin={{ right: 'small', vertical: 'none' }} size="small">
          Deposit / withdrawal management
        </Heading>

        <Box direction="row" align="center">
          {loading && <Loader />}
        </Box>
      </Box>

      {flash.visible && (
        <Notification
          type={flash.type ? flash.type : 'info'}
          onClose={dismissFlash}
          open={flash.visible}
          message={flash.text}
        />
      )}

      {modal.visible && (
        <Modal open={modal.visible} heading={'Confirm'} onClose={dismissModal}>
          <Text>
            Are you sure you want to remove amount{' '}
            {`${itemToRemove.user.first_name} ${itemToRemove.user.last_name}: ${itemToRemove.amount}`}
            ?
          </Text>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            <Button
              label="Cancel"
              onClick={dismissModal}
              disabled={loading}
              color="dark-3"
            />
            <Button
              label={
                <Text color="white">
                  <strong>Delete</strong>
                </Text>
              }
              onClick={() => {
                dismissModal();
                removeItem(itemToRemove);
              }}
              disabled={loading}
              primary
              color="status-critical"
            />
          </Box>
        </Modal>
      )}

      {/* Add Form */}
      <Box flex={false} align="center" background="accent-4" pad="large">
        <Box
          elevation="small"
          pad="medium"
          background="white"
          width="full"
          style={{ maxWidth: '450px' }}
        >
          <Box direction='row' align='center' justify='center'>
            <Heading level="3" size="medium" textAlign='center'>
              Assign deposit/withdrawal
              &nbsp;
              {selectedUser &&
              <Box direction='column' align='center' justify='center'>
                <span style={{marginTop: "6px", fontSize: "16px"}}>
                  {selectedUser}&nbsp;{currency && " - " + currency}
                </span>
                {referrerName &&
                <span style={{marginTop: "6px", fontSize: "16px"}}>
                  Referer: {referrerName}
                </span>}
              </Box>}
            </Heading>
          </Box>

          <Form onSubmit={handleSubmit}>
            <Box
              direction="row-responsive"
              flex={false}
              margin={{ bottom: 'medium' }}
            >
              <Text
                color="brand"
                margin={{ vertical: 'small', right: 'small' }}
                as="label"
                htmlFor="user_id"
                style={{ display: 'block', minWidth: '200px' }}
              >
                Select user:
              </Text>
              <TextInput
                id="user_id"
                name="user_id"
                value={user_id}
                disabled={loading}
                placeholder="Select user"
                style={{ borderColor: formErrors.user_id ? 'red' : '' }}
                dropProps={{ height: 'small' }}
                onChange={handleUpdate}
                onSelect={onUserSelect}
                suggestions={suggestions.map(suggestion => ({
                  value: suggestion.value,
                  label: suggestion.label
                }))}
              />
            </Box>
            {formErrors.user_id && (
              <Text color="status-critical">{formErrors.user_id}</Text>
            )}

            <Box
              direction="row-responsive"
              flex={false}
              margin={{ bottom: 'medium' }}
            >
              <Text
                color="brand"
                margin={{ vertical: 'small', right: 'small' }}
                as="label"
                htmlFor="amount"
                style={{ display: 'block', minWidth: '200px' }}
              >
                Enter the amount:
              </Text>
              <TextInput
                name="amount"
                type="number"
                style={{ borderColor: formErrors.amount ? 'red' : '' }}
                placeholder="Amount of deposit/withdrawal"
                value={amount}
                disabled={loading}
                onChange={handleUpdate}
              />
            </Box>
            {formErrors.amount && (
              <Text color="status-critical">{formErrors.amount}</Text>
            )}

            <Box
              direction="row-responsive"
              flex={false}
              margin={{ bottom: 'medium' }}
            >
              <Text
                color="brand"
                margin={{ vertical: 'small', right: 'small' }}
                as="label"
                htmlFor="created"
                style={{ display: 'block', minWidth: '200px' }}
              >
                Date:
              </Text>
              <MaskedInput
                id="created"
                name="created"
                className={'drop_focus_onclick'}
                mask={[
                  {
                    length: [1, 2],
                    regexp: /^1[0-9]?$|^2[0-9]?$|^3[0-1]?$|^0[1-9]?$/,
                    placeholder: 'DD'
                  },
                  { fixed: '/' },
                  {
                    length: [1, 2],
                    regexp: /^1[0-2]?$|^0[1-9]?$/,
                    placeholder: 'MM'
                  },
                  { fixed: '/' },
                  {
                    length: [4],
                    regexp: /^\d{4}?$|^\d{3}?$|^\d{2}?$|^\d{1}?$/,
                    placeholder: 'YYYY'
                  }
                ]}
                value={created ? created : ''}
                disabled={loading}
                onChange={option =>
                  handleUpdate({
                    target: { name: 'created', value: option.target.value }
                  })
                }
              />
            </Box>
            {formErrors.created && (
              <Text color="status-critical">{formErrors.created}</Text>
            )}

            <Box margin={{ top: 'small' }} align="center">
              <Button
                primary
                disabled={loading}
                color="status-ok"
                label="Submit"
                type="submit"
              />
            </Box>
          </Form>
        </Box>
      </Box>

      <Heading margin={{ bottom: 'large', top: 'xlarge' }} size="small">
        All Deposits / Withdrawals
      </Heading>

      {/* Deposits table */}
      <div style={{ width: `100%`, height: '100vh', position: 'relative' }}>
        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
          <ToolbaredTable
            toolbarProps={{
              children: (
                <Box direction="row-responsive" gap="small">
                  <Text>
                    Total amount: <b>{`${total}`}</b>
                  </Text>
                </Box>
              )
            }}
            toggleableColumns={columns}
            columns={columns}
            rows={deposits}
            loading={loading}
            hiddenColumns={hiddenColumns}
            onHideColumn={hideColumns}
            onRowsDisplay={onRowsDisplay}
          />
        </div>
      </div>
    </Layout>
  );
};

Deposits.propTypes = {
  deposits: PropTypes.array,
  saveItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  user: PropTypes.object,
  formErrors: PropTypes.object,
  hiddenColumns: PropTypes.array,
  loading: PropTypes.bool,
  notification: PropTypes.object
};

Deposits.defaultProps = {
  deposits: [],
  formErrors: {},
  hiddenColumns: [],
  loading: false,
  notification: {}
};

export default Deposits;
