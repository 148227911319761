import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BecomePartnerForm,
  CompanyLogo,
  Layout,
  Link,
  LinkTile,
  Loader,
  Modal,
  Notification,
  Upload
} from '../../components';
import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Heading,
  Paragraph,
  ResponsiveContext,
  Stack,
  Tab,
  Tabs,
  Text
} from 'grommet';
import {
  Atm,
  Checkmark,
  CreditCard,
  Download,
  Inbox,
  Support,
  User,
  UserManager,
    Risk,
    Schedules
} from 'grommet-icons';
import { apiRoutes, routes } from '../../config/routes';
import moment from 'moment';
import Auth from '../../services/auth';
import { Translate } from 'react-localize-redux';
import { PARTNER_STATUS } from '../Partners/Partners';
import { FLASH_TYPES, useFlash } from '../../hooks';
import {getBrand} from "../../services/translations";
import {AKOSOLUTIONS_BRAND, EDU_ONLINE_TRADER_BRAND} from "../../config/site";

// TODO: REFACTOR ROUTE GETTING
function getEndpoint(key) {
  const endpoints = {
    withdrawals: routes
      .find(route => route.name === 'Dashboard')
      .childs.find(route => route.name === 'Withdrawals').path,
    profile: routes
      .find(route => route.name === 'Dashboard')
      .childs.find(route => route.name === 'My profile').path,
    inbox: routes
      .find(route => route.name === 'Dashboard')
      .childs.find(route => route.name === 'Inbox').path,
    support: routes
      .find(route => route.name === 'Dashboard')
      .childs.find(route => route.name === 'Support').path,
    account: routes
      .find(route => route.name === 'Dashboard')
      .childs.find(route => route.name === 'Account').path,
    partnerArea: routes.find(route => route.name === 'Partner').path,
    accountHistory: routes
        .find(route => route.name === 'Dashboard')
        .childs.find(route => route.name === 'Account history').path,
    addDemo: routes
      .find(route => route.name === 'Dashboard')
      .childs.find(route => route.name === 'Add demo account').path,
    addReal: routes
      .find(route => route.name === 'Dashboard')
      .childs.find(route => route.name === 'Add real account').path,
    uploadDocumentsEndpoint: apiRoutes.uploadDocuments.url
  };
  return endpoints[key];
}

const Dashboard = props => {
  const [state, setState] = useState({
    formErrors: {}
  });
  const [flash, showFlash, dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );
  const [partnerModal, showPartnerModal, dismissPartnerModal] = useFlash();
  const [pleaseHoldModal, , dismissPleaseHold] = useFlash();
  const [
    documentsModal,
    showDocumentsModal,
    dismissDocumentsModal
  ] = useFlash();
  const [depositModal, showDepositModal, dismissDepositModal] = useFlash();

  const PartnerButton = useMemo(() => {
    return ({ canEnter, link, loading = false }) => {
      const buttonProps = {
        style: { width: '100%' },
        icon: <UserManager size="30px" />,
        primary: true,
        disabled: loading
      };
      return (
          <Translate>
            {({ translate }) => (
                canEnter ? (
                    <Link href={link} title="My partner area">
                      <Button
                          {...buttonProps}
                          label={<Text weight="bold">{translate('global.enter')}</Text>}
                      />
                    </Link>
                ) : (
                    <Button
                        {...buttonProps}
                        title={translate('becomePartner.title')}
                        style={{ maxWidth: "250px", minHeight: "52px" }}
                        onClick={() => showPartnerModal()}
                        label={
                          <Text weight="bold">
                            {translate('becomePartner.title')}
                          </Text>
                        }
                    />
                )
            )}
          </Translate>
      )
    };
  }, []);

  async function handleBecomePartnerSubmit(event) {
    const fields = ['website', 'info'],
      requiredFields = ['info'],
      errors = {},
      data = {};
    let valid = true;

    // Validation
    for (let i = 0; i < event.target.length; i++) {
      let { value, name } = event.target[i];

      if (fields.includes(name)) {
        data[name] = value;
      }

      if (requiredFields.includes(name) && value.length === 0) {
        errors[name] = 'This field is required.';
        valid = false;
      }
    }

    if (!valid) {
      setState({ ...state, formErrors: errors });
      return false;
    }
    setState({ ...state, formErrors: {} });

    try {
      await props.becomePartnerSubmit(data);
      setState({
        ...state,
        formErrors: {}
      });
      dismissPartnerModal();
      showFlash('Thank you for your application.', FLASH_TYPES.OK);
    } catch (e) {
      showFlash(e.message, FLASH_TYPES.CRITICAL);
      setState({ ...state, formErrors: {} });
    }
    return true; // Request has been processed
  }

  const { formErrors } = state;
  const { companySettings, loading, additionalContent, partner } = props;
  const {
    registeredDate,
    mt_id,
    documentsProvided,
    documents
  } = props.user;
  const user = Auth.getUser();
  const companySettingsKeys = Object.keys(companySettings);

  const uploadedDocuments = (documents || { nodes: [] }).nodes.reduce(
    (acc, item) => {
      acc.push(item.name);
      return acc;
    },
    []
  );

  const brand = getBrand();
  const userId = user.userId;

  const defaultBrand = false;
  let enlistmentLink = 'https://buy.stripe.com/6oE4hG0Yga9wfbW290';

  if (brand === AKOSOLUTIONS_BRAND) {
    enlistmentLink = '#';
  } else if (brand === EDU_ONLINE_TRADER_BRAND) {
    enlistmentLink = 'https://buy.stripe.com/6oE4hG0Yga9wfbW290';
  }

  return (
    <Translate>
      {({ translate }) => (
        <Layout title="Dashboard" fluid>
          <ResponsiveContext.Consumer>
            {size => {
              return (
                <Box direction="row-responsive" gap="large" height="full">
                  {flash.visible && (
                    <Notification
                      type={flash.type ? flash.type : 'info'}
                      onClose={dismissFlash}
                      open={flash.visible}
                      message={flash.text}
                    />
                  )}

                  {/* Sidebar area */}
                  <Box
                    width={size === 'small' ? 'full' : 'medium'}
                    height={size === 'small' ? 'auto' : 'full'}
                    align="center"
                  >
                    <Box
                      pad="medium"
                      width="full"
                      align="center"
                      border={{ color: 'light-4' }}
                      background="white"
                    >
                      <Box width="140px" margin={{ horizontal: 'auto' }}>
                        <CompanyLogo width="140px" height="auto" />
                      </Box>

                      <Box width="140px" margin={{ horizontal: 'auto' }}>
                        {user && (
                          <Heading
                            level="3"
                            margin={{ bottom: 'large' }}
                          >{`${user.name}`}</Heading>
                        )}

                        {loading ? (
                          <Loader />
                        ) : (
                          <Box justify="center" gap="small">
                            <Text color="grey">
                              <Text weight="bold">
                                {translate('tradersRoom.memberSince')}:
                              </Text>
                              <br />
                              {registeredDate &&
                                moment(registeredDate).format('Do MMM YYYY')}
                            </Text>

                            {mt_id && (
                              <Text color="grey">
                                <Text weight="bold">
                                  {translate('tradersRoom.academyAccountNumber')}:
                                </Text>
                                <br />
                                {mt_id}
                              </Text>
                            )}

                            {defaultBrand && (
                                !documentsProvided ? (
                              <Button
                                label="Vložit osobní doklady"
                                primary
                                color="light-4"
                                margin={{ vertical: 'medium' }}
                                onClick={() => showDocumentsModal()}
                              />
                            ) : (
                              <Box direction="row" align="center">
                                <Text color="status-ok">
                                  <Checkmark size="small" color="status-ok" />{' '}
                                  Documents provided
                                </Text>
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {/* Sidebar area end */}

                  {/* Content area */}
                  <Box width="full" gap="large">
                    <Heading level="1">
                      {translate('tradersRoom.title')}
                    </Heading>

                    <Box height="full" flex={false}>
                      {/* My account section */}
                      <Box flex={false}>
                        <Stack anchor="top-left" margin={{ bottom: 'large' }}>
                          <Box
                            border={{ color: 'light-4' }}
                            pad="medium"
                            direction="row-responsive"
                            wrap={true}
                            align="center"
                          >
                            <LinkTile
                              href={getEndpoint('profile')}
                              title={translate('tradersRoom.personalInfo')}
                              label={translate('tradersRoom.personalInfo')}
                              icon={<User />}
                              width={size === 'small' ? 'full' : '150px'}
                            />

                            <LinkTile
                                href={defaultBrand ? getEndpoint('inbox') : '#'}
                              title={translate('tradersRoom.inbox')}
                              label={translate('tradersRoom.inbox')}
                              icon={<Inbox />}
                              width={size === 'small' ? 'full' : '150px'}
                            />

                            <LinkTile
                              href={defaultBrand ? getEndpoint('support') : '#'}
                              title={translate('tradersRoom.clientSupport')}
                              label={translate('tradersRoom.clientSupport')}
                              icon={<Support />}
                              width={size === 'small' ? 'full' : '150px'}
                            />

                            <LinkTile
                               linkProps={{anchor: true, target: "_blank"}}
                                href={enlistmentLink}
                                title={translate('tradersRoom.orderChallenge')}
                                label={translate('tradersRoom.orderChallenge')}
                                icon={<Risk />}
                                width={size === 'small' ? 'full' : '150px'}
                            />

                            <LinkTile
                                href={getEndpoint('accountHistory')}
                                title="Account history"
                                label="Account history"
                                icon={<Schedules />}
                                width={size === 'small' ? 'full' : '150px'}
                            />
                          </Box>

                          <Box
                            background="body"
                            margin={{ left: 'small', top: '-10px' }}
                            pad={{ horizontal: 'small' }}
                          >
                            <Text weight="bold" color="border">
                              {translate('global.myAccount')}
                            </Text>
                          </Box>
                        </Stack>
                      </Box>

                      {/* Finance section */}
                      <Box flex={false}>
                        <Stack anchor="top-left" margin={{ bottom: 'large' }}>
                          <Box
                            border={{ color: 'light-4' }}
                            pad="medium"
                            direction="row-responsive"
                            wrap={true}
                            align="center"
                          >
                            <LinkTile
                              href="#"
                              title={translate('tradersRoom.depositFunds')}
                              label={translate('tradersRoom.depositFunds')}
                              icon={<CreditCard />}
                              width={size === 'small' ? 'full' : '150px'}
                              onClick={defaultBrand ? () => showDepositModal() : () => null}
                            />

                            <LinkTile
                              href={defaultBrand ? getEndpoint('withdrawals') : '#'}
                              title={translate('tradersRoom.withdrawFunds')}
                              label={translate('tradersRoom.withdrawFunds')}
                              icon={<Atm />}
                              width={size === 'small' ? 'full' : '150px'}
                            />
                          </Box>

                          <Box
                            background="body"
                            margin={{ left: 'small', top: '-10px' }}
                            pad={{ horizontal: 'small' }}
                          >
                            <Text weight="bold" color="border">
                              Finance
                            </Text>
                          </Box>
                        </Stack>
                      </Box>

                      {/* Meta trader section */}
                      <Box flex={false}>
                        <Box
                          direction="row-responsive"
                          gap="medium"
                          margin={{ bottom: 'large' }}
                          wrap={true}
                        >
                          <Box
                            background="body"
                            gap="medium"
                            margin={{ left: 'small', top: '-10px' }}
                            pad={{ horizontal: 'small' }}
                          >
                            <Text weight="bold" color="border">
                              { translate("tradersRoom.partnersSection") }
                            </Text>
                            {loading ? (
                              <Loader />
                            ) : (
                              <PartnerButton
                                loading={loading}
                                canEnter={
                                  partner.status &&
                                  partner.status !== PARTNER_STATUS.pending
                                }
                                link={getEndpoint('partnerArea')}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* Content area end */}
                  </Box>
                </Box>
              );
            }}
          </ResponsiveContext.Consumer>

          {/* Deposit modal */}
          {depositModal.visible && (
            <Modal
              open={depositModal.visible}
              heading={translate('tradersRoom.depositFunds')}
              onClose={dismissDepositModal}
            >
              <Text margin={{ bottom: 'medium' }}>
                {translate('tradersRoom.chooseCurrency')}
              </Text>
              {loading ? (
                <Box
                  direction="row"
                  align="center"
                  justify="center"
                  pad="medium"
                >
                  <Loader />
                </Box>
              ) : (
                <Tabs justify="start">
                  {companySettingsKeys.length > 0 ? (
                    companySettingsKeys.map(
                      (key, index) =>
                        key.includes('bank_account') && (
                          <Tab
                            key={index}
                            title={companySettings[key].currency}
                          >
                            <Box pad="medium" animation="fadeIn">
                              <Paragraph>
                                <Text weight="bold">{translate('tradersRoom.bankName')}</Text>:{' '}
                                {companySettings[key].bank_name}
                              </Paragraph>
                              <Paragraph>
                                <Text weight="bold">{translate('tradersRoom.accountNumber')}</Text>:{' '}
                                {companySettings[key].account_number}
                              </Paragraph>
                              <Paragraph>
                                <Text weight="bold">IBAN</Text>:{' '}
                                {companySettings[key].iban}
                              </Paragraph>
                              <Paragraph>
                                <Text weight="bold">SWIFT</Text>:{' '}
                                {companySettings[key].swift}
                              </Paragraph>
                              <Paragraph>
                                <Text weight="bold">{translate('tradersRoom.ownerName')}</Text>:{' '}
                                {companySettings[key].owner_name}
                              </Paragraph>
                              <Paragraph>
                                <Text weight="bold">{translate('tradersRoom.variableSymbol')}</Text>:{' '}
                                {mt_id}
                              </Paragraph>
                            </Box>
                          </Tab>
                        )
                    )
                  ) : (
                    <Text>No data.</Text>
                  )}
                </Tabs>
              )}
            </Modal>
          )}

          {/* Become partner modal */}
          {partnerModal.visible && (
            <Modal
              open={partnerModal.visible}
              heading={translate('becomePartner.title')}
              onClose={dismissPartnerModal}
            >
              <Text margin={{ bottom: 'medium' }}>
                {translate('becomePartner.subtitle')}
              </Text>
              <Box direction="row" align="center" justify="center" pad="medium">
                {props.partner.status === PARTNER_STATUS.pending ? (
                  <Text weight="bold">
                    Vaše žádost čeká na schválení
                  </Text>
                ) : (
                  <BecomePartnerForm
                    handleSubmit={handleBecomePartnerSubmit}
                    errors={formErrors}
                    loading={loading}
                  />
                )}
              </Box>
            </Modal>
          )}

          {/* Please hold modal */}
          {pleaseHoldModal.visible && (
            <Modal
              open={pleaseHoldModal.visible}
              heading={'Vydržte prosím!'}
              onClose={dismissPleaseHold}
            >
              <Text>
                Na této sekci probíhá úprava a je momentálně nedostupná.
              </Text>
              <Box
                as="footer"
                gap="small"
                direction="row"
                align="center"
                justify="center"
                pad={{ top: 'medium', bottom: 'small' }}
              >
                <Button
                  label={
                    <Text color="white">
                      <strong>Dobře</strong>
                    </Text>
                  }
                  onClick={dismissPleaseHold}
                  primary
                  color="status-ok"
                />
              </Box>
            </Modal>
          )}

          {/* Upload documents modal */}
          {documentsModal.visible && (
            <Modal
              open={documentsModal.visible}
              heading={'Vložit doklady'}
              onClose={dismissDocumentsModal}
            >
              <Upload
                uploadEndpoint={getEndpoint('uploadDocuments')}
                dropzone={false}
                expectedFiles={[
                  {
                    name: 'id_passport',
                    disabled: false,
                    label: (
                      <Text weight="bold">Fotokopie OP, nebo pasu</Text>
                    ),
                    ...Object.assign(
                      {},
                      uploadedDocuments.includes('id_passport')
                        ? {
                            disabled: true,
                            help: 'Soubor byl nahrán'
                          }
                        : {}
                    )
                  },
                  {
                    name: 'id_passport2',
                    disabled: false,
                    icon: false,
                    help: 'Kopie/Scan Vašeho OP, nebo cestovního pasu',
                    ...Object.assign(
                      {},
                      uploadedDocuments.includes('id_passport2')
                        ? {
                            disabled: true,
                            help: 'Soubor byl nahrán'
                          }
                        : {}
                    )
                  },
                  {
                    name: 'proof_address',
                    disabled: uploadedDocuments.includes('proof_address'),
                    label: <Text weight="bold">Doklad o adrese</Text>,
                    ...Object.assign(
                      {},
                      uploadedDocuments.includes('proof_address')
                        ? {
                            disabled: true,
                            help: 'Soubor byl nahrán'
                          }
                        : {}
                    )
                  },
                  {
                    name: 'proof_address2',
                    disabled: uploadedDocuments.includes('proof_address2'),
                    icon: false,
                    help:
                      'Kopie/Scan bank.úČtu, nebo účtu za telefon, nebo elektřinu, nebo SIPO a pod. potvrzující Vaši adresu',
                    ...Object.assign(
                      {},
                      uploadedDocuments.includes('proof_address2')
                        ? {
                            disabled: true,
                            help: 'Soubor byl nahrán'
                          }
                        : {}
                    )
                  }
                ]}
                beforeSend={request => {
                  request.setRequestHeader(
                    'Authorization',
                    `Bearer ${Auth.getAuthToken()}`
                  );
                }}
                onError={response => {
                  setState({
                    ...state,
                    notification: {
                      text: response ? response : 'Error uploading file',
                      type: 'critical'
                    }
                  });
                }}
                onSuccess={() => {
                  this.triggerModal('upload-documents', false);
                  setState({
                    ...state,
                    notification: { text: 'Nahrání úspěšné!', type: 'ok' }
                  });
                  if (props.refetch) props.refetch();
                }}
              />
              <Text size="small" textAlign="center">
                Max. velikost 3MB. Povolené formáty: PDF, PNG, JPG, BMP.
              </Text>
            </Modal>
          )}
        </Layout>
      )}
    </Translate>
  );
};

Dashboard.propTypes = {
  companySettings: PropTypes.object,
  additionalContent: PropTypes.object,
  refetch: PropTypes.func,
  becomePartnerSubmit: PropTypes.func.isRequired,
  notification: PropTypes.object,
  user: PropTypes.object,
  partner: PropTypes.object,
  loading: PropTypes.bool
};

Dashboard.defaultProps = {
  companySettings: {},
  additionalContent: {},
  notification: {
    text: '',
    type: 'info'
  },
  user: {},
  partner: {},
  loading: false
};

export default Dashboard;
