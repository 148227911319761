import React from 'react';
import PropTypes from 'prop-types';
import { BackLink, Layout, Loader, Notification } from '../../components';
import { Box, Button, Form, Heading, TextInput, Text, Select } from 'grommet';
import { routes } from '../../config/routes';
import Auth from '../../services/auth';

export class AddBankAccount extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    create: PropTypes.func,
    update: PropTypes.func,
    notification: PropTypes.object,
    formErrors: PropTypes.object,
    data: PropTypes.object
  };

  static defaultProps = {
    loading: false,
    formErrors: {},
    data: {},
    notification: {
      text: '',
      type: 'info'
    }
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissNotifications = this.dismissNotifications.bind(this);
    this.brokerEndpoint = routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Bank accounts').path;

    this.state = {
      data: props.data,
      formErrors: props.formErrors,
      notification: props.notification
    };
  }

  handleUpdate = event => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value }
    });
  };

  dismissNotifications() {
    this.setState({
      notification: {}
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data.id !== prevState.data.id) {
      return {
        data: nextProps.data
      };
    }

    if (nextProps.notification.text || prevState.notification.text) {
      return {
        notification: prevState.notification.text
          ? prevState.notification
          : nextProps.notification
      };
    }

    // Object or null must be returned
    return null;
  }

  async handleSubmit(event) {
    const fields = [
        'bank_name',
        'account_number',
        'iban',
        'swift',
        'owner_name',
        'currency'
      ],
      requiredFields = fields,
      errors = {},
      data = {};
    let valid = true;

    // Validation
    for (let i = 0; i < event.target.length; i++) {
      let { value, name } = event.target[i];

      if (fields.includes(name)) {
        data[name] = value;
      }

      if (requiredFields.includes(name) && value.length === 0) {
        errors[name] = 'Please fill in this field.';
        valid = false;
      }
    }

    if (!valid) {
      return this.setState({ formErrors: errors });
    }

    const id = this.props.data.id;
    data.user_id = Auth.getUser().userId;
    new Promise(async (resolve, reject) => {
      // Editing
      if (id) {
        try {
          data.id = id;
          await this.props.update(data);
          resolve(`Bank account ${data.account_number} updated.`);
        } catch (e) {
          reject(e);
        }
      } else {
        // Creating
        try {
          await this.props.create(data);
          resolve(`Bank account ${data.account_number} added.`);
        } catch (e) {
          reject(e);
        }
      }
    })
      .then(response => {
        const newState = {
          formErrors: {},
          notification: { text: response, type: 'ok' }
        };

        // We didnt update
        if (!id) {
          newState.data = {};
        }

        this.setState(newState);
      })
      .catch(error => {
        this.setState({
          notification: { text: error.message, type: 'critical' }
        });
      });
  }

  render() {
    const { notification, formErrors } = this.state,
      { loading, update } = this.props,
      { bank_name, account_number, iban, owner_name, swift, currency } = this
        .state.data
        ? this.state.data
        : {};

    return (
      <Layout title="Add bank account">
        <BackLink link={this.brokerEndpoint} />

        <Box direction="row" margin={{ vertical: 'medium' }}>
          <Heading size="small" margin={{ right: 'small', vertical: 'none' }}>
            {`${update ? 'Edit' : 'Add'} bank account`}
          </Heading>

          <Box direction="row" align="center">
            {loading && <Loader />}
          </Box>
        </Box>

        {!!notification.text && (
          <Notification
            type={notification.type ? notification.type : 'info'}
            onClose={this.dismissNotifications}
            open={!!notification.text}
            message={notification.text}
          />
        )}

        <Box align="center" justify="center" pad="medium" elevation="small">
          <Form style={{ width: '100%', flex: 1 }} onSubmit={this.handleSubmit}>
            <Box gap="small">
              <Box direction="row-responsive">
                <Text
                  color="brand"
                  margin={{ vertical: 'small', right: 'small' }}
                  as="label"
                  htmlFor="bank_name"
                  style={{ display: 'block', minWidth: '150px' }}
                >
                  Bank name:
                </Text>
                <TextInput
                  id="bank_name"
                  name="bank_name"
                  style={{ borderColor: formErrors.bank_name ? 'red' : '' }}
                  type="text"
                  value={bank_name ? bank_name : ''}
                  disabled={loading}
                  placeholder="Bank name"
                  onChange={this.handleUpdate}
                />
              </Box>
              {formErrors.bank_name && (
                <Text color="status-critical">{formErrors.bank_name}</Text>
              )}

              <Box direction="row-responsive">
                <Text
                  color="brand"
                  margin={{ vertical: 'small', right: 'small' }}
                  as="label"
                  htmlFor="account_number"
                  style={{ display: 'block', minWidth: '150px' }}
                >
                  Account number:
                </Text>
                <TextInput
                  id="account_number"
                  name="account_number"
                  style={{
                    borderColor: formErrors.account_number ? 'red' : ''
                  }}
                  type="text"
                  value={account_number ? account_number : ''}
                  disabled={loading}
                  placeholder="Account number"
                  onChange={this.handleUpdate}
                />
              </Box>
              {formErrors.account_number && (
                <Text color="status-critical">{formErrors.account_number}</Text>
              )}

              <Box direction="row-responsive">
                <Text
                  color="brand"
                  margin={{ vertical: 'small', right: 'small' }}
                  as="label"
                  htmlFor="iban"
                  style={{ display: 'block', minWidth: '150px' }}
                >
                  IBAN:
                </Text>
                <TextInput
                  id="iban"
                  name="iban"
                  style={{ borderColor: formErrors.iban ? 'red' : '' }}
                  type="text"
                  value={iban ? iban : ''}
                  disabled={loading}
                  placeholder="IBAN"
                  onChange={this.handleUpdate}
                />
              </Box>
              {formErrors.iban && (
                <Text color="status-critical">{formErrors.iban}</Text>
              )}

              <Box direction="row-responsive">
                <Text
                  color="brand"
                  margin={{ vertical: 'small', right: 'small' }}
                  as="label"
                  htmlFor="swift"
                  style={{ display: 'block', minWidth: '150px' }}
                >
                  SWIFT:
                </Text>
                <TextInput
                  id="swift"
                  name="swift"
                  style={{ borderColor: formErrors.swift ? 'red' : '' }}
                  type="text"
                  value={swift ? swift : ''}
                  disabled={loading}
                  placeholder="SWIFT code"
                  onChange={this.handleUpdate}
                />
              </Box>
              {formErrors.swift && (
                <Text color="status-critical">{formErrors.swift}</Text>
              )}

              <Box direction="row-responsive">
                <Text
                  color="brand"
                  margin={{ vertical: 'small', right: 'small' }}
                  as="label"
                  htmlFor="owner_name"
                  style={{ display: 'block', minWidth: '150px' }}
                >
                  Company name:
                </Text>
                <TextInput
                  id="owner_name"
                  name="owner_name"
                  style={{ borderColor: formErrors.owner_name ? 'red' : '' }}
                  type="text"
                  value={owner_name ? owner_name : ''}
                  disabled={loading}
                  placeholder="Owner name"
                  onChange={this.handleUpdate}
                />
              </Box>
              {formErrors.owner_name && (
                <Text color="status-critical">{formErrors.owner_name}</Text>
              )}

              <Box direction="row-responsive">
                <Text
                  color="brand"
                  margin={{ vertical: 'small', right: 'small' }}
                  as="label"
                  htmlFor="currency"
                  style={{ display: 'block', minWidth: '150px' }}
                >
                  Currency:
                </Text>
                <Select
                  id="currency"
                  name="currency"
                  placeholder="Select account currency"
                  style={{
                    minWidth: '250px',
                    borderColor: formErrors.currency ? 'red' : 'transparent',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                  }}
                  options={['czk', 'eur', 'usd']}
                  value={currency ? currency.toLowerCase() : ''}
                  onChange={({ option }) => {
                    this.handleUpdate({
                      target: { name: 'currency', value: option }
                    });
                  }}
                />
              </Box>
              {formErrors.currency && (
                <Text color="status-critical">{formErrors.currency}</Text>
              )}

              <Box direction="row" justify="center" margin={{ top: 'large' }}>
                <Button
                  type="submit"
                  disabled={loading}
                  label={`${update ? 'Save' : 'Add bank account'}`}
                  primary
                  color="brand"
                  fill
                  style={{ height: '40px', borderRadius: '4px' }}
                />
              </Box>
            </Box>
          </Form>
        </Box>
      </Layout>
    );
  }
}
