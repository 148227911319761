import React from 'react';
import { Box, Heading } from 'grommet';
import { Layout } from '../../components/Layout';
import PropTypes from 'prop-types';
import { routes } from '../../config/routes';
import { BackLink, Loader } from '../../components';

export class Translations extends React.Component {
  static propTypes = {
    translations: PropTypes.array,
    updateItem: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    notification: PropTypes.object
  };

  static defaultProps = {
    translations: [],
    loading: false,
    notification: {}
  };

  constructor(props) {
    super(props);

    this.dashboardEndpoint = routes.find(route => route.name === 'Broker').path;
    this.dismissNotifications = this.dismissNotifications.bind(this);

    this.state = {
      notification: props.notification,
      itemToUpdate: null,
      modalOpen: false,
      actionsDropOpen: false
    };
  }

  dismissNotifications() {
    this.setState({
      notification: {}
    });
  }

  render() {
    const { loading } = this.props;

    return (
      <Layout>
        <BackLink link={this.dashboardEndpoint} />

        <Box direction="row" align="center" margin={{ vertical: 'medium' }}>
          <Heading margin={{ right: 'small', vertical: 'none' }} size="small">
            Translations
          </Heading>

          <Box direction="row" align="center">
            {loading && <Loader />}
          </Box>
        </Box>

        <Box>Coming soon.</Box>
      </Layout>
    );
  }
}
