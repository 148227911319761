import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import { Box, Button, Heading, ResponsiveContext, Text } from 'grommet/es6';
import { RegisterForm } from '../../components/Forms';
import Auth from '../../services/auth';
import { Redirect } from 'react-router-dom';
import { MinimalLayout } from '../../components/Layout';
import { Notification } from '../../components/Notification';
import { routes } from '../../config/routes';
import { Translate } from 'react-localize-redux';
import { CompanyLogo } from '../../components';
import { FormatUtils } from '../../utils/FormatUtils';

export class Registration extends React.Component {
  static propTypes = {
    register: PropTypes.func.isRequired,
    loggedInRedirect: PropTypes.string,
    realAccount: PropTypes.bool,
    loading: PropTypes.bool,
    notification: PropTypes.object
  };

  static defaultProps = {
    realAccount: true,
    loggedInRedirect: '/',
    loading: false,
    notification: {}
  };

  constructor(props) {
    super(props);

    this.loginScreenEndpoint = routes.find(
      route => route.name === 'Login'
    ).path;
    this.handleSignUp = this.handleSignUp.bind(this);
    this.dismissNotifications = this.dismissNotifications.bind(this);
    this.fadeOutRedirect = this.fadeOutRedirect.bind(this);
    this.dismissOverlay = this.dismissOverlay.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    this.state = {
      showOverlay: false,
      notification: props.notification,
      animation: 'fadeIn',
      isFadingOut: false
    };
  }

  dismissNotifications() {
    this.setState({
      notification: {}
    });
  }

  dismissOverlay() {
    this.setState({
      showOverlay: false
    });
  }

  fadeOutRedirect(endpoint = '/') {
    this.setState({ isFadingOut: true, animation: 'fadeOut' });

    const { isFadingOut } = this.state;

    if (!isFadingOut) {
      setTimeout(() => {
        this.props.history.push(endpoint);
      }, 400);
    }
  }

  async handleSignUp(event, _data) {
    const fields = [
      'email',
      'password',
      'password_repeat',
      'first_name',
      'phone',
      'last_name',
      'born',
      'ask',
      'deposit',
      'leverage',
      'group',
    ];
    const parsingRules = [
        {
          rule: value => (value ? value.replace(/\//g, '-') : ''),
          fields: ['born']
        }
      ],
      requiredFields = [
        'email',
        'password',
        'password_repeat',
        'first_name',
        'last_name',
        'phone',
        'leverage',
        'group',
      ],
      errors = {},
      data = {};
    let valid = true;

    data.leverage = _data.leverage;
    data.group = _data.group;

    // Validation
    for (let i = 0; i < event.target.length; i++) {
      let { value, name } = event.target[i];

      if (fields.includes(name)) {
        parsingRules.forEach(rule => {
          if (rule.fields.includes(name)) {
            value = rule.rule(value);
          }
        });
        data[name] = value;
      }

      if (requiredFields.includes(name) && (value.length === 0 || value <= 0)) {
        errors[name] = 'requiredField';
        valid = false;
      }
    }

    if (data.password !== data.password_repeat) {
      errors['password'] = 'passwordsDoesntMatch';
      errors['password_repeat'] = 'passwordsDoesntMatch';
      valid = false;
    }

    if (!valid) {
      this.setState({ formErrors: errors });
      return false;
    }

    // Try to get referral key from url
    const referral = FormatUtils.getUrlParameter(
        config.read(['app', 'referralKey']),
        window.location
    );

    if (referral) {
      data.referral = referral;
    }

    this.props.register(data)
        .then((response) => response.json())
        .then(({code, message}) => {
          if (code !== 200) {
            throw Error(message);
          }

          this.setState({
            formErrors: {},
            notification: {text: `Registration was successful`, type: 'ok'},
          });

          // Login after register
          this.handleLogin(data.email, data.password);
        })
        .catch((e) => {
          this.setState({
            formErrors: {},
            notification: {text: e.message, type: 'critical'}
          });
        });

    return true; // Request has been processed
  }

  async handleLogin(email, password) {
    try {
      const result = await this.props.handleLogin({
        email,
        password
      });
      const { authToken, refreshToken, user } = result;

      Auth.login(authToken, refreshToken, user);
      Auth.setPersistentNonSensitiveUserData({
        email: user.email,
        name: user.name
      });

      window.location.href = '/';
    } catch (e) {
      this.setState({
        notification: { text: 'Sorry, we could not auto-log you in. Please log in manually.', type: 'critical' },
        formErrors: {}
      });
      this.fadeOutRedirect(this.loginScreenEndpoint);
    }
    return true; // Request has been processed
  }

  render() {
    const {
        notification,
        formErrors,
        animation
      } = this.state,
      { loading, loggedInRedirect } = this.props;

    const isLoggedIn = !!Auth.getAuthToken();

    return isLoggedIn ? (
      <Redirect to={loggedInRedirect} />
    ) : (
      <Translate>
        {({ translate }) => (
          <MinimalLayout
            title="Academy account - Registration"
          >
            {!!notification.text && (
              <Notification
                type={`${notification.type ? notification.type : 'info'}`}
                onClose={this.dismissNotifications}
                open={!!notification.text}
                message={notification.text}
              />
            )}

            <ResponsiveContext.Consumer>
              {size => {
                const portableSize = ['small', 'medium'].includes(size);
                return (
                  <Box width="full" align="center" animation={animation}>
                    <Box
                      alignSelf={size === 'large' ? 'end' : 'center'}
                      flex={false}
                      margin={{
                        horizontal: 'large',
                        top: 'large',
                        bottom: '0'
                      }}
                      align="center"
                      background={'rgba(255,255,255,0.5)'}
                      pad="small"
                    >
                      <CompanyLogo
                        href={config.read(['meta', 'webUrl'])}
                        width={size === 'large' ? '200px' : '220px'}
                      />
                    </Box>

                    <Box
                      pad="large"
                      width={portableSize ? 'full' : '910px'}
                      gap="medium"
                      flex={false}
                      margin={!portableSize ? { right: '300px' } : {}}
                    >
                      <Box
                        gap="small"
                        width="full"
                        margin={{
                          bottom: size === 'small' ? 'xlarge' : 'medium'
                        }}
                      >
                        <Heading margin="none" level="1" color="brand">
                          Academy account - Registration
                        </Heading>
                        <Heading
                          margin="none"
                          size="small"
                          style={{ maxWidth: 'inherit' }}
                          level="3"
                        >
                          <Translate id="registration.subtitle">
                            Please fill in your information and get your account
                            set up in no time.
                          </Translate>
                        </Heading>
                      </Box>

                      <Box width="full">
                        <Box
                          direction="row-responsive"
                          justify="between"
                          align="center"
                          margin={{ bottom: 'large' }}
                        >
                          <Text>
                            <Translate id="registration.alreadyHaveAccount">
                              Already have an account?
                            </Translate>
                            &nbsp;
                            <Button
                              plain
                              onClick={() =>
                                this.fadeOutRedirect(this.loginScreenEndpoint)
                              }
                              label={
                                <Text weight="bold" color="brand">
                                  <Translate id="login.login">Login</Translate>
                                </Text>
                              }
                            />
                          </Text>
                        </Box>
                        <RegisterForm
                            handleSubmit={this.handleSignUp}
                            loading={loading}
                            errors={formErrors}
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              }}
            </ResponsiveContext.Consumer>
          </MinimalLayout>
        )}
      </Translate>
    );
  }
}
