export default class AccountService {
    static LIVE_USD_VIP = 'Live/TA-B-USD-VIP';
    static LIVE_EUR_VIP = 'Live/TA-B-EURO-VIP';

    static LEVERAGE_100 = 100;
    static LEVERAGE_200 = 200;
    static LEVERAGE_300 = 300;
    static LEVERAGE_400 = 400;
    static LEVERAGE_500 = 500;

    static CURRENCY_USD = 'USD';
    static CURRENCY_EUR = 'EUR';

    static accountTypes = [
        AccountService.LIVE_USD_VIP,
        AccountService.LIVE_EUR_VIP,
    ];

    static leverages = [
        AccountService.LEVERAGE_100,
        AccountService.LEVERAGE_200,
        AccountService.LEVERAGE_300,
        AccountService.LEVERAGE_400,
        AccountService.LEVERAGE_500,
    ];

    getCurrency(accountType)
    {
        switch (accountType) {
            case AccountService.LIVE_USD_VIP:
                return AccountService.CURRENCY_USD;
            case AccountService.LIVE_EUR_VIP:
                return AccountService.CURRENCY_EUR;
            default:
                return null;
        }
    }

    getTypeLabel(accountType)
    {
        switch (accountType) {
            case AccountService.LIVE_USD_VIP:
                return 'Academy account USD';
            case AccountService.LIVE_EUR_VIP:
                return 'Academy account EUR';
            default:
                return null;
        }
    }

    getLeverageLabel(leverage)
    {
        switch (leverage - 0) {
            case AccountService.LEVERAGE_100:
                return '1:100';
            case AccountService.LEVERAGE_200:
                return '1:200';
            case AccountService.LEVERAGE_300:
                return '1:300';
            case AccountService.LEVERAGE_400:
                return '1:400';
            case AccountService.LEVERAGE_500:
                return '1:500';
            default:
                return null;
        }
    }
}
