import ApolloClient, { InMemoryCache } from 'apollo-boost';
import Auth from '../services/auth';
import { resolvers } from './resolvers';
import { typeDefs } from './typeDefs';
import { defaults } from './defaults';

const cache = new InMemoryCache();
const refreshAuthEndpoint = process.env.REACT_APP_AUTH_REFRESH_TOKEN_ENDPOINT;

if (!refreshAuthEndpoint) {
  throw new Error('Please specify the refresh token api endpoint');
}

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_API_HOST || 'http://localhost:4466/',
  fetchOptions: {
    credentials: 'include'
  },
  request: async operation => {
    const authToken = Auth.getAuthToken();
    // If auth token is present set it to the http headers
    if (authToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${authToken}`
        }
      });
    }
  },
  onError: ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        console.log(graphQLErrors);
        // handle errors differently based on its extensions
        switch (err.type) {
          /*
          case 'TOKEN_EXPIRED':
          const refreshToken = Auth.getRefreshToken();

          if (refreshToken) {
            const headers = operation.getContext().headers;
            // Retry operation sending refresh token
            operation.setContext({
              headers: {
                ...headers,
                refresh_authorization: refreshToken,
              },
            });
          }
          // Now, pass the modified operation to the next link
          // in the chain. This effectively intercepts the old
          // failed request, and retries it with a new token
          return forward(operation);
          */

          case 'UNAUTHENTICATED':
          case 'TOKEN_EXPIRED':
            Auth.logout();
            break;

          // User doesnt have proper privilegies for an action
          case 'UNAUTHORIZED':
            Auth.unauthorizedRedirect();
            return forward(operation);
          // handle other errors
          default: {
            return forward(operation);
          }
        }
      }
    }
  },
  cache,
  clientState: {
    cache,
    defaults,
    typeDefs,
    resolvers
  }
});

export default client;
