import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Form,
  FormField,
  Heading,
  Select,
  Text,
  TextArea
} from 'grommet/es6';
import {
  BackLink,
  Layout,
  Loader,
  Modal,
  Notification
} from '../../components';
import { routes } from '../../config/routes';
import { FLASH_TYPES, useFlash } from '../../hooks';
import {
  CellActionsFormatter,
  ToolbaredTable
} from '../../components/DataTable';
import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT } from '../../config/site';
import { FormAdd, View } from 'grommet-icons';
import { Translate } from 'react-localize-redux';

function getEndpoint(key) {
  const endpoints = {
    viewTrader: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'View').path,
    broker: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders').path,
    dashboard: routes.find(route => route.name === 'Dashboard').path
  };
  return endpoints[key];
}

const Support = props => {
  const [flash, showFlash, dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );
  const [modal, showModal, dismissModal] = useFlash();
  const [viewModal, showViewModal, dismissViewModal] = useFlash();

  const [inputs, setInputs] = useState({
    subject: undefined,
    message: undefined
  });

  const [state, setState] = useState({
    formErrors: props.formErrors,
    selectedItem: undefined
  });

  const columns = useMemo(() => {
    return [
      {
        key: 'subject',
        name: 'Předmět',
        formatter({ row }) {
          const actions = [
            {
              icon: <View size="small" color="brand"/>,
              text: 'View',
              callback: () => {
                showViewModal();
                setState({ ...state, selectedItem: row });
              }
            }
          ];

          return (
            <>
              <CellActionsFormatter actions={actions}/>
              <Button
                plain
                label={row.subject}
                color="brand"
                title="View message"
                onClick={() => {
                  showViewModal();
                  setState({ ...state, selectedItem: row });
                }}
              />
            </>
          );
        }
      },
      {
        key: 'created',
        name: 'Vytvořeno',
        width: 200,
        formatter: ({ row }) =>
          row.created
            ? moment(row.created).format(DEFAULT_DATETIME_FORMAT)
            : null
      },
      {
        key: 'updated',
        name: 'Obnoveno',
        width: 200,
        formatter: ({ row }) =>
          row.updated
            ? moment(row.updated).format(DEFAULT_DATETIME_FORMAT)
            : null
      },
      {
        key: 'status',
        name: 'Stav',
        width: 100
      }
    ].map(col => {
      return {
        ...col,
        resizable: true,
        sortable: true
      };
    });
  }, []);

  const handleSubmit = async () => {
    const fields = ['subject', 'message'],
      requiredFields = fields,
      formErrors = {},
      data = {};
    let valid = true;

    // Validation
    for (let i = 0; i < fields.length; i++) {
      const name = fields[i];
      const value = inputs[name];

      if (fields.includes(name)) {
        data[name] = value;
      }

      if (requiredFields.includes(name) && (!value || value.length === 0)) {
        formErrors[name] = 'This field is required.';
        valid = false;
      }
    }

    if (!valid) {
      return setState({ ...state, formErrors });
    }

    new Promise(async (resolve, reject) => {
      try {
        await props.createItem(data);
        resolve(`Ticket created.`);
      } catch (e) {
        reject(e);
      }
    })
      .then(response => {
        setInputs({});
        setState({
          ...state,
          formErrors: {}
        });
        showFlash(response, FLASH_TYPES.OK);
        dismissModal();
      })
      .catch(error => {
        showFlash(error.message, FLASH_TYPES.CRITICAL);
      });
    return true;
  };

  const handleUpdate = event => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const { formErrors } = state;
  const { loading, isMyAccount, currentUserId } = props;

  return (
    <Translate>
      {({ translate }) => (
        <Layout title="Client support" fluid>
          <BackLink
            link={
              !isMyAccount
                ? getEndpoint('viewTrader').replace(':id', currentUserId)
                : getEndpoint('dashboard')
            }
          />

          <Box
            direction="row-responsive"
            justify="between"
            align="center"
            margin={{ vertical: 'medium' }}
          >
            <Box direction="row" align="center">
              <Heading size="small">Client support</Heading>
              {loading && <Loader/>}
            </Box>
            <Button
              primary
              label="New issue"
              icon={<FormAdd/>}
              color="status-ok"
              onClick={showModal}
              style={{ padding: '2px 5px' }}
            />
          </Box>

          {flash.visible && (
            <Notification
              type={flash.type ? flash.type : 'info'}
              onClose={dismissFlash}
              open={flash.visible}
              message={flash.text}
            />
          )}

          <Box flex={false} justify="center">
            <Heading size="medium" level="3">
              Issues
            </Heading>
          </Box>

          <div style={{ width: `100%`, height: '500px', position: 'relative' }}>
            <div
              style={{ width: '100%', height: '100%', position: 'absolute' }}
            >
              <ToolbaredTable
                toolbarProps={{
                  enableFiltering: false,
                  enableColumnHide: false
                }}
                columns={columns}
                rows={props.messages}
              />
            </div>
          </div>

          {/* Modals */}
          {modal.visible && (
            <Modal
              open={modal.visible}
              heading={'Open a ticket'}
              onClose={dismissModal}
            >
              <Form
                style={{ width: '100%', flex: 1 }}
                name="submitTicket"
                className="submitTicketForm"
                onSubmit={handleSubmit}
              >
                <FormField
                  style={{ flex: 1 }}
                  label={
                    <Box gap="small" direction="row" align="center">
                      <Translate id="support.labels.subject">Předmět</Translate>
                    </Box>
                  }
                  htmlFor="subject"
                  error={
                    formErrors['subject'] && translate('errors.requiredField')
                  }
                >
                  <Select
                    id="subject"
                    name="subject"
                    type="text"
                    value={inputs.subject ? inputs.subject : ''}
                    disabled={loading}
                    options={[
                      'MT platforma',
                      'Moje obchody - Historie',
                      'Vklad',
                      'Výber',
                      'Jiné'
                    ]}
                    placeholder={translate('support.placeholders.subject')}
                    onChange={({ option }) => {
                      handleUpdate({
                        target: { name: 'subject', value: option }
                      });
                    }}
                  />
                </FormField>

                <FormField
                  style={{ flex: 1 }}
                  label={
                    <Box gap="small" direction="row" align="center">
                      <Translate id="support.labels.message">Message</Translate>
                    </Box>
                  }
                  htmlFor="message"
                  error={
                    formErrors['message'] && translate('errors.requiredField')
                  }
                >
                  <TextArea
                    id="message"
                    name="message"
                    type="text"
                    value={inputs.message ? inputs.message : ''}
                    disabled={loading}
                    placeholder={translate('support.placeholders.message')}
                    onChange={handleUpdate}
                  />
                </FormField>

                <Box
                  as="footer"
                  gap="small"
                  direction="row"
                  align="center"
                  justify="end"
                  pad={{ top: 'medium', bottom: 'small' }}
                >
                  <Button
                    label="Cancel"
                    onClick={() => {
                      dismissModal();
                    }}
                    disabled={loading}
                    color="dark-3"
                  />
                  <Button
                    label={
                      <Text color="white">
                        <Translate id="global.submit">Submit</Translate>
                      </Text>
                    }
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={loading}
                    primary
                    color="status-ok"
                  />
                </Box>
              </Form>
            </Modal>
          )}

          {viewModal.visible && (
            <Modal
              open={viewModal.visible}
              heading={state.selectedItem.subject}
              onClose={dismissViewModal}
              width="90vw"
              onClickOutside={dismissViewModal}
            >
              <Box pad={{ vertical: 'small' }}>
                Datum:{' '}
                {moment(state.selectedItem.created).format(DEFAULT_DATETIME_FORMAT)}
              </Box>
              <div
                style={{
                  maxHeight: '75vh',
                  overflow: 'auto',
                  maxWidth: '600px',
                  margin: '0 auto',
                  padding: '20px',
                  background: 'white'
                }}
                dangerouslySetInnerHTML={{
                  __html: state.selectedItem.message && state.selectedItem.message
                }}
              />
              <Box
                as="footer"
                gap="small"
                direction="row"
                align="center"
                justify="end"
                pad={{ top: 'medium', bottom: 'small' }}
              >
                <Button
                  label="Zavřít"
                  onClick={() => {
                    dismissViewModal();
                    setState({ ...state, selectedItem: undefined });
                  }}
                  disabled={loading}
                  color="dark-3"
                />
              </Box>
            </Modal>
          )}
        </Layout>
      )}
    </Translate>
  );
};

Support.propTypes = {
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  messages: PropTypes.array,
  createItem: PropTypes.func.isRequired,
  hiddenColumns: PropTypes.array,
  loading: PropTypes.bool,
  isMyAccount: PropTypes.bool,
  formErrors: PropTypes.object,
  notification: PropTypes.object
};

Support.defaultProps = {
  messages: [],
  formErrors: {},
  hiddenColumns: [],
  isMyAccount: true,
  loading: false,
  notification: {
    text: '',
    type: 'info'
  }
};

export default Support;
