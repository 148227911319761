import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CheckBox, DropButton, Select, Text } from 'grommet/es6';
import { Filter, FormDown } from 'grommet-icons/es6';
import { Translate } from 'react-localize-redux';

const Toolbar = ({
  height,
  toggleableColumns,
  toggleableOptions,
  activeOptions,
  hiddenColumns = [],
  batchActions,
  onBatchAction,
  enableColumnHide,
  onHideColumn,
  filtersActive,
  onToggleFilters,
  showStats,
  enableFiltering,
  showingRows,
  totalRows,
  children
}) => {
  return (
      <Translate>
        {({ translate }) => (
    <Box
      className="toolbar"
      height={{ min: height }}
      justify="center"
      flex={false}
      overflow="hidden"
      border={{ side: 'bottom', color: 'dark-2' }}
    >
      <Box
        direction="row-responsive"
        pad="small"
        width="full"
        align={'center'}
        justify="between"
      >
        {(showStats || children) && (
          <Box gap="small" direction="row">
            {showStats && (
              <Box direction="row" align="center">
                <Text>
                  {translate('global.shown')}: <b>{showingRows}</b>
                </Text>
                {showingRows !== totalRows && (
                  <Text>
                    &nbsp;z <b>{totalRows}</b>
                  </Text>
                )}
              </Box>
            )}
            {batchActions.length ? (
              <Box direction="row" align="center" gap="xsmall">
                {batchActions.map((action, index) => (
                  <DropButton
                    key={`batch-${index}`}
                    primary
                    color="dark-2"
                    icon={<FormDown size="small" />}
                    reverse
                    style={{
                      borderRadius: '4px',
                      padding: '4.5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '27px'
                    }}
                    label={action.label}
                    dropAlign={{ top: 'bottom', left: 'left' }}
                    dropContent={
                      <Box>
                        {action.actions.map(batch => (
                          <Box key={batch.key}>
                            <Button
                              className="batch-action"
                              plain
                              focusIndicator={false}
                              onClick={() =>
                                onBatchAction ? onBatchAction(batch) : null
                              }
                              {...batch}
                            />
                          </Box>
                        ))}
                      </Box>
                    }
                    {...action.props}
                  />
                ))}
              </Box>
            ) : null}{' '}
            {children}
          </Box>
        )}

        <Box direction="row" align="center" gap="xsmall">
          {toggleableOptions.map(
            ({ label, name, onChange, ...option }, index) => (
              <Box
                key={`option-${index}`}
                direction="row"
                margin={{ right: 'small' }}
              >
                <CheckBox
                  name={name}
                  checked={activeOptions.includes(name)}
                  label={label}
                  onChange={onChange}
                  {...option}
                />
              </Box>
            )
          )}

          {enableFiltering && (
            <Button
              color={filtersActive ? 'dark-3' : 'dark-2'}
              round="xsmall"
              plain
              label={
                <Box pad="xsmall">
                  <Filter color="white" size="18px" />
                </Box>
              }
              title="Toggle filters"
              primary
              onClick={onToggleFilters}
            />
          )}

          {enableColumnHide && (
            <Box round="xsmall" className="toolbarColumnToggle">
              <Select
                multiple
                closeOnChange={false}
                icon={() => <FormDown size="small" />}
                placeholder="Display Columns"
                valueLabel={
                  <Box pad="xsmall">
                    <Text weight="bold" color="light-1">
                      {translate('global.columns')}
                    </Text>
                  </Box>
                }
                selected={hiddenColumns}
                options={toggleableColumns}
                dropHeight="medium"
                onChange={({ selected: nextSelected }) => {
                  if (onHideColumn) onHideColumn(nextSelected);
                }}
              >
                {(option, index) => {
                  return (
                    <Box direction="row" gap="small" align="center" pad="10px">
                      <CheckBox
                        tabIndex="-1"
                        checked={hiddenColumns.indexOf(index) === -1}
                        label={option.name}
                        onChange={() => {}}
                      />
                    </Box>
                  );
                }}
              </Select>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
        )}
      </Translate>
  );
};

Toolbar.propTypes = {
  height: PropTypes.string,
  toggleableColumns: PropTypes.array,
  toggleableOptions: PropTypes.array,
  hiddenColumns: PropTypes.array,
  activeOptions: PropTypes.array,
  batchActions: PropTypes.array,
  onBatchAction: PropTypes.func,
  onHideColumn: PropTypes.func,
  onToggleFilters: PropTypes.func,
  filtersActive: PropTypes.bool,
  showingRows: PropTypes.number,
  totalRows: PropTypes.number,
  enableFiltering: PropTypes.bool,
  showStats: PropTypes.bool,
  children: PropTypes.any
};

Toolbar.defaultProps = {
  hiddenColumns: [],
  activeOptions: [],
  toggleableColumns: [],
  toggleableOptions: [],
  batchActions: [],
  showingRows: 0,
  totalRows: 0,
  showStats: true,
  enableFiltering: true,
  enableColumnHide: true
};

export default Toolbar;
