const config = {
  app: {
    referralKey: 'referral',
    referralLink: 'https://tr.tradingacademy.club/open-real-account/?referral='
  },

  meta: {
    title: "Trader's room - Trading Academy Club",
    canonicalUrl: 'https://tr.tradingacademy.club',
    supportEmail: 'technical@tradingacademy.club',
    webUrl: 'https://tradingacademy.club',
    metaTraderDownload:
      'https://download.mql5.com/cdn/web/trading.academy.club/mt5/tradingacademyclub5setup.exe'
  },

  auth: {
    storageAuthState: 'tr_auth_data',
    refreshTokenHTTPHeader: 'x-jwt-refresh',
    unauthorizedRedirect: '/login',
  }
};

export default config;
