import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT } from '../../config/site';
import { Layout, BackLink } from '../../components';
import { Box, Heading, Text } from 'grommet/es6';
import { Loader, Modal, Notification } from '../../components';
import { routes } from '../../config/routes';
import { FLASH_TYPES, useFlash } from '../../hooks';
import {
  CellActionsFormatter,
  getFilterRenderer,
  ToolbaredTable
} from '../../components/DataTable';
import { Button, CheckBox, Form, TextInput } from 'grommet';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SAVE_OPTION } from '../../redux/app';
import Auth, { USER_ROLES } from '../../services/auth';
import { Close, Edit } from 'grommet-icons';
import TradingService from "../../services/TradingService";
import Container from "../../utils/Container";

function getEndpoint(key) {
  const endpoints = {
    viewTrader: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'View').path,
    dashboard: routes.find(route => route.name === 'Dashboard').path
  };
  return endpoints[key];
}

const ViewAccount = props => {
  const hiddenColumns =
    useSelector(
      state => state.app.storage.options.viewAccountTableCols,
      shallowEqual
    ) || props.hiddenColumns;
  const [orderInputs, setOrderInputs] = useState({ tp: '', sl: '' });
  const [orderErrors, setOrderErrors] = useState({});
  const [totals, setTotals] = useState({ profit: 0, volume: 0, swaps: 0 });
  const [selectedRows, setSelectedRows] = useState(new Set());

  const [flash, showFlash, dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );
  const [modal, showModal, dismissModal] = useFlash();
  const [modifyModal, showModifyModal, dismissModifyModal] = useFlash();

  const dispatch = useDispatch();

  const [state, setState] = useState({
    selectedItem: null,
    isBulk: false,
    loading: false,
  });

  const [positionUpdating, setPositionUpdating] = useState(false);
  const [accountLoading, setAccountLoading] = useState(true);
  const [tradingAccount, setTradingAccount] = useState({});
  const [positionsLoading, setPositionsLoading] = useState(true);
  const [openedPositions, setOpenedPositions] = useState([]);

  const container = Container.getInstance();
  const dataProvider = container.getDataProvider();
  const tradingService = container.getTradingService();
  const accountService = container.getAccountService();

  useEffect(() => {
    loadAccount();
    loadPositions();
  }, []);

  async function loadPositions() {
    dataProvider.getUserOpenedPositions(props.userId).then((positions) => {
      if (positions) {
        setOpenedPositions(positions.map((pos => {
          pos['Cmd'] = tradingService.getAction(pos.Action);
          return pos;
        })));
      }
      setPositionsLoading(false);
    });
  }

  async function loadAccount() {
    dataProvider.getTradingAccount(props.userId).then((account) => {
      if (account) {
        setTradingAccount(account);
      }
      setAccountLoading(false);
    });
  }

  const bulkActions = useMemo(() => {
    return [
      {
        label: 'Bulk edit',
        actions: [
          {
            key: '1',
            label: 'Modify TP/SL',
            onClick: () => {
              setOrderInputs({ ...orderInputs, tp: 0, sl: 0 });
              setState({ ...state, isBulk: true });
              showModifyModal();
            }
          }
        ]
      }
    ];
  }, [selectedRows]);

  const columns = useMemo(() => {
    return [
      Auth.roleCheckVerification(USER_ROLES.administrator) && {
        key: 'selected',
        name: 'Select',
        resizable: false,
        sortable: false,
        width: 80,
        headerRenderer({ allRowsSelected, onAllRowsSelectionChange }) {
          return (
            <Box align="center" justify="center" height="30px" width="full">
              <CheckBox
                className="checkbox-inverted"
                checked={allRowsSelected}
                onChange={({ target: { checked } }) =>
                  onAllRowsSelectionChange(checked)
                }
              />
            </Box>
          );
        },
        formatter({ isRowSelected, onRowSelectionChange }) {
          return (
            <Box align="center" justify="center" height="full" width="full">
              <CheckBox
                checked={isRowSelected}
                onChange={({ target: { checked } }) => {
                  onRowSelectionChange(checked)
                }}
              />
            </Box>
          );
        }
      },
      {
        key: 'Position',
        name: 'Order',
        width: 150,
        formatter({ row }) {
          const actions = Auth.roleCheckVerification(USER_ROLES.administrator)
            ? [
                {
                  icon: <Edit size="small" color="brand" />,
                  text: 'Edit',
                  callback: () => {
                    setState({ ...state, selectedItem: row, isBulk: false });
                    setOrderInputs({ tp: row.PriceTP, sl: row.PriceSL });
                    showModifyModal();
                  }
                },
                {
                  icon: <Close size="small" color="status-critical" />,
                  text: 'Close',
                  callback: () => {
                    setState({ ...state, selectedItem: row });
                    showModal(
                      `Are you sure you want to close order ${row.Position}?`
                    );
                  }
                }
              ]
            : null;

          return (
            <>
              {actions ? <CellActionsFormatter actions={actions} /> : null}
              <div>{row.Position}</div>
            </>
          );
        }
      },
      {
        key: 'Cmd',
        name: 'Direction',
        filterRenderer: getFilterRenderer('text'),
      },
      {
        key: 'Symbol',
        name: 'Symbol',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'Volume',
        name: 'Volume',
        filterRenderer: getFilterRenderer('numeric')
      },
      {
        key: 'Profit',
        name: 'Profit',
        filterRenderer: getFilterRenderer('numeric')
      },
      {
        key: 'Storage',
        name: 'Swap',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'Comment',
        name: 'Comment',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'PriceOpen',
        name: 'Open price',
        filterRenderer: getFilterRenderer('numeric')
      },
      {
        key: 'PriceSL',
        name: 'Stop loss',
        filterRenderer: getFilterRenderer('numeric')
      },
      {
        key: 'PriceTP',
        name: 'Take profit',
        filterRenderer: getFilterRenderer('numeric')
      },
      {
        key: 'TimeCreate',
        name: 'Opened',
        width: 200,
        dateFormat: 'X',
        filterRenderer: getFilterRenderer('date', {
          datePicker: true,
          placeholder: 'Select Date/Range'
        }),
        formatter: ({ row }) =>
            row.TimeCreate
                ? moment(row.TimeCreate, 'X').format(DEFAULT_DATETIME_FORMAT)
                : null
      }
    ].map(col => {
      return {
        resizable: true,
        sortable: true,
        ...col
      };
    });
  }, [selectedRows]);

  const hideColumns = nextState => {
    dispatch({
      type: SAVE_OPTION,
      key: 'viewAccountTableCols',
      value: nextState
    });
  };

  const onSelectedRowsChange = selectedRows => {
    setSelectedRows(selectedRows);
  };

  const handleOrderInputChange = event => {
    const state = { ...orderInputs, [event.target.name]: event.target.value };
    setOrderInputs(state);
  };

  async function updatePosition(position) {
    setPositionUpdating(true);

    dataProvider
        .updatePosition({
          position: position,
          tp: orderInputs.tp,
          sl: orderInputs.sl,
        })
        .then((response) => response.json())
        .then(response => {
          if (response.code !== 200) {
            throw Error(response.message);
          }
        });
  }

  async function bulkUpdatePositions(positions) {
    setPositionUpdating(true);

    const result = {ok: 0, err: 0};
    return new Promise((resolve, reject) => {
      positions.forEach(async (pos, i) => {
        const response = await dataProvider.updatePosition({
          position: pos,
          tp: orderInputs.tp,
          sl: orderInputs.sl,
        });
        const json = await response.json();

        if (json.code !== 200) {
          result.err += 1;
        } else {
          result.ok += 1;
        }

        if (i === (positions.length - 1)) {
          if (result.ok !== 0) {
            resolve(result);
          } else {
            reject("Error bulk processing orders");
          }
        }
      });
    });
  }

  const handleModifyOrderSubmit = async () => {
    setPositionUpdating(true);

    const isBulk = state.isBulk;

    try {
      if (isBulk) {
        const result = await bulkUpdatePositions(Array.from(selectedRows));
        showFlash(`OK: ${result.ok}, ERROR: ${result.err}.`, FLASH_TYPES.OK);
      } else {
        await updatePosition(state.selectedItem.Position);
        showFlash(`Order modified.`, FLASH_TYPES.OK);
      }

      setOrderErrors({});
      setOrderInputs({...orderInputs, tp: '', sl: ''});
      dismissModifyModal();
      loadPositions();
    } catch (e) {
      showFlash(e.message, FLASH_TYPES.CRITICAL);
    }

    setPositionUpdating(false);
    setSelectedRows(new Set());
    setState({
      ...state,
      selectedItem: null,
      isBulk: false
    });
    return true;
  };

  const closeOrder = async item => {
    if (!item || !item.Position) {
      return;
    }

    setPositionUpdating(true);
    await props
      .closeOrder({
        order: item.Position,
        login: item.Login,
        symbol: item.Symbol,
        cmd: item.Action,
        volume: item.Volume,
      })
      .then(response => {
        dismissModal();
        showFlash(`Order ${item.Position} closed`, FLASH_TYPES.OK);
        loadPositions();
      })
      .catch(error => {
        showFlash('Could not close order', FLASH_TYPES.CRITICAL);
      });
    setPositionUpdating(false);
    setState({
      ...state,
      selectedItem: null,
    });
    return true;
  };

  function onRowsChange(rows) {
    setTotals(() =>
      rows.reduce(
        (acc, item) => {
          if (item.Action == TradingService.ACTION_BUY || item.Action == TradingService.ACTION_SELL) {
            acc.volume += parseFloat(item.Volume);
          }
          acc.profit += parseFloat(item.Profit);
          acc.swaps += parseFloat(item.Storage);
          return acc;
        },
        { profit: 0, volume: 0, swaps: 0 }
      )
    );
  }

  const { selectedItem } = state;

  return (
    <Layout title="Trading account" fluid>
      <BackLink
        link={
          props.brokerView
            ? getEndpoint('viewTrader').replace(':id', props.userId)
            : getEndpoint('dashboard')
        }
      />

      <Box
        direction="row"
        justify="between"
        align="center"
        margin={{ vertical: 'medium' }}
      >
        <Box direction="row" align="center">
          <Heading size="small">
            {props.brokerView
              ? 'Trading account details'
              : 'My trading account'}
          </Heading>
        </Box>
      </Box>

      <Box direction="row" align="center">
        <Heading size="medium" level="3">
          {accountLoading
            ? 'Please wait'
            : tradingAccount && tradingAccount.login
            ? `ID: ${tradingAccount.login}`
            : ''}
        </Heading>
        {accountLoading && <Loader />}
      </Box>

      {flash.visible && (
        <Notification
          type={flash.type ? flash.type : 'info'}
          onClose={dismissFlash}
          open={flash.visible}
          message={flash.text}
        />
      )}

      {modal.visible && (
        <Modal open={modal.visible} heading={'Confirm'} onClose={dismissModal}>
          <Text>{modal.text}</Text>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            <Button
              label="Cancel"
              onClick={dismissModal}
              disabled={positionUpdating}
              color="dark-3"
            />
            <Button
              label={
                <Text color="white">
                  <strong>Confirm</strong>
                </Text>
              }
              onClick={() => {
                closeOrder(selectedItem);
              }}
              disabled={positionUpdating}
              primary
              color="status-critical"
            />
          </Box>
        </Modal>
      )}

      {modifyModal.visible && (
        <Modal
          open={modifyModal.visible}
          heading={`Modify order${state.isBulk ? 's' : ''}`}
          onClose={dismissModifyModal}
        >
          <Box margin={{ top: 'small' }}>
            <Form onSubmit={handleModifyOrderSubmit}>
              <Box flex={false} margin={{ bottom: 'medium' }}>
                <Box direction="row-responsive">
                  <Text
                    weight="bold"
                    margin={{ vertical: 'small', right: 'small' }}
                    as="label"
                    style={{ display: 'block', minWidth: '50px' }}
                  >
                    TP:
                  </Text>
                  <TextInput
                    name="tp"
                    type="number"
                    step="0.00001"
                    min="0"
                    style={{ borderColor: orderErrors.tp ? 'red' : '' }}
                    placeholder="Take profit"
                    value={orderInputs.tp}
                    disabled={positionUpdating}
                    onChange={handleOrderInputChange}
                  />
                </Box>
                {orderErrors.tp && (
                  <Text color="status-critical">{orderErrors.tp}</Text>
                )}
              </Box>

              <Box flex={false} margin={{ bottom: 'medium' }}>
                <Box direction="row-responsive">
                  <Text
                    weight="bold"
                    margin={{ vertical: 'small', right: 'small' }}
                    as="label"
                    style={{ display: 'block', minWidth: '50px' }}
                  >
                    SL:
                  </Text>
                  <TextInput
                    name="sl"
                    type="number"
                    step="0.00001"
                    min="0"
                    style={{ borderColor: orderErrors.sl ? 'red' : '' }}
                    placeholder="Stop loss"
                    value={orderInputs.sl}
                    disabled={positionUpdating}
                    onChange={handleOrderInputChange}
                  />
                </Box>
                {orderErrors.sl && (
                  <Text color="status-critical">{orderErrors.sl}</Text>
                )}
              </Box>

              <Box
                as="footer"
                gap="small"
                direction="row"
                align="center"
                justify="end"
                pad={{ top: 'medium', bottom: 'small' }}
              >
                <Button
                  label="Cancel"
                  disabled={positionUpdating}
                  onClick={dismissModifyModal}
                  color="dark-3"
                />
                <Button
                  primary
                  disabled={positionUpdating}
                  color="status-ok"
                  label="Submit"
                  type="submit"
                />
              </Box>
            </Form>
          </Box>
        </Modal>
      )}

      <Box gap="small">
        {['Balance', 'Credit'].map((item, i) => {
          const value = tradingAccount[item] || 0;
          return (
            <Box key={i.toString()} direction="row" gap="small">
              <Text as="label" size="large" color="dark-3">
                {item}:
              </Text>
              <Text size="large" color={accountLoading ? 'dark-4' : 'text'}>
                {accountLoading ? '-' : parseFloat(value).toFixed(2)}
              </Text>
            </Box>
          );
        })}

        <Box direction="row" gap="small">
          <Text as="label" size="large" color="dark-3">
            Profit:
          </Text>
          <Text size="large" color={accountLoading ? 'dark-4' : 'text'}>
            {accountLoading ? '-' : (totals.profit + totals.swaps).toFixed(2)}
          </Text>
          {!accountLoading && totals.swaps !== 0 && (
            <Text size="large" color={accountLoading ? 'dark-4' : 'text'}>
              (Swaps: {totals.swaps.toFixed(2)})
            </Text>
          )}
        </Box>

        {props.brokerView && (
          <Box gap="small">
            <Box direction="row" gap="small">
              <Text as="label" size="large" color="dark-3">
                Leverage:
              </Text>
              <Text size="large" color={accountLoading ? 'dark-4' : 'text'}>
                {accountLoading ? '-' : accountService.getLeverageLabel(tradingAccount.leverage)}
              </Text>
            </Box>

            <Box direction="row" gap="small">
              <Text as="label" size="large" color="dark-3">
                Volume:
              </Text>
              <Text size="large" color={accountLoading ? 'dark-4' : 'text'}>
                {accountLoading ? '-' : totals.volume.toFixed(2)}
              </Text>
            </Box>

            <Box direction="row" gap="small">
              <Text as="label" size="large" color="dark-3">
                Comment:
              </Text>
              <Text size="large" color={accountLoading ? 'dark-4' : 'text'}>
                {accountLoading ? '-' : tradingAccount.comment}
              </Text>
            </Box>
          </Box>
        )}
      </Box>

      <Heading size="medium" level="3">
        Opened orders
      </Heading>

      <div style={{ width: `100%`, height: '100vh', position: 'relative' }}>
        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
          <ToolbaredTable
            toolbarProps={{
              batchActions:
                Auth.roleCheckVerification(USER_ROLES.administrator) &&
                bulkActions
            }}
            toggleableColumns={columns}
            columns={columns}
            rowKey="Position"
            rows={openedPositions}
            loading={positionsLoading}
            hiddenColumns={hiddenColumns}
            onHideColumn={hideColumns}
            selectedRows={selectedRows}
            onSelectedRowsChange={onSelectedRowsChange}
            onRowsDisplay={onRowsChange}
          />
        </div>
      </div>
    </Layout>
  );
};

ViewAccount.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hiddenColumns: PropTypes.array,
  brokerView: PropTypes.bool,
  notification: PropTypes.object
};

ViewAccount.defaultProps = {
  brokerView: false,
  notification: {
    text: '',
    type: 'info'
  }
};

export default ViewAccount;
