/**
 * To check if user can access the content, wrap restricted content inside this Component.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { canUserSeeRoute } from '../../config/routes';

export const PrivateRoute = ({
  component: Component,
  location,
  isLoggedIn,
  notLoggedInRedirect,
  ...rest
}) => {

  return (
    <Route
      exact={rest.exact}
      {...rest}
      render={props =>
        isLoggedIn && canUserSeeRoute(rest) ? (
          <Component {...props} {...rest.props} />
        ) : (
          <Redirect
            to={{ pathname: notLoggedInRedirect, state: { from: location } }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  notLoggedInRedirect: PropTypes.string
};

PrivateRoute.defaultProps = {
  isLoggedIn: false,
  location: {},
  notLoggedInRedirect: '/login'
};
