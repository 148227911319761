import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BackLink,
  Layout,
  Loader,
  Modal,
  Notification
} from '../../components';
import {
  Anchor,
  Box,
  Button,
  Heading,
  MaskedInput,
  Select,
  Text,
  TextInput
} from 'grommet';
import { getApiRoute, routes } from '../../config/routes';
import moment from 'moment';
import Auth, { USER_ROLES } from '../../services/auth';
import { ACCOUNT_TYPES, DEFAULT_DATETIME_FORMAT } from '../../config/site';
import { FormCheckmark, FormClose, Trash } from 'grommet-icons';
import { FLASH_TYPES, useFlash } from '../../hooks';
import { SAVE_OPTION } from '../../redux/app';
import {
  CellActionsFormatter,
  getFilterRenderer,
  ToolbaredTable
} from '../../components/DataTable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';

function getEndpoint(key) {
  const endpoints = {
    viewTrader: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'View').path,
    broker: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders').path,
    dashboard: routes.find(route => route.name === 'Dashboard').path
  };
  return endpoints[key];
}

const Profile = props => {
  // Load hidden column indexes from the storage (global state)
  const hiddenColumns =
    useSelector(
      state => state.app.storage.options.myProfileTableCols,
      shallowEqual
    ) || props.hiddenColumns;
  const dispatch = useDispatch();

  const [state, setState] = useState({
    itemToRemove: null,
    formErrors: props.formErrors
  });

  const [inputs, setInputs] = useState({
    email: props.data.email,
    first_name: props.data.first_name,
    last_name: props.data.last_name,
    address: props.data.address,
    mt_id: props.data.mt_id,
    born: props.data.born,
    city: props.data.city,
    country: props.data.country,
    zip: props.data.zip,
    phone: props.data.phone,
    income: props.data.income,
    experience: props.data.experience,
    registeredDate: props.data.registeredDate
  });

  const [flash, showFlash, dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );
  const [modal, showModal, dismissModal] = useFlash();

  const columns = useMemo(() => {
    return [
      {
        key: 'mt_id',
        name: 'MT ID',
        width: 200,
        filterRenderer: getFilterRenderer('text'),
        formatter: ({ row }) => {
          const actions =
            !ACCOUNT_TYPES.live.includes(row.account_type) &&
            Auth.roleCheckVerification(USER_ROLES.administrator)
              ? [
                  {
                    icon: <Trash size="small" color="status-critical" />,
                    text: 'Delete',
                    callback: () => {
                      showModal();
                      return setState({
                        ...state,
                        itemToRemove: row
                      });
                    }
                  }
                ]
              : null;

          return (
            <>
              {actions ? <CellActionsFormatter actions={actions} /> : null}
              <div>{row.mt_id}</div>
            </>
          );
        }
      },
      {
        key: 'account_type',
        name: 'Typ účtu'
      },
      {
        key: 'account_currency',
        name: 'Měna',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'ask',
        name: 'Dotace'
      },
      {
        key: 'cashback',
        name: 'Auto obch.'
      },
      {
        key: 'deposit',
        name: 'Platba'
      },
      {
        key: 'leverage',
        name: 'Páka'
      }
    ].map(col => {
      return {
        ...col,
        resizable: true,
        sortable: true
      };
    });
  }, []);

  const hideColumns = nextState => {
    dispatch({
      type: SAVE_OPTION,
      key: 'myProfileTableCols',
      value: nextState
    });
  };

  useEffect(() => {
    setInputs({ ...inputs, ...props.data });
  }, [props.data.id]);

  const handleSubmit = async () => {
    const fields = [
        'id',
        'email',
        'mt_id',
        'first_name',
        'last_name',
        'address',
        'born',
        'city',
        'country',
        'zip',
        'phone',
        'income',
        'experience'
      ],
      // requiredFields = ['email', 'first_name', 'last_name', 'born', 'phone', 'country'],
      formErrors = {},
      data = {},
      keys = Object.keys(inputs);
    let valid = true;

    // Validation
    for (let i = 0; i < fields.length; i++) {
      if (keys.includes(fields[i])) {
        data[fields[i]] = inputs[fields[i]];
      }
    }

    if (!valid) {
      return setState({ ...state, formErrors });
    }

    new Promise(async (resolve, reject) => {
      try {
        await props.updateItem(data);
        resolve(
          `${props.isMyAccount ? 'Profile' : `Trader ${data.email}`} updated.`
        );
      } catch (e) {
        reject(e);
      }
    })
      .then(response => {
        setState({
          ...state,
          formErrors: {}
        });
        showFlash(response, FLASH_TYPES.OK);
      })
      .catch(error => {
        showFlash(error.message, FLASH_TYPES.CRITICAL);
      });
    return true;
  };

  const handleUpdate = event => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const removeAccount = async item => {
    if (!item || !item.id) {
      return;
    }

    await props.removeAccount({ id: item.id }).then(
      () => {
        showFlash(`Account ${item.mt_id} removed`, FLASH_TYPES.OK);
        setState({
          ...state,
          itemToRemove: null
        });
        return true;
      },
      error => {
        showFlash('Error removing trading account', FLASH_TYPES.CRITICAL);
        setState({
          ...state,
          itemToRemove: null
        });
        return false;
      }
    );
  };

  const { formErrors, itemToRemove } = state;
  const { loading, isMyAccount, currentUserId, documents, accounts } = props;
  const {
    email,
    first_name,
    last_name,
    address,
    mt_id,
    born,
    city,
    country,
    zip,
    phone,
    income,
    experience,
    registeredDate
  } = inputs;

  return (
      <Translate>
        {({ translate }) => (
    <Layout
      title={isMyAccount ? 'My profile' : "Trader's profile"}
      maxWidth="1200px"
    >
      <BackLink
        link={
          !isMyAccount
            ? getEndpoint('viewTrader').replace(':id', currentUserId)
            : getEndpoint('dashboard')
        }
      />

      <Box
        direction="row"
        justify="between"
        align="center"
        margin={{ vertical: 'medium' }}
      >
        <Box direction="row" align="center" margin={{ vertical: 'medium' }}>
          <Heading
            size="small"
            margin={{
              right: 'small',
              vertical: 'none'
            }}
          >
            {isMyAccount ? translate('tradersRoom.myProfile') : translate('tradersRoom.userProfile')}
          </Heading>

          <Box direction="row" align="center">
            {loading && <Loader />}
          </Box>
        </Box>

        <Button
          disabled={loading}
          label={translate('global.saveChanges')}
          primary
          color="brand"
          onClick={handleSubmit}
          style={{ height: '40px', borderRadius: '4px' }}
        />
      </Box>

      {flash.visible && (
        <Notification
          type={flash.type ? flash.type : 'info'}
          onClose={dismissFlash}
          open={flash.visible}
          message={flash.text}
        />
      )}

      {modal.visible && (
        <Modal open={modal.visible} heading={'Confirm'} onClose={dismissModal}>
          <Text>
            Are you sure you want to delete trading account{' '}
            {itemToRemove && itemToRemove.mt_id}?
          </Text>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            <Button
              label="Cancel"
              onClick={dismissModal}
              disabled={loading}
              color="dark-3"
            />
            <Button
              label={
                <Text color="white">
                  <strong>Delete</strong>
                </Text>
              }
              onClick={() => {
                dismissModal();
                removeAccount(itemToRemove);
              }}
              disabled={loading}
              primary
              color="status-critical"
            />
          </Box>
        </Modal>
      )}

      <Box
        direction="row"
        margin={{ vertical: 'medium' }}
        align="center"
        justify="between"
      >
        {isMyAccount ? (
          <Heading size="medium" level="2">
            {Auth.getUser().name}
          </Heading>
        ) : (
          <Heading size="medium" level="2">
            {loading
              ? 'Loading...'
              : `${
                  first_name && last_name ? `${first_name} ${last_name}` : email
                }`}
          </Heading>
        )}
      </Box>

      <Box direction="row-responsive" gap="large" margin={{ bottom: 'medium' }}>
        <Box gap="small" width="full">
          <Box direction="row-responsive">
            <Text
              color="brand"
              margin={{ vertical: 'small', right: 'small' }}
              as="label"
              style={{ display: 'block', minWidth: '150px' }}
            >
              {translate('global.firstName')}:
            </Text>
            <TextInput
              id="first_name"
              name="first_name"
              style={{ borderColor: formErrors.first_name ? 'red' : '' }}
              type="text"
              value={first_name ? first_name : ''}
              disabled={loading}
              placeholder={translate('global.firstName')}
              onChange={handleUpdate}
            />
          </Box>
          {formErrors.first_name && (
            <Text color="status-critical">{formErrors.first_name}</Text>
          )}

          <Box direction="row-responsive">
            <Text
              color="brand"
              margin={{ vertical: 'small', right: 'small' }}
              as="label"
              style={{ display: 'block', minWidth: '150px' }}
            >
              {translate('global.lastName')}:
            </Text>
            <TextInput
              id="last_name"
              name="last_name"
              style={{ borderColor: formErrors.last_name ? 'red' : '' }}
              type="text"
              value={last_name ? last_name : ''}
              disabled={loading}
              placeholder={translate('global.lastName')}
              onChange={handleUpdate}
            />
          </Box>
          {formErrors.last_name && (
            <Text color="status-critical">{formErrors.last_name}</Text>
          )}

          <Box direction="row-responsive">
            <Text
              color="brand"
              margin={{ vertical: 'small', right: 'small' }}
              as="label"
              style={{ display: 'block', minWidth: '150px' }}
            >
              {translate('global.dateOfBirth')}:
            </Text>
            <MaskedInput
              id="date-of-birth"
              name="born"
              mask={[
                {
                  length: [1, 2],
                  regexp: /^1[0-9]?$|^2[0-9]?$|^3[0-1]?$|^0[1-9]?$/,
                  placeholder: 'DD'
                },
                { fixed: '/' },
                {
                  length: [1, 2],
                  regexp: /^1[0-2]?$|^0[1-9]?$/,
                  placeholder: 'MM'
                },
                { fixed: '/' },
                {
                  length: [4],
                  regexp: /^\d{4}?$|^\d{3}?$|^\d{2}?$|^\d{1}?$/,
                  placeholder: translate('global.yyyy')
                }
              ]}
              value={born ? born : ''}
              disabled={loading}
              onChange={option =>
                handleUpdate({
                  target: { name: 'born', value: option.target.value }
                })
              }
            />
          </Box>
          {formErrors.born && (
            <Text color="status-critical">{formErrors.born}</Text>
          )}

          <Box direction="row-responsive">
            <Text
              color="brand"
              margin={{ vertical: 'small', right: 'small' }}
              as="label"
              style={{ display: 'block', minWidth: '150px' }}
            >
              Email:
            </Text>
            <TextInput
              name="email"
              type="email"
              style={{ borderColor: formErrors.email ? 'red' : '' }}
              placeholder="Email"
              value={email ? email : ''}
              disabled={loading}
              onChange={handleUpdate}
            />
          </Box>
          {formErrors.email && (
            <Text color="status-critical">{formErrors.email}</Text>
          )}

          <Box direction="row-responsive">
            <Text
              color="brand"
              margin={{ vertical: 'small', right: 'small' }}
              as="label"
              style={{ display: 'block', minWidth: '150px' }}
            >
              {translate('global.incomeSource')}:
            </Text>
            <Select
              id="income"
              name="income"
              plain
              style={{ borderColor: formErrors.income ? 'red' : '' }}
              placeholder={translate('global.choose')}
              options={[
                translate('global.employment'),
                translate('global.business'),
                translate('global.investments'),
                translate('global.pension'),
                translate('global.savings'),
                translate('global.other'),
              ]}
              disabled={loading}
              value={income ? income : ''}
              onChange={({ option }) => {
                handleUpdate({ target: { name: 'income', value: option } });
              }}
            />
          </Box>
          {formErrors.income && (
            <Text color="status-critical">{formErrors.income}</Text>
          )}

          <Box direction="row-responsive">
            <Text
              color="brand"
              margin={{ vertical: 'small', right: 'small' }}
              as="label"
              style={{ display: 'block', minWidth: '150px' }}
            >
              {translate('global.tradingExperience')}:
            </Text>
            <Select
              id="experience"
              name="experience"
              plain
              style={{ borderColor: formErrors.experience ? 'red' : '' }}
              placeholder={translate('global.tradingExperience')}
              options={[
                translate('global.none'),
                translate('global.lessThanOneYear'),
                translate('global.oneYear'),
                translate('global.threeYears'),
                translate('global.fiveYears'),
                translate('global.tenYearsMore')
              ]}
              disabled={loading}
              value={experience ? experience : ''}
              onChange={({ option }) => {
                handleUpdate({ target: { name: 'experience', value: option } });
              }}
            />
          </Box>
          {formErrors.experience && (
            <Text color="status-critical">{formErrors.experience}</Text>
          )}
        </Box>

        <Box gap="small" width="full">
          <Box direction="row-responsive">
            <Text
              color="brand"
              margin={{ vertical: 'small', right: 'small' }}
              as="label"
              style={{ display: 'block', minWidth: '150px' }}
            >
              {translate('registration.labels.phone')}:
            </Text>
            <TextInput
              id="phone"
              name="phone"
              style={{ borderColor: formErrors.phone ? 'red' : '' }}
              type="tel"
              value={phone ? phone : ''}
              disabled={loading}
              placeholder={translate('registration.labels.phone')}
              onChange={handleUpdate}
            />
          </Box>
          {formErrors.phone && (
            <Text color="status-critical">{formErrors.phone}</Text>
          )}

          <Box direction="row-responsive">
            <Text
              color="brand"
              margin={{ vertical: 'small', right: 'small' }}
              as="label"
              style={{ display: 'block', minWidth: '150px' }}
            >
              {translate('registration.labels.address')}:
            </Text>
            <TextInput
              id="address"
              name="address"
              style={{ borderColor: formErrors.address ? 'red' : '' }}
              type="text"
              value={address ? address : ''}
              disabled={loading}
              placeholder={translate('registration.labels.address')}
              onChange={handleUpdate}
            />
          </Box>
          {formErrors.address && (
            <Text color="status-critical">{formErrors.address}</Text>
          )}

          <Box direction="row-responsive">
            <Text
              color="brand"
              margin={{ vertical: 'small', right: 'small' }}
              as="label"
              style={{ display: 'block', minWidth: '150px' }}
            >
              {translate('registration.labels.city')}:
            </Text>
            <TextInput
              id="city"
              name="city"
              style={{ borderColor: formErrors.city ? 'red' : '' }}
              type="text"
              value={city ? city : ''}
              disabled={loading}
              placeholder={translate('registration.labels.city')}
              onChange={handleUpdate}
            />
          </Box>
          {formErrors.city && (
            <Text color="status-critical">{formErrors.city}</Text>
          )}

          <Box direction="row-responsive">
            <Text
              color="brand"
              margin={{ vertical: 'small', right: 'small' }}
              as="label"
              style={{ display: 'block', minWidth: '150px' }}
            >
              {translate('registration.labels.country')}:
            </Text>
            <TextInput
              id="country"
              name="country"
              style={{ borderColor: formErrors.country ? 'red' : '' }}
              type="text"
              value={country ? country : ''}
              disabled={loading}
              placeholder={translate('registration.labels.country')}
              onChange={handleUpdate}
            />
          </Box>
          {formErrors.country && (
            <Text color="status-critical">{formErrors.country}</Text>
          )}

          <Box direction="row-responsive">
            <Text
              color="brand"
              margin={{ vertical: 'small', right: 'small' }}
              as="label"
              style={{ display: 'block', minWidth: '150px' }}
            >
              {translate('registration.labels.zipCode')}:
            </Text>
            <TextInput
              id="zip"
              name="zip"
              style={{ borderColor: formErrors.zip ? 'red' : '' }}
              type="text"
              value={zip ? zip : ''}
              disabled={loading}
              placeholder={translate('registration.labels.zipCode')}
              onChange={handleUpdate}
            />
          </Box>
          {formErrors.zip && (
            <Text color="status-critical">{formErrors.zip}</Text>
          )}

          {!isMyAccount && (
            <Box direction="row-responsive" align="center">
              <Text
                color="brand"
                margin={{ vertical: 'small', right: 'small' }}
                as="label"
                style={{ display: 'block', minWidth: '150px' }}
              >
                Meta Trader ID:
              </Text>
              <TextInput
                name="mt_id"
                type="text"
                style={{ borderColor: formErrors.mt_id ? 'red' : '' }}
                placeholder="Meta Trader ID"
                value={mt_id ? mt_id : ''}
                disabled={loading || isMyAccount}
                onChange={handleUpdate}
              />
              {formErrors.mt_id && (
                <Text color="status-critical">{formErrors.mt_id}</Text>
              )}
            </Box>
          )}

          <Box direction="row-responsive" align="center">
            <Text
              color="brand"
              margin={{ vertical: 'small', right: 'small' }}
              as="label"
              style={{ display: 'block', minWidth: '150px' }}
            >
              {translate('tradersRoom.memberSince')}:
            </Text>
            {!loading && (
              <Text weight="bold">
                {moment(registeredDate).format(DEFAULT_DATETIME_FORMAT)}
              </Text>
            )}
          </Box>
        </Box>
      </Box>

      {!isMyAccount && (
        <Box margin={{ vertical: 'medium' }}>
          <Heading level="3" size="medium">
            Uploaded documents
          </Heading>
          {documents.length === 0 ? (
            <Text>
              {loading
                ? 'Loading...'
                : 'User did not upload any documents yet.'}
            </Text>
          ) : (
            <Box gap="small">
              {documents.map(document => (
                <Box
                  key={document.id}
                  gap="small"
                  align="center"
                  direction="row-responsive"
                >
                  <Anchor
                    target="_blank"
                    title="Download file"
                    href={getApiRoute('downloadDocument', [
                      Auth.getAuthToken(),
                      document.document_id
                    ])}
                    label={document.name}
                  />
                  <Text>
                    Uploaded:{' '}
                    {moment(document.uploaded).format(DEFAULT_DATETIME_FORMAT)}
                  </Text>
                  {!document.status ||
                  document.status.toLowerCase() !== 'approved' ? (
                    <Anchor
                      target="_blank"
                      title="Set status as approved"
                      href={getApiRoute('setDocumentStatus', [
                        Auth.getAuthToken(),
                        document.document_id,
                        'APPROVED'
                      ])}
                      label={<FormCheckmark color="status-ok" />}
                    />
                  ) : (
                    <Text color="status-ok">Approved</Text>
                  )}
                  <Anchor
                    target="_blank"
                    title="Reject and remove document"
                    href={getApiRoute('setDocumentStatus', [
                      Auth.getAuthToken(),
                      document.document_id,
                      'REJECTED'
                    ])}
                    label={<FormClose color="status-critical" />}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Layout>
        )}
      </Translate>
  );
};

Profile.propTypes = {
  loading: PropTypes.bool,
  isMyAccount: PropTypes.bool,
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateItem: PropTypes.func.isRequired,
  removeAccount: PropTypes.func.isRequired,
  notification: PropTypes.object,
  formErrors: PropTypes.object,
  data: PropTypes.object,
  accounts: PropTypes.array,
  hiddenColumns: PropTypes.array
};

Profile.defaultProps = {
  loading: false,
  isMyAccount: false,
  formErrors: {},
  data: {},
  accounts: [],
  hiddenColumns: [],
  notification: {
    text: '',
    type: 'info'
  }
};

export default Profile;
