import Auth from '../services/auth';
import { getCurrentLanguage } from '../services/translations';

export const defaults = {
  localUser: {
    authToken: Auth.getAuthToken(),
    user: Auth.getUser(),
    __typename: 'LocalUser'
  },
  language: getCurrentLanguage() || 'en'
};
