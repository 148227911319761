import React from 'react';
import PropTypes from 'prop-types';
import { Document, FormCheckmark } from 'grommet-icons';
import { Box, Button, Meter, Heading, Text } from 'grommet';
import { Dropzone } from './Dropzone';

export class Upload extends React.Component {
  static propTypes = {
    uploadEndpoint: PropTypes.string.isRequired,
    dropzone: PropTypes.bool,
    onError: PropTypes.func,
    onSuccess: PropTypes.func,
    beforeSend: PropTypes.func,
    expectedFiles: PropTypes.array
  };

  static defaultProps = {
    dropzone: true,
    expectedFiles: []
  };

  constructor(props) {
    super(props);

    this.state = {
      progress: {},
      inputs: {},
      errors: {},
      uploading: false,
      success: false
    };
  }

  onFilesAdded = (inputName, files) => {
    this.setState(prevState => ({
      inputs: {
        [inputName]:
          inputName === 'dropzone'
            ? prevState.inputs[inputName].concat(files)
            : files,
        ...prevState.inputs
      }
    }));
  };

  fileListToArray(list) {
    const array = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  sendRequest = (name, file) => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.onprogress = event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.progress };
          copy[file.name] = {
            state: 'pending',
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ progress: copy });
        }
      };

      req.upload.onload = event => {
        const copy = { ...this.state.progress };
        // Do not set state to done here because if file was fully sent doesnt mean server it successfully handled yet!
        copy[file.name] = { state: 'pending', percentage: 100 };
        this.setState({ progress: copy });
      };

      req.upload.onerror = event => {
        const copy = { ...this.state.progress };
        copy[file.name] = { state: 'error', percentage: 0 };
        this.setState({ progress: copy });
      };

      req.onreadystatechange = event => {
        const status = event.target.status;
        const copy = { ...this.state.progress };

        if (status >= 400) {
          copy[file.name].state = 'error';
          this.setState({ progress: copy });
          setTimeout(() => {
            reject(req.response);
          }, 500);
        } else if (status === 200) {
          copy[file.name].state = 'done';
          this.setState({ progress: copy });
          setTimeout(() => {
            resolve(req.response);
          }, 500);
        }
      };

      const formData = new FormData();
      formData.append(name, file, file.name);

      req.open('POST', this.props.uploadEndpoint);
      if (this.props.beforeSend) {
        // Place to modify request before sending
        this.props.beforeSend(req);
      }
      req.send(formData);
    });
  };

  onInputChange = event => {
    const files = this.fileListToArray(event.target.files);
    this.onFilesAdded(event.target.name, files);
  };

  uploadFiles = async () => {
    this.setState({ progress: {}, uploading: true, errors: {} });
    const promises = [],
      inputs = this.state.inputs,
      errors = {};

    this.props.expectedFiles.forEach(expected => {
      const { name, required } = expected;
      if (required && (!inputs[name] || inputs[name].length === 0))
        errors[name] = 'Please provide this file.';
    });

    Object.keys(inputs).forEach(key => {
      inputs[key].forEach(file => promises.push(this.sendRequest(key, file)));
    });

    if (Object.keys(errors).length !== 0) {
      this.setState({ errors, uploading: false });
      return false;
    }

    await Promise.all(promises)
      .then(() => {
        if (this.props.onSuccess) this.props.onSuccess();

        this.setState({ success: true, uploading: false, errors: {} });
      })
      .catch(e => {
        if (this.props.onError) this.props.onError(e);
        this.setState({ success: false, uploading: false, errors: {} });
      });
  };

  renderProgress = file => {
    const progress = this.state.progress[file.name];
    if (this.state.uploading || this.state.success) {
      return (
        <Box key={file.name} direction="row" gap="small" align="center">
          <Meter values={[{ value: progress ? progress.percentage : 0 }]} />
          <FormCheckmark
            color={
              progress && progress.state === 'done'
                ? 'status-ok'
                : 'transparent'
            }
          />
        </Box>
      );
    }
  };

  renderActions = () => {
    if (this.state.success) {
      return (
        <Button
          onClick={() => this.setState({ inputs: {}, success: false })}
          label="Clear"
          primary
          color="dark-2"
        />
      );
    } else {
      return (
        <Button
          disabled={
            Object.keys(this.state.inputs).length < 0 || this.state.uploading
          }
          onClick={this.uploadFiles}
          label="Nahrát"
          primary
          color="brand"
        />
      );
    }
  };

  render() {
    const { uploading, success, inputs, errors } = this.state,
      { dropzone, expectedFiles } = this.props;

    return (
      <Box pad="small" gap="medium">
        {dropzone ? (
          <React.Fragment>
            <Heading level="4">Nahrát soubory</Heading>
            <Dropzone
              onFilesAdded={files => this.onFilesAdded('dropzone', files)}
              disabled={uploading || success}
            />
            {inputs.dropzone &&
              inputs.dropzone.map(file => (
                <Box
                  key={file.name}
                  direction="row-responsive"
                  align="center"
                  gap="medium"
                >
                  <Box as="span" gap="small" direction="row" align="center">
                    <Document />
                    {file.name}
                  </Box>
                  {this.renderProgress(file)}
                </Box>
              ))}
          </React.Fragment>
        ) : (
          expectedFiles.map(file => (
            <Box key={file.name} gap="small" margin={{ bottom: 'small' }}>
              {file.icon !== false && (
                <Box as="span" gap="small" direction="row" align="center">
                  <Document />
                  {file.label}
                </Box>
              )}
              <input
                name={file.name}
                type="file"
                onChange={this.onInputChange}
                disabled={file.disabled}
              />
              {inputs[file.name] &&
                inputs[file.name].map(file => this.renderProgress(file))}
              {file.help && file.help}
              {errors[file.name] && (
                <Text color="status-critical">{errors[file.name]}</Text>
              )}
            </Box>
          ))
        )}
        <Box direction="row-responsive" align="center">
          {this.renderActions()}
        </Box>
      </Box>
    );
  }
}
