import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { AuthProvider, PrivateRoute } from './components';
import { configureHistory, flatRoutes } from './config/routes';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import client from './graphql/client';
import { LocalizeProvider } from 'react-localize-redux';
import { Main } from './containers';
import { Provider } from 'react-redux';
import { store } from './redux';

const history = configureHistory();

const refreshEndpoint = process.env.REACT_APP_AUTH_REFRESH_TOKEN_ENDPOINT;
const csrfEndpoint = process.env.REACT_APP_AUTH_CSRF_TOKEN_ENDPOINT;

const App = props => {
  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <Provider store={store}>
          <LocalizeProvider>
            <Router history={history}>
              <AuthProvider
                refreshEndpoint={refreshEndpoint}
                csrfTokenEndpoint={csrfEndpoint}
                csrfHeader="X-WP-Nonce"
                useCsrf={false}
              >
                {({ loading }) => {
                  return (
                    <Main loading={loading}>
                      <Switch>
                        {flatRoutes.map(route => {
                          if (route.private) {
                            return (
                              <PrivateRoute
                                key={route.path}
                                path={route.path}
                                exact={route.exact ? route.exact : true}
                                props={route.props}
                                component={route.component}
                              />
                            );
                          }
                          return (
                            <Route
                              key={route.path}
                              render={props => (
                                <route.component {...props} {...route.props} />
                              )}
                              path={route.path}
                              exact={route.exact ? route.exact : true}
                            />
                          );
                        })}
                      </Switch>
                    </Main>
                  );
                }}
              </AuthProvider>
            </Router>
          </LocalizeProvider>
        </Provider>
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};

export default App;
