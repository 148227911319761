import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BackLink,
  Layout,
  Loader,
  Modal,
  Notification,
  LinkTile
} from '../../components';
import {
  Box,
  Button,
  Form,
  Heading,
  ResponsiveContext,
  Select,
  Text,
  TextInput,
  Stack
} from 'grommet';
import { getApiRoute, routes } from '../../config/routes';
import { useFlash, FLASH_TYPES } from '../../hooks';
import symbols from '../../config/symbols';
import {
  Atm,
  ContactInfo,
  CreditCard,
  Inbox,
  Pin,
  Schedules,
  User,
  View
} from 'grommet-icons/es6';
import {
  CHANGE_BALANCE_COMMENT_SUGGESTIONS,
  CHANGE_CREDIT_COMMENT_SUGGESTIONS
} from '../../config/site';

function getEndpoint(key) {
  const endpoints = {
    profile: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'Profile').path,
    history: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'History').path,
    account: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'Account').path,
    inbox: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'Inbox').path,
    traders: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders').path
  };
  return endpoints[key];
}

const ViewTrader = props => {
  const [state, setState] = useState({
    loading: false
  });

  const [inputs, setInputs] = useState({
    amount: undefined,
    comment: undefined
  });
  const [inputErrors, setInputErrors] = useState({});
  const [orderInputs, setOrderInputs] = useState({
    cmd: 0,
    symbol: undefined,
    volume: undefined,
    comment: "",
  });
  const [orderErrors, setOrderErrors] = useState({});

  const [flash, showFlash, dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );
    const [
        enableLoginModal,
        showEnableLoginModal,
        dismissEnableLoginModal
    ] = useFlash();
  const [
    modifyAccountModal,
    showModifyAccountModal,
    dismissModifyAccountModal
  ] = useFlash();
  const [
    balanceCreditModal,
    showBalanceCreditModal,
    dismissBalanceCreditModal
  ] = useFlash();
  const [
    openOrderModal,
    showOpenOrderModal,
    dismissOpenOrderModal
  ] = useFlash();

  const handleInputChange = event => {
    const state = { ...inputs, [event.target.name]: event.target.value };
    setInputs(state);
  };

  const handleOrderInputChange = event => {
    const state = { ...orderInputs, [event.target.name]: event.target.value };
    setOrderInputs(state);
  };

  useEffect(() => {
    setInputs({ ...inputs, amount: undefined, comment: undefined });
  }, [balanceCreditModal.text]);

  useEffect(() => {
    setState({ ...state, trader: props.trader, });
  }, [props.trader.id]);

  useEffect(() => {
    if (
      props.notification.text !== flash.text &&
      props.notification.text !== ''
    ) {
      showFlash(props.notification.text, props.notification.type);
    }
  }, [props.notification.text]);

  const changeCreditBalance = (login, value, comment, credit = false) => {
    const url = getApiRoute(`change${credit ? 'Credit' : 'Balance'}`);
    return fetch(url, {
      method: 'POST',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: { 'Content-Type': 'application/json' },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify({
        login,
        value,
        comment
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Could not change ${credit ? 'credit' : 'balance'}.`);
        }
      })
      .then(json => {
        showFlash(`${credit ? 'Credit' : 'Balance'} changed.`, FLASH_TYPES.OK);
        return json;
      })
      .catch(error => {
        showFlash(error.toString(), FLASH_TYPES.CRITICAL);
      });
  };

  const handleBalanceCreditSubmit = async () => {
    const fields = ['amount', 'comment'];
    const errors = {};
    let valid = true;
    fields.forEach(key => {
      if (!inputs[key] || inputs[key] === '') {
        errors[key] = true;
        valid = false;
      }
    });
    if (!valid) {
      return setInputErrors(errors);
    }

    const changeCredit = balanceCreditModal.text === 'credit';
    setState({ ...state, loading: true });
    await changeCreditBalance(
      props.trader.mt_id,
      inputs.amount,
      inputs.comment,
      changeCredit
    );
    setState({ ...state, loading: false });
    setInputs({ ...inputs, amount: undefined, comment: undefined });
    dismissBalanceCreditModal();
  };

  const handleOpenOrderSubmit = async () => {
    const required = ['volume', 'symbol'];
    const errors = {};
    let valid = true;
    required.forEach(key => {
      if (!orderInputs[key] || orderInputs[key] === '') {
        errors[key] = true;
        valid = false;
      }
    });
    if (!valid) {
      return setOrderErrors(errors);
    }

    setOrderErrors({});
    setState({
      ...state,
      loading: true
    });
    new Promise(async (resolve, reject) => {
      try {
        await props.openOrder({
          // id: props.trader.id,
          login: props.trader.mt_id,
          ...orderInputs
        });
        resolve(`Order has been placed.`);
      } catch (e) {
        reject(e);
      }
    })
      .then(response => {
        setOrderErrors({});
        setOrderInputs({ cmd: 0, symbol: undefined, volume: undefined, comment: "" });
        dismissOpenOrderModal();
        showFlash(response, FLASH_TYPES.OK);
      })
      .catch(error => {
        showFlash(error.message, FLASH_TYPES.CRITICAL);
      })
      .finally(() => {
        setState({
          ...state,
          loading: false
        });
      });
    return true;
  };

  const toggleReadOnlyMode = async () => {
    setState({
      ...state,
      loading: true
    });
    dismissModifyAccountModal();
    new Promise(async (resolve, reject) => {
      try {
        await props.updateItem({
          sendToApi: true,
          id: props.trader.id,
          mt_id: props.trader.mt_id,
          enable_read_only: !props.trader.enable_read_only
        });
        resolve(
          `Read only is now ${
            props.trader.enable_read_only ? 'disabled' : 'enabled'
          }`
        );
      } catch (e) {
        reject(e);
      }
    })
      .then(response => {
        setState({
          ...state,
          loading: false
        });
        showFlash(response, FLASH_TYPES.OK);
      })
      .catch(error => {
        showFlash(error.message, FLASH_TYPES.CRITICAL);
      });
    return true;
  };

  const toggleLockedOutMode = async () => {
    setState({
      ...state,
      loading: true
    });
    dismissEnableLoginModal();
    new Promise(async (resolve, reject) => {
      try {
        await props.updateItem({
          sendToApi: true,
          id: props.trader.id,
          mt_id: props.trader.mt_id,
          locked_out: !props.trader.locked_out
        });
        resolve(
            `Login is now ${
                props.trader.locked_out ? 'enabled' : 'disabled'
            }`
        );
      } catch (e) {
        reject(e);
      }
    })
        .then(response => {
          setState({
            ...state,
            loading: false
          });
          showFlash(response, FLASH_TYPES.OK);
        })
        .catch(error => {
          showFlash(error.message, FLASH_TYPES.CRITICAL);
        });
    return true;
  };

  const { userId } = props;
  const { enable_read_only, locked_out } = props.trader;
  const { loading, trader } = state;

  let account = null;

  if (trader && trader.accounts && trader.accounts.nodes[0]) {
    account = trader.accounts.nodes[0];
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Layout title={`View Trader - ${props.trader.name}`}>
          <BackLink link={getEndpoint('traders')} />

          <Box
            direction="row"
            align="center"
            gap="small"
            margin={{ bottom: 'medium' }}
          >
            <Heading size="small">
              {!props.trader.name ? '' : props.trader.name}
            </Heading>

            <Box direction="row" align="center">
              {loading && <Loader/>}
            </Box>
          </Box>

          {account !== null && (
              <Box false height={{min: "80px"}}>
                <Box direction="row" align='center'>
                  <Text weight='bold' size='large'>Currency:&nbsp;</Text>
                  &nbsp;
                  <Text size='large'>{account.account_currency}</Text>
                </Box>
              </Box>
          )}

          {flash.visible && (
            <Notification
              type={flash.type ? flash.type : 'info'}
              onClose={dismissFlash}
              open={flash.visible}
              message={flash.text}
            />
          )}

          {modifyAccountModal.visible && (
            <Modal
              open={modifyAccountModal.visible}
              heading={'Confirm'}
              onClose={dismissModifyAccountModal}
            >
              <Text>{modifyAccountModal.text}</Text>
              <Box
                as="footer"
                gap="small"
                direction="row"
                align="center"
                justify="end"
                pad={{ top: 'medium', bottom: 'small' }}
              >
                <Button
                  label="Cancel"
                  onClick={dismissModifyAccountModal}
                  color="dark-3"
                />
                <Button
                  label="Confirm"
                  onClick={toggleReadOnlyMode}
                  primary
                  color="status-critical"
                />
              </Box>
            </Modal>
          )}

            {enableLoginModal.visible && (
                <Modal
                    open={enableLoginModal.visible}
                    heading={'Confirm'}
                    onClose={dismissEnableLoginModal}
                >
                    <Text>{enableLoginModal.text}</Text>
                    <Box
                        as="footer"
                        gap="small"
                        direction="row"
                        align="center"
                        justify="end"
                        pad={{ top: 'medium', bottom: 'small' }}
                    >
                        <Button
                            label="Cancel"
                            onClick={dismissEnableLoginModal}
                            color="dark-3"
                        />
                        <Button
                            label="Confirm"
                            onClick={toggleLockedOutMode}
                            primary
                            color="status-critical"
                        />
                    </Box>
                </Modal>
            )}

          {openOrderModal.visible && (
            <Modal
              open={openOrderModal.visible}
              heading={'Open order'}
              onClose={dismissOpenOrderModal}
            >
              {/* Add Form */}
              <Box margin={{ top: 'small' }}>
                <Form onSubmit={handleOpenOrderSubmit}>
                  <Box
                    direction="row-responsive"
                    flex={false}
                    margin={{ bottom: 'medium' }}
                  >
                    <Text
                      color="brand"
                      margin={{ vertical: 'small', right: 'small' }}
                      as="label"
                      htmlFor="symbol"
                      style={{ display: 'block', minWidth: '100px' }}
                    >
                      Symbol:
                    </Text>
                    <Select
                      name="symbol"
                      options={symbols}
                      style={{
                        border: '1px solid transparent',
                        borderColor: orderErrors.symbol ? 'red' : 'transparent'
                      }}
                      placeholder="Select symbol"
                      value={orderInputs.symbol}
                      disabled={loading}
                      onChange={({ option }) => {
                        handleOrderInputChange({
                          target: { name: 'symbol', value: option }
                        });
                      }}
                    />
                  </Box>
                  {orderErrors.symbol && (
                    <Text color="status-critical">{orderErrors.symbol}</Text>
                  )}

                  <Box
                    direction="row-responsive"
                    flex={false}
                    margin={{ bottom: 'medium' }}
                  >
                    <Text
                      color="brand"
                      margin={{ vertical: 'small', right: 'small' }}
                      as="label"
                      htmlFor="symbol"
                      style={{ display: 'block', minWidth: '100px' }}
                    >
                      Direction:
                    </Text>
                    <Select
                      name="cmd"
                      options={[
                        { label: 'Buy', value: 0 },
                        { label: 'Sell', value: 1 }
                      ]}
                      style={{ borderColor: orderErrors.cmd ? 'red' : '' }}
                      placeholder="Select direction"
                      labelKey="label"
                      valueLabel={
                        <Text style={{ padding: '8px' }}>
                          {orderInputs.cmd === 0 ? 'Buy' : 'Sell'}
                        </Text>
                      }
                      valueKey="value"
                      disabled={loading}
                      onChange={({ option }) => {
                        handleOrderInputChange({
                          target: { name: 'cmd', value: option.value }
                        });
                      }}
                    />
                  </Box>
                  {orderErrors.cmd && (
                    <Text color="status-critical">{orderErrors.cmd}</Text>
                  )}

                  <Box
                    direction="row-responsive"
                    flex={false}
                    margin={{ bottom: 'medium' }}
                  >
                    <Text
                      color="brand"
                      margin={{ vertical: 'small', right: 'small' }}
                      as="label"
                      htmlFor="volume"
                      style={{ display: 'block', minWidth: '100px' }}
                    >
                      Volume (lots):
                    </Text>
                    <TextInput
                      name="volume"
                      type="number"
                      step="0.1"
                      min="0"
                      style={{ borderColor: orderErrors.volume ? 'red' : '' }}
                      placeholder="Enter volume"
                      value={orderInputs.volume}
                      disabled={loading}
                      onChange={handleOrderInputChange}
                    />
                  </Box>
                  {orderErrors.volume && (
                    <Text color="status-critical">{orderErrors.volume}</Text>
                  )}

                  <Box
                      direction="row-responsive"
                      flex={false}
                      margin={{ bottom: 'medium' }}
                  >
                    <Text
                        color="brand"
                        margin={{ vertical: 'small', right: 'small' }}
                        as="label"
                        htmlFor="comment"
                        style={{ display: 'block', minWidth: '100px' }}
                    >
                      Comment:
                    </Text>
                    <TextInput
                        name="comment"
                        type="text"
                        style={{ borderColor: orderErrors.comment ? 'red' : '' }}
                        placeholder="Enter comment (optional)"
                        value={orderInputs.comment}
                        disabled={loading}
                        onChange={handleOrderInputChange}
                    />
                  </Box>
                  {orderErrors.comment && (
                      <Text color="status-critical">{orderErrors.comment}</Text>
                  )}

                  <Box
                    as="footer"
                    gap="small"
                    direction="row"
                    align="center"
                    justify="end"
                    pad={{ top: 'medium', bottom: 'small' }}
                  >
                    <Button
                      label="Cancel"
                      disabled={loading}
                      onClick={dismissOpenOrderModal}
                      color="dark-3"
                    />
                    <Button
                      primary
                      disabled={loading}
                      color="status-ok"
                      label="Submit"
                      type="submit"
                    />
                  </Box>
                </Form>
              </Box>
            </Modal>
          )}

          {balanceCreditModal.visible && (
            <Modal
              open={balanceCreditModal.visible}
              heading={`Change ${
                balanceCreditModal.text === 'balance' ? 'balance' : 'credit'
              }`}
              onClose={dismissBalanceCreditModal}
            >
              {/* Add Form */}
              <Box margin={{ top: 'small' }}>
                <Form onSubmit={handleBalanceCreditSubmit}>
                  <Box
                    direction="row-responsive"
                    flex={false}
                    margin={{ bottom: 'medium' }}
                  >
                    <Text
                      color="brand"
                      margin={{ vertical: 'small', right: 'small' }}
                      as="label"
                      htmlFor="amount"
                      style={{ display: 'block', minWidth: '100px' }}
                    >
                      Amount:
                    </Text>
                    <TextInput
                      name="amount"
                      type="number"
                      style={{ borderColor: inputErrors.amount ? 'red' : '' }}
                      placeholder="Enter amount"
                      value={inputs.amount}
                      disabled={loading}
                      onChange={handleInputChange}
                    />
                  </Box>
                  {inputErrors.amount && (
                    <Text color="status-critical">{inputErrors.amount}</Text>
                  )}

                  <Box
                    direction="row-responsive"
                    flex={false}
                    margin={{ bottom: 'medium' }}
                  >
                    <Text
                      color="brand"
                      margin={{ vertical: 'small', right: 'small' }}
                      as="label"
                      htmlFor="comment"
                      style={{ display: 'block', minWidth: '100px' }}
                    >
                      Comment:
                    </Text>
                    <TextInput
                      name="comment"
                      type="text"
                      suggestions={
                        balanceCreditModal.text === 'balance'
                          ? CHANGE_BALANCE_COMMENT_SUGGESTIONS
                          : CHANGE_CREDIT_COMMENT_SUGGESTIONS
                      }
                      onSelect={({ suggestion }) =>
                        handleInputChange({
                          target: {
                            name: 'comment',
                            value: suggestion
                          }
                        })
                      }
                      style={{ borderColor: inputErrors.comment ? 'red' : '' }}
                      placeholder="Enter comment"
                      value={inputs.comment}
                      disabled={loading}
                      onChange={handleInputChange}
                    />
                  </Box>
                  {inputErrors.comment && (
                    <Text color="status-critical">{inputErrors.comment}</Text>
                  )}

                  <Box
                    as="footer"
                    gap="small"
                    direction="row"
                    align="center"
                    justify="end"
                    pad={{ top: 'medium', bottom: 'small' }}
                  >
                    <Button
                      label="Cancel"
                      disabled={loading}
                      onClick={dismissBalanceCreditModal}
                      color="dark-3"
                    />
                    <Button
                      primary
                      disabled={loading}
                      color="status-ok"
                      label="Submit"
                      type="submit"
                    />
                  </Box>
                </Form>
              </Box>
            </Modal>
          )}

          <Box justify="between" height="full">
            <Box>
              <Stack anchor="top-left" margin={{ bottom: 'large' }}>
                <Box
                  border={{ color: 'light-4' }}
                  pad="medium"
                  direction="row-responsive"
                  wrap={true}
                  align="center"
                >
                  <LinkTile
                    href={getEndpoint('profile').replace(':id', userId)}
                    title="Profile"
                    label="Trader's profile"
                    icon={<User />}
                    width={size === 'small' ? 'full' : 'small'}
                  />

                  <LinkTile
                    href={getEndpoint('inbox').replace(':id', userId)}
                    title="Trader's inbox"
                    label="Inbox"
                    icon={<Inbox />}
                    width={size === 'small' ? 'full' : 'small'}
                  />

                  <LinkTile
                    href={getEndpoint('account').replace(':id', userId)}
                    title="Trading account"
                    label="Trading account"
                    icon={<ContactInfo />}
                    width={size === 'small' ? 'full' : 'small'}
                  />

                  <LinkTile
                    href={getEndpoint('history').replace(':id', userId)}
                    title="Account history"
                    label="Account history"
                    icon={<Schedules />}
                    width={size === 'small' ? 'full' : 'small'}
                  />
                </Box>

                <Box
                  background="body"
                  margin={{ left: 'small', top: '-10px' }}
                  pad={{ horizontal: 'small' }}
                >
                  <Text weight="bold" color="border">
                    Trader management
                  </Text>
                </Box>
              </Stack>

              <Stack anchor="top-left" margin={{ bottom: 'large' }}>
                <Box
                  border={{ color: 'light-4' }}
                  pad="medium"
                  direction="row-responsive"
                  wrap={true}
                  align="center"
                >
                  <LinkTile
                    href="#"
                    title="Change balance"
                    label="Change balance"
                    icon={<CreditCard />}
                    width={size === 'small' ? 'full' : 'small'}
                    onClick={() => showBalanceCreditModal('balance')}
                  />

                  <LinkTile
                    href="#"
                    title="Change credit"
                    label="Change  credit"
                    icon={<Atm />}
                    width={size === 'small' ? 'full' : 'small'}
                    onClick={() => showBalanceCreditModal('credit')}
                  />

                  <LinkTile
                    href="#"
                    title="Toggle read-only mode"
                    linkProps={{ className: loading ? 'disabled' : '' }}
                    label={
                      <Text color={enable_read_only ? 'white' : undefined}>
                        {enable_read_only ? 'Disable' : 'Enable'} readonly
                      </Text>
                    }
                    icon={
                      <View color={enable_read_only ? 'white' : undefined} />
                    }
                    background={
                      enable_read_only ? 'status-critical' : 'light-3'
                    }
                    width={size === 'small' ? 'full' : '150px'}
                    onClick={() => {
                      if (!loading) {
                        showModifyAccountModal(
                          `Are you sure to ${
                            enable_read_only ? 'disable' : 'enable'
                          } read only mode?`
                        );
                      }
                    }}
                  />

                  <LinkTile
                      href="#"
                      title="Enable/Disable login"
                      linkProps={{ className: loading ? 'disabled' : '' }}
                      label={
                        <Text color={locked_out ? 'white' : undefined}>
                          {locked_out ? 'Enable' : 'Disable'} login
                        </Text>
                      }
                      icon={
                        <View color={locked_out ? 'white' : undefined} />
                      }
                      background={
                          locked_out ? 'status-critical' : 'light-3'
                      }
                      width={size === 'small' ? 'full' : '150px'}
                      onClick={() => {
                        if (!loading) {
                          showEnableLoginModal(
                              `Are you sure to ${
                                  locked_out ? 'enable' : 'disable'
                              } login?`
                          );
                        }
                      }}
                  />

                  <LinkTile
                    href="#"
                    title="Open order"
                    label="Open order"
                    icon={<Pin />}
                    width={size === 'small' ? 'full' : 'small'}
                    onClick={() => showOpenOrderModal()}
                  />
                </Box>

                <Box
                  background="body"
                  margin={{ left: 'small', top: '-10px' }}
                  pad={{ horizontal: 'small' }}
                >
                  <Text weight="bold" color="border">
                    Account management
                  </Text>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Layout>
      )}
    </ResponsiveContext.Consumer>
  );
};

ViewTrader.propTypes = {
  loading: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  notification: PropTypes.object,
  updateItem: PropTypes.func.isRequired,
  openOrder: PropTypes.func.isRequired,
  trader: PropTypes.object
};

ViewTrader.defaultProps = {
  loading: false,
  trader: {},
  notification: {
    text: '',
    type: 'info'
  }
};

export default ViewTrader;
