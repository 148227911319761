import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { routes } from '../../config/routes';
import {
  BackLink,
  Layout,
  Loader,
  Modal,
  Notification
} from '../../components';
import { Anchor, Box, Button, Form, Heading, Text, TextInput } from 'grommet';
import { StatusInfo, Trash, UserAdd, View } from 'grommet-icons';
import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT } from '../../config/site';
import { fetchUsersSuggestions } from '../../utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FLASH_TYPES, useFlash } from '../../hooks';
import { SAVE_OPTION } from '../../redux/app';
import {
  CellActionsFormatter,
  getFilterRenderer,
  ToolbaredTable
} from '../../components/DataTable';

export const PARTNER_STATUS = {
  pending: 'PENDING',
  active: 'ACTIVE',
  disabled: 'DISABLED'
};

function getEndpoint(key) {
  const endpoints = {
    addPartner: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Partners')
      .childs.find(route => route.name === 'Add').path,
    editPartner: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Partners')
      .childs.find(route => route.name === 'View').path,
    viewTrader: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'View').path,
    broker: routes.find(route => route.name === 'Broker').path
  };
  return endpoints[key];
}

const Partners = props => {
  // Load hidden column indexes from the storage (global state)
  const hiddenColumns =
    useSelector(
      state => state.app.storage.options.partnersTableCols,
      shallowEqual
    ) || props.hiddenColumns;
  const dispatch = useDispatch();
  const timeout = useRef(null);
  const [state, setState] = useState({
    chosenItem: null,
    formErrors: props.formErrors,
    suggestions: [{ value: '-', label: 'Start typing MT Id' }],
    userToAssign: null
  });
  const [inputs, setInputs] = useState({
    user_id: undefined
  });
  const [flash, showFlash, dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );
  const [modal, showModal, dismissModal] = useFlash();
  const [
    assignClientModal,
    showAssignClientModal,
    dismissAssignClientModal
  ] = useFlash();

  const hideColumns = nextState => {
    dispatch({ type: SAVE_OPTION, key: 'partnersTableCols', value: nextState });
  };

  const columns = useMemo(() => {
    return [
      {
        key: 'name',
        name: 'Name',
        width: 250,
        filterRenderer: getFilterRenderer('text'),
        formatter({ row }) {
          let actions = [
            {
              icon: <UserAdd size="small" color="brand" />,
              text: 'Add client',
              callback: () => {
                showAssignClientModal();
                return setState({
                  ...state,
                  chosenItem: row
                });
              }
            },
            {
              icon: <Trash size="small" color="status-critical" />,
              text: 'Delete',
              callback: () => {
                showModal();
                return setState({
                  ...state,
                  chosenItem: row
                });
              }
            },
            {
              icon: <StatusInfo size="small" color="status-warning" />,
              text: `Set ${
                row.status !== PARTNER_STATUS.active
                  ? PARTNER_STATUS.active
                  : PARTNER_STATUS.disabled
              }`,
              title: `Set ${
                row.status !== PARTNER_STATUS.active
                  ? PARTNER_STATUS.active
                  : PARTNER_STATUS.disabled
              } status`,
              callback: () =>
                updatePartnerStatus(
                  row.id,
                  row.status !== PARTNER_STATUS.active
                    ? PARTNER_STATUS.active
                    : PARTNER_STATUS.disabled
                )
            }
          ];

          if (row.status === PARTNER_STATUS.active) {
            actions = [
              {
                icon: <View size="small" color="status-ok" />,
                text: 'View',
                callback: () =>
                  props.history.push(
                    getEndpoint('editPartner').replace(':id', row.userId)
                  )
              }
            ].concat(actions);
          }

          return (
            <>
              <CellActionsFormatter actions={actions} />
              <Anchor
                title="Show client"
                target="_blank"
                href={getEndpoint('viewTrader').replace(':id', row.userId)}
                color="brand"
                label={<Text weight="bold">{row.name}</Text>}
              />
            </>
          );
        }
      },
      {
        key: 'website',
        name: 'Web'
      },
      {
        key: 'info',
        name: 'Info'
      },
      {
        key: 'link',
        name: 'Link'
      },
      {
        key: 'provision',
        name: 'Provision %',
        editable: true,
        filterRenderer: getFilterRenderer('numeric', {})
      },
      {
        key: 'status',
        name: 'Status',
        filterRenderer: getFilterRenderer('checklist', {
          rows: [
            { label: PARTNER_STATUS.active, value: 'active' },
            { label: PARTNER_STATUS.disabled, value: 'disabled' },
            { label: PARTNER_STATUS.pending, value: 'pending' }
          ]
        })
      },
      {
        key: 'created',
        name: 'Date',
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        filterRenderer: getFilterRenderer('date', {
          datePicker: true,
          placeholder: 'Select Date/Range'
        }),
        formatter: ({ row }) =>
          row.created
            ? moment(row.created).format(DEFAULT_DATETIME_FORMAT)
            : null
      }
    ].map(col => {
      return {
        ...col,
        sortable: true,
        resizable: true
      };
    });
  }, []);

  useEffect(() => {
    if (inputs.user_id && inputs.user_id.length > 5) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      setState({
        ...state,
        suggestions: [{ value: '-', label: 'Loading...' }]
      });
      timeout.current = setTimeout(async () => {
        const suggestions = await fetchUsersSuggestions(inputs.user_id);
        setState({
          ...state,
          suggestions:
            suggestions.length > 0
              ? suggestions.map(user => ({
                  value: user.id,
                  label: user.display_name
                }))
              : [
                  {
                    value: '-',
                    label: Array.isArray(suggestions)
                      ? 'No results'
                      : suggestions
                  }
                ]
        });
      }, 1500);
    } else if (inputs.user_id && inputs.user_id.length === 0) {
      clearTimeout(timeout.current);
      setState({
        ...state,
        suggestions: [{ value: '-', label: 'Start typing MT Id' }]
      });
    } else {
      clearTimeout(timeout.current);
      setState({
        ...state,
        suggestions: [{ value: '-', label: 'Enter at least 6 digits' }]
      });
    }
  }, [inputs.user_id]);

  const handleInputUpdate = event => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const updatePartnerStatus = async (id, status) => {
    await props
      .updateItem({
        id: id,
        status: status
      })
      .then(
        () => {
          showFlash(`Partner has now status ${status}`, FLASH_TYPES.OK);
          return true;
        },
        error => {
          showFlash("Error changing partner's status", FLASH_TYPES.CRITICAL);
          return false;
        }
      );
  };

  const updatePartnerProvision = async (id, amount) => {
    await props
      .updateItem({
        id: id,
        provision: amount
      })
      .then(
        () => {
          showFlash(`Partner provision was updated`, FLASH_TYPES.OK);
          return true;
        },
        error => {
          showFlash("Error changing partner's provision", FLASH_TYPES.CRITICAL);
          return false;
        }
      );
  };

  const onUserSelect = ({ suggestion: { label, value } }) => {
    if (value !== '-') {
      setInputs({ ...inputs, user_id: value });
      setState({ ...state, userToAssign: label.props.children });
    }
  };

  const getSuggestionNode = label => {
    return (
      <Box width="full" pad="small">
        {label}
      </Box>
    );
  };

  const removePartner = async item => {
    if (!item || !item.id) {
      return;
    }

    await props.removePartner({ id: item.id }).then(
      () => {
        setState({ ...state, chosenItem: null });
        showFlash(`Partner ${item.id} removed`, FLASH_TYPES.OK);
        return true;
      },
      error => {
        setState({ ...state, chosenItem: null });
        showFlash('Error removing partner', FLASH_TYPES.CRITICAL);
        return false;
      }
    );
  };

  const handleAssignClientSubmit = async event => {
    const fields = ['user_id'],
      requiredFields = fields,
      errors = {},
      data = {};
    let valid;
    valid = true;

    // Validation
    for (let i = 0; i < event.target.length; i++) {
      let { value, name } = event.target[i];

      if (fields.includes(name)) {
        data[name] = value;
      }

      if (requiredFields.includes(name) && value.length === 0) {
        errors[name] = 'Please fill in this field.';
        valid = false;
      }
    }

    if (!valid) {
      return setState({ ...state, formErrors: errors });
    }

    new Promise(async (resolve, reject) => {
      try {
        await props.assignClient({
          user_id: data.user_id,
          partner_id: state.chosenItem.userId
        });
        resolve(`Client was assigned.`);
      } catch (e) {
        reject('Error assigning client. Client is assigned to another partner?');
      }
    })
      .then(response => {
        showFlash(response, FLASH_TYPES.OK);
        setState({ ...state, userToAssign: null, formErrors: {}, user_id: '' });
        dismissAssignClientModal();
      })
      .catch(error => {
        dismissAssignClientModal();
        showFlash(error.message, FLASH_TYPES.CRITICAL);
      });
  };

  // Manual edit of cells
  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const _rows = props.partners.slice();
    for (let i = fromRow; i <= toRow; i++) {
      if (updated.provision) {
        updatePartnerProvision(_rows[i].id, updated.provision);
      }
      _rows[i] = { ..._rows[i], ...updated };
    }
    return _rows;
  };

  const { chosenItem, userToAssign, suggestions, formErrors } = state;
  const { user_id } = inputs;
  const { loading, partners } = props;

  return (
    <Layout title="Partners" fluid>
      <BackLink link={getEndpoint('broker')} />

      <Box direction="row" justify="between" margin={{ vertical: 'medium' }}>
        <Box direction="row" align="center">
          <Heading margin={{ right: 'small', vertical: 'none' }} size="small">
            My Partners
          </Heading>

          <Box direction="row" align="center">
            {loading && <Loader />}
          </Box>
        </Box>
      </Box>

      {flash.visible && (
        <Notification
          type={flash.type ? flash.type : 'info'}
          onClose={dismissFlash}
          open={flash.visible}
          message={flash.text}
        />
      )}

      {/* Assign client modal */}
      {assignClientModal.visible && (
        <Modal
          open={assignClientModal.visible}
          heading={'Confirm'}
          onClose={dismissAssignClientModal}
        >
          <Text margin={{ bottom: 'medium' }}>
            Find and assign a client to a partner:{' '}
            {chosenItem &&
              `${chosenItem.user.first_name} ${chosenItem.user.last_name}`}
          </Text>
          <Box direction="row" align="center" pad="medium">
            <Form onSubmit={handleAssignClientSubmit} style={{ width: '100%' }}>
              <Box
                direction="row-responsive"
                flex={false}
                margin={{ bottom: 'medium' }}
              >
                <Text
                  color="brand"
                  margin={{ vertical: 'small', right: 'small' }}
                  as="label"
                  htmlFor="user_id"
                  style={{ display: 'block', minWidth: '100px' }}
                >
                  Select user:
                </Text>
                <TextInput
                  id="user_id"
                  name="user_id"
                  disabled={loading}
                  value={user_id}
                  placeholder="Select user"
                  style={{ borderColor: formErrors.user_id ? 'red' : '' }}
                  dropProps={{ height: 'small' }}
                  onChange={handleInputUpdate}
                  onSelect={onUserSelect}
                  suggestions={suggestions.map(suggestion => ({
                    value: suggestion.value,
                    label: getSuggestionNode(suggestion.label)
                  }))}
                />
              </Box>
              {formErrors.user_id && (
                <Text color="status-critical">{formErrors.user_id}</Text>
              )}
              {userToAssign && userToAssign}

              <Box
                as="footer"
                gap="small"
                direction="row"
                align="center"
                justify="end"
                pad={{ top: 'medium', bottom: 'small' }}
              >
                <Button
                  label="Cancel"
                  onClick={dismissAssignClientModal}
                  color="dark-3"
                />
                <Button
                  primary
                  disabled={loading}
                  color="status-ok"
                  label="Assign"
                  type="submit"
                />
              </Box>
            </Form>
          </Box>
        </Modal>
      )}

      {modal.visible && (
        <Modal open={modal.visible} heading={'Confirm'} onClose={dismissModal}>
          <Text>
            Are you sure you want to delete a partner{' '}
            {chosenItem && chosenItem.email}?
          </Text>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            <Button
              label="Cancel"
              onClick={dismissModal}
              color="dark-3"
              disabled={loading}
            />
            <Button
              label={
                <Text color="white">
                  <strong>Delete</strong>
                </Text>
              }
              disabled={loading}
              onClick={() => removePartner(chosenItem)}
              primary
              color="status-critical"
            />
          </Box>
        </Modal>
      )}

      <div style={{ width: `100%`, height: '100vh', position: 'relative' }}>
        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
          <ToolbaredTable
            toggleableColumns={columns}
            columns={columns}
            rows={partners}
            loading={loading}
            hiddenColumns={hiddenColumns}
            onHideColumn={hideColumns}
            onRowsUpdate={onGridRowsUpdated}
          />
        </div>
      </div>
    </Layout>
  );
};

Partners.propTypes = {
  partners: PropTypes.array,
  hiddenColumns: PropTypes.array,
  removePartner: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  assignClient: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  formErrors: PropTypes.object,
  notification: PropTypes.object
};

Partners.defaultProps = {
  partners: [],
  formErrors: {},
  hiddenColumns: [],
  loading: false,
  notification: {
    text: '',
    type: 'info'
  }
};

export default Partners;
