import * as R from 'ramda';
import StorageUtils from '../utils/StorageUtils';
import Auth from '../services/auth';

// Back compat, used in case some breaking changes was made to local storage and we need to invalidate clients cache
export const CURRENT_STORAGE_VERSION = 1;

export const STORAGE_KEY = 'tr_storage';

// Action types
export const SAVE_OPTION = 'app/SAVE_OPTION';
export const LOAD_STORAGE_DATA = 'app/LOAD_STORAGE_DATA';
export const RESET_STORAGE_DATA = 'app/RESET_STORAGE_DATA';
export const STORE_AUTH_DATA = 'app/STORE_AUTH_DATA';
export const AUTH_IN_PROCESS = 'app/AUTH_IN_PROCESS';

const defaultState = {
  auth: {
    authToken: Auth.getAuthToken(),
    currentUser: Auth.getUser(),
    csrfToken: null,
    authInProcess: true
  },
  // Persistent state
  storage: {
    options: {
      // Object where options such as modals which user marked to not show again or persistent toggles or visible table columns
      tradersTableCols: [],
      partnersTableCols: [],
      viewPartnerClientTableCols: [],
      viewPartnerDepositTableCols: [],
      myProfileTableCols: [],
      brokerWithdrawalsTableCols: [],
      accountHistoryCols: [],
      withdrawalsTableCols: [],
      openedTradesStatisticsCols: [],
      depositsTableCols: [],
      tradingAccountsCols: [],
      marginCallsTableCols: [],
      viewAccountTableCols: [4, 5, 6, 7]
    }
  }
};

/**
 * This reducer manages state related to current application instance, e.x storage data (opened order or alarm tiles),
 * permanent options (table columns visibility, popup dismiss,...), currently navigated screen
 * @return {Function}
 */
const makeReducer = initialState => (
  state = R.mergeDeepRight(defaultState, initialState),
  action
) => {
  switch (action.type) {
    case AUTH_IN_PROCESS: {
      const { loading } = action;
      return {
        ...state,
        auth: {
          ...state.auth,
          authInProcess: loading
        }
      };
    }

    case STORE_AUTH_DATA: {
      const { currentUser, authToken, csrfToken } = action;
      const newState = {
        ...state
      };

      if (currentUser) {
        Auth.setUser(currentUser);
        newState.auth.currentUser = currentUser;
      }

      if (authToken) {
        Auth.setAuthToken(authToken);
        newState.auth.authToken = authToken;
      }

      if (csrfToken) {
        newState.auth.csrfToken = csrfToken;
      }

      return newState;
    }

    case SAVE_OPTION: {
      const { key, value } = action;

      const newOptionsState = {
        ...state.storage.options,
        [key]: value
      };

      StorageUtils.updateStorageData(
        `${STORAGE_KEY}`,
        'options',
        newOptionsState
      );

      return {
        ...state,
        storage: { ...state.storage, options: newOptionsState }
      };
    }

    case LOAD_STORAGE_DATA: {
      let storedData = {};

      // Try to load account related storage data
      storedData = StorageUtils.getStorageData(`${STORAGE_KEY}`);

      return { ...state, storage: { ...state.storage, ...storedData } };
    }

    case RESET_STORAGE_DATA: {
      const defaults = R.mergeDeepRight(defaultState, initialState);
      const newState = {
        ...defaults,
        ...state,
        storage: { ...defaults.storage, version: CURRENT_STORAGE_VERSION }
      };

      StorageUtils.setStorageData(`${STORAGE_KEY}`, newState.storage);

      return newState;
    }

    default:
      return state;
  }
};

export default makeReducer;
