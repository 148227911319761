import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import { Box, Grid } from 'grommet';
import { Footer, Header } from '../';
import { getCategories } from '../../config/routes';
import Auth from '../../services/auth';
import { Helmet } from 'react-helmet';

export const Layout = ({ children, title, maxWidth, fluid }) => {
  return (
    <Grid
      fill
      style={{ flex: '1 1 0' }}
      areas={[
        { name: 'nav', start: [0, 0], end: [0, 0] },
        { name: 'main', start: [0, 1], end: [0, 1] },
        { name: 'footer', start: [0, 2], end: [0, 2] }
      ]}
      columns={['flex']}
      rows={['50px', 'flex', 'auto']}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${title ? title + ' | ' : ''}${config.read([
          'meta',
          'title'
        ])}`}</title>
        <link rel="canonical" href={config.read(['meta', 'canonicalUrl'])} />
      </Helmet>

      <Box
        as="header"
        background="header"
        justify="center"
        width="full"
        gridArea="nav"
        pad={{ horizontal: 'medium' }}
        border={{ side: 'vertical', color: 'dark-1' }}
        height="50px"
      >
        <Box width="full" flex={false} margin={{ horizontal: 'auto' }}>
          <Header
            user={Auth.getUser()}
            categories={getCategories()}
            logout={() => {
              fetch(process.env.REACT_APP_AUTH_LOGOUT_ENDPOINT, {
                method: 'POST',
                credentials: 'include'
              })
                .then(() => {
                  Auth.logout();
                })
                .catch(error => error);
            }}
          />
        </Box>
      </Box>

      <Box
        as="main"
        background="body"
        gridArea="main"
        height={{ min: 'auto', max: 'full' }}
      >
        <Box
          pad={{ vertical: 'medium', horizontal: 'medium', bottom: 'xlarge' }}
          margin={{ horizontal: 'auto' }}
          width="full"
          height="full"
          style={{ maxWidth: fluid ? 'none' : maxWidth }}
          flex={false}
        >
          {children}
        </Box>
      </Box>

      <Box
        as="footer"
        gridArea="footer"
        flex={false}
        height={{ min: 'auto' }}
        background="light-4"
      >
        <Footer />
      </Box>
    </Grid>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  fluid: PropTypes.bool
};

Layout.defaultProps = {
  maxWidth: config.read(['theme', 'sizes', 'containerMax']),
  fluid: false
};
