import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { default as OpenedTrades } from './OpenedTrades';
import { default as MarginCall } from './MarginCall';

const FETCH_OPENED_TRADES = gql`
  {
    statistics {
      opened_trades {
        nodes {
          order
          login
          user {
            userId
            name
            enable_read_only
          }
          cmd
          symbol
          volume
          open_time
          open_price
          sl
          tp
          profit
          commission
          comment
        }
      }
    }
  }
`;

const FETCH_MARGIN_CALLS = gql`
{
  statistics {
    margin_calls {
      nodes {
        margin
        login
        balance
        user {
          userId
          name
          enable_read_only
          referer {
            id
            userId
            name
          }
        }
      }
    }
  }
}
`;

export const OpenedTradesConnected = props => {
  return (
    <Query query={FETCH_OPENED_TRADES}>
      {({ loading, error, data }) => {
        const _data =
          data && data.statistics && data.statistics.opened_trades
            ? data.statistics.opened_trades.nodes
            : [];

        return (
          <OpenedTrades
            notification={
              error && {
                text: error && error.message,
                type: 'critical'
              }
            }
            loading={loading}
            trades={_data}
          />
        );
      }}
    </Query>
  );
};

export const MarginCallConnected = props => {
  return (
    <Query query={FETCH_MARGIN_CALLS}>
      {({ loading, error, data }) => {
        const _data =
            data
            && data.statistics
            && data.statistics.margin_calls
                ? data.statistics.margin_calls.nodes.map((node) => ({
                    ...node,
                    referredBy: node.user
                        && node.user.referer ? node.user.referer.name : ''
                }))
                : [];

        return (
          <MarginCall
            notification={
              error && {
                text: error && error.message,
                type: 'critical'
              }
            }
            loading={loading}
            rows={_data}
          />
        );
      }}
    </Query>
  );
};
