import React from 'react';
import PropTypes from 'prop-types';
import {
  Anchor,
  Box,
  Button,
  Heading,
  ResponsiveContext,
  Text,
  Form,
  TextInput
} from 'grommet/es6';
import Auth from '../../services/auth';
import { Redirect } from 'react-router-dom';
import { MinimalLayout } from '../../components/Layout';
import { Notification } from '../../components/Notification';
import { routes } from '../../config/routes';
import { Translate } from 'react-localize-redux';
import { FormPrevious } from 'grommet-icons';
import { CompanyLogo } from '../../components';

export class LostPassword extends React.Component {
  static propTypes = {
    resetPassword: PropTypes.func.isRequired,
    redirect: PropTypes.string,
    loading: PropTypes.bool,
    notification: PropTypes.object
  };

  static defaultProps = {
    loading: false,
    notification: {}
  };

  constructor(props) {
    super(props);

    this.loginScreenEndpoint = routes.find(
      route => route.name === 'Login'
    ).path;
    this.handleResetSubmit = this.handleResetSubmit.bind(this);
    this.dismissNotifications = this.dismissNotifications.bind(this);

    this.state = {
      notification: props.notification,
      formErrors: {},
      password: '',
      password_repeat: ''
    };
  }

  dismissNotifications() {
    this.setState({
      notification: {}
    });
  }

  async handleResetSubmit(event) {
    const fields = ['password', 'password_repeat'],
      requiredFields = fields,
      errors = {},
      data = {};
    let valid = true;

    // Validation
    for (let i = 0; i < event.target.length; i++) {
      let { value, name } = event.target[i];

      if (fields.includes(name)) {
        data[name] = value;
      }

      if (requiredFields.includes(name) && value.length === 0) {
        errors[name] = 'requiredField';
        valid = false;
      }
    }

    if (data.password !== data.password_repeat) {
      errors['password'] = 'passwordsDoesntMatch';
      errors['password_repeat'] = 'passwordsDoesntMatch';
      valid = false;
    }

    if (!valid) {
      this.setState({ formErrors: errors });
      return false;
    }

    delete data.password_repeat;

    try {
      await this.props.resetPassword(data);
      this.setState({
        formErrors: {},
        notification: {
          text: `Password was reset. You will be redirected.`,
          type: 'ok'
        }
      });
      setTimeout(() => {
        window.location.href = this.props.redirect
          ? this.props.redirect
          : this.loginScreenEndpoint;
      }, 2000);
    } catch (e) {
      this.setState({
        formErrors: {},
        notification: { text: e.message, type: 'critical' }
      });
    }
    return true; // Request has been processed
  }

  render() {
    const { notification, formErrors, password, password_repeat } = this.state,
      { loading } = this.props;

    const isLoggedIn = !!Auth.getAuthToken();

    return isLoggedIn ? (
      <Redirect to={this.loginScreenEndpoint} />
    ) : (
      <Translate>
        {({ translate }) => (
          <MinimalLayout title={translate('login.passwordRenew')}>
            <ResponsiveContext.Consumer>
              {size => (
                <Box height="full" justify="center" align="center">
                  {!!notification.text && (
                    <Notification
                      type={`${notification.type ? notification.type : 'info'}`}
                      onClose={this.dismissNotifications}
                      open={!!notification.text}
                      message={notification.text}
                    />
                  )}

                  <Box
                    width={
                      ['medium', 'large'].includes(size) ? '400px' : 'full'
                    }
                    pad="large"
                    elevation="small"
                    justify="center"
                    align="center"
                  >
                    <Box
                      justify="center"
                      margin={{ bottom: 'large' }}
                      width="full"
                      direction="row"
                      align="center"
                      alignSelf="start"
                    >
                      <CompanyLogo
                        width="200px"
                        href={this.loginScreenEndpoint}
                        fit="contain"
                      />
                    </Box>

                    <Box
                      style={{ maxWidth: '300px', minHeight: '350px' }}
                      width="large"
                      align="center"
                    >
                      <Box
                        gap="small"
                        width="full"
                        margin={{ bottom: 'medium' }}
                      >
                        <Heading
                          margin="none"
                          level="2"
                          textAlign="center"
                          color="brand"
                        >
                          <Translate id="login.passwordRenew">
                            Renew password
                          </Translate>
                        </Heading>
                        <Heading
                          margin="none"
                          size="small"
                          textAlign="center"
                          level="3"
                        >
                          <Translate id="login.chooseNewPassword">
                            Please choose new password
                          </Translate>
                        </Heading>
                      </Box>

                      <Box width="full">
                        <Form
                          name="forgotPass"
                          onSubmit={this.handleResetSubmit}
                        >
                          <Box
                            gap="large"
                            align="center"
                            justify="center"
                            pad="medium"
                          >
                            <Box gap="small" width="full">
                              <Text weight="bold">
                                <Translate id="login.password">
                                  Password
                                </Translate>
                              </Text>
                              <TextInput
                                type="password"
                                name="password"
                                placeholder={translate(
                                  'registration.placeholders.password'
                                )}
                                value={password}
                                style={
                                  formErrors.password
                                    ? { borderColor: 'red' }
                                    : {}
                                }
                                disabled={loading}
                                onChange={event =>
                                  this.setState({
                                    password: event.target.value
                                  })
                                }
                              />
                              {formErrors.password && (
                                <Text color="status-critical">
                                  <Translate
                                    id={`errors.${formErrors.password}`}
                                  />
                                </Text>
                              )}
                            </Box>

                            <Box gap="small" width="full">
                              <Text weight="bold">
                                <Translate id="registration.labels.passwordRepeat">
                                  Confirm password
                                </Translate>
                              </Text>
                              <TextInput
                                type="password"
                                name="password_repeat"
                                placeholder={translate(
                                  'registration.placeholders.passwordRepeat'
                                )}
                                value={password_repeat}
                                style={
                                  formErrors.password_repeat
                                    ? { borderColor: 'red' }
                                    : {}
                                }
                                disabled={loading}
                                onChange={event =>
                                  this.setState({
                                    password_repeat: event.target.value
                                  })
                                }
                              />
                              {formErrors.password_repeat && (
                                <Text color="status-critical">
                                  <Translate
                                    id={`errors.${formErrors.password_repeat}`}
                                  />
                                </Text>
                              )}
                            </Box>

                            <Box width="full">
                              <Button
                                onClick={this.handlePasswordReset}
                                disabled={loading}
                                type="submit"
                                primary
                                color="status-ok"
                                label={translate('global.renew')}
                              />
                            </Box>
                          </Box>
                        </Form>
                      </Box>
                      <Anchor
                        href={this.loginScreenEndpoint}
                        icon={<FormPrevious />}
                        label={translate('global.back')}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </ResponsiveContext.Consumer>
          </MinimalLayout>
        )}
      </Translate>
    );
  }
}
