import React from 'react';
import { Mutation } from 'react-apollo';
import { LostPassword } from './LostPassword';
import gql from 'graphql-tag';
import { FormatUtils } from '../../utils/FormatUtils';
import { Redirect } from 'react-router-dom';

const RESET_PASSWORD_MUTATION = gql`
  mutation resetUserPassword($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      user {
        id
      }
    }
  }
`;

export const LostPasswordConnected = props => {
  const key = FormatUtils.getUrlParameter('key', props.location.search),
    login = FormatUtils.getUrlParameter('login', props.location.search);

  return !key || !login ? (
    <Redirect to={'/'} />
  ) : (
    <Mutation mutation={RESET_PASSWORD_MUTATION}>
      {(resetUserPassword, { loading, error }) => (
        <LostPassword
          loading={loading}
          notification={
            error && {
              text: error && error.message,
              type: 'critical'
            }
          }
          resetPassword={data =>
            resetUserPassword({
              variables: {
                input: {
                  password: data.password,
                  key,
                  login,
                  clientMutationId: 'resetUserPassword' // WPGraphQL requires this
                }
              }
            })
          }
          {...props}
        />
      )}
    </Mutation>
  );
};
