import React from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import { default as Deposits } from './Deposits';

const FETCH_DEPOSITS = gql`
  {
    deposits(first: 3000) {
      nodes {
        amount
        id
        created
        updated
        user_id
        user {
          email
          first_name
          last_name
          mt_id
          name
          referer {
            id
            userId
            name
          }
        }
      }
    }
  }
`;

const SAVE_DEPOSIT_MUTATION = gql`
  mutation upsertDeposit($input: UpsertDepositInput!) {
    upsertDeposit(input: $input) {
      id
    }
  }
`;

const DELETE_DEPOSIT_MUTATION = gql`
  mutation deleteDeposit($input: DeleteDepositInput!) {
    deleteDeposit(input: $input) {
      deletedId
    }
  }
`;

export const DepositsConnected = props => (
  <Query query={FETCH_DEPOSITS}>
    {({ loading, error, data }) => (
      <Mutation
        mutation={DELETE_DEPOSIT_MUTATION}
        refetchQueries={[{ query: FETCH_DEPOSITS }]}
      >
        {(deleteDeposit, deleteDepositMutationProps) => (
          <Mutation
            mutation={SAVE_DEPOSIT_MUTATION}
            refetchQueries={[{ query: FETCH_DEPOSITS }]}
          >
            {(saveDeposit, saveDepositMutationProps) => {
              return (
                <Deposits
                  deposits={
                    data.deposits &&
                    data.deposits.nodes.map(node => ({
                      ...node,
                      name: node.user.name,
                      referredBy: node.user.referer
                        ? node.user.referer.name
                        : ''
                    }))
                  }
                  loading={saveDepositMutationProps.loading || loading}
                  notification={
                    (error ||
                      saveDepositMutationProps.error ||
                      deleteDepositMutationProps.error) && {
                      text:
                        (error && error.message) ||
                        (saveDepositMutationProps.error &&
                          saveDepositMutationProps.error.message) ||
                        (deleteDepositMutationProps.error &&
                          deleteDepositMutationProps.error.message),
                      type: 'critical'
                    }
                  }
                  removeItem={async input =>
                    deleteDeposit({
                      variables: {
                        input: {
                          ...input,
                          clientMutationId: 'deleteDeposit'
                        }
                      }
                    })
                  }
                  saveItem={async data =>
                    saveDeposit({
                      variables: {
                        input: {
                          ...data,
                          clientMutationId: 'saveDeposit' // WPGraphQL requires this
                        }
                      }
                    })
                  }
                  {...props}
                />
              );
            }}
          </Mutation>
        )}
      </Mutation>
    )}
  </Query>
);
