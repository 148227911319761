import React from 'react';
import PropTypes from 'prop-types';
import { BackLink, Layout, Loader, Notification } from '../../components';
import {
  Box,
  Button,
  Form,
  Heading,
  TextInput,
  Text,
  Select
} from 'grommet/es6';
import { ACCOUNT_TYPES } from '../../config/site';
import { routes } from '../../config/routes';
import { MaskedInput } from 'grommet/es6/components/MaskedInput';
import moment from 'moment';

export class AddTrader extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    createUser: PropTypes.func.isRequired,
    notification: PropTypes.object,
    formErrors: PropTypes.object,
    data: PropTypes.object
  };

  static defaultProps = {
    loading: false,
    formErrors: {},
    data: {},
    notification: {
      text: '',
      type: 'info'
    }
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissNotifications = this.dismissNotifications.bind(this);
    this.brokerEndpoint = routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders').path;

    this.state = {
      data: props.data,
      formErrors: props.formErrors,
      notification: props.notification
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.notification.text || prevState.notification.text) {
      return {
        notification: prevState.notification.text
          ? prevState.notification
          : nextProps.notification
      };
    }
    // Object or null must be returned
    return null;
  }

  handleUpdate = event => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value }
    });
  };

  dismissNotifications() {
    this.setState({
      notification: {}
    });
  }

  async handleSubmit(event) {
    const fields = [
        'email',
        'leverage',
        'registered',
        'password',
        'mt_id',
        'account_type',
        'account_currency',
        'first_name',
        'phone',
        'last_name'
      ],
      requiredFields = [
        'email',
        'leverage',
        'account_currency',
        'account_type'
      ],
      errors = {},
      data = {};
    let valid = true;

    // Validation
    for (let i = 0; i < event.target.length; i++) {
      let { value, name } = event.target[i];

      if (fields.includes(name)) {
        data[name] = value;
      }

      if (requiredFields.includes(name) && value.length === 0) {
        errors[name] = 'This field is required.';
        valid = false;
      }
    }

    if (!valid) {
      this.setState({ formErrors: errors });
      return false;
    }

    if (data.registered) {
      data.registered = moment(data.registered, 'DD.MM.YYYY HH:mm').format(
        'DD.MM.YYYY HH:mm'
      );
    }

    new Promise(async (resolve, reject) => {
      try {
        await this.props.createUser(data);
        resolve(`Trader created`);
      } catch (e) {
        reject(e);
      }
    })
      .then(response => {
        const newState = {
          formErrors: {},
          data: {},
          notification: { text: response, type: 'ok' }
        };
        this.setState(newState);
      })
      .catch(error => {
        this.setState({
          formErrors: {},
          notification: { text: error.message, type: 'critical' }
        });
      });
    return true;
  }

  render() {
    const { notification, formErrors } = this.state,
      { loading } = this.props,
      {
        email,
        leverage,
        password,
        mt_id,
        account_type,
        registered,
        account_currency,
        first_name,
        phone,
        last_name
      } = this.state.data ? this.state.data : {};

    return (
      <Layout title={'Add trader'}>
        <BackLink link={this.brokerEndpoint} />
        <Box direction="row" margin={{ vertical: 'medium' }}>
          <Heading size="small" margin={{ right: 'small', vertical: 'none' }}>
            New trader
          </Heading>

          <Box direction="row" align="center">
            {loading && <Loader />}
          </Box>
        </Box>

        {!!notification.text && (
          <Notification
            type={notification.type ? notification.type : 'info'}
            onClose={this.dismissNotifications}
            open={!!notification.text}
            message={notification.text}
          />
        )}

        <Box
          align="center"
          justify="center"
          pad="medium"
          elevation="small"
          background="white"
        >
          <Form style={{ width: '100%', flex: 1 }} onSubmit={this.handleSubmit}>
            <Text
              weight="bold"
              margin={{ vertical: 'small' }}
              as="label"
              style={{ display: 'block' }}
            >
              Meta Trader ID
            </Text>
            <TextInput
              name="mt_id"
              type="text"
              style={{ borderColor: formErrors.mt_id ? 'red' : '' }}
              placeholder="Enter Meta Trader ID"
              value={mt_id ? mt_id : ''}
              disabled={loading}
              onChange={this.handleUpdate}
            />
            {formErrors.mt_id && (
              <Text color="status-critical">{formErrors.mt_id}</Text>
            )}

            <Text
              weight="bold"
              margin={{ vertical: 'small' }}
              as="label"
              style={{ display: 'block' }}
            >
              Email
            </Text>
            <TextInput
              name="email"
              type="email"
              style={{ borderColor: formErrors.email ? 'red' : '' }}
              placeholder="Enter email"
              value={email ? email : ''}
              disabled={loading}
              onChange={this.handleUpdate}
            />
            {formErrors.email && (
              <Text color="status-critical">{formErrors.email}</Text>
            )}

            <Text
              weight="bold"
              margin={{ vertical: 'small' }}
              as="label"
              style={{ display: 'block' }}
            >
              Password
            </Text>
            <TextInput
              name="password"
              type="password"
              style={{ borderColor: formErrors.password ? 'red' : '' }}
              placeholder="Enter password"
              value={password ? password : ''}
              disabled={loading}
              onChange={this.handleUpdate}
            />
            {formErrors.password && (
              <Text color="status-critical">{formErrors.password}</Text>
            )}

            <Text
              weight="bold"
              margin={{ vertical: 'small' }}
              as="label"
              style={{ display: 'block' }}
            >
              First name
            </Text>
            <TextInput
              name="first_name"
              type="text"
              style={{ borderColor: formErrors.first_name ? 'red' : '' }}
              placeholder="Enter first name"
              value={first_name ? first_name : ''}
              disabled={loading}
              onChange={this.handleUpdate}
            />
            {formErrors.first_name && (
              <Text color="status-critical">{formErrors.first_name}</Text>
            )}

            <Text
              weight="bold"
              margin={{ vertical: 'small' }}
              as="label"
              style={{ display: 'block' }}
            >
              Last name
            </Text>
            <TextInput
              name="last_name"
              type="text"
              style={{ borderColor: formErrors.last_name ? 'red' : '' }}
              placeholder="Enter last name"
              value={last_name ? last_name : ''}
              disabled={loading}
              onChange={this.handleUpdate}
            />
            {formErrors.last_name && (
              <Text color="status-critical">{formErrors.last_name}</Text>
            )}

            <Text
              weight="bold"
              margin={{ vertical: 'small' }}
              as="label"
              style={{ display: 'block' }}
            >
              Phone
            </Text>
            <TextInput
              id="phone"
              name="phone"
              type="tel"
              value={phone ? phone : ''}
              disabled={loading}
              placeholder="Phone"
              onChange={this.handleUpdate}
            />
            {formErrors.phone && (
              <Text color="status-critical">{formErrors.phone}</Text>
            )}

            <Text
              weight="bold"
              margin={{ vertical: 'small' }}
              as="label"
              style={{ display: 'block' }}
            >
              Registered
            </Text>
            <MaskedInput
              id="registration-date"
              name="registered"
              mask={[
                {
                  length: [1, 2],
                  regexp: /^1[0-9]?$|^2[0-9]?$|^3[0-1]?$|^0[1-9]?$/,
                  placeholder: 'DD'
                },
                { fixed: '.' },
                {
                  length: [1, 2],
                  regexp: /^1[0-2]?$|^0[1-9]?$/,
                  placeholder: 'MM'
                },
                { fixed: '.' },
                {
                  length: 4,
                  regexp: /^\d{4}?$|^\d{3}?$|^\d{2}?$|^\d{1}?$/,
                  placeholder: 'YYYY'
                },
                { fixed: ' ' },
                {
                  length: [1, 2],
                  regexp: /^1[0-9]?$|^2[0-3]?$|^0[1-9]?$/,
                  placeholder: 'HH'
                },
                { fixed: ':' },
                {
                  length: [1, 2],
                  regexp: /^[1-5][0-9]?$|^0[1-9]?$/,
                  placeholder: 'mm'
                }
              ]}
              value={registered ? registered : ''}
              disabled={loading}
              onChange={option =>
                this.handleUpdate({
                  target: { name: 'registered', value: option.target.value }
                })
              }
            />
            {formErrors.phone && (
              <Text color="status-critical">{formErrors.phone}</Text>
            )}

            <Text
              weight="bold"
              margin={{ vertical: 'small' }}
              as="label"
              style={{ display: 'block' }}
            >
              Account type
            </Text>
            <Select
              id="account_type"
              name="account_type"
              plain
              placeholder="Account type"
              options={ACCOUNT_TYPES.live.concat(ACCOUNT_TYPES.demo)}
              disabled={loading}
              value={account_type ? account_type : ''}
              onChange={({ option }) => {
                this.handleUpdate({
                  target: { name: 'account_type', value: option }
                });
              }}
            />
            {formErrors.account_type && (
              <Text color="status-critical">{formErrors.account_type}</Text>
            )}

            <Text
              weight="bold"
              margin={{ vertical: 'small' }}
              as="label"
              style={{ display: 'block' }}
            >
              Account currency
            </Text>
            <Select
              id="account_currency"
              name="account_currency"
              plain
              placeholder="Currency"
              options={['EUR', 'USD']}
              disabled={loading}
              value={account_currency ? account_currency : ''}
              onChange={({ option }) => {
                this.handleUpdate({
                  target: { name: 'account_currency', value: option }
                });
              }}
            />
            {formErrors.account_currency && (
              <Text color="status-critical">{formErrors.account_currency}</Text>
            )}

            <Text
              weight="bold"
              margin={{ vertical: 'small' }}
              as="label"
              style={{ display: 'block' }}
            >
              Leverage
            </Text>
            <Select
              id="leverage"
              name="leverage"
              plain
              placeholder="Leverage"
              options={['1:500', '1:400', '1:300', '1:200', '1:100']}
              disabled={loading}
              value={leverage ? leverage : ''}
              onChange={({ option }) => {
                this.handleUpdate({
                  target: { name: 'leverage', value: option }
                });
              }}
            />
            {formErrors.leverage && (
              <Text color="status-critical">{formErrors.leverage}</Text>
            )}

            <Box direction="row" justify="center" margin={{ top: 'large' }}>
              <Button
                type="submit"
                disabled={loading}
                label="Create"
                primary
                color="brand"
                fill
                style={{ height: '40px', borderRadius: '4px' }}
              />
            </Box>
          </Form>
        </Box>
      </Layout>
    );
  }
}
