import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Button } from 'grommet';
import { FormPrevious, FormNext } from 'grommet-icons';

export class Paginator extends Component {
  static propTypes = {
    onPageChange: PropTypes.func,
    totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itemsOnPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  };

  constructor(props) {
    super(props);

    this.state = {
      tiles: []
    };
  }

  componentDidMount() {
    this.createTiles(this.props.currentPage, this.props.totalPages);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.totalPages !== prevState.totalPages ||
      nextProps.currentPage !== prevState.currentPage
    ) {
      return {
        totalPages: nextProps.totalPages,
        currentPage: nextProps.currentPage
      };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.totalPages !== this.state.totalPages ||
      prevProps.currentPage !== this.state.currentPage
    ) {
      this.createTiles(this.state.currentPage, this.state.totalPages);
    }
  }

  createTiles(currentPage, totalPages) {
    const tiles = [];

    for (
      let i = currentPage - 5 <= 0 ? 1 : currentPage - 5;
      i <= currentPage + 5 && i <= totalPages;
      i++
    ) {
      tiles.push(i);
    }
    this.setState({
      tiles
    });
  }

  render() {
    const {
        totalPages,
        currentPage,
        pageSize,
        itemsOnPage,
        onPageChange
      } = this.props,
      { tiles } = this.state;

    return (
      <Box direction="row" align="center">
        <Text>
          Page {currentPage} of {totalPages} showing{' '}
          {pageSize * (currentPage - 1) + 1} -{' '}
          {pageSize * (currentPage - 1) + itemsOnPage} items
        </Text>
        {currentPage > 1 && (
          <Button
            onClick={() => onPageChange(currentPage - 1)}
            style={{ paddingTop: '3px' }}
          >
            <FormPrevious />
          </Button>
        )}
        <Box direction="row" justify="between" align="center">
          {tiles.map(pageNumber => (
            <Box
              key={pageNumber}
              pad="small"
              onClick={() => onPageChange(pageNumber)}
              border={false}
            >
              <Button
                plain
                disabled={currentPage === pageNumber}
                label={<Text>{pageNumber}</Text>}
                style={{ fontFamily: 'monospace' }}
              />
            </Box>
          ))}
        </Box>

        {currentPage < totalPages && (
          <Button
            onClick={() => {
              onPageChange(currentPage + 1);
            }}
            style={{ paddingTop: '3px' }}
          >
            <FormNext />
          </Button>
        )}
      </Box>
    );
  }
}
