import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { routes } from '../../config/routes';
import {
  BackLink,
  Layout,
  Loader,
  Modal,
  Notification
} from '../../components';
import { Box, Button, Heading, Text } from 'grommet';
import { Add, FormCheckmark, Trash, View } from 'grommet-icons';
import {
  CellActionsFormatter,
  ToolbaredTable
} from '../../components/DataTable';
import { FLASH_TYPES, useFlash } from '../../hooks';

function getEndpoint(key) {
  const endpoints = {
    broker: routes.find(route => route.name === 'Broker').path,
    viewTrader: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'View').path,
    addBankAccount: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Bank accounts')
      .childs.find(route => route.name === 'Add').path,
    editBankAccount: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Bank accounts')
      .childs.find(route => route.name === 'View').path
  };
  return endpoints[key];
}

const BankAccounts = props => {
  const [flash, showFlash, dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );
  const [modal, showModal, dismissModal] = useFlash();

  const [state, setState] = useState({
    itemToRemove: null,
    actionsDropOpen: false
  });

  const columns = useMemo(() => {
    return [
      {
        key: 'currency',
        name: 'Currency',
        formatter: ({ row }) => {
          let actions = [
            {
              icon: <View size="small" color="brand" />,
              text: 'View/Edit',
              callback: () => {
                props.history.push(
                  getEndpoint('editBankAccount').replace(':id', row.id)
                );
              }
            },
            {
              icon: <Trash size="small" color="status-critical" />,
              text: 'Delete',
              callback: () => {
                showModal();
                setState({
                  ...state,
                  itemToRemove: row
                });
              }
            }
          ];

          if (row[`default_bank_account_${row.currency}`] !== true) {
            actions = [
              {
                icon: <FormCheckmark size="small" />,
                title: `Set as default for ${row.currency}`,
                text: `Set as ${row.currency} default`,
                callback: () => {
                  props.setAccountDefault(row.account_id, row.currency);
                }
              }
            ].concat(actions);
          }

          return (
            <>
              <CellActionsFormatter actions={actions} />
              <div>{row.currency}</div>
            </>
          );
        }
      },
      {
        key: 'account_number',
        name: 'Account Number'
      },
      {
        key: 'iban',
        name: 'IBAN'
      },
      {
        key: 'swift',
        name: 'SWIFT'
      },
      {
        key: 'bank_name',
        name: 'Bank Name'
      },
      {
        key: 'owner_name',
        name: 'Company Name'
      },
      {
        key: 'default',
        name: 'Is default',
        formatter: ({ row }) =>
          row[`default_bank_account_${row.currency}`] === true ? 'Yes' : 'No'
      }
    ].map(col => ({
      ...col,
      sortable: true,
      resizable: true
    }));
  }, []);

  async function removeBankAccount(item) {
    if (!item || !item.id) {
      return;
    }

    await props.removeBankAccount(item.id).then(
      () => {
        showFlash('Bank account removed', FLASH_TYPES.OK);
        dismissModal();
        setState({
          ...state,
          itemToRemove: null
        });
        return true;
      },
      error => {
        showFlash('Error removing bank account', FLASH_TYPES.CRITICAL);
        dismissModal();
        setState({
          ...state,
          itemToRemove: null
        });
        return false;
      }
    );
  }

  const { itemToRemove } = state;
  const { loading, bankAccounts } = props;

  return (
    <Layout title="Bank accounts" fluid>
      <BackLink link={getEndpoint('broker')} />

      <Box direction="row" justify="between" margin={{ vertical: 'medium' }}>
        <Box direction="row" align="center">
          <Heading margin={{ right: 'small', vertical: 'none' }} size="small">
            Bank accounts
          </Heading>

          <Box direction="row" align="center">
            {loading && <Loader />}
          </Box>
        </Box>

        <Button
          label="Add"
          icon={<Add size="small" />}
          primary
          color="brand"
          onClick={() => {
            props.history.push(getEndpoint('addBankAccount'));
          }}
        />
      </Box>

      {flash.visible && (
        <Notification
          type={flash.type ? flash.type : 'info'}
          onClose={dismissFlash}
          open={flash.visible}
          message={flash.text}
        />
      )}

      {modal.visible && (
        <Modal open={modal.visible} heading={'Confirm'} onClose={dismissModal}>
          <Text>Are you sure you want to delete this bank account?</Text>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            <Button
              label="Cancel"
              onClick={() => {
                dismissModal();
                setState({ ...state, itemToRemove: null });
              }}
              disabled={loading}
              color="dark-3"
            />
            <Button
              label={
                <Text color="white">
                  <strong>Delete</strong>
                </Text>
              }
              disabled={loading}
              onClick={() => removeBankAccount(itemToRemove)}
              primary
              color="status-critical"
            />
          </Box>
        </Modal>
      )}

      <div style={{ width: `100%`, height: '500px', position: 'relative' }}>
        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
          <ToolbaredTable
            toolbarProps={{ enableFiltering: false, enableColumnHide: false }}
            columns={columns}
            rows={bankAccounts}
            loading={loading}
          />
        </div>
      </div>
    </Layout>
  );
};

BankAccounts.propTypes = {
  bankAccounts: PropTypes.array,
  removeBankAccount: PropTypes.func.isRequired,
  setAccountDefault: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  notification: PropTypes.object
};

BankAccounts.defaultProps = {
  bankAccounts: [],
  loading: false,
  notification: {}
};

export default BankAccounts;
