import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BackLink,
  Layout,
  Loader,
  Modal,
  Notification
} from '../../components';
import {
  Box,
  Button,
  Form,
  Heading,
  Select,
  Text,
  TextInput
} from 'grommet/es6';
import { routes } from '../../config/routes';
import { Translate } from 'react-localize-redux';
import {
  DEFAULT_DATETIME_FORMAT,
  WITHDRAWAL_STATUSES
} from '../../config/site';
import moment from 'moment';
import { FLASH_TYPES, useFlash } from '../../hooks';
import {
  getFilterRenderer,
  ToolbaredTable
} from '../../components/DataTable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SAVE_OPTION } from '../../redux/app';

function getEndpoint(key) {
  const endpoints = {
    dashboard: routes.find(route => route.name === 'Dashboard').path
  };
  return endpoints[key];
}

const Withdrawals = props => {
  const hiddenColumns =
    useSelector(
      state => state.app.storage.options.withdrawalsTableCols,
      shallowEqual
    ) || props.hiddenColumns;
  const dispatch = useDispatch();

  const [state, setState] = useState({
    formErrors: props.formErrors,
    itemToRemove: null
  });

  const [inputs, setInputs] = useState({
    amount: undefined,
    bank_account: undefined,
    bank_name: undefined,
    note: undefined
  });

  const [flash, showFlash, dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );
  const [modal, showModal, dismissModal] = useFlash();

  const columns = useMemo(() => {
    return [
      {
        key: 'amount',
        name: <Translate id="global.amount">Amount</Translate>,
        filterRenderer: getFilterRenderer('numeric'),
        formatter: ({ row }) => row.amount,
      },
      {
        key: 'method',
        name: <Translate id="global.method">Method</Translate>,
        filterRenderer: getFilterRenderer('text'),
        formatter: ({ row }) => row.method.replace('_', ' ')
      },
      {
        key: 'bank_account',
        name: <Translate id="global.bankAccount">Bank account</Translate>,
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'bank_name',
        name: <Translate id="global.bankAccount">Bank account</Translate>,
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'note',
        name: <Translate id="global.note">Note</Translate>,
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'status',
        name: <Translate id="global.status">Status</Translate>,
        filterRenderer: getFilterRenderer('checklist', {
          rows: [
            { label: 'Schváleno', value: 'accepted' },
            { label: 'Zrušeno', value: 'canceled' },
            { label: 'Čeká na vyřízení', value: 'pending' },
            { label: 'Zamítnuto', value: 'denied' }
          ]
        })
      },
      {
        key: 'created',
        name: <Translate id="global.created">Created</Translate>,
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        filterRenderer: getFilterRenderer('date', {
          datePicker: true,
          placeholder: 'Select Date/Range'
        }),
        formatter: ({ row }) =>
          row.created
            ? moment(row.created).format(DEFAULT_DATETIME_FORMAT)
            : null
      },
      {
        key: 'updated',
        name: <Translate id="global.updated">Updated</Translate>,
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        filterRenderer: getFilterRenderer('date', {
          datePicker: true,
          placeholder: 'Select Date/Range'
        }),
        formatter: ({ row }) =>
          row.updated
            ? moment(row.updated).format(DEFAULT_DATETIME_FORMAT)
            : null
      }
    ].map(col => {
      return {
        ...col,
        resizable: true,
        sortable: true
      };
    });
  }, []);

  const hideColumns = nextState => {
    dispatch({
      type: SAVE_OPTION,
      key: 'withdrawalsTableCols',
      value: nextState
    });
  };

  const handleUpdate = event => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  /**
   * Add withdrawal submit
   * */
  const handleSubmit = async event => {
    const fields = [
        'amount',
        'method',
        'bank_account',
        'bank_name',
        'note'
      ],
      requiredFields = [
        'amount',
        'method',
        'bank_account',
        'bank_name',
        'note'
      ],
      errors = {},
      data = {};
    let valid = true;

    // Validation
    for (let i = 0; i < event.target.length; i++) {
      let { value, name } = event.target[i];

      if (fields.includes(name)) {
        data[name] = value;
      }

      if (requiredFields.includes(name) && value.length === 0) {
        errors[name] = 'Please fill in this field.';
        valid = false;
      }
    }

    if (!valid) {
      return setState({ ...state, formErrors: errors });
    }

    data.user_id = props.user.userId;

    new Promise(async (resolve, reject) => {
      // Creating
      try {
        await props.createItem(data);
        resolve(`Žádost byla odeslána.`);
      } catch (e) {
        reject(e);
      }
    })
      .then(response => {
        setState({ ...state, formErrors: {} });
        setInputs({});
        showFlash(response, FLASH_TYPES.OK);
      })
      .catch(error => {
        showFlash(error.message, FLASH_TYPES.CRITICAL);
      });
  };

  /**
   * Cancelation of request
   * */
  const updateItem = async item => {
    if (!item || !item.id) {
      return;
    }

    const data = { id: item.id, status: WITHDRAWAL_STATUSES.canceled };
    await props
      .updateItem(data)
      .then(() => {
        showFlash(`Žádost byla zrušena`, FLASH_TYPES.OK);
        setState({ ...state, itemToRemove: null });
        return true;
      })
      .catch(error => {
        showFlash(`Error canceling withdrawal request`, FLASH_TYPES.CRITICAL);
        setState({ ...state, itemToRemove: null });
      });
    return false;
  };

  const { itemToRemove, formErrors } = state;
  const {
    loading,
    transferMethods,
    user,
    tradingAccounts,
    withdrawals
  } = props;
  const { amount, bank_account, bank_name, note } = inputs;

  return (
      <Translate>
        {({ translate }) => (
    <Layout title="Withdrawals" maxWidth="1200px">
      <BackLink link={getEndpoint('dashboard')} />

      <Box direction="row" align="center" margin={{ vertical: 'medium' }}>
        <Heading margin={{ right: 'small', vertical: 'none' }} size="small">
          {translate('withdrawal.title')}
        </Heading>

        <Box direction="row" align="center">
          {loading && <Loader />}
        </Box>
      </Box>

      {flash.visible && (
        <Notification
          type={flash.type ? flash.type : 'info'}
          onClose={dismissFlash}
          open={flash.visible}
          message={flash.text}
        />
      )}

      {/* Add withdrawal */}
      <Box gap="small">
        <Form
          style={{ width: '100%', flex: 1, marginBottom: '40px' }}
          onSubmit={handleSubmit}
        >
            <TextInput
                hidden
                id="account_id"
                name="account_id"
                value={tradingAccounts.length > 0 ? tradingAccounts[0]['id'] : ''}
            />
          <Box gap="small" elevation="small" background="white" pad="medium">
            <Heading level="3" size="small">
              {translate("withdrawal.requestWithdrawal")}
            </Heading>
            <Box direction="row-responsive">
              <Text
                color="brand"
                margin={{ vertical: 'small', right: 'small' }}
                as="label"
                htmlFor="method"
                style={{ display: 'block', minWidth: '200px' }}
              >
                {translate("withdrawal.paymentMethod")}:
              </Text>
              <Select
                id="method"
                name="method"
                placeholder={translate("withdrawal.paymentMethod")}
                style={{
                  minWidth: '300px',
                  borderColor: formErrors.method ? 'red' : 'transparent',
                  borderWidth: '1px',
                  borderStyle: 'solid'
                }}
                value={transferMethods[0]}
                labelKey="label"
                valueKey="value"
                disabled={loading}
                options={transferMethods}
                onChange={({ value: nextValue }) =>
                  setInputs({ ...inputs, method: nextValue })
                }
              />
            </Box>
            {formErrors.method && (
              <Text color="status-critical">{formErrors.method}</Text>
            )}

            <Box direction="row-responsive">
              <Text
                color="brand"
                margin={{ vertical: 'small', right: 'small' }}
                as="label"
                htmlFor="amount"
                style={{ display: 'block', minWidth: '200px' }}
              >
                {translate("withdrawal.amount")}:
              </Text>
              <TextInput
                id="amount"
                name="amount"
                style={{ borderColor: formErrors.amount ? 'red' : '' }}
                type="number"
                value={amount ? amount : ''}
                disabled={loading}
                placeholder={translate("withdrawal.amount")}
                onChange={handleUpdate}
              />
            </Box>
            {formErrors.amount && (
              <Text color="status-critical">{formErrors.amount}</Text>
            )}

            <Box direction="row-responsive">
              <Text
                color="brand"
                margin={{ vertical: 'small', right: 'small' }}
                as="label"
                htmlFor="bank_account"
                style={{ display: 'block', minWidth: '200px' }}
              >
                {translate('withdrawal.bankAccountNumber')}:
              </Text>
              <TextInput
                id="bank_account"
                name="bank_account"
                style={{ borderColor: formErrors.bank_account ? 'red' : '' }}
                type="text"
                value={bank_account ? bank_account : ''}
                disabled={loading}
                placeholder={translate('withdrawal.bankAccountNumber')}
                onChange={handleUpdate}
              />
            </Box>
            {formErrors.bank_account && (
              <Text color="status-critical">{formErrors.bank_account}</Text>
            )}

            <Box direction="row-responsive">
              <Text
                color="brand"
                margin={{ vertical: 'small', right: 'small' }}
                as="label"
                htmlFor="bank_name"
                style={{ display: 'block', minWidth: '200px' }}
              >
                {translate('withdrawal.bankName')}:
              </Text>
              <TextInput
                id="bank_name"
                name="bank_name"
                style={{ borderColor: formErrors.bank_name ? 'red' : '' }}
                type="text"
                value={bank_name ? bank_name : ''}
                disabled={loading}
                placeholder={translate('withdrawal.bankName')}
                onChange={handleUpdate}
              />
            </Box>
            {formErrors.bank_name && (
              <Text color="status-critical">{formErrors.bank_name}</Text>
            )}

            <Box direction="row-responsive">
              <Text
                color="brand"
                margin={{ vertical: 'small', right: 'small' }}
                as="label"
                htmlFor="note"
                style={{ display: 'block', minWidth: '200px' }}
              >
                {translate('withdrawal.traded')}:
              </Text>
              <Select
                  id="note"
                  name="note"
                  placeholder={translate('global.choose')}
                  style={{
                    minWidth: '300px',
                    borderColor: 'transparent',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                  }}
                  value={note}
                  disabled={loading}
                  options={[
                      translate('global.moreThan60Lots'),
                      translate('global.moreThan200Lots'),
                      translate('global.moreThan500Lots'),
                  ]}
                  onChange={({ value: nextValue }) =>
                      setInputs({ ...inputs, note: nextValue })
                  }
              />
            </Box>
            {formErrors.note && (
              <Text color="status-critical">{formErrors.note}</Text>
            )}

            <Box margin={{ top: 'small' }} align="center">
              <Button
                primary
                disabled={loading}
                color="status-ok"
                label={translate('global.submit')}
                type="submit"
              />
            </Box>
          </Box>
        </Form>
      </Box>

      {/* Withdrawals table */}
      <Box margin={{ vertical: 'medium' }}>
        <Heading level="3" size="medium">
          {translate('withdrawal.historyOfTransactions')}
        </Heading>
        <div style={{ width: `100%`, height: '300px', position: 'relative' }}>
          <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
            <ToolbaredTable
              toggleableColumns={columns}
              columns={columns}
              rows={withdrawals}
              loading={loading}
              hiddenColumns={hiddenColumns}
              onHideColumn={hideColumns}
            />
          </div>
        </div>
      </Box>

      {/* Modals */}
      {modal.visible && (
        <Modal open={modal.visible} heading={'Confirm'} onClose={dismissModal}>
          <Text>
            Opravdu chcete zrušit žádost o výplatu{' '}
            {itemToRemove && itemToRemove.amount}?
          </Text>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            <Button
              label="Zrušit"
              onClick={() => {
                dismissModal();
                setState({ ...state, itemToRemove: null });
              }}
              disabled={loading}
              color="dark-3"
            />
            <Button
              label={
                <Text color="white">
                  <strong>Potvrdit</strong>
                </Text>
              }
              onClick={() => {
                dismissModal();
                updateItem(itemToRemove);
              }}
              disabled={loading}
              primary
              color="status-critical"
            />
          </Box>
        </Modal>
      )}
    </Layout>
  )}</Translate>);
};

Withdrawals.propTypes = {
  withdrawals: PropTypes.array,
  tradingAccounts: PropTypes.array,
  transferMethods: PropTypes.array,
  hiddenColumns: PropTypes.array,
  formErrors: PropTypes.object,
  user: PropTypes.object,
  createItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  notification: PropTypes.object
};

Withdrawals.defaultProps = {
  withdrawals: [],
  tradingAccounts: [],
  hiddenColumns: [],
  transferMethods: [],
  user: {},
  formErrors: {},
  loading: false,
  notification: {}
};

export default Withdrawals;
