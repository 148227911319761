import React from 'react';
import { Loader } from '../';

const NoRows = ({ loading, height }) => {
  const EMPTY_HEIGHT = 120;
  return (
    <div
      style={{
        textAlign: 'left',
        padding: '5px',
        minHeight: `${height || EMPTY_HEIGHT}px`
      }}
    >
      {loading ? (
        <Loader showLogo={false} style={{ transform: 'scale(0.5)' }} />
      ) : (
        'No data to show'
      )}
    </div>
  );
};

export default NoRows;
