import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { Grommet, Box } from 'grommet/es6';
import { grommetTheme } from '../config/theme';
import {EDU_ONLINE_TRADER_BRAND, languageConfig} from '../config/site';
import {getCurrentLanguage, setBrand, setCurrentLanguage} from '../services/translations';
import { useDispatch } from 'react-redux';
import { LOAD_STORAGE_DATA } from '../redux/app';
import { Loader } from '../components';
import {FormatUtils} from "../utils/FormatUtils";

/**
 * Initialization logic for application should be placed here
 */
const Main = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    props.initialize(languageConfig);
    props.addTranslation(languageConfig.translation);
    props.setActiveLanguage(getCurrentLanguage());

    setCurrentLanguage('en');

    const brand = FormatUtils.getUrlParameter(
        'brand',
        window.location
    );

    // setBrand(EDU_ONLINE_TRADER_BRAND);

    if (brand !== '') {
      setBrand(brand);

      window.location.href = window.location.href.replace('brand=' + brand, '');
    }

    dispatch({ type: LOAD_STORAGE_DATA });
  }, []);

  const { children, loading } = props;

  return (
    <Grommet theme={grommetTheme} full>
      {loading ? (
        <Box
          direction="column"
          align="center"
          justify="center"
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0
          }}
        >
          <Loader />
          <small style={{ padding: '5px' }}>Please wait</small>
        </Box>
      ) : (
        children
      )}
    </Grommet>
  );
};

Main.propTypes = {
  loading: PropTypes.bool
};

Main.defaultProps = {
  loading: false
};

export default withLocalize(Main);
