export default class DataProvider {
    apiUrl = null;

    constructor(apiUrl) {
        this.apiUrl = apiUrl;
    }

    async createLiveAccount(data) {
        const url = new URL(this.apiUrl + "/accounts/v2/create-live-account");
        url.search = new URLSearchParams(data).toString();

        return fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });
    }

    async getUserOpenedPositions(userId) {
        const url = new URL(this.apiUrl + "/trading/v2/positions");
        const params = {
            user_id: userId
        };
        url.search = new URLSearchParams(params).toString();

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        });
        const answer = await response.json();
        return answer.data;
    }

    async getTradingAccount(userId) {
        const url = new URL(this.apiUrl + "/accounts/v2/detail");
        const params = {
            user_id: userId
        };
        url.search = new URLSearchParams(params).toString();

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        });
        const answer = await response.json();
        return answer.data;
    }

    async updatePosition(position) {
        const url = new URL(this.apiUrl + "/trading/v2/update-position");
        const params = {
            position: position.position,
            tp: position.tp,
            sl: position.sl
        };
        url.search = new URLSearchParams(params).toString();

        return fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        });
    }
}
