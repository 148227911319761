export const CURRENCY_SYMBOLS = {
  USD: '$',
  EUR: '€',
  CZK: 'Kč'
};

export class FormatUtils {
  /**
   * Outputs string representation of TradingAccount object
   * @param account object Trading account
   * @return {string}
   */
  static formatTradingAccountDisplayName(account, meta_trader_id) {
    if (account) {
      return (
          `${meta_trader_id ? meta_trader_id : ''}` +
          `${account.leverage ? ' | ' + account.leverage : ''}` +
          `${account.account_currency ? ' | ' + account.account_currency : ''}` +
          `${account.account_type ? ' | ' + account.account_type : ''}`
      );
    }
    return '';
  }

  /**
   * Outputs string representation of price in currency
   * @param amount number|string
   * @param currency string currency label e.x CZK, EUR, USD,...
   * @return {string}
   */
  static formatMoneyAmountDisplay(amount, currency) {
    if (amount) {
      return `${amount}${
        CURRENCY_SYMBOLS[currency] ? ' ' + CURRENCY_SYMBOLS[currency] : ''
      }`;
    }
    return '';
  }

  /**
   * Will try to find url query string param by name
   * @param name string
   * @param url string
   * @return {string}
   */
  static getUrlParameter(name, url) {
    name = name.replace(/[[]/, '[').replace(/[\]]/, ']');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
      results = regex.exec(url);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
}
