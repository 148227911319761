import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BackLink,
  Layout,
  Loader,
  Notification,
  ReadOnlyRows
} from '../../components';
import { Anchor, Box, Heading, Text } from 'grommet/es6';
import { routes } from '../../config/routes';
import { useFlash } from '../../hooks';
import { getFilterRenderer, ToolbaredTable } from '../../components/DataTable';
import { SAVE_OPTION } from '../../redux/app';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

function getEndpoint(key) {
  const endpoints = {
    broker: routes.find(route => route.name === 'Broker').path,
    viewTrader: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'View').path
  };
  return endpoints[key];
}

const TradingAccounts = props => {
  const hiddenColumns =
    useSelector(
      state => state.app.storage.options.tradingAccountsCols,
      shallowEqual
    ) || props.hiddenColumns;
  const dispatch = useDispatch();
  const [totals, setTotals] = useState({ balance: 0 });
  const [flash, , dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );

  const columns = useMemo(() => {
    return [
      {
        key: 'mt_id',
        name: 'Login',
        sortable: true,
        resizable: true,
        width: 200,
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        resizable: true,
        width: 200,
        filterRenderer: getFilterRenderer('text'),
        formatter: ({ row }) => {
          return !row.name ? null : (
            <Anchor
              title="Show client"
              target="_blank"
              href={getEndpoint('viewTrader').replace(':id', row.user_id)}
              color="brand"
              label={<Text weight="bold">{row.name}</Text>}
            />
          );
        }
      },
      {
        key: 'balance',
        name: 'Balance',
        sortable: true,
        resizable: true,
        filterRenderer: getFilterRenderer('numeric')
      }
    ];
  }, []);

  function onRowsChange(rows) {
    setTotals(
      rows.reduce(
        (acc, item) => {
          for (let key in acc) {
            if (acc.hasOwnProperty(key)) {
              acc[key] += parseFloat(item[key] ? item[key] : 0);
            }
          }
          return acc;
        },
        { balance: 0 }
      )
    );
  }

  const hideColumns = nextState => {
    dispatch({
      type: SAVE_OPTION,
      key: 'tradingAccountsCols',
      value: nextState
    });
  };

  const { loading } = props;

  return (
    <Layout title="Trading accounts" fluid>
      <BackLink link={getEndpoint('broker')} />

      <Box
        direction="row"
        justify="between"
        align="center"
        margin={{ vertical: 'medium' }}
      >
        <Box direction="row" align="center">
          <Heading size="small">Trading accounts</Heading>
        </Box>
      </Box>

      {flash.visible && (
        <Notification
          type={flash.type ? flash.type : 'info'}
          onClose={dismissFlash}
          open={flash.visible}
          message={flash.text}
        />
      )}

      <Box gap="small" margin={{ bottom: 'large' }}>
        {loading && (
          <Box direction="row" align="center">
            <Heading size="medium" level="3" margin={{ right: 'small' }}>
              Please wait
            </Heading>
            <Loader />
          </Box>
        )}

        <Box direction="row" gap="small">
          <Text as="label" size="large" color="dark-3">
            Total balance:
          </Text>
          <Text size="large" color={loading ? 'dark-4' : 'text'}>
            {loading ? '-' : totals.balance.toFixed(2)}
          </Text>
        </Box>

        <Box direction="row" gap="small" align="center">
          <div
            style={{ width: '10px', height: '10px', background: '#ff4040' }}
          />
          Read only
        </Box>
      </Box>

      <div style={{ width: `100%`, height: '100vh', position: 'relative' }}>
        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
          <ReadOnlyRows
            rows={props.accounts}
            active={false}
            onRowsChange={onRowsChange}
          >
            {({ rows, active, tableOption, rowRenderer }) => (
              <ToolbaredTable
                toolbarProps={{
                  toggleableOptions: [tableOption],
                  activeOptions: active ? [tableOption.name] : []
                }}
                toggleableColumns={columns}
                columns={columns}
                rows={rows}
                onHideColumn={hideColumns}
                hiddenColumns={hiddenColumns}
                loading={loading}
                rowRenderer={rowRenderer}
              />
            )}
          </ReadOnlyRows>
        </div>
      </div>
    </Layout>
  );
};

TradingAccounts.propTypes = {
  accounts: PropTypes.array,
  hiddenColumns: PropTypes.array,
  loading: PropTypes.bool,
  notification: PropTypes.object
};

TradingAccounts.defaultProps = {
  accounts: [],
  hiddenColumns: [],
  loading: false,
  notification: {
    text: '',
    type: 'info'
  }
};

export default TradingAccounts;
