import React from 'react';
import { CheckListFilter, DateRangeFilter, TextFilter } from '../index';
import NumericFilter from './NumericFilter';

export const getFilterRenderer = (name, outerProps = {}) => {
  let Component = null;
  switch (name.toLowerCase()) {
    case 'text':
      Component = TextFilter;
      break;
    case 'numeric':
      Component = NumericFilter;
      break;
    case 'checklist':
      Component = CheckListFilter;
      break;
    case 'date':
      Component = DateRangeFilter;
      break;
    default:
      throw new Error(`Unknown filter renderer name ${name}`);
  }
  return Component
    ? props => <Component {...props} {...outerProps} />
    : undefined;
};
