import DataProvider from "./DataProvider";
import AccountService from "../services/AccountService";
import TradingService from "../services/TradingService";

export default class Container {
    static instance = null;
    dataProvider = null;
    tradingService = null;
    accountService = null;

    /**
     * @returns Container
     */
    static getInstance() {
        if (Container.instance === null) {
            Container.instance = new Container();
        }

        return Container.instance;
    }

    /**
     * @returns DataProvider
     */
    getDataProvider() {
        if (this.dataProvider === null) {
            this.dataProvider = new DataProvider(process.env.REACT_APP_REST_API_HOST);
        }
        return this.dataProvider;
    }

    /**
     * @returns AccountService
     */
    getAccountService() {
        if (this.accountService === null) {
            this.accountService = new AccountService();
        }
        return this.accountService;
    }

    /**
     * @returns TradingService
     */
    getTradingService() {
        if (this.tradingService === null) {
            this.tradingService = new TradingService();
        }
        return this.tradingService;
    }
}
