import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { parseFilterTermValue, valueContainsSearchTerm } from '../data';

const TextFilter = ({
  className,
  column,
  onChange,
  filterValues,
  getRules,
  ...rest
}) => {
  const [value, setValue] = useState(rest.value);

  useEffect(() => {
    // This handles initial value filters passed as plain value
    if (typeof value !== 'object' && typeof value !== 'undefined') {
      handleChange({ target: { value } });
    }
  }, []);

  function handleChange(event) {
    const { value } = event.target;
    const filters = getRules(value);
    setValue(value);
    onChange({
      filterTerm: filters.length > 0 ? filters : null,
      column,
      rawValue: value,
      filterValues
    });
  }

  return (
    <div className={`rdg-filter-container ${className}`}>
      <input
        type="text"
        {...rest}
        onChange={handleChange}
        value={parseFilterTermValue(value) || ''}
      />
    </div>
  );
};

TextFilter.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired,
  getRules: PropTypes.func,
  filterValues: PropTypes.func,
  placeholder: PropTypes.string
};

TextFilter.defaultProps = {
  className: 'form-group text-filter',
  placeholder: 'Filter...',
  getRules,
  filterValues
};

function filterValues(row, columnFilter, columnKey) {
  if (!columnFilter.filterTerm) {
    return true;
  }

  if (!row[columnKey]) {
    return false;
  }

  return valueContainsSearchTerm(row[columnKey], columnFilter.filterTerm);
}

function getRules(value) {
  return value;
}

export default TextFilter;
