import globalTranslations from '../translations/global';
import { renderToStaticMarkup } from 'react-dom/server';

export const siteMeta = {
  title: "Trader's room - Trading Academy Club",
  canonicalUrl: 'https://tr.tradingacademy.club',
  webUrl: 'https://tradingacademy.club'
};

export const authConfig = {
  storageAuthState: 'tr_auth_data',
  refreshTokenHTTPHeader: 'x-jwt-refresh'
};

export const languageConfig = {
  languages: [{ name: 'English', code: 'en' }, { name: 'Česky', code: 'cz' }],
  translation: globalTranslations,
  options: { renderToStaticMarkup }
};

export const DEFAULT_DATETIME_FORMAT = 'DD. MMM YYYY hh:mm A';

export const defaultLangCode = 'en';

export const LANGUAGE_STORAGE_KEY = 'tr_current_lang';
export const BRAND_STORAGE_KEY = 'tr_current_brand';

export const AKOSOLUTIONS_BRAND = 'akosolutions';
export const EDU_ONLINE_TRADER_BRAND = 'eduonlinetrader';

export const ACCOUNT_TYPES = {
  demo: ['Demo'],
  live: [
      'Standard',
    'VIP',
    'VIP Platinum',
    /*'Akademie 1000'*/
    'Live\\TA-B-USD-VIP',
    'Live\\TA-B-EURO-VIP',
    'Live\\TA-B-USD-SWAP',
    'Live\\TA-B-EURO-SWAP',
  ]
};

export const WITHDRAWAL_STATUSES = {
  pending: 'PENDING',
  denied: 'DENIED',
  accepted: 'ACCEPTED',
  canceled: 'CANCELED'
};

export const MESSAGE_TYPES = {
  inbox: 'inbox',
  support: 'support'
};

export const SUPPORT_TICKET_STATUSES = {
  open: 'open',
  closed: 'closed'
};

export const EXCLUDED_ACCOUNTS_FROM_STATISTICS = [
  '2090025888',
  '2100081200',
  '2100084818',
  '2090043235'
];

export const CHANGE_BALANCE_COMMENT_SUGGESTIONS = [
  'Payment',
  'Akademie - Dotace uctu',
  'Vyber odmeny',
  'Akademie - Kompenzace',
  'Cashback'
];
export const CHANGE_CREDIT_COMMENT_SUGGESTIONS = ['Akademie Dotace'];

export const META_TRADER_DOWNLOAD_LINK =
  'https://download.mql5.com/cdn/web/13592/mt4/tradingacademyclub4setup.exe';

export const REFERRAL_KEY = 'referral';
export const REFERRAL_LINK_BASE = `${siteMeta.canonicalUrl}/open-real-account/?${REFERRAL_KEY}=`;
