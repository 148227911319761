import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Form, FormField, TextInput } from 'grommet/es6';
import { ContactInfo, Language } from 'grommet-icons';
import { Translate } from 'react-localize-redux';

export class BecomePartnerForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    formData: PropTypes.object,
    errors: PropTypes.object
  };

  static defaultProps = {
    loading: false,
    errors: {},
    formData: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      formData: props.formData
    };
  }

  handleUpdate = event => {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.value
      }
    });
  };

  handleSubmit = async event => {
    const result = await this.props.handleSubmit(event);
    if (result) {
      this.setState({ formData: {} });
    }
  };

  render() {
    const { website, info } = this.state.formData,
      { errors, loading } = this.props;

    return (
      <Translate>
        {({ translate }) => (
          <Form
            style={{ width: '100%', flex: 1 }}
            name="becomePartner"
            className="becomePartnerForm"
            onSubmit={this.handleSubmit}
          >
            <FormField
              style={{ flex: 1 }}
              label={
                <Box gap="small" direction="row" align="center">
                  <Language size="16px" />
                  <Translate id="becomePartner.labels.website">
                    Company website
                  </Translate>
                </Box>
              }
              htmlFor="website"
              error={errors['website'] && translate('errors.requiredField')}
            >
              <TextInput
                id="website"
                name="website"
                type="text"
                value={website ? website : ''}
                disabled={loading}
                placeholder={translate('becomePartner.placeholders.website')}
                onChange={this.handleUpdate}
              />
            </FormField>

            <FormField
              style={{ flex: 1 }}
              label={
                <Box gap="small" direction="row" align="center">
                  <ContactInfo />
                  <Translate id="becomePartner.labels.info">
                    Your experience
                  </Translate>
                </Box>
              }
              htmlFor="info"
              error={errors['info'] && translate('errors.requiredField')}
            >
              <TextInput
                id="info"
                name="info"
                type="text"
                value={info ? info : ''}
                disabled={loading}
                placeholder={translate('becomePartner.placeholders.info')}
                onChange={this.handleUpdate}
              />
            </FormField>

            <Box
              direction="row-responsive"
              justify="between"
              align="center"
              margin={{ vertical: 'medium' }}
              gap="medium"
            >
              <Button
                type="submit"
                fill
                disabled={loading}
                label={<Translate id="global.submit">Submit</Translate>}
                primary
                color="brand"
              />
            </Box>
          </Form>
        )}
      </Translate>
    );
  }
}
