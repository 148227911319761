import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from 'grommet';
import { Helmet } from 'react-helmet';
import config from '../../config';

export const MinimalLayout = ({ children, title }) => (
  <Grid
    fill
    style={{ position: 'relative', flex: '1 1 0' }}
    areas={[
      { name: 'main', start: [0, 0], end: [0, 1] },
      { name: 'footer', start: [0, 1], end: [0, 1] }
    ]}
    columns={['flex']}
    rows={['flex', 'auto']}
  >
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`${title ? title + ' | ' : ''}${config.read([
        'meta',
        'title'
      ])}`}</title>
      <link rel="canonical" href={config.read(['meta', 'canonicalUrl'])} />
    </Helmet>

    <Box
      as="main"
      gridArea="main"
      height={{ min: 'auto', max: 'full' }}
      flex={false}
    >
      {children}
    </Box>
  </Grid>
);

MinimalLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};
