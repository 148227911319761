import 'react-app-polyfill/ie11';
import { Compatibility } from './utils/Compatibility';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (Compatibility.browser.isIE()) {
  alert(
    'Dear visitor, it seems that you are using an old version of web browser which may limit your user experience and also security. To get the best of our services, please always use fresh and updated web browser.'
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
