import app from './app';
import { theme } from './theme';
import { lensPath, view, set } from 'ramda';

const config = ((initial = {}) => {
  /**
   * Configuration storage
   */
  const configuration = initial;

  /**
   * Will return value saved under a path
   * @param path {array,string}
   * @param fallback value to return if none is returned
   * @return {*}
   */
  function read(path, fallback = undefined) {
    const readLens = lensPath(path);
    return view(readLens, configuration) || fallback;
  }

  /**
   * Writes a value to the config path
   * @param path {array,string}
   * @param value
   * @return {*}
   */
  function write(path, value) {
    const writeLens = lensPath(path);
    return set(writeLens, value, configuration);
  }

  return {
    read,
    write
  };
})({
  ...app,
  theme
});

export default config;
