export default class TradingService {
    static ACTION_BUY = 0;
    static ACTION_SELL = 1;

    getAction(action) {
        switch (action - 0) {
            case TradingService.ACTION_BUY:
                return "BUY";
            case TradingService.ACTION_SELL:
                return "SELL";
            default:
                return null;
        }
    }
}
