import React from 'react';
import PropTypes from 'prop-types';
import {
  Anchor,
  Box,
  Button,
  Drop,
  DropButton,
  Heading,
  Image,
  ResponsiveContext,
  Text
} from 'grommet';
import { Link } from './';
import { withRouter } from 'react-router';
import logo from '../images/eduoonlinetrader-logo.png';
import { canUserSeeRoute, stripParam } from '../config/routes';
import * as Icons from 'grommet-icons';
import { Translate } from 'react-localize-redux';
import {getBrand} from "../services/translations";
import {AKOSOLUTIONS_BRAND, EDU_ONLINE_TRADER_BRAND} from "../config/site";

class Header extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    logout: PropTypes.func.isRequired,
    logoutRedirect: PropTypes.string,
    megaMenuOpen: PropTypes.bool,
    megaMenuRowLength: PropTypes.number,
    categories: PropTypes.object,
    selectedCategory: PropTypes.array
  };

  static defaultProps = {
    logoutRedirect: '/login',
    selectedCategory: null,
    megaMenuOpen: false,
    megaMenuRowLength: 4,
    categories: {}
  };

  constructor(props) {
    super(props);

    this.targetRef = React.createRef();

    this.state = {
      megaMenuOpen: props.megaMenuOpen,
      mobileMenuOpen: false,
      selectedCategory: props.selectedCategory
    };
  }

  mobileMenuClose = () => {
    this.setState({ mobileMenuOpen: false });
  };

  mobileMenuOpen = () => {
    this.setState({ mobileMenuOpen: true });
  };

  createDropMenu(category) {
    if (!category) {
      return false;
    }

    const menu = [],
      rowLength = this.props.megaMenuRowLength,
      rowCount = Math.ceil(category.length / rowLength) || 1;

    for (let i = 0; i < rowCount; i++) {
      menu.push(
        <Box key={i} direction="row" pad="small" background="header">
          {category
            .slice(rowLength * i, rowLength * (i + 1))
            .map((item, index) => {
              const ItemIcon = item.icon ? Icons[item.icon] : null;
              return (
                <Box
                  key={item.path}
                  onClick={() => this.props.history.push(stripParam(item.path))}
                  direction="row"
                  align="center"
                  justify="center"
                  width="260px"
                  pad="small"
                  margin={{ horizontal: 'xxsmall' }}
                  background={'dark-1'}
                  className="mega-menu-item"
                >
                  <Box background={'brand'} pad="small" round="large">
                    {ItemIcon ? <ItemIcon size="17px" /> : ''}
                  </Box>
                  <Box pad="small" gap="small">
                    <Heading margin="none" level={3}>
                      <Link color={'white'} href={item.path}>
                        {item.name}
                      </Link>
                    </Heading>
                    {item.childs &&
                      item.childs.map(
                        child =>
                          !child.hidden && (
                            <Link
                              key={child.path}
                              onClick={event => {
                                event.stopPropagation();
                                this.props.history.push(stripParam(child.path));
                              }}
                              color={'white'}
                              className="nav-sub-item-decorator"
                              href={child.path}
                            >
                              {child.name}
                            </Link>
                          )
                      )}
                  </Box>
                </Box>
              );
            })}
        </Box>
      );
    }
    return menu;
  }

  createNavbar(categories) {
    return Object.keys(categories).map(key => {
      if (categories[key].length === 0) {
        return [];
      }

      let upperCaseKey = key.charAt(0).toUpperCase() + key.slice(1);

      return (
        <Box key={key} direction="row" align="center" pad="xsmall">
          {categories[key].length === 1 ? (
            canUserSeeRoute(categories[key][0]) && (
              <Link href={stripParam(categories[key][0].path)}>
                {upperCaseKey}
              </Link>
            )
          ) : (
            <Box
              direction="row"
              align="center"
              onClick={() =>
                this.setState({
                  selectedCategory: this.state.selectedCategory
                    ? null
                    : categories[key]
                })
              }
            >
              <Button plain label={upperCaseKey} />
              <Icons.Down size="small" style={{ marginLeft: '5px' }} />
            </Box>
          )}
        </Box>
      );
    });
  }

  render() {
    const { categories, user, logout } = this.props,
      { selectedCategory, mobileMenuOpen } = this.state;

    return (
      <Box height="full" justify="center" width="full">
        <Translate>
          {({ translate }) => (
              <ResponsiveContext.Consumer>
          {size => (
            <Box
              direction={'row'}
              justify={'between'}
              align={'center'}
              height="40px"
              ref={this.targetRef}
            >
              <Box direction="row" align="center">
                <Anchor href="/">
                  <Box
                    justify="center"
                    direction="row"
                    align="center"
                    margin={{ right: 'small' }}
                  >
                    <Image
                      src={logo}
                      fit={'contain'}
                      height="35px"
                      alt={'Trading Academy Club'}
                    />
                  </Box>
                </Anchor>

                {size !== 'small' && (
                  <Box direction="row" align="center">
                    {this.createNavbar(categories)}
                  </Box>
                )}
              </Box>

              {user ? (
                <Box
                  as={'nav'}
                  direction={'row'}
                  pad="small"
                  gap="medium"
                  align="center"
                >
                  {user && user.name && size !== 'small' ? (
                    <Anchor
                      color="white"
                      style={{ fontWeight: 'normal' }}
                      href="/trader/profile"
                    >
                      <Box direction="row" align="center">
                        <Icons.User size="16px" />
                        &nbsp;{user.name}
                      </Box>
                    </Anchor>
                  ) : (
                    ''
                  )}

                  {size === 'small' && (
                    <DropButton
                      open={mobileMenuOpen}
                      onClose={this.mobileMenuClose}
                      onOpen={this.mobileMenuOpen}
                      label={
                        <Box title="Toggle menu">
                          <Icons.Menu size="20px" />
                        </Box>
                      }
                      plain
                      dropProps={{ align: { top: 'bottom' } }}
                      dropContent={
                        <Box
                          pad="small"
                          gap="small"
                          width="300px"
                          background="dark-1"
                        >
                          <Box direction="row" justify="between" align="center">
                            <Text color="dark-4">Menu</Text>
                            <Button
                              icon={<Icons.Close size="small" />}
                              onClick={this.mobileMenuClose}
                            />
                          </Box>
                          {this.createNavbar(categories)}
                        </Box>
                      }
                    />
                  )}

                  {
                    <Anchor
                      // title="Logout"
                      title={translate('global.logout')}
                      color={'white'}
                      href="/"
                      alignSelf="center"
                      label={
                        <Box direction="row" style={{ fontWeight: 'normal' }}>
                          <Icons.Power size="16px" />
                          {/*&nbsp;{size !== "small" && "Logout"}*/}
                          &nbsp;{size !== 'small' && translate('global.logout')}
                        </Box>
                      }
                      onClick={event => {
                        event.preventDefault();
                        if (logout) {
                          logout();
                        }
                      }}
                    />
                  }
                </Box>
              ) : (
                <Box
                  as={'nav'}
                  direction={'row'}
                  pad={{ vertical: 'small' }}
                  align="center"
                >
                  <Anchor
                    color={'white'}
                    margin={{ horizontal: 'medium' }}
                    href="/login"
                  >
                    Login
                  </Anchor>
                </Box>
              )}
            </Box>
          )}
        </ResponsiveContext.Consumer>
          )}
        </Translate>

        {this.targetRef.current && (
          <Drop
            align={{ top: 'bottom', left: 'left' }}
            onClickOutside={() => this.setState({ selectedCategory: null })}
            elevation="none"
            target={this.targetRef.current}
          >
            {this.createDropMenu(selectedCategory)}
          </Drop>
        )}
      </Box>
    );
  }
}

export const HeaderWithRouter = withRouter(Header);
