import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Form, Text, TextInput } from 'grommet/es6';
import { Lock, User } from 'grommet-icons';
import { Link } from '../';
import { Translate } from 'react-localize-redux';

export class LoginForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onLostPassword: PropTypes.func,
    loading: PropTypes.bool,
    email: PropTypes.string,
    password: PropTypes.string,
    errors: PropTypes.object
  };

  static defaultProps = {
    loading: false,
    email: '',
    password: '',
    errors: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      email: props.email,
      password: props.password,
      loading: props.loading
    };
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = async event => {
    const result = await this.props.handleSubmit(event);
    if (result) {
      this.setState({ password: '', email: '' });
    }
  };

  onLostPassword = () => {
    if (this.props.onLostPassword) {
      this.props.onLostPassword();
    }
  };

  render() {
    const { email, password } = this.state,
      { loading, errors } = this.props;

    return (
      <Form style={{ width: '100%', flex: 1 }} onSubmit={this.handleSubmit}>
        <Box margin={{ bottom: 'medium' }}>
          <Box
            direction="row"
            align="center"
            border={{ color: errors.email ? 'red' : 'border' }}
          >
            <Box
              border={{ side: 'right', size: '1px', color: 'border' }}
              pad="small"
            >
              <User size="medium" />
            </Box>
            <TextInput
              plain
              name="email"
              type="email"
              placeholder="Email"
              value={email}
              disabled={loading}
              onChange={this.handleUpdate}
            />
          </Box>
          {errors.email && (
            <Text color="status-critical">
              <Translate id="errors.pleaseEnterEmail">{errors.email}</Translate>
            </Text>
          )}
        </Box>

        <Box margin={{ bottom: 'medium' }}>
          <Box
            direction="row"
            align="center"
            border={{ color: errors.password ? 'red' : 'border' }}
          >
            <Box
              border={{ side: 'right', size: '1px', color: 'border' }}
              pad="small"
            >
              <Lock size="medium" />
            </Box>
            <Translate>
              {({ translate }) => (
                <TextInput
                  plain
                  name="password"
                  type="password"
                  disabled={loading}
                  placeholder={translate('login.password')}
                  value={password}
                  onChange={this.handleUpdate}
                />
              )}
            </Translate>
          </Box>
          {errors.password && (
            <Text color="status-critical">
              <Translate id="errors.pleaseEnterPassword">
                {errors.password}
              </Translate>
            </Text>
          )}
        </Box>

        <Box
            justify="between"
            align="center"
            gap="medium"
            margin={{bottom: 'medium'}}
        >
          <Button
              type="submit"
              disabled={loading}
              label={<Translate id="login.login">Login</Translate>}
              style={{width: '100%', textAlign: 'center'}}
              primary
              color="brand"
          />
        </Box>

        <Box
            direction="row-responsive"
            justify="center"
            align="center"
            margin={{ bottom: 'medium' }}
            gap="medium"
        >
          <Link href="#" onClick={this.onLostPassword} color="border">
            {
              <Translate id="login.forgotPassword">
                Forgot your password?
              </Translate>
            }
          </Link>
        </Box>
      </Form>
    );
  }
}
