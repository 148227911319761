import React from 'react';
import { default as Inbox } from './Inbox';
import { default as Support } from './Support';
import Auth from '../../services/auth';
import { gql } from 'apollo-boost';
import { Mutation, Query } from 'react-apollo';
import { MESSAGE_TYPES } from '../../config/site';

const QUERY_USER_MESSAGES = gql`
  query clientBy($userId: String!, $messageType: String!) {
    clientBy(userId: $userId) {
      id
      mt_id
      email
      hasRealAccount
      messages(where: { messageType: $messageType }) {
        nodes {
          id
          subject
          message
          created
          updated
          sender_id
          status
          parentId
        }
      }
      accounts {
        nodes {
          id
          account_type
          account_currency
          user_id
          ask
          cashback
          deposit
          leverage
        }
      }
      first_name
      last_name
    }
  }
`;

const SUBMIT_TICKET_MUTATION = gql`
  mutation submitTicket($input: SubmitTicketInput!) {
    submitTicket(input: $input) {
      id
    }
  }
`;

export const InboxConnected = ({ isMyAccount, ...props }) => {
  const userId = isMyAccount ? Auth.getUser().userId : props.match.params.id;

  return (
    <Query
      query={QUERY_USER_MESSAGES}
      variables={{ userId, messageType: MESSAGE_TYPES.inbox }}
    >
      {({ data, loading, error }) => {
        return (
          <Inbox
            loading={loading}
            currentUserId={userId}
            isMyAccount={isMyAccount}
            updateItem={() => {}}
            notification={
              error && {
                text: error && error.message,
                type: 'critical'
              }
            }
            messages={
              data && data.clientBy && data.clientBy.messages
                ? data.clientBy.messages.nodes
                : []
            }
            {...props}
          />
        );
      }}
    </Query>
  );
};

export const SupportConnected = ({ isMyAccount, ...props }) => {
  const userId = isMyAccount ? Auth.getUser().userId : props.match.params.id;

  return (
    <Query
      query={QUERY_USER_MESSAGES}
      variables={{ userId, messageType: MESSAGE_TYPES.support }}
    >
      {({ data, loading, error }) => {
        return (
          <Mutation
            mutation={SUBMIT_TICKET_MUTATION}
            refetchQueries={[
              {
                query: QUERY_USER_MESSAGES,
                variables: { userId, messageType: MESSAGE_TYPES.support }
              }
            ]}
          >
            {(submitTicket, submitTicketMutationProps) => (
              <Support
                loading={loading || submitTicketMutationProps.loading}
                currentUserId={userId}
                isMyAccount={isMyAccount}
                createItem={async data => {
                  return submitTicket({
                    variables: {
                      input: {
                        ...data,
                        userId: !isMyAccount ? userId : '1',
                        senderId: Auth.getUser().userId,
                        clientMutationId: 'submitTicket' // WPGraphQL requires this
                      }
                    }
                  });
                }}
                notification={
                  (error || submitTicketMutationProps.error) && {
                    text:
                      (error && error.message) ||
                      (submitTicketMutationProps.error &&
                        submitTicketMutationProps.error.message),
                    type: 'critical'
                  }
                }
                messages={
                  data && data.clientBy && data.clientBy.messages
                    ? data.clientBy.messages.nodes
                    : []
                }
                {...props}
              />
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};
