import config from '../config';

// User roles supported by application
export const USER_ROLES = {
  administrator: 'administrator',
  partner: 'partner',
  subscriber: 'subscriber'
};

// Singleton containing in memory auth state and auth related functions
const Auth = (({ storagePath, unauthorizedUrl }) => {
  // Sensitive data. Only stored in memory
  let authState = {
    authToken: null,
    user: null
  };

  /**
   * Retrieves auth token from the state (memory)
   * @return {null|string}
   */
  function getAuthToken() {
    return authState.authToken;
  }

  /**
   * Retrieves sensitive user data from memory. Appends non sensitive data from storage
   * if available
   * @return {null|object}
   */
  function getUser() {
    return authState.user;
  }

  /**
   * Sets auth token to the memory
   * @param token string
   */
  function setAuthToken(token) {
    authState.authToken = token;
  }

  /**
   * Sets user object to the memory
   * @param user object
   */
  function setUser(user) {
    authState.user = user;
  }

  function logout() {
    // Clears application memory
    authState = {
      authToken: null,
      user: null
    };
    // Clears non sensitive persistent storage
    window.localStorage.removeItem(storagePath);
    unauthorizedRedirect();
  }

  /**
   * Handles the auth data storage
   * @param authToken string
   * @param refreshToken string
   * @param user object
   */
  function login(authToken, refreshToken, user = null) {
    setAuthToken(authToken);
    if (user) {
      setUser(user);
    }
  }

  /**
   * Stores non sensitive data such as email or name to the local storage
   * @param data
   */
  function setPersistentNonSensitiveUserData(data) {
    const _data = JSON.parse(window.localStorage.getItem(storagePath));
    window.localStorage.setItem(
      storagePath,
      JSON.stringify({
        ..._data,
        ...data
      })
    );
  }

  function getPersistentNonSensitiveUserData() {
    return JSON.parse(window.localStorage.getItem(storagePath));
  }

  function unauthorizedRedirect() {
    window.location.href = unauthorizedUrl;
  }

  /**
   * Checks if user has any of the roles passed
   * @param user object from Auth
   * @param roles array of role names
   * @return {boolean}
   */
  function roleCheckVerification(roles) {
    const user = authState.user;
    if (user && user.roles) {
      // Any of the user roles are present in the route role check
      return user.roles.filter(value => -1 !== roles.indexOf(value)).length > 0;
    } else {
      // Rules for route specified but user doesnt have any
      return false;
    }
  }

  return {
    getAuthToken,
    getUser,
    getPersistentNonSensitiveUserData,
    setAuthToken,
    setUser,
    setPersistentNonSensitiveUserData,
    logout,
    login,
    roleCheckVerification,
    unauthorizedRedirect
  };
})({
  storagePath: config.read(['auth', 'storageAuthState']),
  unauthorizedUrl: config.read(['auth', 'unauthorizedRedirect'])
});

export default Auth;
