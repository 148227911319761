import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  BackLink,
  Layout,
  Loader,
  Notification,
  ReadOnlyRows
} from '../../components';
import { Anchor, Box, Heading, Text } from 'grommet/es6';
import { routes } from '../../config/routes';
import { useFlash } from '../../hooks';
import { getFilterRenderer, ToolbaredTable } from '../../components/DataTable';
import { SAVE_OPTION } from '../../redux/app';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

function getEndpoint(key) {
  const endpoints = {
    viewPartner: routes
        .find(route => route.name === 'Broker')
        .childs.find(route => route.name === 'Partners')
        .childs.find(route => route.name === 'View').path,
    broker: routes.find(route => route.name === 'Broker').path,
    viewTrader: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'View').path
  };
  return endpoints[key];
}

const MarginCall = props => {
  const hiddenColumns =
    useSelector(
      state => state.app.storage.options.marginCallsTableCols,
      shallowEqual
    ) || props.hiddenColumns;
  const dispatch = useDispatch();
  const [flash, , dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );

  const columns = useMemo(() => {
    return [
      {
        key: 'login',
        name: 'Login',
        width: 150,
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'name',
        name: 'Name',
        width: 150,
        filterRenderer: getFilterRenderer('text'),
        formatter: ({ row }) =>
            (!row.login || !row.user) ? null : (
            <Anchor
              title="Show client"
              target="_blank"
              href={getEndpoint('viewTrader').replace(':id', row.user.userId)}
              color="brand"
              label={<Text weight="bold">{row.user.name}</Text>}
            />
          )
      },
      {
        key: 'referredBy',
        name: 'Referred by',
        filterRenderer: getFilterRenderer('text'),
        formatter: ({ row }) =>
            (!row.user || !row.user.referer) ? null : (
                <Anchor
                    title="Show partner"
                    target="_blank"
                    href={getEndpoint('viewPartner').replace(
                        ':id',
                        row.user.referer.userId
                    )}
                    color="brand"
                    label={<Text weight="bold">{row.user.referer.name}</Text>}
                />
            )
      },
      {
        key: 'balance',
        name: 'Balance'
      },
      {
        key: 'margin',
        name: 'Margin',
        formatter: ({ row }) =>
          row.margin ? row.margin.toFixed(2) + ' %' : null
      }
    ].map(col => {
      return {
        ...col,
        resizable: true,
        sortable: true
      };
    });
  }, []);

  const hideColumns = nextState => {
    dispatch({
      type: SAVE_OPTION,
      key: 'marginCallsTableCols',
      value: nextState
    });
  };

  const { loading } = props;

  return (
    <Layout title="Margin calls" fluid>
      <BackLink link={getEndpoint('broker')} />

      <Box
        direction="row"
        justify="between"
        align="center"
        margin={{ vertical: 'medium' }}
      >
        <Box direction="row" align="center">
          <Heading size="small">Margin calls</Heading>
        </Box>
      </Box>

      {flash.visible && (
        <Notification
          type={flash.type ? flash.type : 'info'}
          onClose={dismissFlash}
          open={flash.visible}
          message={flash.text}
        />
      )}

      <Box gap="small" margin={{ bottom: 'large' }}>
        {loading && (
          <Box direction="row" align="center">
            <Heading size="medium" level="3" margin={{ right: 'small' }}>
              Please wait
            </Heading>
            <Loader />
          </Box>
        )}

        <Box direction="row" gap="small" align="center">
          <div
            style={{ width: '10px', height: '10px', background: '#ff4040' }}
          />
          Read only
        </Box>
      </Box>

      <div style={{ width: `100%`, height: '100vh', position: 'relative' }}>
        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
          <ReadOnlyRows rows={props.rows} active={true}>
            {({ rows, active, tableOption, rowRenderer }) => (
              <ToolbaredTable
                toggleableColumns={columns}
                toolbarProps={{
                  toggleableOptions: [tableOption],
                  activeOptions: active ? [tableOption.name] : []
                }}
                columns={columns}
                rows={rows}
                loading={loading}
                onHideColumn={hideColumns}
                hiddenColumns={hiddenColumns}
                rowRenderer={rowRenderer}
              />
            )}
          </ReadOnlyRows>
        </div>
      </div>
    </Layout>
  );
};

MarginCall.propTypes = {
  rows: PropTypes.array,
  //hiddenColumns: PropTypes.array,
  loading: PropTypes.bool,
  filtersActive: PropTypes.bool,
  notification: PropTypes.object
};

MarginCall.defaultProps = {
  rows: [],
  // hiddenColumns: [],
  loading: false,
  notification: {
    text: '',
    type: 'info'
  }
};

export default MarginCall;
