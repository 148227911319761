import React from 'react';
import TextFilter from './TextFilter';

const RuleType = {
  Number: 1,
  Range: 2,
  GreaterThan: 3,
  LessThan: 4
};

const NumericFilter = props => {
  return <TextFilter {...props} />;
};

NumericFilter.propTypes = TextFilter.propTypes;

NumericFilter.defaultProps = {
  ...TextFilter.defaultProps,
  placeholder: 'Range x - y, > x, < x',
  filterValues,
  getRules
};

function filterValues(row, columnFilter, columnKey) {
  if (columnFilter.filterTerm == null) {
    return true;
  }

  // implement default filter logic
  const value = parseInt(row[columnKey], 10);
  for (const ruleKey in columnFilter.filterTerm) {
    const rule = columnFilter.filterTerm[ruleKey];

    switch (rule.type) {
      case RuleType.Number:
        if (rule.value === value) {
          return true;
        }
        break;
      case RuleType.GreaterThan:
        if (rule.value <= value) {
          return true;
        }
        break;
      case RuleType.LessThan:
        if (rule.value >= value) {
          return true;
        }
        break;
      case RuleType.Range:
        if (rule.begin <= value && rule.end >= value) {
          return true;
        }
        break;
      default:
        break;
    }
  }

  return false;
}

function getRules(value) {
  if (value === '') {
    return [];
  }

  // handle each value with comma
  return value.split(',').map(str => {
    // handle dash
    const dashIdx = str.indexOf('-');
    if (dashIdx > 0) {
      const begin = parseInt(str.slice(0, dashIdx), 10);
      const end = parseInt(str.slice(dashIdx + 1), 10);
      return { type: RuleType.Range, begin, end };
    }

    // handle greater then
    if (str.includes('>')) {
      const begin = parseInt(str.slice(str.indexOf('>') + 1), 10);
      return { type: RuleType.GreaterThan, value: begin };
    }

    // handle less then
    if (str.includes('<')) {
      const end = parseInt(str.slice(str.indexOf('<') + 1), 10);
      return { type: RuleType.LessThan, value: end };
    }

    // handle normal values
    const numericValue = parseInt(str, 10);
    return { type: RuleType.Number, value: numericValue };
  });
}

export default NumericFilter;
