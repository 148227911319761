import React from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import Auth from '../../services/auth';
import { default as BankAccounts } from './BankAccounts';
import { AddBankAccount } from './AddBankAccount';

const FETCH_MY_ACCOUNTS_QUERY = gql`
  query clientBy($userId: String!) {
    clientBy(userId: $userId) {
      id
      bankAccounts {
        nodes {
          id
          account_id
          account_number
          bank_name
          iban
          owner_name
          swift
          currency
        }
      }
    }
    companySettings {
      id
      default_bank_account_CZK {
        id
        account_id
        account_number
        bank_name
      }
      default_bank_account_EUR {
        id
        account_id
        account_number
        bank_name
      }
      default_bank_account_USD {
        id
        account_id
        account_number
        bank_name
      }
      user_id
      id
    }
  }
`;

const SET_ACCOUNT_DEFAULT_MUTATION = gql`
  mutation updateUserSettings($input: UpdateUserSettingsInput!) {
    updateUserSettings(input: $input) {
      id
    }
  }
`;

const FETCH_BANK_ACCOUNT_QUERY = gql`
  query bankAccount($id: ID!) {
    bankAccount(id: $id) {
      id
      account_id
      bank_name
      currency
      iban
      owner_name
      account_number
      swift
      user_id
    }
  }
`;

const DELETE_BANK_ACCOUNT_MUTATION = gql`
  mutation deleteBankAccount($input: DeleteBankAccountInput!) {
    deleteBankAccount(input: $input) {
      deletedId
    }
  }
`;

const CREATE_BANK_ACCOUNT_MUTATION = gql`
  mutation createBankAccount($input: CreateBankAccountInput!) {
    createBankAccount(input: $input) {
      id
    }
  }
`;

const UPDATE_BANK_ACCOUNT_MUTATION = gql`
  mutation updateBankAccount($input: UpdateBankAccountInput!) {
    updateBankAccount(input: $input) {
      id
    }
  }
`;

const parseBankAccounts = function(data) {
  if (data.clientBy && data.clientBy.bankAccounts) {
    const accounts = data.clientBy.bankAccounts.nodes;

    // Pair bank accounts with default settings
    if (data.companySettings) {
      Object.keys(data.companySettings).forEach(key => {
        const index = accounts.findIndex(
          account =>
            account.account_id === data.companySettings[key]['account_id']
        );

        if (index >= 0) {
          accounts[index][key] = true;
        }
      });
    }
    return accounts;
  }
  return [];
};

export const BankAccountsConnected = props => (
  <Query
    query={FETCH_MY_ACCOUNTS_QUERY}
    variables={{ userId: Auth.getUser().userId }}
  >
    {({ loading, error, data }) => (
      <Mutation
        mutation={DELETE_BANK_ACCOUNT_MUTATION}
        refetchQueries={[
          {
            query: FETCH_MY_ACCOUNTS_QUERY,
            variables: { userId: Auth.getUser().userId }
          }
        ]}
      >
        {(deleteBankAccount, mutationProps) => (
          <BankAccounts
            bankAccounts={data && parseBankAccounts(data)}
            setAccountDefault={async (accountId, currency) =>
              mutationProps.client.mutate({
                mutation: SET_ACCOUNT_DEFAULT_MUTATION,
                variables: {
                  input: {
                    clientMutationId: 'setAccountDefault',
                    userId: Auth.getUser().id,
                    key: `default_bank_account_${currency}`,
                    value: accountId
                  }
                },
                refetchQueries: [
                  {
                    query: FETCH_MY_ACCOUNTS_QUERY,
                    variables: { userId: Auth.getUser().userId }
                  }
                ]
              })
            }
            removeBankAccount={async id =>
              deleteBankAccount({
                variables: {
                  input: {
                    id,
                    clientMutationId: 'removeBankAccount'
                  }
                }
              })
            }
            loading={loading || mutationProps.loading}
            notification={
              (error || mutationProps.error) && {
                text: error.message || mutationProps.error.message,
                type: 'critical'
              }
            }
            {...props}
          />
        )}
      </Mutation>
    )}
  </Query>
);

export const AddBankAccountConnected = props => (
  <Mutation
    mutation={CREATE_BANK_ACCOUNT_MUTATION}
    refetchQueries={[
      {
        query: FETCH_MY_ACCOUNTS_QUERY,
        variables: { userId: Auth.getUser().userId }
      }
    ]}
  >
    {(createBankAccount, { loading, error, data }) => {
      return (
        <AddBankAccount
          loading={loading}
          notification={
            error && {
              text: error.message,
              type: 'critical'
            }
          }
          {...props}
          create={async data =>
            createBankAccount({
              variables: {
                input: {
                  ...data,
                  clientMutationId: 'createBankAccount' // WPGraphQL requires this
                }
              }
            })
          }
        />
      );
    }}
  </Mutation>
);

export const ViewBankAccountConnected = props => (
  // Id passed in route, FETCH data to edit
  <Query
    query={FETCH_BANK_ACCOUNT_QUERY}
    variables={{ id: props.match.params.id }}
  >
    {({ loading, error, data }) => {
      return (
        <Mutation
          mutation={UPDATE_BANK_ACCOUNT_MUTATION}
          refetchQueries={[
            {
              query: FETCH_MY_ACCOUNTS_QUERY,
              variables: { userId: Auth.getUser().userId }
            }
          ]}
        >
          {(updateBankAccount, mutationProps) => {
            return (
              <AddBankAccount
                loading={mutationProps.loading || loading}
                notification={
                  (error || mutationProps.error) && {
                    text:
                      (error && error.message) ||
                      (mutationProps.error && mutationProps.error.message),
                    type: 'critical'
                  }
                }
                data={data && data.bankAccount}
                {...props}
                update={async data =>
                  updateBankAccount({
                    variables: {
                      input: {
                        ...data,
                        clientMutationId: 'updateBankAccount' // WPGraphQL requires this
                      }
                    }
                  })
                }
              />
            );
          }}
        </Mutation>
      );
    }}
  </Query>
);
