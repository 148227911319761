import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataTable, Toolbar, useTable } from './';
import { AutoSizer } from 'react-virtualized';

const TOOLBAR_HEIGHT = 50;
const HEADER_ROW_HEIGHT = 33;
const HEADER_FILTERS_HEIGHT = 33;

const ToolbaredTable = props => {
  const {
    rows,
    setRows,
    columns,
    enableFilters,
    filters,
    setFilters,
    setEnableFilters,
    hiddenColumns,
    sortDirection,
    sortColumn,
    setSortColumn,
    setSortDirection
  } = useTable(props);

  const [stats, setStats] = useState({
    total: rows.length,
    showing: rows.length
  });

  useEffect(() => {
    if (!enableFilters) {
      onClearFilters();
    }
  }, [enableFilters]);

  function onToggleFilters() {
    if (props.onToggleFilters) {
      props.onToggleFilters(!enableFilters);
    }
    setEnableFilters(!enableFilters);
  }

  function onSort(columnKey, sortDirection) {
    setSortColumn(columnKey);
    setSortDirection(sortDirection);
    if (props.onSort) {
      props.onSort(columnKey, sortDirection);
    }
  }

  function onHideColumn(hiddenColumns) {
    if (props.onHideColumn) {
      props.onHideColumn(hiddenColumns);
    }
  }

  function onRowsDisplay(r) {
    setStats({ total: rows.length, showing: r.length });
    if (props.onRowsDisplay) {
      props.onRowsDisplay(r);
    }
  }

  function onRowsUpdate({ fromRow, toRow, updated }) {
    if (props.onRowsUpdate) {
      setRows(props.onRowsUpdate({ fromRow, toRow, updated }));
    }
  }

  function onClearFilters() {
    // Assign empty string to every filter key
    const emptyFilters = Object.fromEntries(
      Object.keys(props.filters).map(key => [key, ''])
    );

    if (props.onClearFilters) {
      props.onClearFilters();
    }

    if (props.onFiltersChange) {
      props.onFiltersChange(emptyFilters);
    }
    setFilters(emptyFilters);
  }

  const {
    toolbarProps,
    toggleableColumns,
    selectedRows,
    onBatchAction,
    batchActions,
    ...rest
  } = props;

  return (
    <>
      <Toolbar
        batchActions={batchActions}
        height={`${TOOLBAR_HEIGHT}px`}
        onBatchAction={onBatchAction}
        selectedRows={selectedRows}
        enableFilters={enableFilters}
        onToggleFilters={onToggleFilters}
        toggleableColumns={toggleableColumns}
        hiddenColumns={hiddenColumns}
        onHideColumn={onHideColumn}
        onClearFilters={onClearFilters}
        totalRows={stats.total}
        showingRows={stats.showing}
        {...toolbarProps}
      />
      <AutoSizer>
        {({ height, width }) => (
          <DataTable
            width={width}
            height={height - TOOLBAR_HEIGHT}
            headerRowHeight={HEADER_ROW_HEIGHT}
            headerFiltersHeight={HEADER_FILTERS_HEIGHT}
            selectedRows={selectedRows}
            {...rest}
            rows={rows}
            columns={columns}
            filters={filters}
            onSort={onSort}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onFiltersChange={setFilters}
            enableFilters={enableFilters}
            onRowsDisplay={onRowsDisplay}
            onRowsUpdate={onRowsUpdate}
          />
        )}
      </AutoSizer>
    </>
  );
};

ToolbaredTable.propTypes = {
  ...DataTable.propTypes,
  width: PropTypes.number,
  height: PropTypes.number,
  batchActions: PropTypes.array,
  onBatchAction: PropTypes.func,
  toggleableColumns: PropTypes.array,
  hiddenColumns: PropTypes.array,
  onToggleFilters: PropTypes.func,
  onClearFilters: PropTypes.func,
  onHideColumn: PropTypes.func,
  toolbarProps: PropTypes.shape(Toolbar.propTypes)
};

ToolbaredTable.defaultProps = {
  ...DataTable.defaultProps,
  hiddenColumns: [],
  columns: []
};

export default ToolbaredTable;
