import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Text, Layer } from 'grommet';
import {
  StatusGood,
  StatusCritical,
  StatusInfo,
  FormClose
} from 'grommet-icons';

export class Notification extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    children: PropTypes.any,
    message: PropTypes.string,
    type: PropTypes.oneOf(['ok', 'critical', 'info']),
    position: PropTypes.oneOf([
      'bottom',
      'bottom-left',
      'bottom-right',
      'center',
      'hidden',
      'left',
      'right',
      'top',
      'top-left',
      'top-right'
    ]),
    responsive: PropTypes.bool
  };

  static defaultProps = {
    type: 'info',
    message: '',
    position: 'top',
    responsive: false
  };

  constructor(props) {
    super(props);

    this.icon = this.getIcon();

    this.state = {
      open: props.open
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.open !== state.open) {
      return {
        open: props.open
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.open && !prevState.open) {
      this.onOpen();
    } else if (!this.state.open && prevState.open) {
      this.onClose();
    }
  }

  onOpen = () => {
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  getIcon() {
    const types = {
      ok: <StatusGood color="white" />,
      info: <StatusInfo color="white" />,
      critical: <StatusCritical color="white" />
    };

    if (Object.keys(types).includes(this.props.type)) {
      return types[this.props.type];
    }
    return types.info;
  }

  render() {
    const { open } = this.state,
      { type, message, children, position, responsive } = this.props;

    return (
      open && (
        <Layer
          position={position}
          modal={false}
          responsive={responsive}
          onEsc={this.onClose}
        >
          <Box align="start" pad={{ vertical: 'medium', horizontal: 'small' }}>
            <Box
              align="center"
              direction="row"
              gap="small"
              round="medium"
              elevation="xsmall"
              pad={{ vertical: 'xsmall', horizontal: 'small' }}
              background={`status-${type}`}
            >
              <Box align="center" direction="row" gap="xsmall">
                {this.icon}
                {children ? (
                  children
                ) : (
                  <Text weight="bold" color="white">
                    {message}
                  </Text>
                )}
              </Box>
              <Button
                icon={<FormClose color="white" />}
                color="white"
                onClick={this.onClose}
                plain
              />
            </Box>
          </Box>
        </Layer>
      )
    );
  }
}
