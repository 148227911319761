import { Link } from './';
import { Box, Text } from 'grommet/es6';
import React from 'react';
import { useResponsive } from '../../hooks';
import {Anchor} from "grommet";

export const LinkTile = ({
  href,
  label,
  icon,
  children,
  linkProps = {},
  ...props
}) => {
  const { setValues } = useResponsive();

  let LinkComponent = Link;
  if (linkProps.anchor) {
      LinkComponent = Anchor;
  }

  return (
    <LinkComponent href={href ? href : '#'} {...linkProps}>
      <Box
        className="link_tile"
        align="center"
        pad="medium"
        margin={{
          bottom: setValues({ medium: 'small', small: 'medium' }),
          horizontal: setValues({ medium: '4px', small: 'none' })
        }}
        justify="center"
        background="light-3"
        elevation="small"
        width="small"
        border={{ color: 'light-3' }}
        {...props}
      >
        {icon && icon}
        {children && children}
        <Text weight="bold">{label}</Text>
      </Box>
    </LinkComponent>
  );
};
