import React from 'react';
import PropTypes from 'prop-types';
import { Layout, LinkTile, Modal } from '../../components';
import {
  Box,
  Button,
  Heading,
  Image,
  ResponsiveContext,
  Stack,
  Text
} from 'grommet';
import {
  Atm,
  CreditCard,
  Currency,
  DocumentDownload,
  Local,
  User,
  UserManager
} from 'grommet-icons';
import { routes } from '../../config/routes';
import spinner from '../../images/spinner.gif';

export class BrokerDashboard extends React.Component {
  static propTypes = {
    statistics: PropTypes.object,
    pendingWithdrawals: PropTypes.number,
    loading: PropTypes.bool
  };

  static defaultProps = {
    statistics: {},
    pendingWithdrawals: 0,
    loading: false
  };

  constructor(props) {
    super(props);

    this.state = {
      modal: {}
    };

    this.triggerModal = this.triggerModal.bind(this);

    // TODO: REFACTOR ROUTE GETTING
    this.tradersEndpoint = routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders').path;
    this.partnersEndpoint = routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Partners').path;
    this.withdrawalsEndpoint = routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Withdrawals').path;
    this.bankAccountsEndpoint = routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Bank accounts').path;
    this.translationsEndpoint = routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Translations').path;
    this.exportsEndpoint = routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Exports').path;
    this.depositsEndpoint = routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Deposits').path;
    this.openedTradesEndpoint = routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'All opened trades').path;
    this.marginCallEndpoint = routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Margin call').path;
    this.tradingAccountsEndpoint = routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Trading accounts').path;
  }

  triggerModal(name, state = null) {
    if (name instanceof Event) name = name.target.name;

    this.setState({
      modal: { [name]: state === null ? !this.state.modal[name] : state }
    });
  }

  render() {
    const { modal } = this.state,
      { statistics, loading } = this.props;

    return (
      <Layout title="Broker's dashboard" fluid>
        <ResponsiveContext.Consumer>
          {size => {
            return (
              <Box direction="row-responsive" gap="large" height="full">
                {/* Sidebar area */}
                <Box
                  width={size === 'small' ? 'full' : 'medium'}
                  height={size === 'small' ? 'auto' : 'full'}
                  align="center"
                >
                  <Box
                    pad="medium"
                    width="full"
                    align="center"
                    border={{ color: 'light-4' }}
                    gap="medium"
                    background="white"
                  >
                    <LinkTile
                      href={this.openedTradesEndpoint}
                      margin={undefined}
                      title="All Open Trades"
                      linkProps={{ style: { width: '100%' } }}
                      label="All Open Trades"
                      width={'full'}
                    />

                    <LinkTile
                      href={this.marginCallEndpoint}
                      title="Margin Call"
                      margin={undefined}
                      linkProps={{ style: { width: '100%' } }}
                      label="Margin Call"
                      width={'full'}
                    />

                    <LinkTile
                      href={'#'}
                      title="Send Email"
                      margin={undefined}
                      linkProps={{ style: { width: '100%' } }}
                      label="Send Email"
                      width={'full'}
                    />
                  </Box>
                </Box>
                {/* Sidebar area end */}

                {/* Content area */}
                <Box width="full" gap="large">
                  <Heading level="1">CRM Broker</Heading>

                  <Box height="full">
                    {/* Traders management section */}
                    <Box>
                      <Stack anchor="top-left" margin={{ bottom: 'large' }}>
                        <Box
                          border={{ color: 'light-4' }}
                          pad="medium"
                          direction="row-responsive"
                          wrap={true}
                          align="center"
                        >
                          <LinkTile
                            href={this.tradersEndpoint}
                            title="Manage traders"
                            label="Traders"
                            icon={
                              statistics.newUsersThisMonth ? (
                                <Text
                                  weight="bold"
                                  size="large"
                                  color="status-ok"
                                >
                                  {statistics.newUsersThisMonth}
                                </Text>
                              ) : (
                                <User />
                              )
                            }
                            width={size === 'small' ? 'full' : 'small'}
                          />

                          <LinkTile
                            href={this.partnersEndpoint}
                            title="Manage partners"
                            label="Partners"
                            icon={
                              statistics.activePartners ? (
                                <Text
                                  weight="bold"
                                  size="large"
                                  color="status-ok"
                                >
                                  {statistics.activePartners}
                                </Text>
                              ) : (
                                <UserManager />
                              )
                            }
                            width={size === 'small' ? 'full' : 'small'}
                          />

                          <LinkTile
                            href={this.depositsEndpoint}
                            title="Manage deposits / withdrawals"
                            label="Deposits/Withdrawals"
                            icon={
                              statistics.depositAmountThisMonth ? (
                                <Text
                                  weight="bold"
                                  size="large"
                                  color="status-ok"
                                >
                                  {statistics.depositAmountThisMonth}
                                </Text>
                              ) : (
                                <CreditCard />
                              )
                            }
                            width={size === 'small' ? 'full' : 'small'}
                          />

                          <LinkTile
                            href={this.withdrawalsEndpoint}
                            title="Manage withdrawals"
                            label="Withdrawals"
                            icon={
                              this.props.pendingWithdrawals ? (
                                <Text
                                  weight="bold"
                                  size="large"
                                  color="status-critical"
                                >
                                  {this.props.pendingWithdrawals}
                                </Text>
                              ) : (
                                <Atm />
                              )
                            }
                            width={size === 'small' ? 'full' : 'small'}
                          />
                        </Box>

                        <Box
                          background="body"
                          margin={{ left: 'small', top: '-10px' }}
                          pad={{ horizontal: 'small' }}
                        >
                          <Text weight="bold" color="border">
                            Traders management
                          </Text>
                        </Box>
                      </Stack>
                    </Box>

                    {/* Overview */}
                    <Box>
                      <Stack anchor="top-left" margin={{ bottom: 'large' }}>
                        <Box
                          border={{ color: 'light-4' }}
                          pad="medium"
                          direction="row-responsive"
                          wrap={true}
                          align="center"
                        >
                          <LinkTile
                            href={`${this.tradersEndpoint}?type=live`}
                            title="Live accounts opened"
                            label="Live Accounts"
                            justify="between"
                            width={size === 'small' ? 'full' : 'small'}
                          >
                            <Text weight="bold" size="large" color="status-ok">
                              {loading ? (
                                <Image src={spinner} width="20px" />
                              ) : statistics ? (
                                statistics.activeLiveAccounts
                              ) : (
                                ''
                              )}
                            </Text>
                          </LinkTile>

                          <LinkTile
                            href={`${this.tradersEndpoint}?type=demo`}
                            label="Demo Accounts"
                            title="Demo accounts opened"
                            justify="between"
                            width={size === 'small' ? 'full' : 'small'}
                          >
                            <Text weight="bold" size="large" color="status-ok">
                              {loading ? (
                                <Image src={spinner} width="20px" />
                              ) : statistics ? (
                                statistics.activeDemoAccounts
                              ) : (
                                ''
                              )}
                            </Text>
                          </LinkTile>

                          <LinkTile
                            href={`${this.tradingAccountsEndpoint}`}
                            label="Accounts"
                            title="Accounts"
                            justify="between"
                            width={size === 'small' ? 'full' : 'small'}
                          >
                            <Text weight="bold" size="large" color="status-ok">
                              {loading ? (
                                <Image src={spinner} width="20px" />
                              ) : statistics ? (
                                statistics.readOnlyAccounts
                              ) : (
                                ''
                              )}
                            </Text>
                          </LinkTile>
                        </Box>

                        <Box
                          background="body"
                          margin={{ left: 'small', top: '-10px' }}
                          pad={{ horizontal: 'small' }}
                        >
                          <Text weight="bold" color="border">
                            Overview
                          </Text>
                        </Box>
                      </Stack>
                    </Box>

                    {/* Finance management  */}
                    <Box>
                      <Stack anchor="top-left" margin={{ bottom: 'large' }}>
                        <Box
                          border={{ color: 'light-4' }}
                          pad="medium"
                          direction="row-responsive"
                          wrap={true}
                          align="center"
                        >
                          <LinkTile
                            href={this.bankAccountsEndpoint}
                            title="Manage bank accounts"
                            label="Bank Accounts"
                            icon={<Currency />}
                            width={size === 'small' ? 'full' : 'small'}
                          />

                          <LinkTile
                            href={this.translationsEndpoint}
                            label="Translations"
                            icon={<Local />}
                            width={size === 'small' ? 'full' : 'small'}
                          />

                          <LinkTile
                            href={this.exportsEndpoint}
                            label="Exports"
                            icon={<DocumentDownload />}
                            width={size === 'small' ? 'full' : 'small'}
                          />
                        </Box>

                        <Box
                          background="body"
                          margin={{ left: 'small', top: '-10px' }}
                          pad={{ horizontal: 'small' }}
                        >
                          <Text weight="bold" color="border">
                            Settings
                          </Text>
                        </Box>
                      </Stack>
                    </Box>
                  </Box>

                  {/* Content area end */}
                </Box>
              </Box>
            );
          }}
        </ResponsiveContext.Consumer>

        <Modal
          name="please-hold"
          open={modal['please-hold']}
          heading={'Please hold tight!'}
          onClose={this.triggerModal}
          onClickOutside={this.triggerModal}
        >
          <Text>
            We're doing everything we can to make this feature working very
            soon.
          </Text>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="center"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            <Button
              label={
                <Text color="white">
                  <strong>Okay</strong>
                </Text>
              }
              onClick={this.triggerModal}
              primary
              color="status-ok"
            />
          </Box>
        </Modal>
      </Layout>
    );
  }
}
