import React from 'react';
import PropTypes from 'prop-types';
import { Anchor, Box, Image } from 'grommet/es6';
import trading_academy_logo from '../images/logo.png';
import akosolutions_logo from '../images/akosolutions-logo.svg';
import eduonlinetrader_logo from '../images/eduoonlinetrader-logo.png';
import {getBrand} from "../services/translations";
import {AKOSOLUTIONS_BRAND, EDU_ONLINE_TRADER_BRAND} from "../config/site";

export const CompanyLogo = ({ src, href, width, alt, height, ...props }) => {
  return null;

  const brand = getBrand();

  let logoSrc = eduonlinetrader_logo;
  let hrefLink = 'https://tradingacademy.club';
  let altText = 'Trading Academy';

  if (brand === '' || brand === null || brand === undefined) {
    return null;
  }

  if (brand === AKOSOLUTIONS_BRAND) {
    logoSrc = akosolutions_logo;
    hrefLink = 'https://akosolutions.tradingacademy.club';
    altText = 'Ako Solutions';
  }
  else if (brand === EDU_ONLINE_TRADER_BRAND) {
    logoSrc = eduonlinetrader_logo;
    hrefLink = 'https://eduonlinetrader.com/';
    altText = 'Edu Online Trader';
  }

  return (
      <Box width={width} height={height}>
        {href ? (
            <Anchor href={hrefLink} target="_blank" rel="nofollow">
              <Image src={logoSrc} width="100%" alt={altText} {...props} />
            </Anchor>
        ) : (
            <Image src={logoSrc} alt={altText} width="100%" {...props} />
        )}
      </Box>
  )
};

CompanyLogo.propTypes = {
  src: PropTypes.string,
  href: PropTypes.string,
  width: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.string
};

CompanyLogo.defaultProps = {
  src: trading_academy_logo,
  width: '150px',
  height: 'auto',
  alt: 'Trading Academy'
};
