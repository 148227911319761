import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Form,
  TextInput,
  Text,
  FormField,
  Stack,
  Select,
  MaskedInput
} from 'grommet';
import { Translate } from 'react-localize-redux';
import { ACCOUNT_TYPES } from '../../config/site';

export class OpenRealForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    newRegistration: PropTypes.bool,
    errors: PropTypes.object,
    loading: PropTypes.bool,
    formData: PropTypes.object
  };

  static defaultProps = {
    loading: false,
    newRegistration: true,
    errors: {},
    formData: {
      cashback: 'No'
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      formData: props.formData
    };
  }

  handleUpdate = event => {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: this.parseInput(event.target)
      }
    });
  };

  parseInput(target) {
    if (!target) {
      return false;
    }

    switch (target.type) {
      case 'checkbox':
        return target.checked;
      default:
        return target.value;
    }
  }

  handleSubmit = async event => {
    const result = await this.props.handleSubmit(event);
    if (result) {
      this.setState({ formData: { cashback: 'No' } });
    }
  };

  render() {
    const {
        email,
        password,
        password_repeat,
        leverage,
        account_type,
        account_currency,
        ask,
        deposit,
        cashback,
        first_name,
        phone,
        last_name,
        born,
        income,
        experience,
        country,
        zip,
        address,
        city
      } = this.state.formData,
      { errors, loading, newRegistration } = this.props;

    const regOptTrKey = 'registration.options';

    return (
      <Translate>
        {({ translate }) => (
          <Form
            style={{ width: '100%', flex: 1 }}
            name="real"
            className="signUpForm"
            onSubmit={this.handleSubmit}
          >
            <Stack anchor="top-left" margin={{ bottom: 'large' }}>
              <Box border={{ color: 'black' }} pad="small">
                {newRegistration && (
                  <Box
                    direction="row-responsive"
                    gap="medium"
                    margin={{ bottom: 'small' }}
                    align="center"
                  >
                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="registration.labels.firstName">
                          First name
                        </Translate>
                      }
                      htmlFor="first-name"
                      error={
                        errors['first_name'] &&
                        translate('errors.requiredField')
                      }
                    >
                      <TextInput
                        id="first-name"
                        name="first_name"
                        type="text"
                        value={first_name ? first_name : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.firstName'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>
                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="registration.labels.lastName">
                          Last name
                        </Translate>
                      }
                      htmlFor="last-name"
                      error={
                        errors['last_name'] && translate('errors.requiredField')
                      }
                    >
                      <TextInput
                        id="last-name"
                        name="last_name"
                        type="text"
                        value={last_name ? last_name : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.lastName'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>

                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="registration.labels.dateOfBirth">
                          Date of birth
                        </Translate>
                      }
                      htmlFor="date-of-birth"
                      error={
                        errors['born'] && translate('errors.requiredField')
                      }
                    >
                      <MaskedInput
                        id="date-of-birth"
                        name="born"
                        mask={[
                          {
                            length: [1, 2],
                            regexp: /^1[0-9]?$|^2[0-9]?$|^3[0-1]?$|^0[1-9]?$/,
                            placeholder: 'DD'
                          },
                          { fixed: '/' },
                          {
                            length: [1, 2],
                            regexp: /^1[0-2]?$|^0[1-9]?$/,
                            placeholder: 'MM'
                          },
                          { fixed: '/' },
                          {
                            length: [4],
                            regexp: /^\d{4}?$|^\d{3}?$|^\d{2}?$|^\d{1}?$/,
                            placeholder: translate(
                              'registration.placeholders.dateOfBirthYear'
                            )
                          }
                        ]}
                        value={born ? born : ''}
                        disabled={loading}
                        onChange={option =>
                          this.handleUpdate({
                            target: { name: 'born', value: option.target.value }
                          })
                        }
                      />
                    </FormField>
                  </Box>
                )}

                <Box
                  direction="row-responsive"
                  gap="medium"
                  margin={{ bottom: 'small' }}
                  align="center"
                >
                  {newRegistration && (
                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="registration.labels.phone">
                          Phone
                        </Translate>
                      }
                      htmlFor="phone"
                      error={
                        errors['phone'] && translate('errors.requiredField')
                      }
                    >
                      <TextInput
                        id="phone"
                        name="phone"
                        type="tel"
                        value={phone ? phone : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.phone'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>
                  )}

                  {/*
                  <FormField style={{flex: 1}}
                             label={<Translate id="registration.labels.education">Education</Translate>}
                             htmlFor="education"
                             error={errors['education'] && translate("errors.requiredField")}>

                    <Select
                      id="education"
                      name="education"
                      plain
                      placeholder={translate("registration.placeholders.education")}
                      options={[translate(regOptTrKey + ".education.highSchool"), translate(regOptTrKey + ".education.bachelorsDegree"), translate(regOptTrKey + ".education.mastersDegree"), translate(regOptTrKey + ".education.doctorate"), translate("global.other")]}
                      disabled={loading}
                      value={education ? education : ""}
                      onChange={({option}) => {
                        this.handleUpdate({target: {name: "education", value: option}})
                      }}
                    />
                  </FormField>
                  */}

                  <FormField
                    style={{ flex: 1 }}
                    label={
                      <Translate id="registration.labels.tradingExperience">
                        Trading experience
                      </Translate>
                    }
                    htmlFor="experience"
                    error={
                      errors['experience'] && translate('errors.requiredField')
                    }
                  >
                    <Select
                      id="experience"
                      name="experience"
                      plain
                      placeholder={translate(
                        'registration.placeholders.tradingExperience'
                      )}
                      options={[
                        translate('global.none'),
                        translate(
                          regOptTrKey + '.tradingExperience.upToOneYear'
                        ),
                        translate(
                          regOptTrKey + '.tradingExperience.oneToThreeYears'
                        ),
                        translate(
                          regOptTrKey + '.tradingExperience.threeToFiveYears'
                        ),
                        translate(
                          regOptTrKey + '.tradingExperience.fiveToTenYears'
                        ),
                        translate(
                          regOptTrKey + '.tradingExperience.moreThanTenYears'
                        )
                      ]}
                      disabled={loading}
                      value={experience ? experience : ''}
                      onChange={({ option }) => {
                        this.handleUpdate({
                          target: { name: 'experience', value: option }
                        });
                      }}
                    />
                  </FormField>

                  <FormField
                    style={{ flex: 1 }}
                    label={
                      <Translate id="registration.labels.incomeSource">
                        Income source
                      </Translate>
                    }
                    htmlFor="income"
                    error={
                      errors['income'] && translate('errors.requiredField')
                    }
                  >
                    <Select
                      id="income"
                      name="income"
                      plain
                      placeholder={translate(
                        'registration.placeholders.incomeSource'
                      )}
                      options={[
                        translate(regOptTrKey + '.incomeSource.employeeSalary'),
                        translate(regOptTrKey + '.incomeSource.selfEmployed'),
                        translate(
                          regOptTrKey + '.incomeSource.investmentsDividends'
                        ),
                        translate(regOptTrKey + '.incomeSource.pension'),
                        translate(regOptTrKey + '.incomeSource.savings'),
                        translate('global.other')
                      ]}
                      disabled={loading}
                      value={income ? income : ''}
                      onChange={({ option }) => {
                        this.handleUpdate({
                          target: { name: 'income', value: option }
                        });
                      }}
                    />
                  </FormField>
                </Box>
              </Box>
              <Box
                background="white"
                margin={{ left: 'small', top: '-10px' }}
                pad={{ horizontal: 'small' }}
              >
                <Text weight="bold">
                  <Translate id="global.personalInformation">
                    Personal information
                  </Translate>
                </Text>
              </Box>
            </Stack>

            <Stack anchor="top-left" margin={{ bottom: 'large' }}>
              <Box border={{ color: 'black' }} pad="small">
                {newRegistration && (
                  <Box
                    direction="row-responsive"
                    gap="medium"
                    margin={{ bottom: 'small' }}
                    align="center"
                  >
                    <FormField
                      style={{ flex: 1 }}
                      label="Email"
                      htmlFor="email"
                      error={
                        errors['email'] && translate('errors.requiredField')
                      }
                    >
                      <TextInput
                        id="email"
                        name="email"
                        type="email"
                        value={email ? email : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.email'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>
                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="login.password">Password</Translate>
                      }
                      htmlFor="password"
                      error={
                        errors['password'] &&
                        translate(`errors.${errors.password}`)
                      }
                    >
                      <TextInput
                        id="password"
                        name="password"
                        type="password"
                        value={password ? password : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.password'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>
                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="registration.labels.passwordRepeat">
                          Confirm password
                        </Translate>
                      }
                      htmlFor="password_repeat"
                      error={
                        errors['password_repeat'] &&
                        translate(`errors.${errors.password_repeat}`)
                      }
                    >
                      <TextInput
                        id="password_repeat"
                        name="password_repeat"
                        type="password"
                        value={password_repeat ? password_repeat : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.passwordRepeat'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>
                  </Box>
                )}

                <Box
                  direction="row-responsive"
                  gap="medium"
                  margin={{ bottom: 'small' }}
                  align="center"
                >
                  <FormField
                    style={{ flex: 1 }}
                    label={
                      <Translate id="registration.labels.accountType">
                        Account type
                      </Translate>
                    }
                    htmlFor="account_type"
                    error={
                      errors['account_type'] &&
                      translate('errors.requiredField')
                    }
                  >
                    <Select
                      id="account_type"
                      name="account_type"
                      plain
                      placeholder={translate(
                        'registration.placeholders.accountType'
                      )}
                      options={ACCOUNT_TYPES.live}
                      disabled={loading}
                      value={account_type ? account_type : ''}
                      onChange={({ option }) => {
                        this.handleUpdate({
                          target: { name: 'account_type', value: option }
                        });
                      }}
                    />
                  </FormField>
                  <FormField
                    style={{ flex: 1 }}
                    label={
                      <Translate id="registration.labels.accountCurrency">
                        Account currency
                      </Translate>
                    }
                    htmlFor="account_currency"
                    error={
                      errors['account_currency'] &&
                      translate('errors.requiredField')
                    }
                  >
                    <Select
                      id="account_currency"
                      name="account_currency"
                      plain
                      placeholder={translate(
                        'registration.placeholders.accountCurrency'
                      )}
                      options={['EUR', 'USD']}
                      disabled={loading}
                      value={account_currency ? account_currency : ''}
                      onChange={({ option }) => {
                        this.handleUpdate({
                          target: { name: 'account_currency', value: option }
                        });
                      }}
                    />
                  </FormField>
                  <FormField
                    style={{ flex: 1 }}
                    label={
                      <Translate id="registration.labels.askingCredit">
                        Asking credit
                      </Translate>
                    }
                    htmlFor="ask"
                    error={errors['ask'] && translate('errors.requiredField')}
                  >
                    <Box direction="row" align="center">
                      <TextInput
                        id="ask"
                        name="ask"
                        plain
                        placeholder={translate(
                          'registration.placeholders.askingCredit'
                        )}
                        disabled={loading}
                        type="number"
                        value={ask ? ask : ''}
                        onChange={event => {
                          this.handleUpdate({
                            target: { name: 'ask', value: event.target.value }
                          });
                        }}
                      />
                    </Box>
                  </FormField>
                </Box>

                <Box
                  direction="row-responsive"
                  gap="medium"
                  margin={{ bottom: 'small' }}
                  align="center"
                >
                  <FormField
                    style={{ flex: 1 }}
                    label={
                      <Translate id="registration.labels.depositAmount">
                        Deposit amount
                      </Translate>
                    }
                    htmlFor="deposit"
                    error={
                      errors['deposit'] && translate('errors.requiredField')
                    }
                  >
                    <Box direction="row" align="center">
                      <TextInput
                        id="deposit"
                        name="deposit"
                        plain
                        type="number"
                        placeholder={translate(
                          'registration.placeholders.depositAmount'
                        )}
                        disabled={loading}
                        value={deposit ? deposit : ''}
                        onChange={event => {
                          this.handleUpdate({
                            target: {
                              name: 'deposit',
                              value: event.target.value
                            }
                          });
                        }}
                      />
                    </Box>
                  </FormField>

                  <FormField
                    style={{ flex: 1 }}
                    label={
                      <Translate id="registration.labels.autotrade">
                        Autotrade
                      </Translate>
                    }
                    htmlFor="cashback"
                    error={
                      errors['cashback'] && translate('errors.requiredField')
                    }
                  >
                    <Select
                      id="cashback"
                      name="cashback"
                      plain
                      placeholder={translate(
                        'registration.placeholders.cashback'
                      )}
                      options={[
                        translate('global.no'),
                        translate('global.yes')
                      ]}
                      disabled={loading}
                      value={
                        cashback
                          ? translate(
                              cashback === 'No' ? 'global.no' : 'global.yes'
                            )
                          : ''
                      }
                      onChange={({ option }) => {
                        this.handleUpdate({
                          target: { name: 'cashback', value: option }
                        });
                      }}
                    />
                  </FormField>

                  <FormField
                    style={{ flex: 1 }}
                    label={
                      <Translate id="registration.labels.leverage">
                        Leverage
                      </Translate>
                    }
                    htmlFor="leverage"
                    error={
                      errors['leverage'] && translate('errors.requiredField')
                    }
                  >
                    <Select
                      id="leverage"
                      name="leverage"
                      plain
                      placeholder={translate(
                        'registration.placeholders.leverage'
                      )}
                      options={['1:500', '1:400', '1:300', '1:200', '1:100']}
                      disabled={loading}
                      value={leverage ? leverage : ''}
                      onChange={({ option }) => {
                        this.handleUpdate({
                          target: { name: 'leverage', value: option }
                        });
                      }}
                    />
                  </FormField>
                </Box>
              </Box>
              <Box
                background="white"
                margin={{ left: 'small', top: '-10px' }}
                pad={{ horizontal: 'small' }}
              >
                <Text weight="bold">
                  <Translate id="global.accountSettings">
                    Account settings
                  </Translate>
                </Text>
              </Box>
            </Stack>

            <Stack anchor="top-left" margin={{ bottom: 'large' }}>
              <Box border={{ color: 'black' }} pad="small">
                <Box
                  direction="row-responsive"
                  gap="medium"
                  margin={{ bottom: 'small' }}
                  align="center"
                >
                  {newRegistration && (
                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="registration.labels.country">
                          Country
                        </Translate>
                      }
                      htmlFor="country"
                      error={
                        errors['country'] && translate('errors.requiredField')
                      }
                    >
                      <TextInput
                        id="country"
                        name="country"
                        type="text"
                        value={country ? country : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.country'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>
                  )}
                  <FormField
                    style={{ flex: 1 }}
                    label={
                      <Translate id="registration.labels.address">
                        Address
                      </Translate>
                    }
                    htmlFor="address"
                    error={
                      errors['address'] && translate('errors.requiredField')
                    }
                  >
                    <TextInput
                      id="address"
                      name="address"
                      type="text"
                      value={address ? address : ''}
                      disabled={loading}
                      placeholder={translate(
                        'registration.placeholders.address'
                      )}
                      onChange={this.handleUpdate}
                    />
                  </FormField>
                  <FormField
                    style={{ flex: 1 }}
                    label={
                      <Translate id="registration.labels.city">City</Translate>
                    }
                    htmlFor="city"
                    error={errors['city'] && translate('errors.requiredField')}
                  >
                    <TextInput
                      id="city"
                      name="city"
                      type="text"
                      value={city ? city : ''}
                      disabled={loading}
                      placeholder={translate('registration.placeholders.city')}
                      onChange={this.handleUpdate}
                    />
                  </FormField>
                </Box>

                <Box
                  direction="row-responsive"
                  gap="medium"
                  margin={{ bottom: 'small' }}
                  align="center"
                >
                  <FormField
                    style={{ minWidth: '245px' }}
                    label={
                      <Translate id="registration.labels.zipCode">
                        Zip code
                      </Translate>
                    }
                    htmlFor="zip"
                    error={errors['zip'] && translate('errors.requiredField')}
                  >
                    <TextInput
                      id="zip"
                      name="zip"
                      type="text"
                      value={zip ? zip : ''}
                      disabled={loading}
                      placeholder={translate(
                        'registration.placeholders.zipCode'
                      )}
                      onChange={this.handleUpdate}
                    />
                  </FormField>
                </Box>
              </Box>
              <Box
                background="white"
                margin={{ left: 'small', top: '-10px' }}
                pad={{ horizontal: 'small' }}
              >
                <Text weight="bold">
                  <Translate id="global.addressInformation">
                    Address information
                  </Translate>
                </Text>
              </Box>
            </Stack>

            <Box
              direction="row-responsive"
              pad={{ vertical: 'xsmall' }}
              gap="small"
            >
              <Button
                disabled={loading}
                label={<Translate id="global.submit">Submit</Translate>}
                type="submit"
                primary
                color="brand"
                fill
              />
            </Box>
          </Form>
        )}
      </Translate>
    );
  }
}
