export default class StorageUtils {
  static getStorageData = storageKey => {
    let storage =
      window &&
      window.localStorage &&
      JSON.parse(window.localStorage.getItem(storageKey));
    return storage || {};
  };

  static updateStorageData = (storageKey, key, value) => {
    const data = StorageUtils.getStorageData(storageKey);
    data[key] = value;
    StorageUtils.setStorageData(storageKey, data);
  };

  static setStorageData = (key, data) => {
    if (window && window.localStorage) {
      window.localStorage.setItem(key, JSON.stringify(data));
    }
  };
}
