import { LANGUAGE_STORAGE_KEY, BRAND_STORAGE_KEY, defaultLangCode } from '../config/site';

export function getCurrentLanguage() {
  if (window && window.localStorage) {
    const lang = window.localStorage.getItem(LANGUAGE_STORAGE_KEY);
    return lang ? lang : defaultLangCode;
  }
}

export function setCurrentLanguage(langCode = defaultLangCode) {
  return window && window.localStorage
    ? window.localStorage.setItem(LANGUAGE_STORAGE_KEY, langCode)
    : false;
}

export function getBrand() {
    if (window && window.localStorage) {
        return window.localStorage.getItem(BRAND_STORAGE_KEY);
    }
    return null;
}

export function setBrand(brand) {
    if (window && window.localStorage) {
        window.localStorage.setItem(BRAND_STORAGE_KEY, brand)
    }
}
