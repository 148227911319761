import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CheckBox, Text, TextInput } from 'grommet/es6';
import { isEmptyArray, sortRows, valueContainsSearchTerm } from '../data';

const CheckListFilter = props => {
  const [state, setState] = useState({
    placeholder: 'Select',
    selected: [],
    inputValue: '',
    filters: props.filters
  });

  useEffect(() => {
    const selected = Array.isArray(props.value) ? props.value : [];
    if (selected.length !== 0) {
      handleChange(selected);
    }
  }, []);

  const options = useMemo(() => {
    return getOptions(props.rows, state.inputValue, props.column.key);
  }, [props.rows, state.inputValue]);

  function columnValueContainsSearchTerms(columnValue, filterTermValue) {
    return props.columnValueContainsSearchTerms(columnValue, filterTermValue);
  }

  function getOptions(rows, inputValue = '', column) {
    let options = props.rows;

    if (props.getOptions) {
      options = props.getOptions(rows, column);
    }

    options = options
      .filter(o => !!o)
      .map(o => {
        if (typeof o === 'string') {
          return { value: o, label: o };
        }
        return o;
      });

    if (props.sortOptions) {
      options = props.sortOptions(options, 'label', props.sortOptionsDirection)(
        options
      );
    }

    if (inputValue.length) {
      options = props.filterOptionsCallback
        ? props.filterOptionsCallback(options, inputValue)
        : options.filter(o => {
            return (
              o.value &&
              o.label
                .toString()
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            );
          });
    }
    return options;
  }

  function filterValues(row, columnFilter, columnKey) {
    let include = true;
    if (columnFilter === null) {
      include = false;
    } else if (
      columnFilter.filterTerm &&
      !isEmptyArray(columnFilter.filterTerm)
    ) {
      if (columnFilter.filterTerm.length) {
        include = columnFilter.filterTerm.every(filterTerm => {
          return (
            columnValueContainsSearchTerms(row[columnKey], filterTerm) !== true
          );
        });
      } else {
        include = columnValueContainsSearchTerms(
          row[columnKey],
          columnFilter.filterTerm
        );
      }
    }
    return include;
  }

  /**
   * @param selected array of option values which are selected
   */
  function handleChange(selected) {
    setState({ ...state, filters: selected, selected });
    props.onChange({
      filterTerm: selected,
      column: props.column,
      rawValue: state.inputValue,
      filterValues
    });
  }

  function handleInputChange({ target: { value } }) {
    setState({ ...state, inputValue: value });
  }

  function onCheckUncheckAll(checkAll = false) {
    const selected = checkAll ? [] : options.map(option => option.value);
    handleChange(selected);
  }

  /**
   * On Option select
   */
  function onSelect(value) {
    const selected = state.selected;
    const index = selected.indexOf(value);
    if (index === -1) {
      selected.push(value);
    } else {
      selected.splice(index, 1);
    }
    handleChange(selected);
  }

  return (
    <Box className="form-group" justify="center">
      <TextInput
        dropHeight="medium"
        size="small"
        suggestions={[]
          .concat(
            props.showCheckUncheckAll && !state.inputValue.length
              ? [
                  { label: 'Check all', value: true },
                  { label: 'Uncheck all', value: false }
                ].map(val => ({
                  label: (
                    <Box
                      direction="row"
                      gap="small"
                      align="center"
                      pad="10px"
                      onClick={event => {
                        // Prevent drop from closing when select!
                        event.stopPropagation();
                        onCheckUncheckAll(val.value);
                      }}
                    >
                      <Text
                        style={{ marginLeft: '28px', width: '80px' }}
                        size="small"
                      >
                        {val.label}
                      </Text>
                    </Box>
                  )
                }))
              : []
          )
          .concat(
            options.map((option, index) => {
              return {
                label: (
                  <Box
                    direction="row"
                    gap="small"
                    align="center"
                    pad="10px"
                    onClick={event => {
                      // Prevent drop from closing when select!
                      event.stopPropagation();
                      onSelect(option.value);
                    }}
                  >
                    <CheckBox
                      tabIndex="-1"
                      className="checklistFilter-Option-Checkbox"
                      checked={state.selected.indexOf(option.value) === -1}
                      label={<Text size="small">{option.label}</Text>}
                      onChange={() => {}}
                      {...props.checkboxProps}
                    />
                  </Box>
                ),
                value: option.value
              };
            })
          )}
        type="text"
        plain
        focusIndicator={false}
        value={state.inputValue}
        placeholder={state.placeholder}
        onChange={handleInputChange}
        {...props.inputProps}
      />
    </Box>
  );
};

CheckListFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired,
  // Use this if you need to filter options based on different values. For example if this table column is using custom formatter so the displayed values may vary from real values
  filterOptionsCallback: PropTypes.func,
  showCheckUncheckAll: PropTypes.bool,
  enableSearch: PropTypes.bool,
  checkboxLabelRender: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string
  ]),
  checkboxProps: PropTypes.object,
  inputProps: PropTypes.object,
  rows: PropTypes.array,
  columnValueContainsSearchTerms: PropTypes.func.isRequired,
  getOptions: PropTypes.func,
  sortOptions: PropTypes.func,
  sortOptionsDirection: PropTypes.string
};

CheckListFilter.defaultProps = {
  checkboxLabelRender: (option, index) => option.label,
  showCheckUncheckAll: true,
  enableSearch: true,
  checkboxProps: {},
  inputProps: {},
  columnValueContainsSearchTerms: valueContainsSearchTerm,
  sortOptions: sortRows,
  sortOptionsDirection: 'ASC',
  rows: []
};

export default CheckListFilter;
