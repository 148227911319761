import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Form,
  TextInput,
  Text,
  FormField,
  Stack,
  CheckBox,
  Select
} from 'grommet';
import { Translate } from 'react-localize-redux';
import AccountService from "../../services/AccountService";
import Container from "../../utils/Container";

export class RegisterForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object,
    loading: PropTypes.bool,
    formData: PropTypes.object
  };

  static defaultProps = {
    loading: false,
    errors: {},
    formData: {
      ask: 100000,
      deposit: 50000,
      leverage: AccountService.LEVERAGE_500,
      group: AccountService.LIVE_USD_VIP,
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      formData: props.formData,
      agreement: true
    };
  }

  handleUpdate = event => {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: this.parseInput(event.target)
      }
    });
  };

  parseInput(target) {
    if (!target) {
      return false;
    }

    switch (target.type) {
      case 'checkbox':
        return target.checked;
      default:
        return target.value;
    }
  }

  handleSubmit = async event => {
    const result = await this.props.handleSubmit(event, this.state.formData);
    if (result) {
      this.setState({ formData: RegisterForm.defaultProps.formData });
    }
  };

  render() {
    const {
        email,
        password,
        password_repeat,
        first_name,
        phone,
        last_name,
        deposit,
        ask,
        leverage,
        group,
      } = this.state.formData,
      { errors, loading } = this.props;

    const container = Container.getInstance();
    const accountService = container.getAccountService();

    return (
      <Translate>
        {({ translate }) => (
          <Form
            style={{ width: '100%', flex: 1 }}
            name="real"
            className="signUpForm"
            onSubmit={this.handleSubmit}
          >
            <Stack anchor="top-left" margin={{ bottom: 'large' }}>
              <Box border={{ color: 'black' }} pad="small">
                  <Box
                    direction="row-responsive"
                    gap="medium"
                    margin={{ bottom: 'small' }}
                    align="center"
                  >
                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="registration.labels.firstName">
                          First name
                        </Translate>
                      }
                      htmlFor="first-name"
                      error={
                        errors['first_name'] &&
                        translate('errors.requiredField')
                      }
                    >
                      <TextInput
                        id="first-name"
                        name="first_name"
                        type="text"
                        value={first_name ? first_name : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.firstName'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>
                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="registration.labels.lastName">
                          Last name
                        </Translate>
                      }
                      htmlFor="last-name"
                      error={
                        errors['last_name'] && translate('errors.requiredField')
                      }
                    >
                      <TextInput
                        id="last-name"
                        name="last_name"
                        type="text"
                        value={last_name ? last_name : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.lastName'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>
                    <FormField
                      style={{ flex: 1 }}
                      label="Email"
                      htmlFor="email"
                      error={
                        errors['email'] && translate('errors.requiredField')
                      }
                    >
                      <TextInput
                        id="email"
                        name="email"
                        type="email"
                        value={email ? email : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.email'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>
                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <Translate id="registration.labels.phone">
                          Phone
                        </Translate>
                      }
                      htmlFor="phone"
                      error={
                        errors['phone'] && translate('errors.requiredField')
                      }
                    >
                      <TextInput
                        id="phone"
                        name="phone"
                        type="tel"
                        value={phone ? phone : ''}
                        disabled={loading}
                        placeholder={translate(
                          'registration.placeholders.phone'
                        )}
                        onChange={this.handleUpdate}
                      />
                    </FormField>
                  </Box>
                  <Box
                      direction="row-responsive"
                      gap="medium"
                      margin={{ bottom: 'small' }}
                      align="center"
                  >
                    <FormField
                        style={{ flex: 0.25 }}
                        label={
                          <Translate id="login.password">Password</Translate>
                        }
                        htmlFor="password"
                        error={
                          errors['password'] &&
                          translate(`errors.${errors.password}`)
                        }
                    >
                      <TextInput
                          id="password"
                          name="password"
                          type="password"
                          value={password ? password : ''}
                          disabled={loading}
                          placeholder={translate(
                              'registration.placeholders.password'
                          )}
                          onChange={this.handleUpdate}
                      />
                    </FormField>
                    <FormField
                        style={{ flex: 0.25 }}
                        label={
                          <Translate id="registration.labels.passwordRepeat">
                            Confirm password
                          </Translate>
                        }
                        htmlFor="password_repeat"
                        error={
                          errors['password_repeat'] &&
                          translate(`errors.${errors.password_repeat}`)
                        }
                    >
                      <TextInput
                          id="password_repeat"
                          name="password_repeat"
                          type="password"
                          value={password_repeat ? password_repeat : ''}
                          disabled={loading}
                          placeholder={translate(
                              'registration.placeholders.passwordRepeat'
                          )}
                          onChange={this.handleUpdate}
                      />
                    </FormField>
                  </Box>
              </Box>
              <Box
                background="white"
                margin={{ left: 'small', top: '-10px' }}
                pad={{ horizontal: 'small' }}
              >
                <Text weight="bold">
                  <Translate id="global.personalInformation">
                    Personal information
                  </Translate>
                </Text>
              </Box>
            </Stack>

            <Stack anchor="top-left" margin={{ bottom: 'large' }}>
              <Box border={{ color: 'black' }} pad="small">
                  <Box
                    direction="row-responsive"
                    gap="medium"
                    margin={{ bottom: 'small' }}
                    align="center"
                  >
                    <FormField
                      style={{ flex: 1 }}
                      label={
                        <>
                          <Translate id="registration.labels.askingCredit">
                            Asking credit
                          </Translate>
                          &nbsp;(USD)
                        </>
                      }
                      htmlFor="ask"
                      error={errors['ask'] && translate('errors.requiredField')}
                    >
                      <Box direction="row" align="center">
                        <TextInput
                          id="ask"
                          name="ask"
                          plain
                          placeholder="Amount of asked dotation"
                          disabled={loading}
                          type="number"
                          value={ask ? ask : ''}
                          onChange={event => {
                            this.handleUpdate({
                              target: { name: 'ask', value: event.target.value }
                            });
                          }}
                        />
                      </Box>
                    </FormField>
                    <FormField
                      style={{ flex: 1 }}
                      label="Payment amount (USD)"
                      htmlFor="deposit"
                      error={
                        errors['deposit'] && translate('errors.requiredField')
                      }
                    >
                      <Box direction="row" align="center">
                        <TextInput
                          id="deposit"
                          name="deposit"
                          plain
                          type="number"
                          placeholder="Deposit amount"
                          disabled={loading}
                          value={deposit ? deposit : ''}
                          onChange={event => {
                            this.handleUpdate({
                              target: {
                                name: 'deposit',
                                value: event.target.value
                              }
                            });
                          }}
                        />
                      </Box>
                    </FormField>
                    <FormField
                      style={{ flex: 1 }}
                      label="Leverage"
                      htmlFor="leverage"
                      error={
                        errors['leverage'] && translate('errors.requiredField')
                      }
                    >
                      <Select
                          placeholder="Choose leverage"
                          selected={[leverage]}
                          name="leverage"
                          valueLabel={<Box pad="8px">{accountService.getLeverageLabel(leverage)}</Box>}
                          options={AccountService.leverages.map(lev => ({label: accountService.getLeverageLabel(lev), value: lev}))}
                          dropHeight="medium"
                          onChange={({ option }) => {
                            this.handleUpdate({
                              target: { name: 'leverage', value: option.value }
                            });
                          }}
                      >
                        {(option, index) => {
                          return (
                              <Box direction="row" pad="10px">
                                {option.label}
                              </Box>
                          );
                        }}
                      </Select>
                    </FormField>
                    <FormField
                      style={{ flex: 1 }}
                      label="Account type"
                      htmlFor="group"
                      error={
                        errors['group'] && translate('errors.requiredField')
                      }
                    >
                      <Select
                          placeholder="Choose account type"
                          selected={[group]}
                          name="group"
                          valueLabel={<Box
                              pad="8px"
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap"
                                }}>
                              {accountService.getTypeLabel(group)}
                            </Box>
                          }
                          options={AccountService.accountTypes.map(type => ({label: accountService.getTypeLabel(type), value: type}))}
                          dropHeight="medium"
                          onChange={({ option }) => {
                            this.handleUpdate({
                              target: { name: 'group', value: option.value }
                            });
                          }}
                      >
                        {(option, index) => {
                          return (
                              <Box direction="row" pad="10px">
                                {option.label}
                              </Box>
                          );
                        }}
                      </Select>
                    </FormField>
                  </Box>
                </Box>
              <Box
                  background="white"
                  margin={{ left: 'small', top: '-10px' }}
                  pad={{ horizontal: 'small' }}
              >
                <Text weight="bold">
                  <Translate id="global.accountSettings">
                    Account settings
                  </Translate>
                </Text>
              </Box>
            </Stack>

            <Box margin={{bottom: "medium"}}>
              <CheckBox
                onClick={() => {
                  this.setState({agreement: !this.state.agreement})
                }}
                checked={this.state.agreement}
                label={<Text>
                By clicking on "Submit" bellow, I confirm that I've read, understood, accept and agree with<br/>
                  <Text as="a" color="brand" href="https://akosolutions.tradingacademy.club/pages/general-terms"
                      target="_blank">General terms</Text>.
              </Text>}
                />
            </Box>

            <Box
              direction="row-responsive"
              pad={{ vertical: 'xsmall' }}
              gap="small"
            >
              <Button
                disabled={loading || this.state.agreement === false}
                label={<Translate id="global.submit">Submit</Translate>}
                type="submit"
                primary
                color="brand"
                fill
              />
            </Box>
          </Form>
        )}
      </Translate>
    );
  }
}
