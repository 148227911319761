import React from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import Auth from '../../services/auth';
import { default as Withdrawals } from './Withdrawals';
import { default as BrokerWithdrawals } from './BrokerWithdrawals';
import { ACCOUNT_TYPES } from '../../config/site';

const FETCH_USER_WITHDRAWALS_AND_ACCOUNTS = gql`
  query clientBy($userId: String!) {
    clientBy(userId: $userId) {
      id
      mt_id
      userId
      accounts {
        nodes {
          id
          account_type
          account_currency
          user_id
          ask
          cashback
          deposit
          leverage
        }
      }
      withdrawals(first: 3000) {
        nodes {
          id
          account_id
          account {
            account_type
            account_currency
            ask
            mt_id
            cashback
            deposit
            id
            leverage
          }
          amount
          method
          created
          updated
          bank_account
          bank_name
          note
          status
        }
      }
    }
  }
`;

const FETCH_BROKER_WITHDRAWALS = gql`
  {
    withdrawals(first: 3000) {
      nodes {
        account {
          account_currency
          account_type
          ask
          cashback
          deposit
          mt_id
          id
          leverage
          user_id
        }
        account_id
        amount
        amount
        bank_account
        bank_name
        id
        note
        method
        created
        updated
        status
        withdrawal_id
        user_id
        user {
          email
          mt_id
          first_name
          last_name
        }
      }
    }
  }
`;

const UPDATE_WITHDRAWAL_REQUEST_MUTATION = gql`
  mutation updateWithdrawal($input: UpdateWithdrawalInput!) {
    updateWithdrawal(input: $input) {
      id
    }
  }
`;

const REMOVE_WITHDRAWAL_REQUEST_MUTATION = gql`
  mutation deleteWithdrawal($input: DeleteWithdrawalInput!) {
    deleteWithdrawal(input: $input) {
      deletedId
    }
  }
`;

const CREATE_WITHDRAWAL_REQUEST_MUTATION = gql`
  mutation createWithdrawal($input: CreateWithdrawalInput!) {
    createWithdrawal(input: $input) {
      id
    }
  }
`;

export const WithdrawalsConnected = props => (
  <Query
    query={FETCH_USER_WITHDRAWALS_AND_ACCOUNTS}
    variables={{ userId: Auth.getUser().userId }}
  >
    {({ loading, error, data }) => (
      <Mutation
        mutation={CREATE_WITHDRAWAL_REQUEST_MUTATION}
        refetchQueries={[
          {
            query: FETCH_USER_WITHDRAWALS_AND_ACCOUNTS,
            variables: { userId: Auth.getUser().userId }
          }
        ]}
      >
        {(createWithdrawal, createWithdrawalMutationProps) => (
          <Mutation
            mutation={UPDATE_WITHDRAWAL_REQUEST_MUTATION}
            refetchQueries={[
              {
                query: FETCH_USER_WITHDRAWALS_AND_ACCOUNTS,
                variables: { userId: Auth.getUser().userId }
              }
            ]}
          >
            {(updateWithdrawal, updateWithdrawalMutationProps) => {
              return (
                <Withdrawals
                  withdrawals={
                    data &&
                    data.clientBy &&
                    data.clientBy.withdrawals &&
                    data.clientBy.withdrawals.nodes
                  }
                  tradingAccounts={
                    data &&
                    data.clientBy &&
                    data.clientBy.accounts &&
                    data.clientBy.accounts.nodes.filter(item =>
                      ACCOUNT_TYPES.live.includes(item.account_type)
                    )
                  }
                  transferMethods={[
                    { label: 'Bankovní převod', value: 'bank_transfer' }
                  ]}
                  loading={
                    loading ||
                    createWithdrawalMutationProps.loading ||
                    updateWithdrawalMutationProps.loading
                  }
                  notification={
                    (error || createWithdrawalMutationProps.error) && {
                      text:
                        (error && error.message) ||
                        (createWithdrawalMutationProps.error &&
                          createWithdrawalMutationProps.error.message),
                      type: 'critical'
                    }
                  }
                  {...props}
                  user={data && data.clientBy}
                  createItem={async data =>
                    createWithdrawal({
                      variables: {
                        input: {
                          ...data,
                          clientMutationId: 'createWithdrawal' // WPGraphQL requires this
                        }
                      }
                    })
                  }
                  updateItem={async data =>
                    updateWithdrawal({
                      variables: {
                        input: {
                          ...data,
                          clientMutationId: 'updateWithdrawal' // WPGraphQL requires this
                        }
                      }
                    })
                  }
                />
              );
            }}
          </Mutation>
        )}
      </Mutation>
    )}
  </Query>
);

export const BrokerWithdrawalsConnected = props => (
  <Query query={FETCH_BROKER_WITHDRAWALS}>
    {({ loading, error, data }) => (
      <Mutation
        mutation={REMOVE_WITHDRAWAL_REQUEST_MUTATION}
        refetchQueries={[
          {
            query: FETCH_BROKER_WITHDRAWALS
          }
        ]}
      >
        {(removeWithdrawal, removeWithdrawalMutationProps) => (
          <Mutation
            mutation={UPDATE_WITHDRAWAL_REQUEST_MUTATION}
            refetchQueries={[{ query: FETCH_BROKER_WITHDRAWALS }]}
          >
            {(updateWithdrawal, updateWithdrawalMutationProps) => {
              return (
                <BrokerWithdrawals
                  withdrawals={
                    data && data.withdrawals && data.withdrawals.nodes
                  }
                  loading={
                    loading ||
                    updateWithdrawalMutationProps.loading ||
                    removeWithdrawalMutationProps.loading
                  }
                  notification={
                    (error ||
                      updateWithdrawalMutationProps.error ||
                      removeWithdrawalMutationProps.error) && {
                      text:
                        (error && error.message) ||
                        (updateWithdrawalMutationProps.error &&
                          updateWithdrawalMutationProps.error.message) ||
                        removeWithdrawalMutationProps.error.message,
                      type: 'critical'
                    }
                  }
                  {...props}
                  removeItem={async data =>
                    removeWithdrawal({
                      variables: {
                        input: {
                          id: data.id,
                          clientMutationId: 'removeWithdrawal' // WPGraphQL requires this
                        }
                      }
                    })
                  }
                  updateItem={async data =>
                    updateWithdrawal({
                      variables: {
                        input: {
                          ...data,
                          clientMutationId: 'updateWithdrawal' // WPGraphQL requires this
                        }
                      }
                    })
                  }
                />
              );
            }}
          </Mutation>
        )}
      </Mutation>
    )}
  </Query>
);
