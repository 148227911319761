import React from 'react';
import { Mutation } from 'react-apollo';
import { Registration } from './Registration';
import gql from 'graphql-tag';
import Container from "../../utils/Container";

const REGISTRATION_MUTATION = gql`
  mutation register($input: RegisterInput!) {
    register(input: $input) {
      response {
        content
        title
      }
    }
  }
`;

export const RegistrationConnected = props => {
  return (
    <Mutation mutation={REGISTRATION_MUTATION}>
      {(register, registerProps) => (
            <Registration
              loading={
                registerProps.loading
              }
              error={{
                ...register.error
              }}
              handleLogin={credentials =>
                  fetch(`${process.env.REACT_APP_AUTH_LOGIN_ENDPOINT}`, {
                    method: 'POST',
                    credentials: 'include', // include, *same-origin, omit
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      username: credentials.email,
                      password: credentials.password
                    })
                  }).then(response => {
                    if (response.ok) {
                      return response.json();
                    } else {
                      throw new Error('Invalid username or password');
                    }
                  })
              }
              register={data => {
                const container = Container.getInstance();
                const dataProvider = container.getDataProvider();
                return dataProvider.createLiveAccount(data);
              }}
              {...props}
            />
      )}
    </Mutation>
  );
};
