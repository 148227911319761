import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { default as Dashboard } from './Dashboard';
import { BrokerDashboard } from './BrokerDashboard';
import { gql } from 'apollo-boost';
import Auth from '../../services/auth';

const FETCH_TRADER_QUERY = gql`
  query clientBy($userId: String!) {
    clientBy(userId: $userId) {
      id
      mt_id
      email
      hasRealAccount
      accounts {
        nodes {
          id
          account_type
          account_currency
          user_id
          ask
          cashback
          deposit
          leverage
        }
      }
      documentsProvided
      documents {
        nodes {
          id
          document_id
          name
          path
          status
          uploaded
        }
      }
      first_name
      last_name
      address
      born
      city
      country
      education
      zip
      phone
      income
      experience
      registeredDate
    }

    companySettings {
      default_bank_account_CZK {
        account_id
        account_number
        bank_name
        currency
        iban
        account_id
        owner_name
        swift
        user_id
      }
      default_bank_account_EUR {
        account_id
        account_number
        bank_name
        currency
        iban
        account_id
        owner_name
        swift
        user_id
      }
      default_bank_account_USD {
        account_id
        account_number
        bank_name
        currency
        iban
        account_id
        owner_name
        swift
        user_id
      }
      user_id
      id
    }

    partnerBy(userId: $userId) {
      website
      link
      status
      created
      info
    }

    post(id: "cG9zdDoxMDkw") {
      status
      content
    }
  }
`;

const CREATE_PARTNER_MUTATION = gql`
  mutation createPartner($input: CreatePartnerInput!) {
    createPartner(input: $input) {
      id
    }
  }
`;

const BROKER_DASHBOARD_QUERY = gql`
  {
    statistics {
      activeLiveAccounts
      activeDemoAccounts
      depositAmountThisMonth
      depositCountThisMonth
      newUsersThisMonth
      activePartners
      readOnlyAccounts
    }
    pendingWithdrawals
  }
`;

export const ConnectedDashboard = props => {
  const userId = Auth.getUser().userId;

  return (
    <Mutation mutation={CREATE_PARTNER_MUTATION}>
      {(createPartner, createPartnerProps) => (
        <Query query={FETCH_TRADER_QUERY} variables={{ userId }}>
          {({ loading, error, data, refetch }) => {
            return (
              <Dashboard
                user={data && data.clientBy}
                partner={data && data.partnerBy}
                additionalContent={data && data.post}
                refetch={refetch}
                companySettings={data && data.companySettings}
                loading={loading}
                becomePartnerSubmit={data =>
                  createPartner({
                    variables: {
                      input: {
                        ...data,
                        userId,
                        clientMutationId: 'createPartner' // WPGraphQL requires this
                      }
                    },
                    refetchQueries: [
                      {
                        query: FETCH_TRADER_QUERY,
                        variables: { userId }
                      }
                    ]
                  })
                }
              />
            );
          }}
        </Query>
      )}
    </Mutation>
  );
};

export const ConnectedBrokerDashboard = props => {
  return (
    <Query query={BROKER_DASHBOARD_QUERY}>
      {({ loading, error, data }) => {
        return (
          <BrokerDashboard
            statistics={data && data.statistics}
            pendingWithdrawals={data && data.pendingWithdrawals}
            loading={loading}
          />
        );
      }}
    </Query>
  );
};
