import { getApiRoute } from '../config/routes';
import Auth from '../services/auth';
import moment from 'moment';

/**
 * Sorts array by date using Moment
 * @param arr Array input array
 * @param format string Format to parse dates from the array values
 * @param dateGetter function Getter to get date from the array values
 */
export function sortByDate(arr, format = null, dateGetter = item => item) {
  return arr.sort(
    (a, b) =>
      moment(dateGetter(a), format).format('YYYYMMDD') -
      moment(dateGetter(b), format).format('YYYYMMDD')
  );
}

/**
 * Returns object of options for born date form control
 * @return {{yearOptions: string[], dayOptions: string[], monthOptions: string[]}}
 */
export function getBornFormOptions() {
  const maxAge = 90;
  const currentYear = new Date().getFullYear();

  return {
    dayOptions: new Array(31)
      .fill(0)
      .map((item, index) => (index <= 8 ? '0' : '') + (index + 1)),
    monthOptions: new Array(12)
      .fill(0)
      .map((item, index) => (index <= 8 ? '0' : '') + (index + 1)),
    yearOptions: new Array(maxAge)
      .fill(0)
      .map((item, index) => (currentYear - (maxAge - index)).toString())
  };
}

/**
 * Performs a rest api request to fetch users by their mt id
 * @param mtValue
 * @return {Promise<any>}
 */
export const fetchUsersSuggestions = mtValue => {
  const url = getApiRoute('findUsers', [Auth.getAuthToken(), 'mt_id', mtValue]);
  return fetch(url, {
    method: 'GET',
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: { 'Content-Type': 'application/json' },
    redirect: 'follow',
    referrer: 'no-referrer'
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Could not fetch users');
      }
    })
    .catch(error => {
      return error;
    });
};

export const fetchUserAccount = mtValue => {
  const url = getApiRoute('getAccount');
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      user_id: mtValue
    }),
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: { 'Content-Type': 'application/json' },
    redirect: 'follow',
    referrer: 'no-referrer'
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Could not fetch trading account');
      }
    })
    .catch(error => {
      return error;
    });
};

export const fetchUser = mtValue => {
  const url = getApiRoute('findUsers', [Auth.getAuthToken(), 'mt_id', mtValue]);
  return fetch(url, {
    method: 'GET',
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: { 'Content-Type': 'application/json' },
    redirect: 'follow',
    referrer: 'no-referrer'
  })
    .then(response => {
      if (response.ok) {
        return response.json().then(data => {
          if (Array.isArray(data) && data.length > 0) {
            return data[0];
          }
          return data;
        });
      } else {
        throw new Error('Could not fetch user');
      }
    })
    .catch(error => {
      return error;
    });
};

export const hasUpperCase = str => {
  return /[A-Z]/.test(str);
};

export const hasNumber = str => {
  return /\d/.test(str);
};

export function getCookie(cname) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function consumeCookie(cname) {
  const cookie = getCookie(cname);
  if (cookie) {
    setCookie(cname, '');
  }
  return cookie;
}

export function setCookie(cname, cvalue, options = {}) {
  document.cookie =
    cname +
    '=' +
    cvalue +
    Object.values(options).reduce((carry, option) => {
      carry += ';' + option[0] + '=' + option[1];
      return carry;
    }, '');
}
