import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Layout } from '../../components/Layout';
import { BackLink } from '../../components/Link';
import { Box, Heading, Text } from 'grommet/es6';
import { Loader, Notification } from '../../components';
import { routes } from '../../config/routes';
import { useFlash } from '../../hooks';
import {
  getFilterRenderer,
  StyledRow,
  ToolbaredTable
} from '../../components/DataTable';
import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT } from '../../config/site';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SAVE_OPTION } from '../../redux/app';

function getEndpoint(key) {
  const endpoints = {
    viewTrader: routes
      .find(route => route.name === 'Broker')
      .childs.find(route => route.name === 'Traders')
      .childs.find(route => route.name === 'View').path,
    dashboard: routes.find(route => route.name === 'Dashboard').path
  };
  return endpoints[key];
}

const CustomRowRenderer = props => {
  const { row } = props;
  const { isGreen, isRed } = row;
  let className = '';
  if (isGreen) {
    className = 'green';
  }
  if (isRed) {
    className = 'red';
  }
  return (
    <StyledRow
      className={`account-history-row ${className}`}
      {...props}
    />
  );
};

const AccountHistory = props => {
  const hiddenColumns =
    useSelector(
      state => state.app.storage.options.accountHistoryCols,
      shallowEqual
    ) || props.hiddenColumns;
  const dispatch = useDispatch();
  const [rows, setRows] = useState(props.orders);
  const [totals, setTotals] = useState({
    profit: 0,
    volume: 0,
    deposits: 0,
    withdrawals: 0
  });
  const [statistics, setStatistics] = useState({
    green: { volume: 0, count: 0, profit: 0 },
    red: { volume: 0, count: 0, profit: 0 },
  });
  const [flash, , dismissFlash] = useFlash(
    props.notification.text,
    props.notification.type,
    !!props.notification.text
  );

  const hideColumns = nextState => {
    dispatch({
      type: SAVE_OPTION,
      key: 'accountHistoryCols',
      value: nextState
    });
  };

  useEffect(() => {
    setRows(props.orders);
  }, [props.orders]);

  const columns = useMemo(() => {
    return [
      {
        key: 'cmd',
        name: 'Direction',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'symbol',
        name: 'Symbol',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'volume',
        name: 'Volume',
        filterRenderer: getFilterRenderer('numeric')
      },
      {
        key: 'profit',
        name: 'Profit',
        filterRenderer: getFilterRenderer('numeric')
      },
      {
        key: 'order',
        name: 'Order',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'open_price',
        name: 'Open price',
        filterRenderer: getFilterRenderer('numeric')
      },
      {
        key: 'isGreen',
        name: 'Green',
        filterRenderer: getFilterRenderer('checklist', {
          rows: [
            { label: 'Yes', value: true },
            { label: 'No', value: value => value !== true }
          ]
        }),
        formatter: ({ row }) => {
          return row.isGreen ? 'Yes' : 'No';
        }
      },
      {
        key: 'open_time',
        name: 'Open time',
        width: 200,
        dateFormat: 'X',
        filterRenderer: getFilterRenderer('date', {
          datePicker: true,
          placeholder: 'Select Date/Range'
        }),
        formatter: ({ row }) =>
          row.open_time
            ? moment(row.open_time, 'X').format(DEFAULT_DATETIME_FORMAT)
            : null
      },
      {
        key: 'close_price',
        name: 'Close price',
        filterRenderer: getFilterRenderer('numeric')
      },
      {
        key: 'close_time',
        name: 'Close time',
        width: 200,
        dateFormat: 'X',
        filterRenderer: getFilterRenderer('date', {
          datePicker: true,
          placeholder: 'Select Date/Range'
        }),
        formatter: ({ row }) =>
          row.close_time
            ? moment(row.close_time, 'X').format(DEFAULT_DATETIME_FORMAT)
            : null
      },
      {
        key: 'comment',
        width: 250,
        name: 'Comment',
        filterRenderer: getFilterRenderer('text')
      },
      {
        key: 'sl',
        name: 'Stop loss',
        filterRenderer: getFilterRenderer('numeric')
      },
      {
        key: 'tp',
        name: 'Take profit',
        filterRenderer: getFilterRenderer('numeric')
      }
    ].map(col => {
      return {
        ...col,
        sortable: true,
        resizable: true
      };
    });
  }, []);

  function calculateStatistics(r) {
    const greenRows = r.filter(trade => {
      return trade['isGreen'] === true;
    });
    const redRows = r.filter(trade => {
      return trade['isRed'] === true;
    });
    setStatistics(
      [...greenRows, ...redRows].reduce(
        (acc, item) => {
          if (item.isGreen === true) {
            acc.green.count++;
            acc.green.profit += item.profit;
            acc.green.volume += parseFloat(item.volume || '0');
          }
          if (item.isRed === true) {
            acc.red.count++;
            acc.red.profit += item.profit;
            acc.red.volume += parseFloat(item.volume || '0');
          }
          return acc;
        },
        { green: { volume: 0, count: 0, profit: 0 }, red: { volume: 0, count: 0, profit: 0 } }
      )
    );

    setTotals(
      r.reduce(
        (acc, item) => {
          if (item.cmd === 'buy' || item.cmd === 'sell') {
            acc.profit +=
              parseFloat(item.profit || '0') + parseFloat(item.swap || '0');
            acc.volume += parseFloat(item.volume || '0');
          }

          const profit = parseFloat(item.profit || '0');

          if (item.cmd === 'deposit' && profit > 0) {
            acc.deposits += profit;
          } else if (item.cmd === 'deposit' && profit < 0) {
            acc.withdrawals += Math.abs(profit);
          }
          return acc;
        },
        { profit: 0, volume: 0, deposits: 0, withdrawals: 0 }
      )
    );
  }

  const { loading, backLink } = props;

  return (
    <Layout title="Account history" fluid>
      <BackLink link={backLink || getEndpoint('viewTrader').replace(':id', props.userId)} />
      <Box
        direction="row"
        justify="between"
        align="center"
        margin={{ vertical: 'medium' }}
      >
        <Box direction="row" align="center">
          <Heading size="small">Account history</Heading>
        </Box>
      </Box>
      {flash.visible && (
        <Notification
          type={flash.type ? flash.type : 'info'}
          onClose={dismissFlash}
          open={flash.visible}
          message={flash.text}
        />
      )}

      <Box direction="row" align="center">
        <Heading size="medium" level="3" margin={{ right: 'small' }}>
          {props.account && props.account.mt_id
            ? `ID: ${props.account.mt_id}`
            : ''}
          {loading && (
            <Box direction="row">
              Please wait <Loader />
            </Box>
          )}
        </Heading>
      </Box>

      <Box gap="small" margin={{ bottom: 'large' }}>
        <Box direction="row" gap="small">
          <Text as="label" size="large" color="dark-3">
            Total volume:
          </Text>
          <Text size="large" color={loading ? 'dark-4' : 'text'}>
            {loading ? '-' : totals.volume.toFixed(2)}
          </Text>
        </Box>

        <Box direction="row" gap="small">
          <Text as="label" size="large" color="dark-3">
            Total profit/loss:
          </Text>
          <Text size="large" color={loading ? 'dark-4' : 'text'}>
            {loading ? '-' : totals.profit.toFixed(2)}
          </Text>
        </Box>

        <Box direction="row" gap="small">
          <Text as="label" size="large" color="dark-3">
            Total deposits:
          </Text>
          <Text size="large" color={loading ? 'dark-4' : 'text'}>
            {loading ? '-' : totals.deposits.toFixed(2)}
          </Text>
        </Box>

        <Box direction="row" gap="small">
          <Text as="label" size="large" color="dark-3">
            Total withdrawals:
          </Text>
          <Text size="large" color={loading ? 'dark-4' : 'text'}>
            {loading ? '-' : totals.withdrawals.toFixed(2)}
          </Text>
        </Box>
      </Box>
      <div style={{ width: `100%`, height: '500px', position: 'relative' }}>
        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
          <ToolbaredTable
            toolbarProps={{
              children: (
                <Box direction="row-responsive" gap="small">
                  <Text title="Total condition not meeting orders | lots">
                    <span style={{ color: 'lime' }}>Green</span>:{' '}
                    <b>
                      Orders: {`${statistics.green.count}`} |{' '}
                      Volume: {`${statistics.green.volume.toFixed(2)}`} |{' '}
                      Profit: {`${statistics.green.profit.toFixed(2)}`}
                    </b>
                  </Text>
                  <Text title="Total condition not meeting orders | lots">
                    <span style={{ color: 'red' }}>Red</span>:{' '}
                    <b>
                      Orders: {`${statistics.red.count}`} |{' '}
                      Volume: {`${statistics.red.volume.toFixed(2)}`}
                    </b>
                  </Text>
                </Box>
              )
            }}
            toggleableColumns={columns}
            columns={columns}
            rows={rows}
            loading={loading}
            hiddenColumns={hiddenColumns}
            onRowsDisplay={calculateStatistics}
            onHideColumn={hideColumns}
            rowRenderer={CustomRowRenderer}
          />
        </div>
      </div>
    </Layout>
  );
};

AccountHistory.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  account: PropTypes.object,
  orders: PropTypes.array,
  hiddenColumns: PropTypes.array,
  loading: PropTypes.bool,
  brokerView: PropTypes.bool,
  notification: PropTypes.object,
  backLink: PropTypes.string
};

AccountHistory.defaultProps = {
  account: {},
  orders: [],
  hiddenColumns: [4, 5, 6, 7],
  brokerView: false,
  loading: false,
  notification: {
    text: '',
    type: 'info'
  }
};

export default AccountHistory;
