import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import bg from '../../images/bg.jpg';
import {
  CompanyLogo,
  Footer,
  LanguageToggle,
  LoginForm,
  MinimalLayout,
  Modal,
  Notification
} from '../../components';
import {
  Box,
  Button,
  Heading,
  ResponsiveContext,
  Text,
  TextInput
} from 'grommet';
import Auth, { USER_ROLES } from '../../services/auth';
import { setCurrentLanguage } from '../../services/translations';
import { Redirect } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import { routes } from '../../config/routes';

export class Login extends React.Component {
  static propTypes = {
    handleLogin: PropTypes.func.isRequired,
    handlePasswordReset: PropTypes.func,
    loading: PropTypes.bool,
    notification: PropTypes.object,
    loginRedirect: PropTypes.string
  };

  static defaultProps = {
    loginRedirect: '/',
    loading: false,
    notification: {}
  };

  constructor(props) {
    super(props);

    this.registrationRoute = routes.find(
      route => route.name === 'Registration'
    ).path;
    this.handleLogin = this.handleLogin.bind(this);
    this.handlePasswordReset = this.handlePasswordReset.bind(this);
    this.fadeOutRedirect = this.fadeOutRedirect.bind(this);
    this.dismissNotifications = this.dismissNotifications.bind(this);
    this.triggerModal = this.triggerModal.bind(this);

    this.state = {
      notification: props.notification,
      isFadingOut: false,
      animation: 'fadeIn',
      modal: {},
      formErrors: {}
    };
  }

  triggerModal(name, state = null) {
    if (name instanceof Event) name = name.target.name;

    this.setState({
      modal: { [name]: state === null ? !this.state.modal[name] : state }
    });
  }

  fadeOutRedirect(endpoint = '/') {
    this.setState({ isFadingOut: true, animation: 'fadeOut' });

    const { isFadingOut } = this.state;

    if (!isFadingOut) {
      setTimeout(() => {
        this.props.history.push(endpoint);
      }, 400);
    }
  }

  dismissNotifications() {
    this.setState({
      notification: {}
    });
  }

  async handleLogin(event) {
    const fields = ['email', 'password'],
      requiredFields = fields,
      errors = {},
      data = {};
    let valid = true;

    // Validation
    for (let i = 0; i < event.target.length; i++) {
      let { value, name } = event.target[i];

      if (fields.includes(name)) {
        data[name] = value;
      }

      if (requiredFields.includes(name) && value.length === 0) {
        errors[name] = `Please enter your ${name}.`;
        valid = false;
      }
    }

    if (!valid) {
      this.setState({ formErrors: errors });
      return false;
    }
    this.setState({ formErrors: {} });

    try {
      const result = await this.props.handleLogin(data);
      const { authToken, refreshToken, user } = result;

      Auth.login(authToken, refreshToken, user);
      Auth.setPersistentNonSensitiveUserData({
        email: user.email,
        name: user.name
      });

      let redirect = this.props.loginRedirect;
      if (Auth.roleCheckVerification(USER_ROLES.administrator)) {
        redirect = '/broker';
      }
      window.location.href = redirect;
    } catch (e) {
      this.setState({
        notification: { text: e.message, type: 'critical' },
        formErrors: {}
      });
    }
    return true; // Request has been processed
  }

  handlePasswordReset = async () => {
    // Validation
    if (
      !this.state.forgotPassEmail ||
      this.state.forgotPassEmail.length === 0
    ) {
      this.setState({
        formErrors: { forgotPassEmail: `Please enter your email.` }
      });
      return false;
    }
    this.setState({ formErrors: {} });

    if (!this.props.handlePasswordReset) {
      return true;
    }

    try {
      await this.props.handlePasswordReset({
        email: this.state.forgotPassEmail
      });
      this.setState({
        modal: { forgotPass: undefined },
        notification: { text: 'Link was sent to your email.', type: 'ok' },
        formErrors: {},
        forgotPassEmail: ''
      });
    } catch (e) {
      this.setState({
        notification: { text: e.message, type: 'critical' },
        formErrors: {}
      });
    }
    return true; // Request has been processed
  };

  render() {
    const { notification, animation, formErrors, forgotPassEmail } = this.state,
      { loading, loginRedirect } = this.props,
      { forgotPass } = this.state.modal;

    const isLoggedIn = !!Auth.getAuthToken();

    return isLoggedIn ? (
      <Redirect to={loginRedirect} />
    ) : (
      <Translate>
        {({ translate }) => (
          <MinimalLayout title={translate('login.login')}>
            <ResponsiveContext.Consumer>
              {size => (
                <Box
                  width="full"
                  align="center"
                  justify="center"
                  animation={animation}
                  height="full"
                  background={{ image: `url(${bg})` }}
                >
                  {!!notification.text && (
                    <Notification
                      type={`${notification.type ? notification.type : 'info'}`}
                      onClose={this.dismissNotifications}
                      open={!!notification.text}
                      message={notification.text}
                    />
                  )}

                  <Box
                    width={
                      ['medium', 'large'].includes(size) ? '400px' : 'full'
                    }
                    pad="large"
                    elevation="small"
                    margin={{ bottom: '70px' }}
                    background={'rgba(255,255,255,0.9)'}
                    justify="center"
                    align="center"
                  >
                    <Box
                      justify="center"
                      margin={{ bottom: 'large' }}
                      width="full"
                      direction="row"
                      align="center"
                      alignSelf="start"
                    >
                      <CompanyLogo
                        width="200px"
                        height="70px"
                        fit="contain"
                        href={config.read(['meta', 'webUrl'])}
                      />
                    </Box>

                    <Box
                      style={{ maxWidth: '300px', minHeight: '350px' }}
                      width="large"
                      align="center"
                    >
                      <Box
                        gap="small"
                        width="full"
                        margin={{ bottom: 'large' }}
                        justify="center"
                        align="center"
                      >
                        <Heading
                          margin="none"
                          level="2"
                          textAlign="center"
                          color="brand"
                        >
                          <Translate id="tradersRoom.title">
                            Client zone
                          </Translate>
                        </Heading>
                        <Heading
                          margin="none"
                          size="small"
                          textAlign="center"
                          level="3"
                        >
                          <Translate id="login.welcome">
                            Welcome. Please sign in.
                          </Translate>
                        </Heading>
                      </Box>

                      <Box width="full">
                        <LoginForm
                          handleSubmit={this.handleLogin}
                          loading={loading}
                          errors={formErrors}
                          onLostPassword={() =>
                            this.triggerModal('forgotPass', true)
                          }
                        />

                        <Box align="center" margin={{ top: 'medium' }}>
                          <LanguageToggle
                            onLanguageChange={setCurrentLanguage}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    as="footer"
                    gridArea="footer"
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0
                    }}
                    height="80px"
                  >
                    <Footer color="white" />
                  </Box>

                  {/* Renew password modal */}
                  <Modal
                    name="forgotPass"
                    open={forgotPass}
                    heading={translate('login.passwordRenew')}
                    onClose={this.triggerModal}
                    onClickOutside={this.triggerModal}
                  >
                    <Text margin={{ bottom: 'medium' }}>
                      {translate('login.enterEmailResetPassword')}
                    </Text>
                    <Box
                      gap="medium"
                      align="center"
                      justify="center"
                      pad="medium"
                    >
                      <TextInput
                        type="email"
                        name="forgotPassEmail"
                        placeholder={translate(
                          'registration.placeholders.email'
                        )}
                        value={forgotPassEmail}
                        style={
                          formErrors.forgotPassEmail
                            ? { borderColor: 'red' }
                            : {}
                        }
                        disabled={loading}
                        onChange={event =>
                          this.setState({ forgotPassEmail: event.target.value })
                        }
                      />
                      <Button
                        onClick={this.handlePasswordReset}
                        disabled={loading}
                        primary
                        color="status-ok"
                        label={translate('global.submit')}
                      />
                    </Box>
                  </Modal>
                </Box>
              )}
            </ResponsiveContext.Consumer>
          </MinimalLayout>
        )}
      </Translate>
    );
  }
}
