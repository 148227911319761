import { combineReducers, createStore } from 'redux';
import makeAppReducer from './app';

const store = (initialState => {
  return createStore(
    combineReducers({
      app: makeAppReducer(initialState.app)
    })
  );
})({
  app: {}
});

export default store;
