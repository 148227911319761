import React from 'react';
import PropTypes from 'prop-types';
import { Select, Box, Image, Text } from 'grommet';
import { withLocalize } from 'react-localize-redux';
import { flags } from './';

export class LanguageToggle extends React.Component {
  static propTypes = {
    languages: PropTypes.array,
    activeLanguage: PropTypes.object,
    setActiveLanguage: PropTypes.func,
    onLanguageChange: PropTypes.func
  };

  static defaultProps = {
    languages: [],
    activeLanguage: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      activeLanguage: props.activeLanguage
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.activeLanguage.code !== this.state.activeLanguage.code &&
      this.props.onLanguageChange
    ) {
      this.props.onLanguageChange(this.state.activeLanguage.code);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.activeLanguage.code !== prevState.activeLanguage.code) {
      return { activeLanguage: nextProps.activeLanguage };
    }
    return {};
  }

  static getLangObject = (languages, code) => {
    return languages.find(lang => lang.code === code);
  };

  render() {
    const { languages, setActiveLanguage } = this.props,
      { activeLanguage } = this.state;

    return (
      <Select
        id="language-toggle"
        name="language-toggle"
        value={activeLanguage}
        options={languages}
        onChange={({ option }) => {
          setActiveLanguage(option.code);
          this.setState({ activeLanguage: option });
        }}
        labelKey={lang => (
          <Box direction="row" align="center">
            <Text>
              <Image src={flags[lang.code]} />
              &nbsp;{lang.name}
            </Text>
          </Box>
        )}
        valueKey="code"
        plain
        valueLabel={
          <Box overflow="hidden" align="center">
            {activeLanguage ? (
              <Text>
                <Image src={flags[activeLanguage.code]} />
                &nbsp;{activeLanguage.name}
              </Text>
            ) : (
              'Select language'
            )}
          </Box>
        }
      />
    );
  }
}

export const LocalizedLanguageToggle = withLocalize(LanguageToggle);
