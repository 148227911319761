import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import styled from 'styled-components';

const dotStyle = `
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #000;
    border-radius: 100%;
    -webkit-animation: bounce 2s infinite ease-in-out;
    animation: bounce 2s infinite ease-in-out;
    transition: background-color .6s
`;

const Dot1 = styled.div`
  ${dotStyle}
`;

const Dot2 = styled.div`
  ${dotStyle}
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
`;

const Spinner = styled.div`
  width: 20px;
  height: 20px;
  text-align: center;
  z-index: 9999999;
  -webkit-animation: rotate 2s infinite linear;
  animation: rotate 2s infinite linear;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes bounce {
    0%,
    100% {
      transform: scale(1);
    }

    50% {
      transform: scale(0);
    }
  }
`;

/*
const SpinnerMessage = styled.div`
    margin-top: 130px;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    color: #000;
    z-index: 9999999;
`;
*/

export const Loader = props => {
  const { showLogo, ...rest } = props;

  return (
    <Box justify="center" align="center" {...rest}>
      <Spinner>
        <Dot1 />
        <Dot2 />
      </Spinner>
    </Box>
  );
};

Loader.propTypes = {
  showLogo: PropTypes.bool
};

Loader.defaultProps = {
  showLogo: true
};
