import React from 'react';
import { Box, Button } from 'grommet/es6';
import { Link } from './index';
import { Previous } from 'grommet-icons';

export const BackLink = ({ link = '#' }) => {
  return (
    <Box
      width="30px"
      height="30px"
      flex={false}
      margin={{ vertical: 'medium' }}
    >
      <Link
        color="white"
        href={link}
        style={{ width: '100%', height: '100%', textAlign: 'center' }}
      >
        <Button
          primary
          align="center"
          color="dark-4"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          plain
          fill
          label={<Previous color="white" size="18px" />}
        />
      </Link>
    </Box>
  );
};
