import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Login } from './Login';
import { useSelector } from 'react-redux';

/*
const LOGIN_MUTATION = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      authToken
      refreshToken
      user {
        id
        userId
        roles
        email
        name
      }
    }
  }
`;
*/

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation sendPasswordResetEmail($input: SendPasswordResetEmailInput!) {
    sendPasswordResetEmail(input: $input) {
      user {
        id
      }
    }
  }
`;

export const LoginConnected = props => {
  const loading = useSelector(state => state.app.auth.authInProcess);

  return (
    <Mutation mutation={SEND_PASSWORD_RESET_EMAIL}>
      {(sendPasswordResetEmail, resetPassMutationProps) => (
        <Login
          loading={loading || resetPassMutationProps.loading}
          notification={
            resetPassMutationProps.error && {
              text:
                resetPassMutationProps.error &&
                resetPassMutationProps.error.message,
              type: 'critical'
            }
          }
          handlePasswordReset={data => {
            return sendPasswordResetEmail({
              variables: {
                input: {
                  username: data.email,
                  clientMutationId: 'sendPasswordResetEmail'
                }
              }
            });
          }}
          handleLogin={credentials =>
            fetch(`${process.env.REACT_APP_AUTH_LOGIN_ENDPOINT}`, {
              method: 'POST',
              credentials: 'include', // include, *same-origin, omit
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                username: credentials.email,
                password: credentials.password
              })
            }).then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Invalid username or password');
              }
            })
          }
          {...props}
        />
      )}
    </Mutation>
  );
};
