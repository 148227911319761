import { useEffect, useMemo, useRef, useState } from 'react';
import {SortDirection} from "react-virtualized";

export function handleFilterChange(filter) {
  return filters => {
    const newFilters = { ...filters };
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    return newFilters;
  };
}

export function getValidFilterValues(rows, columnId) {
  return rows
    .map(r => {
      return r && r[columnId];
    })
    .filter((item, i, a) => {
      return i === a.indexOf(item);
    });
}

export function isEmptyArray(t) {
  return Array.isArray(t) && 0 === t.length;
}

export function parseFilterTermValue(term) {
  return typeof term === 'object' ? term.rawValue : term;
}

export function valueContainsSearchTerm(value, filterTerm) {
  if (typeof filterTerm === 'function') {
    return filterTerm(value);
  }
  if (value !== undefined && value !== null && filterTerm !== undefined) {
    const strV = value.toString();
    const strTerm = filterTerm.toString();
    return (
      strV
        .trim()
        .toLowerCase()
        .indexOf(strTerm.trim().toLowerCase()) !== -1
    );
  }
  return false;
}

/**
 * Default sort strategy to pass to DataTable
 * @param initialRows
 * @param sortColumn
 * @param sortDirection
 * @return {function(*): *}
 */
export function sortRows(initialRows, sortColumn, sortDirection) {
  return rows => {
    if (sortDirection === 'NONE') return initialRows;

    if (sortDirection !== SortDirection.ASC && sortDirection !== SortDirection.DESC) return initialRows;

    const comparer = (a, b) => {
      let [_a, _b] = [a[sortColumn], b[sortColumn]];

      if (
        (sortColumn === 'referredBy')) {
        [_a, _b] = [a.referredBy, b.referredBy];
      }

      if (!_a) {
        _a = "0";
      }

      if (!_b) {
        _b = "0";
      }

      const dir = sortDirection === 'ASC' ? 1 : -1;

      if (_a > _b) {
        return 1 * dir;
      }

      if (_a < _b) {
        return -1 * dir;
      }

      return 0;
    };
    return [...rows].sort(comparer);
  };
}

/**
 * Table state management. This exists to free ourselves from shitty copy-pasting
 * ReactDataGrid state management in each fucking component that uses it.
 * @param props
 */
export function useTable(props) {
  const [rows, setRows] = useState(props.rows || []);
  const [hiddenColumns, setHiddenColumns] = useState(props.hiddenColumns || []);
  const [filters, setFilters] = useState(props.filters || {});
  const [enableFilters, setEnableFilters] = useState(
    props.enableFilters || !!props.filters
  );
  const [sortColumn, setSortColumn] = useState(props.sortColumn);
  const [sortDirection, setSortDirection] = useState(
    props.sortDirection || 'NONE'
  );

  const tableRef = useRef(null);

  const columns = useMemo(() => {
    if (hiddenColumns.length > 0) {
      return props.columns.filter((col, i) => hiddenColumns.indexOf(i) === -1);
    }
    return props.columns;
  }, [hiddenColumns, props.columns, props.hiddenColumns]);

  /**
   * Effects to allow state management override from outside
   */
  useEffect(() => {
    setHiddenColumns(props.hiddenColumns);
  }, [props.hiddenColumns]);

  useEffect(() => {
    setFilters(props.filters);
  }, [props.filters]);

  useEffect(() => {
    setEnableFilters(props.enableFilters);
  }, [props.enableFilters]);

  useEffect(() => {
    setSortDirection(props.sortDirection);
  }, [props.sortDirection]);

  useEffect(() => {
    setSortColumn(props.sortColumn);
  }, [props.sortColumn]);
  /**
   * Update rows if changed from outside or sort column is clicked
   */
  useEffect(() => {
    if (sortColumn && sortDirection !== 'NONE') {
      setRows(
        props.sortStrategy(props.rows, sortColumn, sortDirection)(props.rows)
      );
    } else {
      setRows(props.rows);
    }
  }, [props.rows, sortColumn, sortDirection]);

  return {
    filters,
    setFilters,
    enableFilters,
    setEnableFilters,
    sortColumn,
    setSortColumn,
    sortDirection,
    setSortDirection,
    tableRef,
    columns,
    hiddenColumns,
    setHiddenColumns,
    rows,
    setRows
  };
}
